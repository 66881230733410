<template>
  <div
    class="inline-flex items-center font-semibold whitespace-nowrap"
    :class="classes"
  >
    <svg v-if="dot" :class="dotClasses" fill="currentColor" viewBox="0 0 8 8">
      <circle cx="4" cy="4" r="3" />
    </svg>
    <slot />
  </div>
</template>
<script lang="ts" setup>
import { computed, PropType } from "vue";

export type BadgeColor =
  'white' |
  'gray' |
  'red' |
  'orange' |
  'yellow' |
  'green' |
  'teal' |
  'blue' |
  'indigo' |
  'purple' |
  'pink'

const shades: Record<BadgeColor, string> = {
  white: "bg-white text-gray-800 border-gray-200",
  gray: "bg-gray-100 text-gray-800 border-gray-200",
  red: "bg-red-100 text-red-800 border-red-200",
  orange: "bg-orange-100 text-orange-800 border-orange-200",
  yellow: "bg-yellow-100 text-yellow-800 border-yellow-200",
  green: "bg-green-100 text-green-800 border-green-200",
  teal: "bg-teal-100 text-teal-800 border-teal-200",
  blue: "bg-blue-100 text-blue-800 border-blue-200",
  indigo: "bg-indigo-100 text-indigo-800 border-indigo-200",
  purple: "bg-purple-100 text-purple-800 border-purple-200",
  pink: "bg-pink-100 text-pink-800 border-pink-200",
};

const props = defineProps({
  color: {
    type: String as PropType<BadgeColor>,
    default: 'gray'
  },
  rounded: Boolean,
  large: Boolean,
  dot: Boolean,
  border: Boolean
})

const classes = computed(() => {
  const shadeClasses = shades[props.color];
  return {
    [shadeClasses]: true,
    rounded: props.rounded,
    "rounded-full": !props.rounded,
    "px-3 py-0.5": props.large,
    "px-2.5 py-0.5": !props.large,
    "text-xs": !props.large,
    "text-sm": props.large,
    border: props.border,
  };
})

const dotClasses = computed(() => {
  return {
    "mr-1.5 h-2 w-2": true,
    "-ml-1": props.large && !props.rounded,
    "-ml-0.5": !props.large || (props.large && props.rounded),
  };
})

</script>
