<template>
  <div>
    <template v-if="auth.isFullyLoggedIn">
      <BackButton :subscription="subscription" />
      <div class="border-t my-4"></div>
    </template>
    <div>
      <PageTitle name="renewal"></PageTitle>
      <p class="mt-4">
        {{ $t("subscription-renewal.heading") }}
      </p>
    </div>
    <div v-if="showSuccessOverlay" class="py-16 md:py-24 px-4 text-center rounded-card border mt-4">
      <font-awesome-icon icon="check" class="text-success-600" size="4x"></font-awesome-icon>
      <div class="mt-6 font-display font-medium text-lg">
        {{ $t('subscription-renewal.success') }}
      </div>
    </div>
    <Spinner v-else-if="loading"></Spinner>
    <div v-else-if="subscription">
      <SubscriptionDetails :subscription="subscription" class="mt-4" />
      <form
        ref="form"
        @submit.prevent="submit"
        class="w-full flex flex-col md:flex-row"
      >
        <div class="flex-grow">
          <EmptyState v-if="!recommendations || recommendations.length === 0">
            {{ $t("subscription-renewal.no-recommendations") }}
          </EmptyState>
          <template v-else>
            <RecommendationPicker
              :title="$t('subscription-renewal.renewal-options.label')"
              v-model="selectedRecommendation"
              :recommendations="recommendations"
            />
            <ButtonBar class="mt-4">
              <ButtonWithLoadingIndicator
                class="submit btn btn-primary"
                type="submit"
                :disabled="submitting || !selectedRecommendation"
                :loading="submitting"
              >
                {{ $t("subscription-renewal.submit") }}
              </ButtonWithLoadingIndicator>
            </ButtonBar>
          </template>
        </div>
      </form>
      <AlertDialog
        ref="success"
        name="success"
        :negative-text="$t('app.back')"
        @negative="$router.back()"
      >
        {{ $t("subscription-renewal.success") }}
      </AlertDialog>
      <PopupWindow
        ref="popup"
        v-if="showPopup"
        :url="popupWindowUrl"
        :placeholder="$t('subscription-renewal.please-wait')"
        :title="$t('subscription-renewal.popup-window-title')"
        with-overlay
        target="renewal"
        @message="handlePopupMessage"
        @close="handlePopupClose"
      ></PopupWindow>
      <Overlay :show="waitingForPayment">
        <div class="text-white text-center max-w-xl p-4 z-20">
          <Spinner></Spinner>
          <p class="text-lg">{{$t('subscription-renewal.payment-processing')}}</p>
          <p class="text-xs mt-2">{{$t('subscription-renewal.payment-taking-too-long')}}
            <button type="button" class="underline inline-block" @click="stopWaitingForPayment">{{ $t('app.close') }}</button>
          </p>
        </div>
      </Overlay>
    </div>
    <AlertDialog
      name="error"
      :negative-text="$t('app.close')"
      :positive-text="$t('app.to-contact')"
      @positive="$router.push('/contact')"
    >
      {{ $t("subscription-renewal.error") }}
    </AlertDialog>
    <AlertDialog
      name="paymentCanceled"
      :negative-text="$t('app.close')"
    >
      {{$t('subscription-renewal.payment-canceled')}}
    </AlertDialog>
    <AlertDialog
      name="paymentError"
      :negative-text="$t('app.close')"
      :positive-text="$t('app.to-contact')"
      @positive="$router.push('/contact')"
    >
      {{$t('subscription-renewal.payment-error')}}
    </AlertDialog>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, ref } from 'vue'
import BackButton from "@/components/BackButton.vue";
import PageTitle from "@/components/PageTitle.vue";
import Spinner from "@/components/Spinner.vue";
import ButtonBar from "@/components/ButtonBar.vue";
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import AlertDialog from "@/components/AlertDialog.vue";
import PopupWindow from "@/components/PopupWindow.vue";
import { Recommendation } from "@/models/recommendation";
import { Subscription } from "@/models/subscription";
import EmptyState from "@/components/EmptyState.vue";
import { useRoute } from "vue-router";
import { useSubscription } from "@/pinia/orders";
import { useAuth } from "@/pinia/auth";
import { useVfm } from "@/plugins/vfm";
import { useTracker } from "@/plugins/tracker";
import { useAxios } from "@/plugins/axios";
import SubscriptionDetails from "@/components/SubscriptionDetails.vue";
import RecommendationPicker from "@/components/RecommendationPicker.vue";
import { useTenant } from "@/plugins/tenant";
import { AcceptRecommendationResult } from "@/models/acceptRecommendationResult";
import Overlay from "@/components/Overlay.vue";
import { CrossSellingOffer, PendingPayment } from "@/models/pendingPayment";
import CrossSellingOfferModal from "@/components/CrossSellingOfferModal.vue";

const route = useRoute()
const auth = useAuth()
const vfm = useVfm()
const tracker = useTracker()
const axios = useAxios()
const tenant = useTenant()

const subscriptionId = route.params.id as string

const { subscription, subscriptionLoading } = useSubscription(subscriptionId)

const recommendationsLoading = ref(false)
const submitting = ref(false)
const showPopup = ref(false)
const showSuccessOverlay = ref(false)
const popupWindowUrl = ref("")
const recommendations = ref<Recommendation[] | null>(null)
const selectedRecommendation = ref<Recommendation | null>(null)
const acceptRecommendationResult = ref<AcceptRecommendationResult | null>(null)
const waitingForPayment = ref(false)
const interval = ref<number | null>(null)

const loading = computed(() => subscriptionLoading.value || recommendationsLoading.value)

function showSuccess() {
  if (auth.isFullyLoggedIn) {
    vfm.show("success");
  } else {
    // show an overlay instead
    showSuccessOverlay.value = true
  }
}

async function checkPaymentStatus() {
  try {
    const paymentId = acceptRecommendationResult.value!.pendingPaymentId
    const response = await axios.get<PendingPayment>(`/subscriptions/${subscriptionId}/pending-payments/${paymentId}`,)

    if (response.data.status === 'pending') {
      return
    }

    if (response.data.status === 'success') {
      if (response.data.crossSellingOffer !== null) {
        vfm.show({
          component: CrossSellingOfferModal,
          bind: {
            subscription: subscription.value,
            offer: response.data.crossSellingOffer
          }
        })
      } else {
        showSuccess()
      }
    } else if (response.data.status === 'error') {
      vfm.show('paymentError')
    } else if (response.data.status === 'canceled') {
      vfm.show('paymentCanceled')
    }
    stopWaitingForPayment()
  } catch (error) {
    // TODO
  }
}

function waitForPayment() {
  waitingForPayment.value = true
  interval.value = setInterval(checkPaymentStatus, 1000) as unknown as number
}

function stopWaitingForPayment() {
  waitingForPayment.value = false
  if (interval.value) {
    clearInterval(interval.value)
  }
}

onBeforeUnmount(stopWaitingForPayment)

function handlePopupMessage() {
  showPopup.value = false;
  popupWindowUrl.value = "";
  if (acceptRecommendationResult.value?.crossSelling) {
    // when cross selling is enabled, we wait for a successful payment
    waitForPayment()
  } else {
    showSuccess();
  }
}

function handlePopupClose() {
  showPopup.value = false;
  popupWindowUrl.value = "";
}

async function submit() {
  try {
    submitting.value = true;
    await acceptRecommendation(selectedRecommendation.value!);
    tracker.trackEvent(
      "Subscription",
      "Renewal",
      subscription.value.publication
    );
  } catch (error) {
    vfm.show("error");
  } finally {
    submitting.value = false;
  }
}

async function loadRecommendations() {
  const category = route.query.category ?? tenant.renewalConfig.category
  const campaign = route.query.campaign
  try {
    recommendationsLoading.value = true;
    const response = await axios.get<Recommendation[]>(
      `/subscriptions/${subscriptionId}/recommendations`,
      {
        params: {
          category,
          campaign,
          source: 'renewal'
        },
      }
    );
    recommendations.value = response.data;
    if (recommendations.value.length) {
      selectedRecommendation.value = recommendations.value[0]
    }
  } catch (error) {
    vfm.show("error");
  } finally {
    recommendationsLoading.value = false;
  }
}

async function acceptRecommendation(recommendation: Recommendation) {
  showPopup.value = true;
  try {
    const category = route.query.category ?? tenant.renewalConfig.category
    const campaign = route.query.campaign
    const response = await axios.post<AcceptRecommendationResult>(
      `/subscriptions/${subscriptionId}/recommendations/${recommendation.recommendationId}/accept`,
      null,
      {
        params: {
          category,
          campaign,
          upgradeTime: recommendation.upgradeOption?.upgradeTime
        },
      }
    );
    const data = response.data;
    // TODO: what if showPopup is false but the service returns an url?
    if (showPopup && data.url) {
      acceptRecommendationResult.value = data
      popupWindowUrl.value = data.url;
    } else {
      showSuccess();
    }
  } catch (error) {
    showPopup.value = false
    popupWindowUrl.value = "";
    vfm.show("error");
  }
}

loadRecommendations()
</script>
