<template>
  <component
    :is="tag"
    :disabled="disabled || loading"
    :title="label"
    v-bind="$attrs"
  >
    <InlineIconSpinner v-if="loading" inverted />
    <font-awesome-icon v-else-if="icon" :icon="icon" />
    <span v-if="!iconOnly" class="ml-2">{{ label }}</span>
  </component>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<script lang="ts" setup>
import { computed } from "vue";
import InlineIconSpinner from "@/components/InlineIconSpinner.vue";
import { useTenant } from "@/plugins/tenant";
import { useI18n } from "vue-i18n";

const defaultIcons: Record<string, string | undefined> = {
  addresses: "address-card",
  "holiday-service": "pause",
  "opt-ins": "check-square",
  "payment-details": "credit-card",
  complaint: "exclamation-circle",
  "gift-certificate": "certificate",
  licenses: "id-badge",
  renewal: "redo",
  upgrade: "plus-square",
  download: "download",
  cancellation: "window-close",
  "cancellation-revocation": "window-close",
  contact: "life-ring",
  manage: "cog",
  invoice: "envelope",
  "external-link": undefined,
  "upgrade-phase": "arrow-up",
  "e-card": "gift-card",
  oauth: undefined,
};

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  iconOnly: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    required: true
  },
  tag: {
    type: String,
    default: "button"
  }
})

const tenant = useTenant()
const i18n = useI18n()

const label = computed(() => {
  const spec = tenant.buttonConfig[props.name];
  if (spec) {
    const translation = spec.translations[i18n.locale.value];
    if (translation) {
      return translation;
    }
  }
  return (i18n.tm("buttons") as any)[props.name];
})

const icon = computed(() => {
  const spec = tenant.buttonConfig[props.name];
  if (spec && spec.icon) {
    return spec.icon;
  }
  return defaultIcons[props.name];
})
</script>
