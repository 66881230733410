<template>
  <div>
    <template v-if="auth.isFullyLoggedIn">
      <BackButton :subscription="subscription" />
      <div class="border-t my-4"></div>
    </template>
    <div>
      <PageTitle name="upgrade"></PageTitle>
      <p class="mt-4">
        {{ $t("subscription-upgrade.heading") }}
      </p>
    </div>
    <div v-if="showSuccessOverlay" class="py-16 md:py-24 px-4 text-center rounded-card border mt-4">
        <font-awesome-icon icon="check" class="text-success-600" size="4x"></font-awesome-icon>
        <div class="mt-6 font-display font-medium text-lg">
          {{$t('subscription-upgrade.success')}}
        </div>
    </div>
    <Spinner v-else-if="loading"></Spinner>
    <div v-else>
      <SubscriptionDetails :subscription="subscription" class="mt-4" />
      <form
        ref="form"
        @submit.prevent="submit"
        class="w-full flex flex-col md:flex-row"
      >
        <div class="flex-grow">
          <EmptyState v-if="!recommendations || recommendations.length === 0">
            {{ $t("subscription-upgrade.no-recommendations") }}
          </EmptyState>
          <template v-else>
            <RecommendationPicker
              :title="$t('subscription-upgrade.upgrade-options.label')"
              v-model="selectedRecommendation"
              :recommendations="recommendations"
            />
            <ButtonBar class="mt-4">
              <ButtonWithLoadingIndicator
                class="submit btn btn-primary"
                type="submit"
                :disabled="submitting || !selectedRecommendation"
                :loading="submitting"
              >
                {{ $t("subscription-upgrade.submit") }}
              </ButtonWithLoadingIndicator>
            </ButtonBar>
          </template>
        </div>
      </form>
      <AlertDialog
        ref="success"
        name="success"
        :negative-text="$t('app.back')"
        @negative="$router.back()"
      >
        {{ $t("subscription-upgrade.success") }}
      </AlertDialog>
      <AlertDialog
        ref="error"
        name="error"
        :negative-text="$t('app.close')"
        :positive-text="$t('app.to-contact')"
        @positive="$router.push('/contact')"
      >
        {{ $t("subscription-upgrade.error") }}
      </AlertDialog>
      <PopupWindow
        ref="popup"
        v-if="showPopup"
        :url="popupWindowUrl"
        :placeholder="$t('subscription-upgrade.please-wait')"
        :title="$t('subscription-upgrade.popup-window-title')"
        with-overlay
        target="renewal"
        @message="handlePopupMessage"
        @close="handlePopupClose"
      ></PopupWindow>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineComponent, ref } from 'vue'
import BackButton from "@/components/BackButton.vue";
import PageTitle from "@/components/PageTitle.vue";
import Spinner from "@/components/Spinner.vue";
import ButtonBar from "@/components/ButtonBar.vue";
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import AlertDialog from "@/components/AlertDialog.vue";
import PopupWindow from "@/components/PopupWindow.vue";
import { Recommendation } from "@/models/recommendation";
import { Subscription } from "@/models/subscription";
import EmptyState from "@/components/EmptyState.vue";
import { useRoute } from "vue-router";
import { useSubscription } from "@/pinia/orders";
import { useAuth } from "@/pinia/auth";
import { useVfm } from "@/plugins/vfm";
import { useTracker } from "@/plugins/tracker";
import { useAxios } from "@/plugins/axios";
import SubscriptionDetails from "@/components/SubscriptionDetails.vue";
import RecommendationPicker from "@/components/RecommendationPicker.vue";

const route = useRoute()
const auth = useAuth()
const vfm = useVfm()
const tracker = useTracker()
const axios = useAxios()

const subscriptionId = route.params.id as string

const { subscription, subscriptionLoading } = useSubscription(subscriptionId)

const recommendationsLoading = ref(false)
const submitting = ref(false)
const showPopup = ref(false)
const showSuccessOverlay = ref(false)
const popupWindowUrl = ref("")
const recommendations = ref<Recommendation[] | null>(null)
const selectedRecommendation = ref<Recommendation | null>(null)

const loading = computed(() => subscriptionLoading.value || recommendationsLoading.value)

function showSuccess() {
  if (auth.isFullyLoggedIn) {
    vfm.show("success");
  } else {
    // show an overlay instead
    showSuccessOverlay.value = true
  }
}

function handlePopupMessage() {
  showPopup.value = false;
  popupWindowUrl.value = "";
  showSuccess();
}

function handlePopupClose() {
  showPopup.value = false;
  popupWindowUrl.value = "";
}

async function submit() {
  try {
    submitting.value = true;
    await acceptRecommendation(selectedRecommendation.value!);
    tracker.trackEvent(
      "Subscription",
      "Upgrade",
      subscription.value.publication
    );
  } catch (error) {
    vfm.show("error");
  } finally {
    submitting.value = false;
  }
}

async function loadRecommendations() {
  const category = route.query.category ?? "UPGRD"
  const campaign = route.query.campaign
  try {
    recommendationsLoading.value = true;
    const response = await axios.get<Recommendation[]>(
      `/subscriptions/${subscriptionId}/recommendations`,
      {
        params: {
          category,
          campaign,
          source: 'upgrade'
        },
      }
    );
    recommendations.value = response.data;
    if (recommendations.value.length) {
      selectedRecommendation.value = recommendations.value[0]
    }
  } catch (error) {
    vfm.show("error");
  } finally {
    recommendationsLoading.value = false;
  }
}

async function acceptRecommendation(recommendation: Recommendation) {
  showPopup.value = true;
  try {
    const category = route.query.category ?? "UPGRD"
    const campaign = route.query.campaign
    const response = await axios.post<any>(
      `/subscriptions/${subscriptionId}/recommendations/${recommendation.recommendationId}/accept`,
      null,
      {
        params: {
          category,
          campaign,
          upgradeTime: recommendation.upgradeOption?.upgradeTime
        },
      }
    );
    const data = response.data;
    // TODO: what if showPopup is false but the service returns an url?
    if (showPopup && data.url) {
      popupWindowUrl.value = data.url;
    } else {
      showSuccess();
    }
  } catch (error) {
    showPopup.value = false
    popupWindowUrl.value = "";
    vfm.show("error");
  }
}

loadRecommendations()
</script>
