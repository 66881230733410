<template>
  <div class="pb-4 md:pb-8">
    <h3 class="text-xl font-medium font-display">
      Ihre Mitgliedschaften
    </h3>
    <ul class="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2">
      <SubscriptionListItem
        v-for="subscription in orders.membershipSubscriptions"
        :key="subscription.id"
        :subscription="subscription"
      />
    </ul>
    <section class="mt-6">
      <h3 class="text-xl font-medium font-display">
        {{ $t("memberships.user-profiles.heading") }}
      </h3>
      <p>
        {{ $t("memberships.user-profiles.info") }}
      </p>
      <ul class="grid p-5 border rounded grid-automin-24 grid-gap-4 mt-4">
        <li class="text-blue-900 bg-blue-500 user hover:-translate-y-1">Ich</li>
        <li class="text-green-800 bg-green-500 user hover:-translate-y-1">
          Schatz
        </li>
        <li class="text-yellow-900 bg-yellow-500 user hover:-translate-y-1">
          Sohn
        </li>
        <li class="text-orange-900 bg-orange-400 user hover:-translate-y-1">
          Tochter
        </li>
        <li class="text-red-900 bg-red-400 user hover:-translate-y-1">Papa</li>
        <li class="text-gray-600 bg-gray-200 user hover:-translate-y-1">
          {{ $t("memberships.user-profiles.add") }}
        </li>
      </ul>
    </section>
  </div>
</template>

<script lang="ts" setup>
import SubscriptionListItem from "@/pages/orders/SubscriptionListItem.vue";
import { useOrders } from "@/pinia/orders";

const orders = useOrders()
</script>

<style lang="postcss" scoped>
.user {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.25) 100%
  );
  background-size: 150% 150%;
  @apply flex cursor-pointer items-center shadow justify-center rounded h-32 text-xl bg-right bg-no-repeat transition-all duration-100;
}

.user:hover {
  @apply shadow-lg;
}
</style>
