<template>
  <div>
    <Alert v-if="!tenant.supportsOptIns()" variant="error">
      <i18n-t keypath="opt-in-widget.disabled">
        <template #email
        ><a
          class="link"
          :href="'mailto:' + tenant.contactConfig.fallbackEmail"
        >{{ tenant.contactConfig.fallbackEmail }}</a
        ></template
        >
        <template #phone
        ><a
          class="link"
          :href="'tel:' + tenant.contactConfig.fallbackPhoneNumber"
        >{{ tenant.contactConfig.fallbackPhoneNumber }}</a
        ></template
        >
      </i18n-t>
    </Alert>
    <template v-else>
      <h3 class="font-display font-medium text-xl">
        {{ messages.title }}
      </h3>
      <div class="mt-2 prose prose--hard-break" v-html="messages.greetingHtml"></div>
      <hr class="my-4" />
      <div v-if="success">
        <alert variant="success" icon="check">
          <div class="prose prose--hard-break" v-html="successHtml"></div>
        </alert>
      </div>
      <form v-else ref="form" @submit.prevent="submit">
        <FormSelect
          :label="$t('bp-form.salutation.label')"
          :options="salutations"
          value-key="code"
          name-key="title"
          required
          class="w-32"
          v-model="formData.salutationCode"
        />
        <div class="flex flex-wrap -mx-2">
          <FormInput
            class="w-full md:w-1/2 px-2"
            :label="$t('bp-form.first-name.label')"
            max-length="35"
            v-model="formData.firstName"
            required
          />
          <FormInput
            class="w-full md:w-1/2 px-2"
            :label="$t('bp-form.last-name.label')"
            max-length="35"
            v-model="formData.lastName1"
            required
          />
        </div>
        <FormInput
          v-model="formData.email"
          type="email"
          required
          :label="$t('bp-form.email.label')"
        ></FormInput>
        <FormCheckbox class="mt-6" v-model="formData.optIn" required>
          {{ messages.disclaimer }}
        </FormCheckbox>
        <ButtonBar class="mt-6">
          <ButtonWithLoadingIndicator
            type="submit"
            :loading="submitting"
            class="btn btn-primary font-semibold"
          >
            {{ $t('opt-in-widget.submit') }}
          </ButtonWithLoadingIndicator>
        </ButtonBar>
      </form>
    </template>
    <AlertDialog name="error" :negative-text="$t('app.close')">
      {{ $t("app.error") }}
    </AlertDialog>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import Alert from "@/components/Alert.vue";
import { useAxios } from "@/plugins/axios";
import { useTracker } from "@/plugins/tracker";
import { useVfm } from "@/plugins/vfm";
import FormInput from "@/components/form/FormInput.vue";
import ButtonBar from "@/components/ButtonBar.vue";
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import { useTenant } from "@/plugins/tenant";
import FormCheckbox from "@/components/form/FormCheckbox.vue";
import FormSelect from "@/components/form/FormSelect.vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import AlertDialog from "@/components/AlertDialog.vue";
import pupa from "pupa";

const axios = useAxios()
const tracker = useTracker()
const vfm = useVfm()
const tenant = useTenant()
const route = useRoute()
const { t } = useI18n()

const voucherCodeKey = route.query[tenant.optInConfig.voucherCodeParam] ?? null
const provider = route.query[tenant.optInConfig.providerParam] as string ?? null

const salutationCode = route.query['salutationcode'] ?? null
const firstName = route.query['firstname'] as string ?? ""
const lastName1 = route.query['lastname'] as string ?? ""
const email = route.query['email'] as string ?? ""

const submitting = ref(false)
const success = ref(false)
const voucherCode = ref<string|null>(null)

const formData = ref({
  salutationCode: salutationCode,
  firstName: firstName,
  lastName1: lastName1,
  email: email,
  optIn: false,
  voucherCodeKey: voucherCodeKey,
  provider: provider
})

const salutations = computed(() => {
    return tenant.salutations.filter(
      (salutation) => salutation.isUserSelectable)
})

const defaultMessages = computed(() => {
  return tenant.optInConfig.messages.find((messages) => messages.provider === 'default')!
})

const messages = computed(() => {
  return tenant.optInConfig.messages.find((messages) => messages.provider === provider) ?? defaultMessages.value
})

const successHtml = computed(() => {
  return pupa(messages.value.successHtml, {
    voucherCode: voucherCode.value ?? ''
  })
})

async function submit() {
  try {
    submitting.value = true;
    const response = await axios.post<{voucherCode: string|null}>('/opt-in', formData.value);
    success.value = true;
    voucherCode.value = response.data.voucherCode

    tracker.trackEvent("OptInWidget", "Submit");
  } catch (error: any) {
    vfm.show("error");
  } finally {
    submitting.value = false;
  }
}

</script>
