<template>
  <div>
    <h3 class="text-xl font-medium font-display">
      {{ $t("business-partners.title") }}
    </h3>
    <p class="mt-2">{{ $t("business-partners.heading") }}</p>
    <div class="flex mt-2 relative">
      <input
        v-model="query"
        class="block w-full py-2 pl-9 pr-3 border rounded-input focus:shadow-outline focus:outline-none"
        type="search"
        :placeholder="$t('business-partners.search.placeholder')"
      />
      <div
        class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
      >
        <font-awesome-icon
          icon="search"
          :class="query.length !== 0 ? 'text-primary' : 'text-gray-400'"
        />
      </div>
      <FormSelect
        class="flex-shrink-0 ml-2"
        v-model="filter"
        :options="$tm('business-partners.filters.options')"
      ></FormSelect>
    </div>
    <EmptyState v-if="error" :text="$t('business-partners.loading-error')">
      <button
        type="button"
        class="btn btn-primary btn-small"
        @click="loadOrders"
      >
        {{ $t("app.try-again") }}
      </button>
    </EmptyState>
    <Spinner v-else-if="loading" />
    <ul class="grid list-none gap-4 grid-cols-1 mt-4" v-else>
      <BusinessPartnerListItem v-for="entry in filteredEntries" :entry="entry"
                               :key="entry.businessPartner.bpNumber"></BusinessPartnerListItem>
      <EmptyState v-if="filteredEntries.length === 0" :text="$t('business-partners.empty')" />
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import Spinner from "@/components/Spinner.vue";
import EmptyState from "@/components/EmptyState.vue";
import { useOrders } from "@/pinia/orders";
import { BusinessPartner, BusinessPartnerRole } from "@/models/businessPartner";
import { Subscription } from "@/models/subscription";
import BusinessPartnerListItem from "@/pages/business-partners/BusinessPartnerListItem.vue";
import FormSelect from "@/components/form/FormSelect.vue";
import Fuse from "fuse.js";

export interface SubscriptionWithRoles {
  subscription: Subscription
  roles: BusinessPartnerRole[]
}

export interface BusinessPartnerListEntry {
  businessPartner: BusinessPartner
  subscriptions: SubscriptionWithRoles[]
}

const orders = useOrders()
const loading = computed(() => orders.loading)
const error = computed(() => orders.error)

function loadOrders() {
  orders.fetch()
}

const entries = computed(() => {
  const entries: Map<string, BusinessPartnerListEntry> = new Map()
  for (const subscription of orders.subscriptions) {
    for (const businessPartner of subscription.businessPartners) {
      if (!entries.has(businessPartner.bpNumber)) {
        // insert entry
        entries.set(businessPartner.bpNumber, {
          businessPartner,
          subscriptions: [
            {
              subscription,
              roles: [businessPartner.role!]
            }
          ]
        })
      } else {
        // merge
        const existingEntry = entries.get(businessPartner.bpNumber)!
        const existingSubscription = existingEntry.subscriptions.find((s: SubscriptionWithRoles) => s.subscription.id === subscription.id)
        if (existingSubscription) {
          existingSubscription.roles.push(businessPartner.role!)
        } else {
          existingEntry.subscriptions.push({
            subscription,
            roles: [businessPartner.role!]
          })
        }
      }
    }
  }
  return Array.from(entries.values())
})

const fusedEntries = computed(() => {
  return new Fuse(entries.value, {
    ignoreLocation: true,
    keys: ['businessPartner.bpNumber', 'subscriptions.subscription.orderNumber', 'subscriptions.subscription.displayName']
  })
})

const filter = ref<BusinessPartnerRole | ''>('')
const query = ref('')

const filteredEntries = computed(() => {
  let localEntries = entries.value;

  if (query.value !== '') {
    const queryResult = fusedEntries.value.search(query.value);
    localEntries = queryResult.map((entry) => entry.item)
  }

  if (filter.value !== '') {
    localEntries = localEntries.filter((entry) => {
      return entry.subscriptions.some((sr) => sr.roles.includes(filter.value as BusinessPartnerRole))
    });
  }

  return localEntries
})


loadOrders()
</script>
