<template>
  <AlertDialog name="license-details"
               :negative-text="$t('app.close')"
  >
    <template #title>
      {{ $t('subscription-licenses.details') }}
    </template>
    <Spinner v-if="businessPartnerLoading"></Spinner>
    <div v-else-if="businessPartner && license">
      <dl class="grid grid-cols-2">
        <dt>{{ $t('business-partner.email.label') }}:</dt>
        <dd>
          {{ license.businessPartner.emailAddress }}
        </dd>
        <dt>{{ $t('business-partner.status.label') }}:</dt>
        <dd>
          {{
            license.isActive ? $t('business-partner.status.active') : $t('business-partner.status.inactive')
          }}
        </dd>
        <dt>{{ $t('business-partner.reactivatable.label') }}:</dt>
        <dd>
          <Checkmark :value="license.isReactivatable"></Checkmark>
        </dd>
        <template v-if="showConfirmedStatus">
          <dt>{{ $t('business-partner.invitation-accepted.label') }}:</dt>
          <dd>
            <Checkmark :value="license.isConfirmed"></Checkmark>
          </dd>
        </template>
        <template v-if="showRole">
          <dt>{{ $t('bp-form.role.label') }}:</dt>
          <dd>
            {{ license.role || '-' }}
          </dd>
          <dt>{{ $t('bp-form.academic-year.label') }}:</dt>
          <dd>
            {{ license.academicYear || '-' }}
          </dd>
        </template>
      </dl>
      <template
        v-if="showAddress"
      >
        <FormSectionHeader
          class="mt-4"
          :title="$t('bp-form.name-and-address')"
        ></FormSectionHeader>
        <BusinessPartnerAddress class="mt-1" :business-partner="businessPartner" />
      </template>
    </div>
  </AlertDialog>
</template>

<script lang="ts" setup>
import { PropType, ref, watchEffect } from 'vue'
import Spinner from "@/components/Spinner.vue";
import Checkmark from "@/components/Checkmark.vue";
import FormSectionHeader from "@/components/form/FormSectionHeader.vue";
import BusinessPartnerAddress from "@/components/business-partner/BusinessPartnerAddress.vue";
import AlertDialog from "@/components/AlertDialog.vue";
import { MultiUserLicense } from "@/models/multiUserLicense";
import { BusinessPartner } from "@/models/businessPartner";
import { useVfm } from "@/plugins/vfm";
import { useAxios } from "@/plugins/axios";
import { Subscription } from "@/models/subscription";

const props = defineProps({
  license: {
    type: Object as PropType<MultiUserLicense | null>,
    default: null
  },
  subscription: {
    type: Subscription,
    default: undefined
  },
  orderNumber: {
    type: String,
    default: ''
  },
  showConfirmedStatus: {
    type: Boolean,
    default: false
  },
  showRole: {
    type: Boolean,
    default: false
  },
  showAddress: {
    type: Boolean,
    default: false
  }
})

const vfm = useVfm()
const axios = useAxios()

const businessPartner = ref<BusinessPartner | null>(null)
const businessPartnerLoading = ref<boolean>(false)

async function loadBusinessPartner() {
  try {
    businessPartnerLoading.value = true
    const response = await axios.get<BusinessPartner>(
      `/subscriptions/${props.subscription!.id}/objects/${props.orderNumber}/licenses/${props.license!.id}/business-partner`
    );
    businessPartner.value = response.data
  } catch (e) {
    console.log(e)
  } finally {
    businessPartnerLoading.value = false
  }
}

watchEffect(() => {
  if (props.license) {
    vfm.show("license-details");
    loadBusinessPartner()
  }
})
</script>
