import { FaqConfig } from "@/models/faqConfig";
import { FaqEntry } from "@/models/faqEntry";
import { ContactConfig } from "@/models/contactConfig";
import { GiftCertificateConfig } from "@/models/giftCertificateConfig";
import { PaymentMethodChangeConfig } from "@/models/paymentMethodChangeConfig";
import { Salutation } from "@/models/salutation";
import { NavigationLink } from "@/models/navigationLink";
import { AddressChangeConfig } from "@/models/addressChangeConfig";
import { DownloadConfig } from "@/models/downloadConfig";
import { ButtonConfig } from "@/models/buttonConfig";
import { ExternalSubscriptionLinkConfig } from "@/models/externalSubscriptionLinkConfig";
import { Subscription } from "@/models/subscription";
import { IssueCalendarConfig } from "@/models/issueCalendarConfig";
import { HolidayServiceConfig } from "@/models/holidayServiceConfig";
import { SubscriptionModel } from "@/models/subscriptionModel";
import { CancellationConfig } from "@/models/cancellationConfig";
import { PaymentHistoryConfig } from "@/models/paymentHistoryConfig";
import { AcademicTitle } from "@/models/academicTitle";
import { IssueComplaintConfig } from "@/models/issueComplaintConfig";
import { EmailWidgetConfig } from "@/models/emailWidgetConfig";
import { LinkSubscriptionConfig } from "@/models/linkSubscriptionConfig";
import { OrderAttributesConfig } from "@/models/orderAttributesConfig";
import { ShippingStatusConfig } from "@/models/shippingStatusConfig";
import { RenewalConfig } from "@/models/renewalConfig";
import { ECardConfig } from "@/models/eCardConfig";
import { OptInConfig } from "@/models/optInConfig";
import { IssueHistoryConfig } from "@/models/issueHistoryConfig";
import { BundleConfig } from "@/models/bundleConfig";

export enum Feature {
  Cancellation = "cancellation",
  Membership = "membership",
  IssueDownload = "issue_download",
  PurchaseOrderDownload = "purchase_order_download",
  AddressLines = "address_lines",
  ManualRegistration = "manual_registration",
  AddressChange = "address_change",
  HolidayRedirect = "holiday_redirect",
  HolidayBreak = "holiday_break",
  HolidayDownload = "holiday_download",
  IssueComplaint = "issue_complaint",
  PaymentHistory = "payment_history",
  ValidFrom = "valid_from",
  IssueHistory = "issue_history",
  IssueCalendar = "issue_calendar",
  OptIns = "opt_ins",
  PaymentMethodChange = "payment_method_change",
  FrequentlyAskedQuestions = "faq",
  Cominto = "cominto",
  GiftCertificate = "gift_certificate",
  InvoiceRequest = "invoice_request",
  InvoiceDownload = "invoice_download",
  ContactForm = "contact_form",
  BillingFrequency = "billing_frequency",
  Renewal = "renewal",
  PendingOrders = "pending_orders",
  Announcement = "announcement",
  ExternalNavigation = "external_navigation",
  MultiUserLicenses = "multi_user_licenses",
  EmailWidget = "email_widget",
  ExternalSubscriptionLink = "external_subscription_link",
  Oauth = "oauth",
  PhaseUpgrade = "phase_upgrade",
  ShippingStatus = "shipping_status",
  Upgrade = "upgrade",
  ECard = "e_card"
}

export class Tenant {
  readonly name: string;
  readonly logoUrl: string;
  readonly logoTargetUrl: string;
  readonly termsOfServiceUrl: string;
  readonly siteNoticeUrl: string;
  readonly privacyPolicyUrl: string;
  readonly contactUrl: string;
  readonly googleAnalyticsId: string;
  readonly gtmId: string;
  readonly matomoSiteId: number;
  readonly matomoTrackerUrl: string;
  readonly isEmbedded: boolean;
  readonly faqConfig: FaqConfig;
  readonly faqEntries: Array<FaqEntry>;
  readonly contactConfig: ContactConfig;
  readonly giftCertificateConfig: GiftCertificateConfig;
  readonly paymentMethodChangeConfig: PaymentMethodChangeConfig;
  readonly supportedFeatures: Set<string>;
  readonly defaultLocale: string;
  readonly defaultSubscriptionFilter: string;
  readonly coverImagePlaceholderUrl: string | null;
  readonly standaloneLoginMessage: string;
  readonly orderNumberExampleImageUrl: string;
  readonly hasMultipleAvsClients: boolean;
  readonly announcementConfig: AnnouncementConfig;
  readonly salutations: Salutation[];
  readonly academicTitles: AcademicTitle[];
  readonly externalNavigationLinks: NavigationLink[];
  readonly addressChangeConfig: AddressChangeConfig;
  readonly downloadConfig: DownloadConfig;
  readonly buttonConfig: ButtonConfig;
  readonly holidayServiceMixTypeBlacklist: string[];
  readonly issueCalendarConfig: IssueCalendarConfig;
  readonly holidayServiceConfig: HolidayServiceConfig;
  readonly multiUserLicensesConfig: MultiUserLicensesConfig;
  readonly externalSubscriptionLinkConfig: ExternalSubscriptionLinkConfig;
  readonly subscriptionModel: SubscriptionModel;
  readonly cancellationConfig: CancellationConfig;
  readonly paymentHistoryConfig: PaymentHistoryConfig;
  readonly issueComplaintConfig: IssueComplaintConfig;
  readonly emailWidgetConfig: EmailWidgetConfig;
  readonly linkSubscriptionConfig: LinkSubscriptionConfig;
  readonly shippingStatusConfig: ShippingStatusConfig;
  readonly renewalConfig: RenewalConfig;
  readonly orderAttributesConfig: OrderAttributesConfig;
  readonly eCardConfig: ECardConfig;
  readonly optInConfig: OptInConfig;
  readonly issueHistoryConfig: IssueHistoryConfig;
  readonly bundleConfig: BundleConfig;
  readonly translationOverrides: Record<string, string>;
  readonly theme: Theme;

  constructor(json: any) {
    this.name = json.name;
    this.logoUrl = json.logoUrl;
    this.logoTargetUrl = json.logoTargetUrl;
    this.termsOfServiceUrl = json.termsOfServiceUrl;
    this.siteNoticeUrl = json.siteNoticeUrl;
    this.privacyPolicyUrl = json.privacyPolicyUrl;
    this.contactUrl = json.contactUrl;
    this.googleAnalyticsId = json.googleAnalyticsId;
    this.gtmId = json.gtmId;
    this.matomoSiteId = json.matomoSiteId;
    this.matomoTrackerUrl = json.matomoTrackerUrl;
    this.isEmbedded = json.isEmbedded;
    this.faqConfig = json.faqConfig;
    this.faqEntries = json.faqEntries;
    this.contactConfig = json.contactConfig;
    this.giftCertificateConfig = json.giftCertificateConfig;
    this.supportedFeatures = new Set(json.supportedFeatures);
    this.defaultLocale = json.defaultLocale;
    this.paymentMethodChangeConfig = json.paymentMethodChangeConfig;
    this.standaloneLoginMessage = json.standaloneLoginMessage;
    this.orderNumberExampleImageUrl = json.orderNumberExampleImageUrl;
    this.hasMultipleAvsClients = json.hasMultipleAvsClients;
    this.announcementConfig = json.announcementConfig;
    this.salutations = json.salutations;
    this.academicTitles = json.academicTitles;
    this.externalNavigationLinks = json.externalNavigationLinks;
    this.addressChangeConfig = json.addressChangeConfig;
    this.downloadConfig = json.downloadConfig;
    this.holidayServiceMixTypeBlacklist = json.holidayServiceMixTypeBlacklist;
    this.externalSubscriptionLinkConfig = json.externalSubscriptionLinkConfig;
    this.issueCalendarConfig = json.issueCalendarConfig;
    this.holidayServiceConfig = json.holidayServiceConfig;
    this.multiUserLicensesConfig = json.multiUserLicensesConfig;
    this.subscriptionModel = json.subscriptionModel;
    this.cancellationConfig = json.cancellationConfig;
    this.paymentHistoryConfig = json.paymentHistoryConfig;
    this.issueComplaintConfig = json.issueComplaintConfig;
    this.emailWidgetConfig = json.emailWidgetConfig;
    this.linkSubscriptionConfig = json.linkSubscriptionConfig;
    this.orderAttributesConfig = json.orderAttributesConfig;
    this.shippingStatusConfig = json.shippingStatusConfig;
    this.renewalConfig = json.renewalConfig;
    this.translationOverrides = json.translationOverrides;
    this.defaultSubscriptionFilter = json.defaultSubscriptionFilter;
    this.coverImagePlaceholderUrl = json.coverImagePlaceholderUrl;
    this.eCardConfig = json.eCardConfig
    this.optInConfig = json.optInConfig
    this.issueHistoryConfig = json.issueHistoryConfig
    this.bundleConfig = json.bundleConfig
    this.theme = json.theme;
    this.buttonConfig = Array.isArray(json.buttonConfig)
      ? {}
      : json.buttonConfig;
  }

  getContactPhoneNumber(publication: string | null | undefined): string {
    if (publication && publication in this.contactConfig.phoneNumberTargets) {
      return this.contactConfig.phoneNumberTargets[publication];
    }
    return this.contactConfig.fallbackPhoneNumber;
  }

  getContactEmail(publication: string | null | undefined): string {
    if (publication && publication in this.contactConfig.emailTargets) {
      return this.contactConfig.emailTargets[publication];
    }
    return this.contactConfig.fallbackEmail;
  }

  getHolidayServiceMaxDuration(publication: string) {
    if (publication in this.holidayServiceConfig.maxDurations) {
      return this.holidayServiceConfig.maxDurations[publication];
    }
    return this.holidayServiceConfig.defaultMaxDuration;
  }

  supportsFeature(feature: string): boolean {
    return this.supportedFeatures.has(feature);
  }

  supportsCancellation(): boolean {
    return this.supportedFeatures.has(Feature.Cancellation);
  }

  supportsCancellationRevocation(): boolean {
    return this.supportedFeatures.has(Feature.Cancellation) && this.cancellationConfig.revocationContactActivity !== null;
  }

  supportsMembership(): boolean {
    return this.supportedFeatures.has(Feature.Membership);
  }

  supportsIssueDownload(): boolean {
    return this.supportedFeatures.has(Feature.IssueDownload);
  }

  supportsPurchaseOrderDownload(): boolean {
    return this.supportedFeatures.has(Feature.PurchaseOrderDownload);
  }

  supportsAddressLines(): boolean {
    return this.supportedFeatures.has(Feature.AddressLines);
  }

  supportsAddressChange(): boolean {
    return this.supportedFeatures.has(Feature.AddressChange);
  }

  supportsManualRegistration(): boolean {
    return this.supportedFeatures.has(Feature.ManualRegistration);
  }

  supportsHolidayRedirect(): boolean {
    return this.supportedFeatures.has(Feature.HolidayRedirect);
  }

  supportsHolidayBreak(): boolean {
    return this.supportedFeatures.has(Feature.HolidayBreak);
  }

  supportsHolidayDownload(): boolean {
    return this.supportedFeatures.has(Feature.HolidayDownload);
  }

  supportsHolidayService(mixType: string): boolean {
    return (
      (this.supportsHolidayBreak() ||
        this.supportsHolidayDownload() ||
        this.supportsHolidayRedirect()) &&
      !this.holidayServiceMixTypeBlacklist.includes(mixType)
    );
  }

  supportsIssueComplaint(publication: string): boolean {
    return this.supportedFeatures.has(Feature.IssueComplaint) && !this.issueComplaintConfig.blacklistedPublications.includes(publication);
  }

  supportsIssueHistory(publication: string, mixType: string): boolean {
    return this.supportedFeatures.has(Feature.IssueHistory)
      && !this.issueHistoryConfig.excludedPublications.find((excluded) => {
        return excluded.publication === publication && (excluded.mixType === null || excluded.mixType === mixType)
      });
  }

  supportsBundleSlaves(publication: string): boolean {
    return !this.bundleConfig.publicationsWithHiddenComponents.includes(publication)
  }

  supportsIssueCalendar(): boolean {
    return this.supportedFeatures.has(Feature.IssueCalendar);
  }

  supportsPaymentHistory(): boolean {
    return this.supportedFeatures.has(Feature.PaymentHistory);
  }

  supportsValidFrom(): boolean {
    return this.supportedFeatures.has(Feature.ValidFrom);
  }

  supportsOptIns(): boolean {
    return this.supportedFeatures.has(Feature.OptIns);
  }

  supportsPaymentMethodChange(): boolean {
    return this.supportedFeatures.has(Feature.PaymentMethodChange);
  }

  supportsFrequentlyAskedQuestions(): boolean {
    return this.supportedFeatures.has(Feature.FrequentlyAskedQuestions);
  }

  supportsGiftCertificate(): boolean {
    return (
      this.supportedFeatures.has(Feature.GiftCertificate) &&
      this.giftCertificateConfig != null
    );
  }

  supportsInvoiceRequest() {
    return this.supportedFeatures.has(Feature.InvoiceRequest);
  }

  supportsInvoiceDownload() {
    return this.supportedFeatures.has(Feature.InvoiceDownload);
  }

  supportsContactForm() {
    return this.supportedFeatures.has(Feature.ContactForm);
  }

  supportsBillingFrequency() {
    return this.supportedFeatures.has(Feature.BillingFrequency);
  }

  supportsRenewal() {
    return this.supportedFeatures.has(Feature.Renewal);
  }

  supportsPendingOrders() {
    return this.supportedFeatures.has(Feature.PendingOrders);
  }

  supportsAnnouncement() {
    return this.supportedFeatures.has(Feature.Announcement);
  }

  supportsExternalNavigation() {
    return this.supportedFeatures.has(Feature.ExternalNavigation);
  }

  supportsMultiUserLicenses() {
    return this.supportedFeatures.has(Feature.MultiUserLicenses);
  }

  supportsEmailWidget() {
    return this.supportedFeatures.has(Feature.EmailWidget);
  }

  supportsOAuth() {
    return this.supportedFeatures.has(Feature.Oauth);
  }

  supportsPhaseUpgrade() {
    return this.supportedFeatures.has(Feature.PhaseUpgrade);
  }

  supportsUpgrade() {
    return this.supportedFeatures.has(Feature.Upgrade);
  }

  supportsECard() {
    return this.supportedFeatures.has(Feature.ECard);
  }

  supportsShippingStatus() {
    return this.supportedFeatures.has(Feature.ShippingStatus);
  }

  supportsExternalSubscriptionLink(subscription: Subscription) {
    if (!this.supportedFeatures.has(Feature.ExternalSubscriptionLink)) {
      return false;
    }
    const publicationSupported =
      !this.externalSubscriptionLinkConfig.whitelistedPublications.length ||
      this.externalSubscriptionLinkConfig.whitelistedPublications.includes(
        subscription.publication
      );
    const statusSupported =
      !this.externalSubscriptionLinkConfig.showOnlyForActive ||
      subscription.isActive();
    return publicationSupported && statusSupported;
  }
}
