<template>
  <div>
    <div class="flex justify-end">
      <IssueCalendarModeSelector
        v-if="showModeSelector"
        v-model="mode"
      ></IssueCalendarModeSelector>
      <div class="flex-1"></div>
      <FormSelect
        class="flex-1 md:flex-none"
        v-model="selectedSubscription"
        value-key="id"
        name-key="displayName"
        :options="filterOptions"
        return-object
      ></FormSelect>
    </div>
    <Spinner v-if="loading" />
    <div v-else-if="hasIssues" class="mt-4">
      <IssueCalendarCalendar
        v-if="showCalendar"
        :issues="filteredIssues"
      ></IssueCalendarCalendar>
      <IssueCalendarListView
        v-if="showList"
        :issues="filteredIssues"
      ></IssueCalendarListView>
    </div>
    <EmptyState
      v-else-if="subscriptions.length === 0"
      :text="$t('issue-calendar.no-subscriptions')"
    />
    <EmptyState
      v-else
      data-testid="issue-calendar-empty"
      :text="$t('issue-calendar.empty')"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useTenant } from "@/plugins/tenant";
import { useAuthenticatedIssueCalendar } from "@/pinia/authenticatedIssueCalendar";
import { useI18n } from "vue-i18n";
import { IssueCalendarMode } from "@/models/issueCalendarConfig";
import { Subscription } from "@/models/subscription";
import { storeToRefs } from "pinia";
import EmptyState from "@/components/EmptyState.vue";
import IssueCalendarCalendar from "@/pages/issue-calendar/IssueCalendarCalendar.vue";
import IssueCalendarListView from "@/pages/issue-calendar/IssueCalendarListView.vue";
import Spinner from "@/components/Spinner.vue";
import IssueCalendarModeSelector from "@/pages/issue-calendar/IssueCalendarModeSelector.vue";
import FormSelect from "@/components/form/FormSelect.vue";

const tenant = useTenant()
const issueCalendar = useAuthenticatedIssueCalendar();
const i18n = useI18n()

const mode = ref<IssueCalendarMode>('calendar')
const selectedSubscription = ref<Subscription | null>(null)

// @ts-ignore
const { loading, issues, subscriptions } = storeToRefs(issueCalendar)

const hasIssues = computed(() => Array.isArray(issues.value) && issues.value.length !== 0)
const showCalendar = computed(() => mode.value === 'calendar')
const showList = computed(() => mode.value === 'list')
const filterOptions = computed(() => [
  { id: "", displayName: i18n.t("issue-calendar.all-subscriptions") },
  ...subscriptions.value,
])
const filteredIssues = computed(() => {
  if (
    selectedSubscription.value === null ||
    selectedSubscription.value.id === ""
  ) {
    return issues.value!;
  }
  return issues.value!.filter(
    (issue) => issue.subscription === selectedSubscription.value
  );
})
const showModeSelector = computed(() => tenant.issueCalendarConfig.mode === "any")

if (tenant.issueCalendarConfig.mode === "any") {
  mode.value = "calendar";
} else {
  mode.value = tenant.issueCalendarConfig.mode;
}

issueCalendar.fetch()
</script>
