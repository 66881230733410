import { CommunicationAttribute } from "@/models/communicationAttribute";

export interface BusinessPartner {
  role?: BusinessPartnerRole;
  bpNumber: string;
  title?: string;
  lastName?: string;
  lastName1?: string;
  lastName2?: string;
  firstName: string;
  fullLastName?: string;
  salutationCode: string;
  titleAcademic?: string;
  titleCodeAcademic?: string;
  salutation?: string;
  fullSalutation?: string;
  company1?: string;
  company2?: string;
  jobTitle?: string;
  department?: string;
  zipcode: string;
  street: string;
  streetSuffix?: string;
  streetNumber: string;
  streetNumberSuffix?: string;
  city: string;
  countryCode: string;
  country?: string;
  emailAddress?: string;
  phoneNumber?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  info?: string;
  validFrom?: Date;
  validTo?: Date;
  dateOfBirth?: Date|string;
  listOfAttribute?: Array<BusinessPartnerAttribute>;
  listOfCommunication: Array<CommunicationAttribute>;
  bpHolidayAddress?: BusinessPartner[];
  otherAddresses: BusinessPartner[];
}

export interface BusinessPartnerAttribute {
  type: string;
  value: string;
  description?: string;
}

export enum BusinessPartnerRole {
  WARENEMPFAENGER = "WE",
  RECHNUNGSEMPFAENGER = "RE",
  AUFTRAGGEBER = "AG",
  LIZENZVERWALTER = "LV",
}

export enum SalutationCode {
  Mr = "01",
  Ms = "02",
  Company = "04",
}

export function extractEmail(businessPartner: BusinessPartner): string | null {
  if (businessPartner.emailAddress) {
    return businessPartner.emailAddress;
  }
  if (businessPartner.listOfCommunication) {
    const attribute = businessPartner.listOfCommunication!.find(
      (attribute) =>
        attribute.communicationType === "WW" &&
        attribute.communicationDefault === "Y"
    );
    if (attribute) {
      return attribute.communicationValue
    }
  }
  return null;
}

type PartialNullable<T> = {
  [P in keyof T]?: T[P] | null;
};

/**
 * @param businessPartner The business partner to clone
 * @param whitelist If set to true, this will ignore all properties from the source business partner
 * that are not part of the defaults.
 */
export function cloneBusinessPartnerWithDefaults(
  businessPartner: Partial<BusinessPartner>,
  whitelist: boolean = false
): BusinessPartner {
  const defaults: PartialNullable<BusinessPartner> = {
    city: null,
    countryCode: null,
    firstName: null,
    lastName1: null,
    department: null,
    company1: null,
    jobTitle: null,
    emailAddress: null,
    phoneNumber: null,
    salutationCode: null,
    titleCodeAcademic: null,
    street: null,
    streetSuffix: null,
    streetNumber: null,
    streetNumberSuffix: null,
    zipcode: null,
    addressLine1: null,
    addressLine2: null,
    addressLine3: null,
    listOfCommunication: null,
    validFrom: null,
    otherAddresses: null,
  };
  if (whitelist) {
    for (const key in defaults) {
      if (key in businessPartner) {
        // @ts-ignore
        defaults[key] = businessPartner[key];
      }
    }
    return defaults as BusinessPartner;
  }
  return Object.assign(defaults, businessPartner) as BusinessPartner;
}
