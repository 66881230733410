import { OrderAttribute, OrderStatus, Subscription } from "@/models/subscription";
import { PurchaseOrder } from "@/models/purchaseOrder";
import { PendingOrder } from "@/models/pendingOrder";
import { acceptHMRUpdate, defineStore } from "pinia";
import axios from "axios";
import { CancelReason } from "@/models/cancelReason";
import { Payment } from "@/models/payment";
import { Issue } from "@/models/issue";
import { DirectDebitAccount, PaymentDetails } from "@/models/paymentDetails";
import { useBusinessPartners } from "@/pinia/businessPartners";
import { computed, ref } from "vue";
import { HolidayServiceType } from "@/models/holidayServiceType";
import { BusinessPartner, BusinessPartnerRole } from "@/models/businessPartner";
import { Publication } from "@/models/publication";
import { CancellationInfos } from "@/models/cancellationInfos";

export interface OrdersState {
  subscriptions: Subscription[];
  purchaseOrders: PurchaseOrder[];
  pendingOrders: PendingOrder[];
  bundleMasterMap: Map<string, string>; // maps bundle component order numbers to master order ids
  ordersComplete: boolean;
  loading: boolean;
  error: boolean;
  newSubscriptionAvailable: boolean;
}

export interface SetupHolidayServiceRequest {
  subscriptionId: string;
  service: HolidayServiceType;
  dateFrom: Date;
  dateTo: Date;
  issueNumberFrom: string;
  issueNumberTo: string;
  businessPartner: BusinessPartner | null;
}

export interface CancelHolidayServiceRequest {
  subscriptionId: string;
  service: HolidayServiceType;
}

export interface LinkSubscriptionRequest {
  publication: Publication;
  orderNumber: string;
  zipcode: string;
  lastName: string | null;
  preferredRole: BusinessPartnerRole | null
}

async function fetchSubscriptions() {
  const response = await axios.get<Array<any>>("/subscriptions");
  return response.data.map((o) => {
    return new Subscription(o);
  });
}

async function fetchPurchaseOrders() {
  const response = await axios.get<Array<PurchaseOrder>>(
    "/purchase-orders"
  );
  return response.data;
}

async function fetchPendingOrders() {
  const response = await axios.get<Array<PendingOrder>>("/pending-orders");
  return response.data;
}

async function fetchSubscription(id: string) {
  const response = await axios.get<any>("/subscriptions/" + id);
  return new Subscription(response.data);
}

export const useOrders = defineStore('orders', {
  state: (): OrdersState => ({
    subscriptions: [],
    purchaseOrders: [],
    pendingOrders: [],
    bundleMasterMap: new Map(),
    ordersComplete: false,
    loading: false,
    error: false,
    newSubscriptionAvailable: false,
  }),
  actions: {
    async fetch(): Promise<boolean> {
      if (this.ordersComplete || this.loading) {
        return false
      }
      this.loading = true
      this.error = false
      try {
        const supportsPendingOrders = this.tenant.supportsPendingOrders();
        // fetch subscriptions, purchase orders and pending orders
        let [subscriptions, purchaseOrders, pendingOrders] = await Promise.all([
          fetchSubscriptions(),
          fetchPurchaseOrders(),
          supportsPendingOrders ? fetchPendingOrders() : Promise.resolve([]),
        ]);

        // store the business partners separately
        const businessPartners = useBusinessPartners()
        businessPartners.addBusinessPartnersFromSubscriptions(subscriptions)

        if (pendingOrders.length !== 0) {
          // filter out pending orders that have been processed in the meantime
          const custOrderNumbers = new Set();
          for (const subscription of subscriptions) {
            custOrderNumbers.add(subscription.custOrderNumber);
          }
          for (const purchaseOrder of purchaseOrders) {
            custOrderNumbers.add(purchaseOrder.purchaseOrderId);
          }
          pendingOrders = pendingOrders.filter((pendingOrder) => {
            return !custOrderNumbers.has(pendingOrder.orderNumber);
          });
        }

        // build the bundle master map
        const bundleMasterMap = new Map();
        for (const subscription of subscriptions) {
          if (subscription.isBundleMaster()) {
            for (const bundle of subscription.bundles!) {
              bundleMasterMap.set(bundle.bundleOrderNumber, subscription.id)
            }
          }
        }

        this.$state = {
          subscriptions,
          purchaseOrders,
          pendingOrders,
          bundleMasterMap,
          newSubscriptionAvailable: false,
          ordersComplete: true,
          loading: false,
          error: false
        }

        return true;
      } catch (error) {
        this.$patch({
          error: true,
          loading: false
        })
      }
      return false;
    },
    async fetchSubscription(subscriptionId: string) {
      let subscription: Subscription = this.getSubscriptionById(
        subscriptionId
      );
      if (subscription) {
        return subscription;
      }
      subscription = await fetchSubscription(subscriptionId);
      this.putSubscription(subscription)
      return subscription
    },
    async linkSubscription(
      payload: LinkSubscriptionRequest
    ): Promise<Subscription> {
      const response = await axios.post<any>("/subscriptions", payload);
      const subscription = new Subscription(response.data);
      this.putSubscription(subscription)
      this.newSubscriptionAvailable = true
      return subscription
      /*commit("issueCalendar/reset", null, { root: true });*/
    },
    async setupHolidayService(
      request: SetupHolidayServiceRequest
    ): Promise<any> {
      const response = await axios.post<any>(
        `/subscriptions/${request.subscriptionId}/holiday-service`,
        request
      );
      const subscription = new Subscription(response.data);
      this.putSubscription(subscription)
      return subscription;
    },
    async cancelHolidayService(
      request: CancelHolidayServiceRequest
    ): Promise<any> {
      const response = await axios.post<any>(
        `/subscriptions/${request.subscriptionId}/holiday-service/cancel`,
        request
      );
      const subscription = new Subscription(response.data);
      this.putSubscription(subscription)
      return subscription;
    },
    async cancelSubscription(
      payload: {
        subscriptionId: string;
        reason: CancelReason | null;
        feedback: string;
        issue: Issue | null;
      }
    ): Promise<CancellationInfos> {

      const response = await axios.post<CancellationInfos>(`/subscriptions/${payload.subscriptionId}/cancel`, payload);
      const subscription: Subscription = this.getSubscriptionById(
        payload.subscriptionId
      );
      if (subscription) {
        subscription.orderStatus = OrderStatus.ACTIVE_KNOWN_TERMINATION_DATE;
        subscription.isCancelable = false
      }
      return response.data
    },
    // TODO: put these in a separate store?
    async fetchPaymentHistory(subscriptionId: string) {
      const subscription = this.getSubscriptionById(subscriptionId);
      const response = await axios.get<Array<Payment>>(
        `/subscriptions/${subscriptionId}/payment-history`
      );
      subscription.paymentHistory = response.data;
      // this.putSubscription(subscription)
    },
    async fetchDeliveredIssues(subscriptionId: string) {
      const subscription = this.getSubscriptionById(subscriptionId);
      const response = await axios.get<Array<Issue>>(
        `/subscriptions/${subscriptionId}/delivered-issues`
      );
      subscription.deliveredIssues = response.data;
      // this.putSubscription(subscription)
    },
    async fetchPaymentDetails(subscriptionId: string) {
      const subscription: Subscription = this.getSubscriptionById(subscriptionId);
      const response = await axios.get<PaymentDetails>(
        `/subscriptions/${subscriptionId}/payment-details`
      );
      subscription.paymentDetails = response.data;
      // this.putSubscription(subscription)
    },
    async fetchCurrentPhase(subscriptionId: string) {
      const subscription: Subscription = this.getSubscriptionById(subscriptionId);
      const response = await axios.get<SubscriptionPhase>(
        `/subscriptions/${subscriptionId}/current-phase`
      );
      subscription.currentPhase = response.data;
      // this.putSubscription(subscription)
    },
    async upgradePhase(subscriptionId: string) {
      const subscription: Subscription = this.getSubscriptionById(subscriptionId);
      const response = await axios.post<SubscriptionPhase>(
        `/subscriptions/${subscriptionId}/upgrade-phase`
      );
      subscription.currentPhase = response.data;
      // this.putSubscription(subscription)
    },
    // TODO: move this to their respective components
    async updateSepaPaymentDetails(
      payload: { subscriptionId: string; account: Partial<DirectDebitAccount> }
    ) {
      const subscription: Subscription = this.getSubscriptionById(
        payload.subscriptionId
      );
      await axios.post(
        `/subscriptions/${subscription.id}/payment-details/sepa`,
        payload.account
      );
      // TODO: update details locally?
    },
    async updateDirectDebitPaymentDetails(
      payload: { subscriptionId: string; account: Partial<DirectDebitAccount> }
    ) {
      const subscription: Subscription = this.getSubscriptionById(
        payload.subscriptionId
      );
      await axios.post(
        `/subscriptions/${subscription.id}/payment-details/direct-debit`,
        payload.account
      );
      // TODO: update details locally?
    },
    async updateAttribute(payload: { subscriptionId: string; attribute: OrderAttribute }) {
      const subscription: Subscription = this.getSubscriptionById(
        payload.subscriptionId
      );
      await axios.post(
        `/subscriptions/${subscription.id}/attributes`,
        payload.attribute
      );
      const attribute = subscription.orderAttributes.find((attribute) => attribute.type === payload.attribute.type)
      if (attribute) {
        // update the value locally
        attribute.value = payload.attribute.value
      } else {
        // this is a new attribute
        subscription.orderAttributes.push(payload.attribute)
      }
    },
    putSubscription(subscription: Subscription) {
      const index = this.subscriptions.findIndex(
        (s) => s.id == subscription.id
      );
      if (index !== -1) {
        this.subscriptions[index] = subscription;
      } else {
        // the subscription does not exist yet
        this.subscriptions.push(subscription)
        const businessPartners = useBusinessPartners()
        businessPartners.addBusinessPartnersFromSubscription(subscription)
      }
    },
    clearNewSubscriptionAvailable() {
      this.newSubscriptionAvailable = false;
    }
  },
  getters: {
    getSubscriptionById(state): ((id: string) => Subscription) {
      return (id: string): Subscription => state.subscriptions.find((subscription) => subscription.id === id)!
    },
    getSubscriptionsByBpNumber(state): ((id: string) => Subscription[]) {
      return (bpNumber: string): Subscription[] => state.subscriptions.filter((subscription) => subscription.businessPartners.some((bp) => bp.bpNumber === bpNumber))
    },
    getBundleMaster(state): ((orderNumber: string) => Subscription|null) {
      return (orderNumber: string): Subscription | null => {
        const masterId = state.bundleMasterMap.get(orderNumber)
        if (!masterId) {
          return null
        }
        return state.subscriptions.find((subscription) => subscription.id === masterId) ?? null
      }
    },
    hasSubscriptions(state): boolean {
      return state.subscriptions.length !== 0;
    },
    hasActiveSubscriptions(state): boolean {
      return state.subscriptions.some((subscription) =>
        subscription.isActiveStrict()
      );
    },
    hasOrders(state): boolean {
      return (
        state.subscriptions.length > 0 ||
        state.purchaseOrders.length > 0 ||
        state.pendingOrders.length > 0
      );
    },
    inactiveSubscriptions(state): Subscription[] {
      return state.subscriptions.filter(
        (subscription) => !subscription.isActive()
      );
    },
    activeSubscriptions(state): Subscription[] {
      return state.subscriptions.filter((subscription) =>
        subscription.isActive()
      );
    },
    membershipSubscriptions(state): Subscription[] {
      return state.subscriptions.filter((subscription) =>
        subscription.isMembership()
      );
    },
    custOrderNumbers(state): Set<string> {
      const custOrderNumbers = new Set<string>();
      for (const subscription of state.subscriptions) {
        custOrderNumbers.add(subscription.custOrderNumber);
      }
      for (const purchaseOrder of state.purchaseOrders) {
        custOrderNumbers.add(purchaseOrder.purchaseOrderId);
      }
      return custOrderNumbers;
    },
    regularSubscriptions(state): Subscription[] {
      return state.subscriptions.filter(
        (subscription) => !subscription.isMembership()
      );
    },
    listedSubscriptions(state): Subscription[] {
      return state.subscriptions.filter((subscription) => {
        if (subscription.isMembership()) {
          return false
        }
        if (subscription.isBundleSlave()) {
          // get the bundle master
          const master = this.getBundleMaster(subscription.orderNumber)
          if (master) {
            // check if the master's components should be listed
            return !this.tenant.bundleConfig.publicationsWithHiddenComponents.includes(master.publication)
          }
        }
        return true
      });
    },
  }
})

export function useSubscription(id: string) {
  const orders = useOrders()


  const subscription = computed(() => orders.getSubscriptionById(id))
  const subscriptionLoading = ref(false)
  const subscriptionError = ref(false)


  async function fetchSubscription() {
    try {
      subscriptionLoading.value = true
      await orders.fetchSubscription(id)
    } catch (e) {
      subscriptionError.value = true
    } finally {
      subscriptionLoading.value = false
    }
  }

  if (!subscription.value) {
    fetchSubscription()
  }

  return {
    subscription,
    subscriptionLoading,
  };
}

export function useSubscriptions() {
  const orders = useOrders()

  const subscriptions = computed(() => orders.subscriptions)
  orders.fetch()

  return {
    subscriptions,
    subscriptionsLoading: computed(() => orders.loading),
    getSubscriptionById: orders.getSubscriptionById
  };
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOrders, import.meta.hot))
}
