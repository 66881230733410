import { App, inject } from "vue";
import { Tenant } from "@/models/tenant";
import VueGtag, {trackRouter} from "vue-gtag-next";
import { createGtm } from '@gtm-support/vue-gtm';
import { Router } from "vue-router";
// @ts-ignore
import VueMatomo from 'vue-matomo'

export interface Tracker {
  trackEvent(category: string, action: string, name?: string, value?: number): void;
}

function installGoogleAnalytics(app: App, googleAnalyticsId: string, router: Router) {
  app.use(VueGtag, {
    property: {
      id: googleAnalyticsId
    },
    isEnabled: import.meta.env.PROD,
  });
  trackRouter(router)
}

function installGtm(app: App, gtmId: string, router: Router) {
  app.use(
    // https://www.npmjs.com/package/@gtm-support/vue-gtm#configuration
    createGtm({
      id: gtmId,
      // defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
      // compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      // debug: true, // Whether or not display console logs debugs (optional)
      // loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    })
  );
}

function installMatomo(app: App, siteId: number, host: string, router: Router) {
  app.use(VueMatomo, {
    host,
    siteId,
    router,
  });
}

export function useTracker(): Tracker {
  return inject('tracker')!
}

export default {
  install: (app: App, tenant: Tenant, router: Router) => {
    if (tenant.gtmId) {
      installGtm(app, tenant.gtmId, router);
    } else if (tenant.googleAnalyticsId) {
      installGoogleAnalytics(app, tenant.googleAnalyticsId, router);
    }

    if (tenant.matomoSiteId && tenant.matomoTrackerUrl) {
      installMatomo(app, tenant.matomoSiteId, tenant.matomoTrackerUrl, router);
    }

    function trackEvent(
      category: string,
      action: string,
      name?: string,
      value?: number
    ) {
      if (app.config.globalProperties.$gtag) {
        app.config.globalProperties.$gtag.event(action, {
          'event_category': category,
          'event_label': name,
          'value': value
        });
      }
      if (app.config.globalProperties.$gtm) {
        app.config.globalProperties.$gtm.trackEvent({
          'action': action,
          'category': category,
          'label': name,
          'value': value
        });
      }
      if (app.config.globalProperties.$matomo) {
        app.config.globalProperties.$matomo.trackEvent(category, action, name, value);
      }
    }

    app.config.globalProperties.$tracker = {
      trackEvent
    }

    app.provide('tracker', app.config.globalProperties.$tracker)
  }
}

