<template>
  <li
    class="issue-complaint-card"
    :class="{
      'issue-complaint-card--selected': isSelected,
    }"
  >
    <label class="px-4 py-3 cursor-pointer flex items-center">
      <input
        :value="issue.issueNumber"
        @change="$emit('update:modelValue', issue)"
        :checked="isSelected"
        type="radio"
        class="form-radio outline-none"
      />
      <dl class="grid grid-cols-2 ml-4 flex-1">
        <dt>{{ $t("issue.issue") }}:</dt>
        <dd class="text-right font-semibold truncate">
          <IssueLabel :issue="issue"></IssueLabel>
        </dd>
        <dt>{{ $t("issue.date-of-publication") }}:</dt>
        <dd class="text-right">
          {{ $d(issue.dateOfFirstSale) }}
        </dd>
      </dl>
    </label>
    <form
      ref="form"
      v-if="isSelected"
      @submit.prevent="submit"
      class="p-4 bg-gray-50 relative"
    >
      <FormSelect
        class="-mt-5"
        v-model="complaintReason"
        :label="$t('subscription-complaint.reason.label')"
        :options="complaintReasons"
        :placeholder="$t('subscription-complaint.reason.placeholder')"
        required
      >
      </FormSelect>
      <FormSelect
        v-model="compensationOption"
        :label="$t('subscription-complaint.compensation.label')"
        :options="availableCompensationOptions"
        :placeholder="$t('subscription-complaint.compensation.placeholder')"
        required
      >
      </FormSelect>
      <FormCheckbox
        input-class="bg-white border-2"
        v-if="showHasMovedCheckbox"
        class="mt-5"
        v-model="hasMoved"
        :label="$t('subscription-complaint.address-changed.label')"
      >
      </FormCheckbox>
      <BusinessPartnerAddressForm
        class="mt-5 pt-5 border-t"
        v-if="hasMoved"
        show-name
        :allow-country-change="$tenant.addressChangeConfig.allowCountryChange"
        :subscription="subscription"
        :business-partner="goodsRecipient"
        show-affected-issue-alert
      ></BusinessPartnerAddressForm>
      <ButtonBar class="mt-5 justify-end">
        <ButtonWithLoadingIndicator
          type="submit"
          :loading="submitting"
          :disabled="submitDisabled"
          class="btn-primary"
        >
          {{ $t("subscription-complaint.submit.label") }}
        </ButtonWithLoadingIndicator>
      </ButtonBar>
      <div
        v-if="showOverlay"
        class="absolute inset-0 p-4 bg-gray-50 text-center md:text-lg flex items-center justify-center"
      >
        <div v-if="compensationOptionsLoading">
          <Spinner no-margin></Spinner>
          <div class="mt-8 text-muted">
            {{ $t("subscription-complaint.loading-compensation-options") }}
          </div>
        </div>
        <div v-else class="text-red-600">
          <p>
            {{ $t("subscription-complaint.no-compensation-options") }}
            <br class="hidden md:block" />
            {{ $t("subscription-complaint.contact-customer-support") }}
          </p>
          <router-link
            :to="{
              name: 'contact',
              query: {
                subscriptionId: subscription.id,
                showMessageInput: 'true',
                linkedPage: 'subscription-complaint'
              },
            }"
            class="inline-block btn btn-primary mt-4"
          >{{ $t("subscription-complaint.to-contact") }}
          </router-link>
        </div>
      </div>
    </form>
  </li>
</template>

<script lang="ts" setup>
import { Subscription } from "@/models/subscription";
import { Issue } from "@/models/issue";
import { CompensationOption } from "@/models/compensationOption";
import {
  BusinessPartner,
  cloneBusinessPartnerWithDefaults,
} from "@/models/businessPartner";
import { computed, PropType, reactive, toRefs, watch } from "vue";
import IssueLabel from "@/components/IssueLabel.vue";
import FormSelect from "@/components/form/FormSelect.vue";
import FormCheckbox from "@/components/form/FormCheckbox.vue";
import ButtonBar from "@/components/ButtonBar.vue";
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import Spinner from "@/components/Spinner.vue";
import BusinessPartnerAddressForm from "@/components/business-partner/BusinessPartnerAddressForm.vue";
import { useBusinessPartners } from "@/pinia/businessPartners";
import { useTracker } from "@/plugins/tracker";
import { useAxios } from "@/plugins/axios";
import { useTenant } from "@/plugins/tenant";
import { useI18n } from "vue-i18n";

export interface IssueComplaintEvent {
  compensationOption: CompensationOption
  downloadUrl: string | null
}

interface IssueComplaintCardState {
  compensationOption: string | null
  complaintReason: string | null
  availableCompensationOptions: any
  compensationOptionsLoading: boolean
  submitting: boolean
  hasMoved: boolean
  goodsRecipient: BusinessPartner
}

const props = defineProps({
  subscription: {
    type: Subscription,
    required: true
  },
  issue: {
    type: Object as PropType<Issue>,
    required: true
  },
  modelValue: {
    type: Object as PropType<Issue | null>,
    default: null
  }
})

const emit = defineEmits(['update:modelValue', 'success', 'error'])

const tracker = useTracker()
const axios = useAxios()
const tenant = useTenant()
const {tm} = useI18n()
const businessPartners = useBusinessPartners();

const state = reactive<IssueComplaintCardState>({
  compensationOption: null,
  complaintReason: null,
  availableCompensationOptions: null,
  compensationOptionsLoading: false,
  submitting: false,
  hasMoved: false,
  goodsRecipient: cloneBusinessPartnerWithDefaults(
    props.subscription.goodsRecipient
  )
})

const {
  compensationOption,
  complaintReason,
  availableCompensationOptions,
  compensationOptionsLoading,
  submitting,
  hasMoved,
  goodsRecipient,
} = toRefs(state)

const isSelected = computed(() => {
  return props.modelValue === props.issue;
})

const showHasMovedCheckbox = computed(() => {
  return (
    tenant.supportsAddressChange() &&
    (complaintReason.value === "notdelivered" ||
      complaintReason.value === "other")
  );
})

const availableCompensationOptionsEmpty = computed(() => {
  return (
    Array.isArray(availableCompensationOptions.value) &&
    availableCompensationOptions.value!.length === 0
  );
})

const showOverlay = computed(() => {
  return (
    compensationOptionsLoading.value || availableCompensationOptionsEmpty.value
  );
})

const submitDisabled = computed(() => {
  return complaintReason.value === null || compensationOption.value === null;
})

const complaintReasons = computed(() =>{
  const translations = tm("subscription-complaint.reasons") as any
  return Object.keys(translations).map((key) => ({
    // @ts-ignore
    name: translations[key],
    value: key,
  }));
})

const allCompensationOptions = computed(() =>{
  const translations = tm("subscription-complaint.compensation-options") as any
  return Object.keys(translations).map((key) => ({
    // @ts-ignore
    name: translations[key],
    value: key,
  }));
})

watch(isSelected, (value) => {
  if (
    value &&
    !compensationOptionsLoading.value &&
    availableCompensationOptions.value === null
  ) {
    // perform the initial load
    loadAvailableCompensationOptions();
  }
})

async function submit() {
  try {
    submitting.value = true;

    if (hasMoved.value) {
      // perform the address change first
      await businessPartners.changeAddress({
        businessPartner: goodsRecipient.value,
        subscriptionId: props.subscription.id,
      });
      tracker.trackEvent("BusinessPartner", "ChangeAddress");
    }

    const response = await axios.post<any>(
      `/subscriptions/${props.subscription.id}/delivered-issues/request-compensation`,
      {
        issue: props.issue,
        complaintReason: complaintReason.value,
        compensationOption: compensationOption.value,
      }
    );
    tracker.trackEvent(
      "Subscription",
      "Complaint",
      props.subscription.publication
    );

    emit("success", {
      compensationOption: compensationOption.value,
      downloadUrl: response.data.url,
    });
  } catch (error) {
    emit("error");
  } finally {
    submitting.value = false;
  }
}

async function loadAvailableCompensationOptions() {
  try {
    compensationOptionsLoading.value = true;
    const response = await axios.post<Array<CompensationOption>>(
      `/subscriptions/${props.subscription.id}/delivered-issues/compensation-options`,
      {
        issue: props.issue,
      }
    );
    availableCompensationOptions.value = allCompensationOptions.value.filter(
      (option) => response.data.includes(option.value as CompensationOption)
    );
    compensationOption.value = null;
  } catch (error) {
  } finally {
    compensationOptionsLoading.value = false;
  }
}

</script>

<style>
.issue-complaint-card {
  @apply border border-b-0 overflow-hidden;
}

.issue-complaint-card:first-child {
  @apply rounded-t-card;
}

.issue-complaint-card:last-child {
  @apply rounded-b-card;
}

.issue-complaint-card:hover {
  @apply shadow-md;
}

.issue-complaint-card--selected {
  @apply shadow-md border-primary-light border-b;
}

.issue-complaint-card--selected + .issue-complaint-card {
  @apply border-t-0;
}

.issue-complaint-card:last-child {
  @apply border-b;
}

.mt-0i {
  margin-top: 0 !important;
}

.form-radio {
  @apply text-primary;
  appearance: none;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  user-select: none;
  flex-shrink: 0;
  border-radius: 100%;
  height: 1em;
  width: 1em;
  background-color: #fff;
  border-color: #e2e8f0;
  border-width: 1px;
}

.form-radio:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
