<template>
  <form @submit.prevent="redirectToCancellation">
    <FormSelect
      :options="subscriptions"
      :disabled="subscriptionsLoading"
      :loading="subscriptionsLoading"
      v-model="selectedSubscription"
      name-key="displayName"
      value-key="id"
      return-object
      :label="$t('contact.form.subscriptions.label')"
      :placeholder="$t('contact.form.subscriptions.placeholder')"
      required
    />
    <p class="mt-1 text-xs text-gray-700">
      {{ $t("contact.form.subscriptions.hint") }}
    </p>
    <ButtonBar class="mt-6">
      <button type="submit" class="btn btn-primary font-semibold">
        {{ $t("app.continue") }}
      </button>
    </ButtonBar>
  </form>
</template>

<script lang="ts" setup>
import { useSubscriptions } from "@/pinia/orders";
import { ref } from "vue";
import ButtonBar from "@/components/ButtonBar.vue";
import FormSelect from "@/components/form/FormSelect.vue";
import { useRouter } from "vue-router";
import { Subscription } from "@/models/subscription";

const router = useRouter()

const selectedSubscription = ref<Subscription|null>(null)

const { subscriptions, subscriptionsLoading } = useSubscriptions()

function redirectToCancellation() {
  router.push({
    name: "subscription-cancellation",
    params: {
      id: selectedSubscription.value?.id,
    },
    query: {
      returnTo: "cancellation",
    },
  });
}
</script>
