<template>
  <section class="flex">
    <div class="hidden w-1/2 md:block">
      <CoverImage :src="subscription.coverImageUrl" />
    </div>
    <div class="flex flex-col w-full md:ml-4">
      <div class="flex">
        <CoverImage
          class="h-28 mr-2 md:hidden"
          :src="subscription.coverImageUrl"
          hide-on-error
        />
        <div
          class="flex flex-col items-start w-full sm:flex-row font-display md:px-2"
        >
          <div class="sm:flex-1">
            <h3 class="text-xl font-bold">
              {{ subscription.displayName }}
            </h3>
            <p v-if="rules.showOfferDescription">
              {{ subscription.renewal!.maxCycleOfferOfferDescription }}
            </p>
            <p>
              {{
                $t("subscription-detail.subscription-number-short")
              }}&nbsp;{{ subscription.orderNumber }}
            </p>
          </div>
          <SubscriptionTypeBadge
            :subscription="subscription"
            class="mt-1 sm:mt-0 sm:ml-2 rounded-card"
          />
        </div>
      </div>
      <slot name="alerts"></slot>
      <dl class="grid grid-cols-2 gap-1 py-4 mt-4 border-t border-b md:px-2">
        <DescriptionListItem :label="$t('subscription-detail.status')">
          <OrderStatusLabel :subscription="subscription" />
        </DescriptionListItem>
        <DescriptionListItem
          v-if="rules.showValidFrom"
          :label="subscription.isActiveStrict() ? $t('subscription-detail.valid-from-active') : $t('subscription-detail.valid-from')">
          {{ $d(subscription.validFromOnSaleDate) }}
        </DescriptionListItem>
        <DescriptionListItem v-if="rules.showValidToDate" :label="$t('subscription-detail.valid-until')">
          {{ $d(subscription.validToOnSaleDate) }}
        </DescriptionListItem>
        <template v-if="rules.showSuspensionDetails">
          <DescriptionListItem :label="$t('subscription-detail.suspended-from')">
            {{ $d(subscription.currentSuspension!.suspendedFromDate) }}
          </DescriptionListItem>
          <DescriptionListItem :label="$t('subscription-detail.suspended-until')">
            {{
              subscription.currentSuspension!.suspendedToDate
                ? $d(subscription.currentSuspension!.suspendedToDate)
                : $t("subscription-detail.suspended-until-unknown")
            }}
          </DescriptionListItem>
        </template>
        <DescriptionListItem
          v-if="rules.showNextIssue"
          :label="subscription.isInactiveWithKnownActivationDate() ? $t('subscription-detail.first-issue') : $t('subscription-detail.next-issue')">
          <IssueLabel :issue="subscription.nextIssue!" show-date></IssueLabel>
        </DescriptionListItem>
        <DescriptionListItem v-if="rules.showExpiryIssue"
                             :label="$t(subscription.isTimebased ? 'subscription-detail.active-until' : 'subscription-detail.expiry-issue')">
          <IssueLabel
            :issue="
                    subscription.renewal!.expiryDesc ||
                    subscription.renewal!.expiryIssueNr
                  "
          ></IssueLabel>
        </DescriptionListItem>
        <DescriptionListItem v-if="rules.showPaymentMethod" :label="$t('subscription-detail.payment-method')">
          <PaymentMethodLabel :subscription="subscription" class="text-right truncate" />
        </DescriptionListItem>
        <DescriptionListItem :label="$t('subscription-detail.billing-frequency')">
              <span
                v-if="subscription.hasAutoRenewal()"
              >
                {{ subscription.renewal!.autoRenewalLastPaymentFrequencyText }}
              </span>
          <span v-else>
                {{ subscription.billingFrequencyShortDescription }}
              </span>
        </DescriptionListItem>
        <DescriptionListItem v-if="rules.showCurrentCyclePaidAmount"
                             :label="$t('subscription-detail.current-cycle-paid-amount')">
          {{
            $n(
              subscription.currentCyclePaidAmount,
              subscription.renewal!.orderCurrency
              )
          }}
        </DescriptionListItem>
        <DescriptionListItem v-if="rules.showAutoRenewal" :label="$t('subscription-detail.auto-renewal')">
          <Checkmark :value="true"></Checkmark>
        </DescriptionListItem>
        <DescriptionListItem v-if="rules.showNextPaymentDate" :label="$t('subscription-detail.next-payment-date')">
          {{ $d(subscription.renewal!.autoRenewalNextPaymentDate) }}
        </DescriptionListItem>
        <DescriptionListItem v-if="rules.showNextPaymentAmount" :label="$t('subscription-detail.next-payment-amount')">
          {{
            $n(
              subscription.renewal!.autoRenewalNextPaymentAmount,
              subscription.renewal!.orderCurrency
              )
          }}
        </DescriptionListItem>
        <DescriptionListItem v-if="rules.showPremiumDescription" :label="$t('subscription-detail.premium')">
          {{ subscription.premiumDescription }}
        </DescriptionListItem>
        <DescriptionListItem v-if="rules.showGoodsRecipient" :label="$t('subscription-detail.goods-recipient')">
          <BusinessPartnerName
            :business-partner="subscription.goodsRecipient"
          ></BusinessPartnerName>
        </DescriptionListItem>
        <DescriptionListItem v-for="attribute in orderAttributes" :label="attribute.label" :key="attribute.type">
          <span v-if="attribute.creatable && !attribute.value" class="italic">
            {{$t('subscription-detail.attribute-placeholder')}}
          </span>
          <span v-else>
            {{ attribute.value }}
          </span>
          <template #action>
            <button class="ml-2 inline-block text-gray-700 hover:text-primary"
                    v-if="attribute.editable || attribute.creatable"
                    @click="showEditOrderAttributeModal(attribute)">
              <font-awesome-icon icon="edit"/>
            </button>
          </template>
        </DescriptionListItem>
      </dl>
      <slot name="actions"></slot>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { computed, toRef } from 'vue'
import { Subscription } from "@/models/subscription";
import { useSubscriptionRules } from "@/rules/subscription";
import DescriptionListItem from "@/components/DescriptionListItem.vue";
import Checkmark from "@/components/Checkmark.vue";
import { useTenant } from "@/plugins/tenant";
import IssueLabel from "@/components/IssueLabel.vue";
import PaymentMethodLabel from "@/components/PaymentMethodLabel.vue";
import OrderStatusLabel from "@/components/OrderStatusLabel.vue";
import BusinessPartnerName from "@/components/business-partner/BusinessPartnerName.vue";
import CoverImage from "@/components/CoverImage.vue";
import SubscriptionTypeBadge from "@/components/SubscriptionTypeBadge.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { OrderAttributeConfig } from "@/models/orderAttributesConfig";
import { useVfm } from "@/plugins/vfm";
import EditOrderAttributeModal from "@/components/EditOrderAttributeModal.vue";

const props = defineProps({
  subscription: {
    type: Subscription,
    required: true
  }
})

const tenant = useTenant()
const vfm = useVfm()

const orderAttributes = computed(() => {
  const attributes = []
  for (const attribute of tenant.orderAttributesConfig.attributes) {
    // check if the attribute exists on this subscription
    const orderAttribute = props.subscription.orderAttributes.find((attr) => attr.type === attribute.type)
    if (orderAttribute || attribute.creatable) {
      attributes.push({
        type: attribute.type,
        label: attribute.label,
        value: attribute.value ?? orderAttribute?.value ?? null,
        editable: attribute.editable,
        creatable: attribute.creatable,
        inputType: attribute.inputType
      })
    }
  }
  return attributes
})

const rules = useSubscriptionRules(toRef(props,'subscription'), tenant)

function showEditOrderAttributeModal(attribute: OrderAttributeConfig) {
  vfm.show({
    component: EditOrderAttributeModal,
    bind: {
      subscription: props.subscription,
      attribute: attribute
    }
  })
}

</script>
