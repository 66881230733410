export enum PaymentMethod {
  CREDIT_CARD = "credit_card",
  SEPA = "sepa",
  DIRECT_DEBIT = "direct_debit",
  PAYPAL = "paypal",
  INVOICE = "invoice",
  NONE = "none",
}

export interface PaymentDetails {
  paymentMethod: PaymentMethod;
  account: CreditCardAccount | DirectDebitAccount | PaypalAccount;
}

export interface CreditCardAccount {
  ccNumber: string;
  ccCompanyCode: string;
  ccCardholderName: string;
  ccExpiry: string;
}

export interface DirectDebitAccount {
  debitBankName: string;
  debitBankCode: string;
  debitAccountNumber: string;
  debitAccountHolder: string;
  debitContinuous: boolean;
  iban?: string;
  bic?: string;
}

export interface PaypalAccount {}

export interface InvoiceAccount {}
