<template>
  <div>
    <div class="border-b pb-4 mb-4" v-if="showBackButton">
      <BackButton return-to="issue-calendar" />
    </div>
    <Spinner v-if="publicationsLoading"></Spinner>
    <div v-else class="items-start">
      <div class="md:flex items-center">
        <h4 class="text-xl font-medium font-display">{{ publication?.publication.description }}</h4>
        <div class="flex-1"></div>
        <IssueCalendarModeSelector
          class="mt-4 md:mt-0"
          v-if="showModeSelector"
          v-model="mode"
        ></IssueCalendarModeSelector>
      </div>
      <Spinner v-if="issuesLoading"></Spinner>
      <IssueCalendarCalendar
        v-else-if="showCalendar"
        class="mt-4"
        two-cols
        :issues="selectedPublicationIssues"
      ></IssueCalendarCalendar>
      <IssueCalendarListView
        v-else-if="showList"
        class="mt-4"
        :issues="selectedPublicationIssues"
      ></IssueCalendarListView>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue'
import Spinner from "@/components/Spinner.vue";
import { useTenant } from "@/plugins/tenant";
import { useUnauthenticatedIssueCalendar } from "@/pinia/unauthenticatedIssueCalendar";
import { IssueCalendarPublication } from "@/models/issueCalendarPublication";
import { storeToRefs } from "pinia";
import IssueCalendarCalendar from "@/pages/issue-calendar/IssueCalendarCalendar.vue";
import IssueCalendarListView from "@/pages/issue-calendar/IssueCalendarListView.vue";
import IssueCalendarModeSelector from "@/pages/issue-calendar/IssueCalendarModeSelector.vue";
import { useRoute, useRouter } from "vue-router";
import BackButton from "@/components/BackButton.vue";

const tenant = useTenant()
const issueCalendar = useUnauthenticatedIssueCalendar()
const route = useRoute()
const router = useRouter()

const showBackButton = !inject('hideBackToOverview', false)

// @ts-ignore
const { publicationsLoading, issuesLoading, mode, publications, issues } = storeToRefs(issueCalendar)

const showCalendar = computed(() => mode.value === 'calendar')
const showList = computed(() => mode.value === 'list')
const showModeSelector = computed(() => tenant.issueCalendarConfig.mode === "any")
const loading = computed(() => issuesLoading.value || publicationsLoading.value)
const publication = computed<IssueCalendarPublication | null>(() => {
  if (publications.value === null) {
    return null
  }
  return publications.value.find((publication) => publication.publication.publication === route.params.publication) ?? null;
})

const selectedPublicationIssues = computed(() => {
  return issues.value[publication.value!.publication.publication] ?? []
})

if (issueCalendar.mode === null) {
  if (tenant.issueCalendarConfig.mode === "any") {
    mode.value = "calendar";
  } else {
    mode.value = tenant.issueCalendarConfig.mode;
  }
}

async function load() {
  if (publications.value === null) {
    await issueCalendar.fetchPublications();
  }
  if (publication.value !== null) {
    issueCalendar.fetchIssues(publication.value!.publication);
  }
}

function back() {
  router.push({
    name: 'issue-calendar'
  })
}

load()
</script>
