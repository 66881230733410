<template>
  <li
    class="flex transition-all duration-200 transform border rounded-card shadow hover:-translate-y-1 hover:shadow-lg"
  >
    <div class="hidden w-1/2 p-2 border-r md:block relative">
      <CoverImage :src="order.coverImageUrl" />
    </div>
    <div class="flex flex-col w-full">
      <div class="flex items-start p-3 md:pb-2">
        <CoverImage class="h-28 mr-2 md:hidden" :src="order.coverImageUrl" />
        <div>
          <h3 class="font-bold">{{ order.title }}</h3>
        </div>
      </div>
      <dl class="grid grid-cols-2 gap-1 mx-3 text-sm leading-tight md:px-0">
        <template>
          <dt>{{ $t("pending-order.order-number") }}:</dt>
          <dd class="text-right truncate">
            {{ order.orderNumber }}
          </dd>
        </template>
        <template>
          <dt>{{ $t("pending-order.order-date") }}:</dt>
          <dd class="text-right truncate">
            {{ $d(new Date(order.orderDate)) }}
          </dd>
        </template>
        <template v-if="order.issueNumberFrom">
          <dt>{{ $t("pending-order.first-issue") }}:</dt>
          <dd class="text-right truncate">
            <IssueLabel :issue="order.issueNumberFrom"></IssueLabel>
          </dd>
        </template>
      </dl>
      <div class="flex-1"></div>
      <div class="flex m-3 justify-end">
        <a
          :href="download.url"
          :download="download.isFile"
          :target="download.isFile ? '_self' : '_blank'"
          v-if="showDownload"
          class="btn mr-2 btn-primary"
        >
          <font-awesome-icon icon="download" />
          <span class="ml-2">{{ $t("subscription-detail.e-paper") }}</span></a
        >
      </div>
    </div>
  </li>
</template>
<script lang="ts" setup>
import { PendingOrder } from "@/models/pendingOrder";
import { computed, PropType } from "vue";
import IssueLabel from "@/components/IssueLabel.vue";
import CoverImage from "@/components/CoverImage.vue";
import { useTenant } from "@/plugins/tenant";

const props = defineProps({
  order: {
    type: Object as PropType<PendingOrder>,
    required: true
  }
})

const tenant = useTenant()

const showDownload = computed(() => {
  if (props.order.source === "VHS") {
    return (
      tenant.supportsPurchaseOrderDownload() &&
      props.order.download !== null
    );
  }
  return tenant.supportsIssueDownload() && props.order.download !== null;
})

const download = computed(() => props.order.download!)
</script>
