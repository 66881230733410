<template>
  <label class="block ">
    <div class="mb-1 mt-5" v-if="label">
      {{ label }} <span v-if="required" class="text-primary">*</span>
    </div>
    <div class="relative">
      <select
        ref="input"
        :value="getOptionValue(modelValue)"
        @change="handleChange"
        class="select outline-none"
        :class="showClearButton ? 'bg-none' : ''"
        :required="required"
        :disabled="disabled"
      >
        <option v-if="placeholder || loading" value="" disabled selected
        >{{ loading ? $t("app.loading") : placeholder }}
        </option>
        <option
          v-for="option in _options"
          :key="getOptionKey(option)"
          :value="getOptionValue(option)"
        >{{ getOptionName(option) }}
        </option>
      </select>
      <button
        v-if="showClearButton"
        type="button"
        tabindex="-1"
        class="absolute bottom-0 px-3 py-3 right-0 flex items-center focus:outline-none"
        @click.prevent.stop="clear"
      >
        <font-awesome-icon
          icon="times"
          class="h-4 w-4 text-gray-700"
          aria-hidden="true"
        />
      </button>
      <InlineIconSpinner v-if="loading" class="right-3 top-3 bg-white" absolute />
    </div>
  </label>
</template>
<script lang="ts" setup>
import { ref, computed, PropType, watch } from "vue";
import InlineIconSpinner from "@/components/InlineIconSpinner.vue";

const props = defineProps({
  label: String,
  required: Boolean,
  placeholder: String,
  disabled: Boolean,
  loading: Boolean,
  returnObject: Boolean,
  clearable: Boolean,
  modelValue: [String, Object] as PropType<string | any>,
  options: [Array, Object] as PropType<Array<string | any> | Record<string, any> | null | undefined>,
  valueKey: {
    type: String,
    default: "value"
  },
  nameKey: {
    type: String,
    default: "name"
  },
  nameFormatter: {
    type: Function as PropType<(value: any) => string>,
    default: undefined
  },
})

const emit = defineEmits(['update:modelValue'])
const input = ref<HTMLSelectElement|null>(null)

const _options = computed(() => {
  if (Array.isArray(props.options)) {
    return props.options
  }
  if (typeof props.options === 'object' && props.options != null) {
    return Object.entries(props.options).map((e) => ({
      value: e[0],
      name: e[1]
    }))
  }
  return []
})

const showClearButton = computed(() => {
  return props.clearable && props.modelValue != null;
})

watch(() => props.modelValue, (value) => {
  if (value === null) {
    // this triggers the "placeholder" to be shown again
    input.value!.selectedIndex = 0
  }
})

function getOptionName(option: any) {
  if (option !== null && typeof option === "object") {
    if (props.nameFormatter) {
      return props.nameFormatter(option);
    }
    if (!(props.nameKey in option)) {
      return console.warn(
        `[form-select warn]: Name key "option.${props.nameKey}" does not` +
        ` exist in options object ${JSON.stringify(option)}.`
      );
    }
    return option[props.nameKey];
  }
  return option;
}

function getOptionKey(option: any) {
  return getOptionValue(option);
}

function getOptionValue(option: any) {
  if (option !== null && typeof option === "object") {
    if (!(props.valueKey in option)) {
      return console.warn(
        `[form-select warn]: Value key "option.${props.valueKey}" does not` +
        ` exist in options object ${JSON.stringify(option)}.`
      );
    }
    return option[props.valueKey];
  }
  return option;
}

function clear() {
  emit("update:modelValue", null);
}

function handleChange(event: Event) {
  let value = (event.target as HTMLSelectElement).value as any;
  if (props.returnObject) {
    value = _options.value.find(
      (option) => getOptionValue(option) === value
    );
  }
  emit("update:modelValue", value);
}

</script>
<style lang="postcss">
.select {
  @apply border rounded-input p-2 pr-6 appearance-none;
  width: 100%;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
}

.select:focus {
  @apply shadow-outline;
}

.select:disabled {
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.6);
}
</style>
