<template>
  <AlertDialog
    :loading="loading"
    :positive-text="$t('subscription-licenses.add')"
    :negative-text="$t('app.cancel')"
    @negative="emit('update:modelValue', false)"
    disable-auto-hide
    name="add-license"
    form="add-license-form"
    scrollable
  >
    <form @submit.prevent="submit" id="add-license-form">
      <p class="font-semibold">{{ $t("subscription-licenses.add") }}</p>
      <FormInput
        v-model="form.businessPartner.emailAddress"
        :disabled="loading"
        required
        :label="$t('business-partner.email.label')"
        type="email"
      ></FormInput>
      <FormSelect
        class="w-1/4"
        v-if="config.requireSalutation"
        :label="$t('business-partner.salutation.label')"
        :options="salutations"
        value-key="code"
        name-key="title"
        required
        v-model="form.businessPartner.salutationCode"
      />
      <FormInput
        v-model="form.businessPartner.firstName"
        :disabled="loading"
        required
        max-length="30"
        :label="$t('business-partner.first-name.label')"
      ></FormInput>
      <FormInput
        v-model="form.businessPartner.lastName1"
        :disabled="loading"
        required
        max-length="30"
        :label="$t('business-partner.last-name.label')"
      ></FormInput>
      <div>
        <FormCheckbox
          v-if="config.userControlledReactivation"
          class="mt-5"
          v-model="form.reactivatable"
          :disabled="loading"
          :label="$t('business-partner.reactivatable.label')"
        ></FormCheckbox>
      </div>
      <div>
        <FormCheckbox
          class="mt-5"
          v-model="form.sendInvitation"
          :disabled="loading"
          :label="$t('business-partner.send-invitation.label')"
        ></FormCheckbox>
      </div>
    </form>
  </AlertDialog>
</template>

<script lang="ts" setup>
import { computed, PropType, ref, watchEffect } from 'vue'
import AlertDialog from "@/components/AlertDialog.vue";
import FormInput from "@/components/form/FormInput.vue";
import FormSelect from "@/components/form/FormSelect.vue";
import FormCheckbox from "@/components/form/FormCheckbox.vue";
import { BusinessPartner, cloneBusinessPartnerWithDefaults } from "@/models/businessPartner";
import { useVfm } from "@/plugins/vfm";
import { Salutation } from "@/models/salutation";
import { MultiUserLicenseObject } from "@/models/multiUserLicenseObject";

export interface AddLicenseFormData {
  businessPartner: BusinessPartner
  reactivatable: boolean,
  sendInvitation: boolean
}

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: Boolean,
    default: false
  },
  config: {
    type: Object as PropType<MultiUserLicensesConfig>,
    required: true
  },
  salutations: {
    type: Array as PropType<Salutation[]>,
    required: true
  },
  object: {
    type: Object as PropType<MultiUserLicenseObject>,
    required: false
  }
})

const emit = defineEmits(['submit', 'update:modelValue'])

const vfm = useVfm()

const form = ref<AddLicenseFormData>({
  businessPartner: cloneBusinessPartnerWithDefaults({}),
  reactivatable: false,
  sendInvitation: false
})

const defaultSalutationCode = computed(() => {
  if (props.config.requireSalutation) {
    return props.salutations[0].code;
  }
  return undefined;
})

function submit() {
  emit('submit', form.value, props.object)
}

watchEffect(() => {
  if (props.modelValue) {
    form.value = {
      businessPartner: cloneBusinessPartnerWithDefaults({
        salutationCode: defaultSalutationCode.value
      }),
      reactivatable: props.config.allowReactivation,
      sendInvitation: true
    }
    vfm.show('add-license')
  } else {
    vfm.hide('add-license')
  }
})
</script>
