<template>
  <div>
    <div class="pb-4 mb-4 border-b" v-if="showBackButton">
      <BackButton />
      <p class="mt-2 text-gray-700" v-if="heading.length">{{ heading }}</p>
    </div>
    <template v-if="subscription">
      <SubscriptionDetails :subscription="subscription">
        <template #alerts>
          <Alert
            variant="info"
            class="mt-4 flex"
            v-if="rules.showActiveHolidayService"
          >
            <i18n-t tag="span" keypath="subscription-detail.active-holiday-service">
              <template #service>
                <b>{{
                    $tm("subscription-holiday-service.title")[
                      subscription.activeHolidayServiceType!
                      ]
                  }}</b>
              </template>
            </i18n-t>
            <template #action>
              <router-link
                :to="{
                  name: 'subscription-holiday-service',
                  params: {
                    id: subscription.id
                  }
                }"
                class="py-2 px-3 bg-white border rounded-btn self-center transition-all duration-100 hover:shadow ml-2"
              >{{ $t("subscription-detail.manage") }}
              </router-link
              >
            </template>
          </Alert>
        </template>
        <template #actions>
          <div class="grid gap-2 mt-4 sm:grid-cols-2">
            <SubscriptionNavButton
              v-if="rules.supportsRenewal"
              :subscription="subscription"
              to="subscription-renewal"
              name="renewal"
            >
            </SubscriptionNavButton>
            <SubscriptionNavButton
              v-if="rules.supportsIssueComplaint"
              :subscription="subscription"
              to="subscription-complaint"
              name="complaint"
            >{{ $t("subscription-detail.complaint") }}
            </SubscriptionNavButton>
            <SubscriptionNavButton
              v-if="rules.supportsAddressChange"
              :subscription="subscription"
              name="addresses"
              to="subscription-business-partners"
            >
            </SubscriptionNavButton>
            <SubscriptionNavButton
              v-if="rules.supportsHolidayService"
              :subscription="subscription"
              to="subscription-holiday-service"
              name="holiday-service"
            ></SubscriptionNavButton>
            <SubscriptionNavButton
              v-if="rules.supportsPaymentMethodChange"
              :subscription="subscription"
              to="subscription-payment-details"
              name="payment-details"
            >
            </SubscriptionNavButton>
            <SubscriptionNavButton
              v-if="rules.supportsUpgrade"
              :subscription="subscription"
              to="subscription-upgrade"
              name="upgrade"
            >
            </SubscriptionNavButton>
            <SubscriptionNavButton
              v-if="rules.supportsCancellation"
              :subscription="subscription"
              to="subscription-cancellation"
              name="cancellation"
            ></SubscriptionNavButton>
            <SubscriptionNavButton
              v-if="rules.supportsCancellationRevocation"
              :subscription="subscription"
              to="contact"
              name="cancellation-revocation"
              :query="{ subscriptionId: subscription.id, activity: $tenant.cancellationConfig.revocationContactActivity }"
            ></SubscriptionNavButton>
            <SubscriptionNavButton
              v-if="rules.supportsOptIns"
              :subscription="subscription"
              name="opt-ins"
              to="subscription-opt-ins"
            >
            </SubscriptionNavButton>
            <GiftCertificateDownloadButton
              v-if="rules.supportsGiftCertificate"
              :subscription="subscription"
            >
            </GiftCertificateDownloadButton>
            <SubscriptionNavButton
              v-if="rules.supportsMultiUserLicenses"
              :subscription="subscription"
              to="subscription-licenses"
              name="licenses"
            >
            </SubscriptionNavButton>
            <SubscriptionNavButton
              v-if="rules.supportsECard"
              :subscription="subscription"
              to="subscription-e-card"
              name="e-card"
            ></SubscriptionNavButton>
            <CustomizableButton
              v-if="rules.showExternalLink"
              class="btn btn-primary"
              name="external-link"
              tag="a"
              :href="externalLink"
              target="_blank"
            >
            </CustomizableButton>
            <SubscriptionPhaseUpgradeButton
              v-if="rules.showPhaseUpgrade"
              :subscription="subscription"
            >
            </SubscriptionPhaseUpgradeButton>
            <SubscriptionNavButton
              v-if="rules.supportsContactForm"
              :subscription="subscription"
              :query="{ subscriptionId: subscription.id }"
              to="contact"
              name="contact"
            >
            </SubscriptionNavButton>
          </div>
        </template>
      </SubscriptionDetails>
      <section
        v-if="orders.ordersComplete && rules.showBundleSlaves"
        class="mt-5"
        data-testid="bundled-subscriptions-list"
      >
        <h4 class="text-xl font-medium font-display">
          {{ $t("subscription-detail.bundled-subscriptions") }}
        </h4>
        <ul class="grid grid-cols-1 gap-4 mt-3 sm:grid-cols-2">
          <SubscriptionListItem
            v-for="subscription in bundleSubscriptions"
            :key="subscription.id"
            :subscription="subscription"
            is-bundle-slave
          ></SubscriptionListItem>
        </ul>
      </section>
      <section
        v-if="rules.showIssuesHistory"
        class="mt-5"
        data-testid="issue-history"
      >
        <button
          class="text-xl font-medium font-display text-gray-700 block w-full text-left border rounded-card py-2 px-3 hover:bg-gray-50"
          :class="issueHistoryOpen && 'rounded-b-none'"
          @click="issueHistoryOpen = !issueHistoryOpen"
        >
          <font-awesome-icon class="transform mr-1" icon="chevron-down" :class="issueHistoryOpen && 'rotate-180'"/>
          {{ $t("subscription-detail.delivered-issues") }}
        </button>
        <div v-if="issueHistoryOpen">
          <Spinner v-if="deliveredIssuesLoading" />
          <SubscriptionIssuesList
            v-if="subscription.deliveredIssues"
            :subscription="subscription"
          />
        </div>
      </section>
      <section
        v-if="rules.showPaymentHistory"
        class="mt-4"
        data-testid="payment-history"
      >
        <button
          class="text-xl font-medium font-display text-gray-700 block w-full text-left border rounded-card  py-2 px-3 hover:bg-gray-50"
          :class="paymentHistoryOpen && 'rounded-b-none'"
          @click="paymentHistoryOpen = !paymentHistoryOpen"
        >
         <font-awesome-icon class="transform mr-1" icon="chevron-down" :class="paymentHistoryOpen && 'rotate-180'"/>
          {{ $t("subscription-detail.invoices") }}
        </button>
        <div v-if="paymentHistoryOpen">
          <Alert
            variant="info"
            icon="info-circle"
            class="rounded-l-none rounded-r-none -mt-px"
            v-if="rules.showInvoiceStatusInfoText"
          >
            {{ tenant.paymentHistoryConfig.invoiceStatusInfoText }}
          </Alert>
          <Spinner v-if="paymentHistoryLoading" />
          <SubscriptionPaymentList
            v-if="subscription.paymentHistory"
            :subscription="subscription"
            :show-invoice-status="rules.showInvoiceStatus"
          />
        </div>
      </section>
    </template>
    <Spinner v-if="subscriptionLoading" />
    <AlertDialog name="downloadError" :negative-text="$t('app.close')"
    >{{ $t("issue.download-not-available") }}
    </AlertDialog>
    <AlertDialog name="downloadStarted" :positive-text="$t('app.close')">
      {{ $t("issue.download-started") }}
    </AlertDialog>
    <AlertDialog
      name="loadingError"
      :positive-text="$t('app.back-to-overview')"
      @positive="$router.replace('/orders')"
    >{{ $t("subscription-detail.loading-error") }}
    </AlertDialog>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, ref, watch } from 'vue'
import Spinner from "@/components/Spinner.vue";
import Alert from "@/components/Alert.vue";
import SubscriptionListItem from "@/pages/orders/SubscriptionListItem.vue";
import { useSubscriptionRules } from "@/rules/subscription";
import { useRoute } from "vue-router";
import { Subscription } from "@/models/subscription";
import BackButton from "@/components/BackButton.vue";
import SubscriptionNavButton from "@/pages/subscription/SubscriptionNavButton.vue";
import SubscriptionPaymentList from "@/pages/subscription/SubscriptionPaymentList.vue";
import pupa from "pupa";
import GiftCertificateDownloadButton from "@/components/GiftCertificatesButton.vue";
import SubscriptionIssuesList from "@/pages/subscription/SubscriptionIssuesList.vue";
import CustomizableButton from "@/components/CustomizableButton.vue";
import AlertDialog from "@/components/AlertDialog.vue";
import { useTenant } from "@/plugins/tenant";
import { useOrders } from "@/pinia/orders";
import SubscriptionPhaseUpgradeButton from "@/pages/subscription/SubscriptionPhaseUpgradeButton.vue";
import SubscriptionDetails from "@/components/SubscriptionDetails.vue";
import { useVfm } from "@/plugins/vfm";
import { useI18n } from "vue-i18n";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const orders = useOrders()
const route = useRoute()
const tenant = useTenant()
const vfm = useVfm()
const { t } = useI18n()

const showBackButton = !inject('hideBackToOverview', false)

const orderId = route.params.id as string

const subscriptionLoading = ref(false)
const paymentHistoryLoading = ref(false)
const paymentHistoryError = ref(false)
const deliveredIssuesLoading = ref(false)
const deliveredIssuesError = ref(false)
const issueHistoryOpen = ref(false)
const paymentHistoryOpen = ref(false)

const heading = computed(() => t('subscription-detail.heading'))
const subscription = computed<Subscription>(() => orders.getSubscriptionById(orderId))
const bundleSubscriptions = computed<Subscription[]>(() => {
  if (subscription.value.bundles === null) {
    return [];
  }
  const clientId = orderId.substring(0, 3)
  return subscription.value.bundles.map((bundle) =>
    orders.getSubscriptionById(clientId + bundle.bundleOrderNumber)
  );
})
const externalLink = computed(() => {
  const context = {
    orderNumber: subscription.value.orderNumber,
    publication: subscription.value.publication,
    mixType: subscription.value.mixType,
    edition: subscription.value.edition,
    bp: subscription.value.currentRoleBusinessPartner,
  };
  return pupa(
    tenant.externalSubscriptionLinkConfig.urlTemplate,
    context
  );
})

const rules = useSubscriptionRules(subscription, tenant)

async function fetchPaymentHistory() {
  if (
    tenant.supportsPaymentHistory() &&
    subscription.value.paymentHistory == null
  ) {
    try {
      paymentHistoryLoading.value = true;
      await orders.fetchPaymentHistory(orderId);
    } catch (error) {
      paymentHistoryError.value = true;
    } finally {
      paymentHistoryLoading.value = false;
    }
  }
}

async function fetchDeliveredIssues() {
  if (
    tenant.supportsIssueHistory(subscription.value.publication, subscription.value.mixType) &&
    subscription.value.deliveredIssues == null
  ) {
    try {
      deliveredIssuesLoading.value = true;
      await orders.fetchDeliveredIssues(orderId);
    } catch (error) {
      deliveredIssuesError.value = true;
    } finally {
      deliveredIssuesLoading.value = false;
    }
  }
}

async function fetchSubscription() {
  try {
    subscriptionLoading.value = true;
    await orders.fetchSubscription(orderId);
    subscriptionLoading.value = false;
  } catch (e) {
    vfm.show("loadingError");
  }
}

watch(issueHistoryOpen, () => fetchDeliveredIssues())
watch(paymentHistoryOpen, () => fetchPaymentHistory())

// initial fetch
fetchSubscription()
</script>
