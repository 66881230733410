<template>
  <AlertDialog name="linkSubscriptionError" :negative-text="$t('app.close')">
    <template #title>
      <font-awesome-icon icon="exclamation-circle" class="text-red-600"></font-awesome-icon>
      <span class="ml-4">{{ $t('app.an-error-occurred') }}</span>
    </template>
    <i18n-t :keypath="errorMessagePath">
      <template #orderNumber
        ><b>{{ orderNumber }}</b></template
      >
      <template #maskedEmail
        ><b>{{ email }}</b></template
      >
    </i18n-t>
  </AlertDialog>
</template>

<script lang="ts" setup>
import {
  LinkSubscriptionResult,
  LinkSubscriptionStatus,
} from "@/models/linkSubscriptionResult";
import { computed, PropType } from "vue";
import AlertDialog from "@/components/AlertDialog.vue";

export type VerificationMethod = 'name' | 'zipcode' | 'name-and-zipcode'

const props = defineProps( {
  linkSubscriptionResult: Object as PropType<LinkSubscriptionResult | null | undefined>,
  orderNumber: String as PropType<String | null | undefined>,
  verificationMethod: {
    type: String as PropType<VerificationMethod>,
    default: 'zipcode'
  },
})

const email = computed(() => {
  return props.linkSubscriptionResult
    ? props.linkSubscriptionResult!.maskedEmail
    : null;
})

const errorMessagePath = computed(() => {
  if (!props.linkSubscriptionResult) {
    return "contact.errors.unknown-error";
  }
  switch (props.linkSubscriptionResult!.status) {
    case LinkSubscriptionStatus.EmailRequired:
      return "contact.errors.email-required";
    case LinkSubscriptionStatus.ConfirmEmail:
      break;
    case LinkSubscriptionStatus.ValidationFailed:
      return "contact.errors.validation-failed";
    case LinkSubscriptionStatus.VerificationFailed:
      return "contact.errors.invalid-" + props.verificationMethod;
    case LinkSubscriptionStatus.NotFound:
      return "contact.errors.subscription-not-found";
    case LinkSubscriptionStatus.NotSupported:
      return "contact.errors.subscription-not-supported";
    case LinkSubscriptionStatus.AlreadyLinked:
      return "contact.errors.subscription-already-linked";
  }
  return "contact.errors.unknown-error";
})
</script>
