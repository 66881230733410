<template>
  <AlertDialog
    name="package-info"
    :negative-text="$t('app.close')"
    click-to-close
  >
    <template #title>
      <font-awesome-icon icon="info-circle"></font-awesome-icon>
      <span class="ml-4">{{ $t('purchase-order.package-info') }}</span>
    </template>
    <Alert variant="info">
      {{ $t('purchase-order.shipping-status-notice') }}
    </Alert>
    <dl
      class="grid grid-cols-2 gap-1 py-4 md:px-2"
    >
      <DescriptionListItem :label="$t('purchase-order.shipping-status.label')">
        {{ isShipped ? $t(`purchase-order.shipping-status-shipped`) : $t(`purchase-order.shipping-status-pending`) }}
      </DescriptionListItem>
      <DescriptionListItem :label="$t('purchase-order.shipping-provider.label')">
        {{ shippingProvider?.name ?? $t('purchase-order.shipping-provider-unknown') }}
      </DescriptionListItem>
      <DescriptionListItem :label="$t('purchase-order.delivery-date.label')">
        {{ packageInfo.deliveryDate ? $d(packageInfo.deliveryDate) : '-' }}
      </DescriptionListItem>
      <DescriptionListItem :label="$t('purchase-order.consignment-number.label')">
        <a :href="trackingUrl" target="_blank" v-if="trackingUrl" class="link inline-flex items-center">
          {{ packageInfo.consignmentNumber }}
          <font-awesome-icon icon="external-link-alt" size="xs" class="ml-1"></font-awesome-icon>
        </a>
        <span v-else>{{ packageInfo.consignmentNumber || '-' }}
        </span>
      </DescriptionListItem>
    </dl>
  </AlertDialog>
</template>

<script lang="ts" setup>
import { computed, defineComponent, PropType } from 'vue'
import AlertDialog from "@/components/AlertDialog.vue";
import DescriptionListItem from "@/components/DescriptionListItem.vue";
import PurchaseOrderShippingStatusLabel from "@/pages/orders/PurchaseOrderShippingStatusLabel.vue";
import { PackageInfo, ScanStatus } from "@/models/packageInfo";
import Alert from "@/components/Alert.vue";
import { ShippingProvider } from "@/models/shippingStatusConfig";
import pupa from "pupa";
import { useTenant } from "@/plugins/tenant";

const props = defineProps({
  packageInfo: {
    type: Object as PropType<PackageInfo>,
    required: true
  }
})

const tenant = useTenant()

const isShipped = computed(() => {
  return props.packageInfo.scanStatus === ScanStatus.Shipped
})
const shippingProvider = computed(() => {
  return tenant.shippingStatusConfig.shippingProviders.find((provider) => provider.code === props.packageInfo.versWeg) ?? null
})
const trackingUrl = computed(() => {
  if (shippingProvider.value === null || !props.packageInfo.consignmentNumber || !shippingProvider.value.trackingUrlTemplate) {
    return null
  }
  return pupa(shippingProvider.value.trackingUrlTemplate, props.packageInfo)
})
</script>
