<template>
  <CustomizableButton
    :to="route"
    :name="name"
    tag="router-link"
    class="btn btn-primary"
  >
  </CustomizableButton>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import CustomizableButton from "@/components/CustomizableButton.vue";
import { Subscription } from "@/models/subscription";
import { RouteLocationRaw } from "vue-router";

const props = defineProps({
  to: {
    type: String,
    required: true
  },
  subscription: {
    type: Subscription,
    required: true
  },
  query: Object,
  name: {
    type: String,
    required: true
  }
})

const route = computed((): RouteLocationRaw => {
  return {
    name: props.to,
    params: {
      id: props.subscription.id
    },
    query: props.query
  }
})
</script>
