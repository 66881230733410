// @ts-ignore
import { App, Plugin } from "vue";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// need to import each icon individually
import {
  faCheck,
  faTimes,
  faPowerOff,
  faChevronRight,
  faDownload,
  faChevronLeft,
  faCreditCard,
  faCheckCircle,
  faEdit,
  faBars,
  faAddressCard,
  faLifeRing,
  faPause,
  faSearch,
  faFilter,
  faEnvelope,
  faExclamationCircle,
  faFileInvoice,
  faCheckSquare,
  faWindowClose,
  faCog,
  faUserCircle,
  faWallet,
  faBookOpen,
  faCertificate,
  faEllipsisV,
  faRedo,
  faCaravan,
  faShare,
  faFileDownload,
  faInfoCircle,
  faPlane,
  faIdBadge,
  faTrash,
  faPlay,
  faStream,
  faCalendar,
  faMousePointer,
  faExternalLinkAlt,
  faAt,
  faArrowUp,
  faPlusSquare,
  faImage,
  faChevronUp,
  faChevronDown, faCopy,
} from "@fortawesome/free-solid-svg-icons";

import {
  faGiftCard
} from "@fortawesome/pro-solid-svg-icons";

import {
  faCcPaypal,
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcDiscover,
} from "@fortawesome/free-brands-svg-icons";

// add them to our library
library.add(
  faCheck,
  faImage,
  faTimes,
  faPowerOff,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faDownload,
  faCreditCard,
  faCheckCircle,
  faEdit,
  faBars,
  faAddressCard,
  faLifeRing,
  faPause,
  faEnvelope,
  faExclamationCircle,
  faFileInvoice,
  faCheckSquare,
  faWindowClose,
  faCog,
  faUserCircle,
  faWallet,
  faCcPaypal,
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcDiscover,
  faBookOpen,
  faCertificate,
  faFileInvoice,
  faEllipsisV,
  faRedo,
  faCaravan,
  faShare,
  faFileDownload,
  faInfoCircle,
  faPlane,
  faIdBadge,
  faTrash,
  faPlay,
  faMousePointer,
  faPlay,
  faSearch,
  faFilter,
  faStream,
  faCalendar,
  faMousePointer,
  faExternalLinkAlt,
  faAt,
  faArrowUp,
  faPlusSquare,
  faGiftCard,
  faCopy
);

export default {
  install: (app: App, ...options: any[]) => {
    app.component("font-awesome-icon", FontAwesomeIcon)
  }
} as Plugin
