<template>
  <div
    class="group p-0.5 rounded-input flex bg-white border hover:border-primary"
  >
    <button
      class="toggle-button p-1.5 pl-2.5 r-3.5 flex flex-1 justify-center items-center focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2 focus:outline-none focus-visible:ring-offset-gray-100 rounded-input"
      @click="$emit('update:modelValue', 'calendar')"
      :class="calendarSelected
              ? 'bg-primary shadow-sm ring-1 ring-black ring-opacity-5 '
              : ''
          "
    >
      <font-awesome-icon
        icon="calendar"
        class="mr-2"
        :class="calendarSelected ? 'text-white' : 'text-gray-500'"
      ></font-awesome-icon>
      <span
        :class="calendarSelected? 'text-white' : 'text-gray-600 group-hover:text-gray-900'"
      >{{ $t("issue-calendar.calendar") }}</span
      >
    </button>
    <button
      class="toggle-button ml-0.5 p-1.5 pl-2.5 pr-3.5 flex flex-1 justify-center items-center focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2 focus:outline-none focus-visible:ring-offset-gray-100 rounded-input"
      @click="$emit('update:modelValue', 'list')"
      :class="
            listSelected
              ? 'bg-primary shadow-sm ring-1 ring-black ring-opacity-5'
              : ''
          "
    >
      <font-awesome-icon
        icon="stream"
        class="mr-2"
        :class="
              listSelected
                ? 'text-white'
                : 'text-gray-500 group-hover:text-gray-900'
            "
      ></font-awesome-icon>
      <span
        :class="
              listSelected
                ? 'text-white'
                : 'text-gray-600 group-hover:text-gray-900'
            "
      >{{ $t("issue-calendar.list") }}</span
      >
    </button>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { IssueCalendarMode } from "@/models/issueCalendarConfig";

const props = defineProps( {
  modelValue: {
    type: String as PropType<IssueCalendarMode | null>,
    required: true
  },
  flex: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue'])

const calendarSelected = computed(() => {
  return props.modelValue === 'calendar'
})

const listSelected = computed(() => {
  return props.modelValue === 'list'
})
</script>
