<template>
  <div class="mt-2 hint">
    <slot>
      {{ $t("order-number-hint.message") }}
    </slot>
    <template v-if="$i18n.locale === 'de-DE'">&nbsp;<a href="#" @click.prevent="showExample = true" class="link">{{
         $t("order-number-hint.show-example")
      }}</a>
      <img
        alt="label"
        v-if="showExample"
        class="mt-2 h-48"
        :src="imageUrl"
      />
    </template>
  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import defaultImageUrl from "../assets/adressaufkleber.png";
import { useTenant } from "@/plugins/tenant";

const tenant = useTenant()

const showExample = ref(false)
const imageUrl = tenant.orderNumberExampleImageUrl ?? defaultImageUrl
</script>
