<template>
  <Badge :color="color" border>
    {{ text }}
  </Badge>
</template>
<script lang="ts" setup>
import { computed, PropType } from "vue";
import { InvoiceStatus } from "@/models/payment";
import Badge from "@/components/Badge.vue";
import type { BadgeColor } from "@/components/Badge.vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  status: {
    type: String as PropType<InvoiceStatus>,
    required: true
  }
})

const {tm} = useI18n()

const color = computed(() =>  {
  switch (props.status) {
    case InvoiceStatus.Unknown:
      return "gray";
    case InvoiceStatus.Paid:
      return "green";
    case InvoiceStatus.PartiallyPaid:
      return "orange";
    case InvoiceStatus.Unpaid:
      return "indigo";
  }
  return "gray"
})

const text = computed(() => {
  return (tm("payments.status.options") as any)[props.status];
})
</script>
