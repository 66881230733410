import { App, inject } from "vue";
import VueFinalModal, { VueFinalModalProperty } from "vue-final-modal";

export function useVfm(): VueFinalModalProperty {
  return inject('vfm')!;
}

export default {
  install: (app: App) => {
    app.use(VueFinalModal)
    app.provide('vfm', app.config.globalProperties.$vfm)
  }
}
