import axios, { AxiosError, AxiosRequestConfig, AxiosStatic } from "axios";
import { JSONHelper } from "@/util/jsonHelper";
// @ts-ignore
import { App, inject, Plugin } from "vue";
import VueAxios from "vue-axios";
import { useAuth } from "@/pinia/auth";

axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL as string;

// this transformer uses a "reviver" to parse date objects
axios.defaults.transformResponse = function (data: any, headers?: any) {
  if (data != null && headers["content-type"] === "application/json") {
    return JSONHelper.deserialize(data);
  }
  return data;
};

export function useAxios(): AxiosStatic {
  return inject('axios')!
}

export default {
  install: (app: App) => {
    // interceptor that notifies the store whenever a 401 error occurs (i.e. the session timed out)
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError) => {
        if (error.request)
          if (
            error.response &&
            error.response.status === 401 &&
            !error.response.config.url!.includes("login")
          ) {
            const authStore = useAuth()
            authStore.logOut(false)
          }
        return Promise.reject(error);
      }
    );

    // interceptor that attaches the auth token to each request
    axios.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        // @ts-ignore
        const authStore = useAuth()
        const token = authStore.accessToken;
        if (token) {
          config.headers!["x-auth-token"] = "Bearer " + token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    app.use(VueAxios, axios)
    app.provide('axios', app.config.globalProperties.axios)
  }
} as Plugin
