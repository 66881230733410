<template>
  <CustomizableButton
    :loading="downloading"
    :disabled="isExpired"
    @click.native="startDownload"
    :icon-only="iconOnly"
    name="download"
  >
  </CustomizableButton>
</template>

<script lang="ts" setup>
import { Issue } from "@/models/issue";
import { Subscription } from "@/models/subscription";
import { Download } from "@/models/download";
import { PurchaseOrder } from "@/models/purchaseOrder";
import { DownloadStatus } from "@/models/downloadStatus";
import { computed, PropType, ref } from "vue";
import CustomizableButton from "@/components/CustomizableButton.vue";
import { useVfm } from "@/plugins/vfm";
import { useTenant } from "@/plugins/tenant";
import { useTracker } from "@/plugins/tracker";
import { useAxios } from "@/plugins/axios";

const props = defineProps({
  iconOnly: Boolean,
  subscription: {
    type: Subscription as PropType<Subscription>,
    default: null
  },
  purchaseOrder: {
    type: Object as PropType<PurchaseOrder>,
    default: null
  },
  issue: {
    type: Object as PropType<Issue>,
    default: null
  },
  download: {
    type: Object as PropType<Download>,
    default: null
  },
})

const emit = defineEmits(["complete"])

const vfm = useVfm()
const tenant = useTenant()
const tracker = useTracker()
const axios = useAxios()

const downloading = ref(false)
const tab = ref<Window|null>(null)

const isExpired = computed(() => {
  return (
    props.purchaseOrder != null &&
    props.purchaseOrder.downloadStatus === DownloadStatus.Expired
  );
})

async function startDownload() {
  try {
    prepareDownload();
    let download;
    if (props.download) {
      download = props.download;
    } else if (props.purchaseOrder) {
      download = await downloadPurchaseOrder();
    } else {
      download = await downloadIssue();
    }
    completeDownload(download);
  } catch (e) {
    console.log(e)
    abortDownload();
  }
}

async function downloadIssue() {
  const response = await axios.post<Download>(
    `subscriptions/${props.subscription!.id}/delivered-issues/download`,
    {
      issue: props.issue,
    }
  );
  return response.data;
}

async function downloadPurchaseOrder() {
  const response = await axios.get<Download>(
    `purchase-orders/${props.purchaseOrder!.id}/download`,
    {
      params: {
        distributionArticleNumber: props.purchaseOrder!
          .distributionArticleNumber,
      },
    }
  );
  return response.data;
}

function prepareDownload() {
  downloading.value = true;
  if (tenant.downloadConfig.openInNewTab) {
    tab.value = openTab();
  }
}

function completeDownload(download: Download) {
  if (tab.value) {
    tab.value.location.href = download.url;
  } else {
    vfm.show("downloadStarted");
    window.location.href = download.url;
  }
  tracker.trackEvent("Frontend", "PdfDownload", download.key);
  downloading.value = false;
  emit("complete");
}


function abortDownload() {
  downloading.value = false;
  tab.value?.close();
  vfm.show("downloadError");
}

function openTab() {
  const tab = window.open(undefined, "_blank");
  if (!tab) {
    console.warn('Failed to open tab')
    return null
  }
  tab.document.title = "Download";
  const element = tab.document.createElement("div");
  element.style.textAlign = "center";
  element.style.fontFamily = "sans-serif";
  element.style.fontSize = "1.5rem";
  element.style.padding = "4rem";
  element.append("Bitte warten, Sie werden in Kürze weitergeleitet...");
  tab.document.body.appendChild(element);
  return tab;
}


</script>
