<template>
  <AlertDialog name="confirm-invoice-request" :positive-text="$t('payments.request-invoice')" :negative-text="$t('app.cancel')" @positive="positive">
    <template #title>
      {{ $t('payments.request-invoice') }}
    </template>
    {{$t('payments.confirm-request-invoice')}}
  </AlertDialog>
</template>

<script lang="ts" setup>
import AlertDialog from "@/components/AlertDialog.vue";

const emit = defineEmits(['confirm'])

function positive() {
  emit('confirm')
}
</script>
