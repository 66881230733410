<template>
  <div>
    <PaymentMethodUpdaterCard
      :payment-method="PaymentMethod.CREDIT_CARD"
      :active="isActive"
      :disabled="props.disabled"
      @edit="startUpdateProcess"
    >
      <template #details>
        <div class="mt-2">
          {{ account.ccNumber }}
        </div>
        <div class="mt-1 text-gray-600 sm:flex sm:items-center">
          <div>
            {{ account.ccCardholderName }}
          </div>
          <span class="hidden sm:mx-2 sm:inline" aria-hidden="true">
            &middot;
          </span>
          <div class="mt-1 sm:mt-0">
            {{
              $t("subscription-payment-details.method-expires-at", {
                date: ccExpiryDate,
              })
            }}
          </div>
        </div>
      </template>
    </PaymentMethodUpdaterCard>
    <PopupWindow
      ref="popup"
      v-if="isUpdating"
      :url="popupWindowUrl"
      :placeholder="$t('payment-method-updater.please-wait')"
      :title="$t('credit-card-updater.add-credit-card')"
      target="creditcard"
      with-overlay
      @close="handlePopupClose"
      @message="handlePopupMessage"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from "vue";
import { CreditCardAccount, PaymentDetails, PaymentMethod } from "@/models/paymentDetails";
import { useAxios } from "@/plugins/axios";
import { useTracker } from "@/plugins/tracker";
import PopupWindow from "@/components/PopupWindow.vue";
import PaymentMethodUpdaterCard from "./PaymentMethodUpdaterCard.vue";
import { Subscription } from "@/models/subscription";

const axios = useAxios();
const tracker = useTracker();

const props = defineProps({
  subscription: {
    type: Subscription as PropType<Subscription>,
    required: true
  },
  paymentDetails: {
    type: Object as PropType<PaymentDetails>,
    required: true
  },
  disabled: Boolean
})
const emit = defineEmits(['success', 'error'])
const isActive = computed(() => PaymentMethod.CREDIT_CARD === props.paymentDetails.paymentMethod)

const isUpdating = ref(false);
const popupWindowUrl = ref("");
const popup = ref<typeof PopupWindow | null>(null);

const account = computed(() => props.paymentDetails.account as CreditCardAccount)

const ccExpiryDate = computed(() => {
  const year = account.value.ccExpiry.substring(0, 4);
  const month = account.value.ccExpiry.substring(4, 6);
  return `${month}/${year}`;
})

function handlePopupClose() {
  isUpdating.value = false;
  popupWindowUrl.value = "";
}

function handlePopupMessage(message: string) {
  isUpdating.value = false;
  popupWindowUrl.value = "";
  emit("success");
}

async function fetchPaymentWidgetUrl() {
  const response = await axios.post<any>(
    `/subscriptions/${props.subscription.id}/payment-details/credit-card`
  );
  return response.data.url;
}

async function startUpdateProcess() {
  try {
    isUpdating.value = true;
    popupWindowUrl.value = await fetchPaymentWidgetUrl();
    tracker.trackEvent(
      "Subscription",
      "ChangePaymentDetails",
      PaymentMethod.CREDIT_CARD
    );
  } catch (e) {
    isUpdating.value = false;
    emit("error");
  }
}
</script>

