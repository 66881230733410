<template>
  <PaymentMethodUpdaterCard
    :payment-method="PaymentMethod.DIRECT_DEBIT"
    :active="isActive"
    :editing="isEditing"
    :disabled="props.disabled || isSubmitting"
    :loading="isSubmitting"
    @edit="isEditing = true"
    @cancel="isEditing = false"
    @submit="submit"
  >
    <template #details>
      <div class="mt-2">
        {{ account.debitAccountHolder }}
      </div>
      <div class="mt-1">
        {{ account.debitAccountNumber }}
      </div>
      <div class="mt-1">
        {{ account.debitBankName }}
      </div>
    </template>
    <template #form>
      <FormInput
        :label="$t('direct-debit-updater.account-holder.label')"
        :placeholder="$t('direct-debit-updater.account-holder.placeholder')"
        v-model="newAccount.debitAccountHolder"
        required
      />
      <FormInput
        v-model="newAccount.debitAccountNumber"
        :label="$t('direct-debit-updater.account-number.label')"
        required
      ></FormInput>
      <FormInput
        v-model="newAccount.debitBankCode"
        :label="$t('direct-debit-updater.bank-code.label')"
        required
      ></FormInput>
      <p class="text-gray-600 text-sm mt-4">
        {{ notes }}
      </p>
    </template>
  </PaymentMethodUpdaterCard>
</template>

<script setup lang="ts">
import { computed, PropType, reactive, ref } from "vue";
import { DirectDebitAccount, PaymentDetails, PaymentMethod } from "@/models/paymentDetails";
import { useAxios } from "@/plugins/axios";
import { useTracker } from "@/plugins/tracker";
import FormInput from "@/components/form/FormInput.vue";
import PaymentMethodUpdaterCard from "./PaymentMethodUpdaterCard.vue";
import { Subscription } from "@/models/subscription";
import { useTenant } from "@/plugins/tenant";
import { useOrders } from "@/pinia/orders";

const axios = useAxios();
const tracker = useTracker();
const orders = useOrders();
const tenant = useTenant();

const props = defineProps({
  subscription: {
    type: Subscription as PropType<Subscription>,
    required: true
  },
  paymentDetails: {
    type: Object as PropType<PaymentDetails>,
    required: true
  },
  disabled: Boolean
})
const emit = defineEmits(['success', 'error'])
const isActive = computed(() => PaymentMethod.DIRECT_DEBIT === props.paymentDetails.paymentMethod)

const isSubmitting = ref(false);
const isEditing = ref(false);

const account = computed(() => props.paymentDetails.account as DirectDebitAccount)
const notes = computed(() => {
  return (
    tenant.paymentMethodChangeConfig.sepaNotes[
      props.subscription.salesOrganization
      ] || tenant.paymentMethodChangeConfig.fallbackSepaNotes
  );
})

function emptyAccount(): Partial<DirectDebitAccount> {
  return {
    debitAccountHolder: account.value && account.value.debitAccountHolder ? account.value.debitAccountHolder : "",
    debitAccountNumber: "",
    debitBankCode: "",
  };
}

const newAccount = reactive(emptyAccount())

async function submit() {
  try {
    isSubmitting.value = true;
    await orders.updateDirectDebitPaymentDetails({
      subscriptionId: props.subscription.id,
      account: newAccount,
    });
    tracker.trackEvent(
      "Subscription",
      "ChangePaymentDetails",
      PaymentMethod.DIRECT_DEBIT
    );
    isEditing.value = false;
    emit("success");
  } catch (e) {
    emit("error");
  } finally {
    isSubmitting.value = false;
  }
}
</script>
