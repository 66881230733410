<template>
  <button
    :disabled="loading"
    @click="downloadInvoice"
    :title="$t('payments.download-invoice')"
  >
    <InlineIconSpinner v-if="loading" inverted />
    <font-awesome-icon v-else icon="download" />
    <slot />
  </button>
</template>

<script lang="ts" setup>
import { Payment } from "@/models/payment";
import { Subscription } from "@/models/subscription";
import { PropType, ref } from "vue";
import InlineIconSpinner from "@/components/InlineIconSpinner.vue";
import { useAxios } from "@/plugins/axios";
import { useVfm } from "@/plugins/vfm";
import { useTracker } from "@/plugins/tracker";

const props = defineProps({
  subscription: {
    type: Subscription,
    required: true
  },
  payment: {
    type: Object as PropType<Payment>,
    required: true
  }
})

const axios = useAxios()
const vfm = useVfm()
const tracker = useTracker()

const loading = ref(false)

async function downloadInvoice() {
  loading.value = true;
  try {
    const response = await axios.get<any>(
      `/subscriptions/${props.subscription.id}/invoices/${props.payment.id}/download`,
      { responseType: "blob" }
    );
    const blob = new Blob([response.data], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${props.payment.documentId}.pdf`;
    link.click();
    URL.revokeObjectURL(link.href);
    tracker.trackEvent(
      "Subscription",
      "DownloadInvoice",
      props.subscription.publication
    );
  } catch (error) {
    vfm.show("downloadError");
  } finally {
    loading.value = false;
  }
}
</script>
