import { BusinessPartnerRole } from "@/models/businessPartner";

export type ContactActivityInfoMessageType = "warning" |
  "success" |
  "error" |
  "info"

export interface ContactActivity {
  name: string;
  activity: string;
  infoMessage?: string;
  infoMessageType?: ContactActivityInfoMessageType;
  requiresSubscription: boolean;
  supportsSelfService: boolean;
  linkedPage: string;
  preferredRole: BusinessPartnerRole;
  visibility: ContactActivityVisibility;
  supportsImageUpload: boolean;
  disableMessageInput: boolean;
  showOptInCheckbox: boolean;
  allowMissingOrderNumber: boolean;
}

export enum ContactActivityVisibility {
  All = "all",
  Authenticated = "authenticated",
  Anonymous = "anonymous",
}
