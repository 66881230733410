<template>
  <Menu as="div" class="ml-3 relative" v-if="auth.isLoggedIn">
    <MenuButton class="w-6 h-6">
      <font-awesome-icon icon="user-circle" size="lg" class="text-muted" />
    </MenuButton>
    <MenuItems
      class="origin-top-right absolute right-0 mt-2 w-56 shadow-lg"
    >
      <div class="py-1 bg-white shadow-xs">
        <MenuItem
          as="div"
          disabled
          class="block text-sm break-words text-left font-semibold px-4 py-2 leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-100 ease-in-out"
        >
          {{ auth.user!.email }}
        </MenuItem>
        <MenuItem
          as="button"
          @click="logout"
          class="block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-100 ease-in-out"
        >
          {{ $t("auth.log-out") }}
        </MenuItem>
      </div>
    </MenuItems>
  </Menu>
</template>

<script lang="ts" setup>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { useAuth } from "@/pinia/auth";

const auth = useAuth()

function logout() {
  auth.logOut(false)
}
</script>

<style scoped></style>
