<template>
  <span :class="classes">
    {{ $tm(`purchase-order.invoice-status-label`)[invoiceStatus] }}
  </span>
</template>
<script lang="ts" setup>
import { PurchaseOrderInvoiceStatus } from "@/models/purchaseOrder";
import { computed, PropType } from "vue";

const props = defineProps({
  invoiceStatus: {
    type: String as PropType<PurchaseOrderInvoiceStatus>,
    required: true
  }
})

const classMap = new Map<PurchaseOrderInvoiceStatus, string>([
  [PurchaseOrderInvoiceStatus.Open, "text-danger-600"],
  [PurchaseOrderInvoiceStatus.Paid, "text-success-600"],
  [PurchaseOrderInvoiceStatus.Overpaid, "text-orange-600"],
]);

const classes = computed(() => classMap.get(props.invoiceStatus)!)
</script>
