<template>
  <div>
    <h3 class="font-display font-medium text-xl">
      {{ $t("accept-license.title") }}
    </h3>
    <p class="mt-2">
      {{ $t("accept-license.heading") }}
    </p>
    <hr class="my-4" />
    <FeatureDisabledAlert v-if="!featureEnabled"></FeatureDisabledAlert>
    <Spinner v-else-if="loading"></Spinner>
    <Alert v-else-if="error" variant="error">
      {{ $t("accept-license.error") }}
      <template #action>
        <button class="btn btn-border" @click="fetchLicense">
          {{ $t("app.try-again") }}
        </button>
      </template>
    </Alert>
    <Alert v-else-if="notFound" variant="info" icon="info-circle">
      {{ $t("accept-license.not-found") }}
    </Alert>
    <div
      v-else-if="success"
      class="flex flex-col justify-center items-center py-12"
    >
      <font-awesome-icon
        size="4x"
        class="text-green-500"
        icon="check-circle"
      ></font-awesome-icon>
      <p class="mt-6 text-xl">
        {{ $t("accept-license.success") }}
      </p>
    </div>
    <form v-else @submit.prevent="submit">
      <FormSectionHeader
        :title="$t('bp-form.your-license')"
        class="mt-6"
      ></FormSectionHeader>
      <div class="flex mt-4">
        <div class="text-center w-1/3 md:w-56">
          <CoverImage :src="license!.subscription!.coverImageUrl" />
        </div>
        <div class="ml-4">
          <dl>
            <dt class="font-semibold inline-block">
              {{ $t("subscription-detail.subscription-short") }}:
            </dt>
            &nbsp;
            <dd class="inline-block">{{ license!.subscription!.displayName }}</dd>
          </dl>
          <dl class="mt-1">
            <dt class="font-semibold inline-block">
              {{ $t("subscription-detail.status") }}:
            </dt>
            &nbsp;
            <dd class="inline-block">
              <OrderStatusLabel :subscription="license!.subscription!" />
            </dd>
          </dl>
        </div>
      </div>
      <FormSectionHeader
        :title="$t('bp-form.name-and-company')"
        class="mt-6"
      ></FormSectionHeader>
      <FormInput
        disabled
        required
        class="w-full"
        :label="$t('bp-form.email.label')"
        max-length="35"
        :model-value="email"
      />
      <FormSelect
        class="w-40"
        :label="$t('bp-form.salutation.label')"
        :options="salutations"
        value-key="code"
        name-key="title"
        required
        v-model="form.businessPartner.salutationCode"
      />
      <FormSelect
        v-if="$tenant.multiUserLicensesConfig.requireTitleDuringAccept"
        class="w-56"
        v-model="form.businessPartner.titleCodeAcademic"
        :options="academicTitles"
        :label="$t('bp-form.title.label')"
        value-key="code"
        name-key="title"
      />
      <div class="flex flex-wrap -mx-2">
        <FormInput
          class="w-full md:w-1/2 px-2"
          :label="$t('bp-form.first-name.label')"
          max-length="35"
          v-model="form.businessPartner.firstName"
          required
        />
        <FormInput
          class="w-full md:w-1/2 px-2"
          :label="$t('bp-form.last-name.label')"
          max-length="35"
          v-model="form.businessPartner.lastName1"
          required
        />
      </div>
      <FormInput
        class="w-full"
        :label="$t('bp-form.company.label')"
        :placeholder="$t('bp-form.company.placeholder')"
        max-length="35"
        required
        v-model="form.businessPartner.company1"
      />
      <FormSectionHeader
        v-if="$tenant.multiUserLicensesConfig.requireRoleDuringAccept"
        :title="$t('bp-form.other')"
        class="mt-6"
      ></FormSectionHeader>
      <FormSelect
        class="w-full"
        v-if="$tenant.multiUserLicensesConfig.requireRoleDuringAccept"
        :label="$t('bp-form.role.label')"
        :placeholder="$t('bp-form.role.placeholder')"
        :options="roles"
        required
        v-model="form.role"
      />
      <FormInput
        v-mask="'##'"
        v-if="requireAcademicYear"
        class="w-full"
        :label="$t('bp-form.academic-year.label')"
        max-length="2"
        v-model="form.academicYear"
        required
      />
      <ButtonBar class="mt-6">
        <ButtonWithLoadingIndicator
          type="submit"
          :disabled="success"
          :loading="submitting"
          class="btn btn-primary font-semibold"
        >
          {{ $t("accept-license.submit") }}
        </ButtonWithLoadingIndicator>
      </ButtonBar>
    </form>
    <AlertDialog name="error" :negative-text="$t('app.close')">
      {{ $t("accept-license.error") }}
    </AlertDialog>
  </div>
</template>

<script lang="ts" setup>
import {
  BusinessPartner,
  cloneBusinessPartnerWithDefaults,
  extractEmail,
} from "@/models/businessPartner";
import { MultiUserLicense } from "@/models/multiUserLicense";
import { Subscription } from "@/models/subscription";
import { computed, reactive, toRefs } from "vue";
import FeatureDisabledAlert from "@/components/FeatureDisabledAlert.vue";
import Spinner from "@/components/Spinner.vue";
import Alert from "@/components/Alert.vue";
import FormSectionHeader from "@/components/form/FormSectionHeader.vue";
import CoverImage from "@/components/CoverImage.vue";
import OrderStatusLabel from "@/components/OrderStatusLabel.vue";
import FormInput from "@/components/form/FormInput.vue";
import FormSelect from "@/components/form/FormSelect.vue";
import ButtonBar from "@/components/ButtonBar.vue";
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import AlertDialog from "@/components/AlertDialog.vue";
import { useAxios } from "@/plugins/axios";
import { useVfm } from "@/plugins/vfm";
import { useRoute } from "vue-router";
import { useTenant } from "@/plugins/tenant";

interface State {
  loading: boolean
  error: boolean
  notFound: boolean
  license: MultiUserLicense | null
  submitting: boolean
  success: boolean
  form: {
    businessPartner: BusinessPartner,
    role: string | null
    academicYear: string | null
  }
}

const tenant = useTenant()
const axios = useAxios()
const vfm = useVfm()
const route = useRoute()

const state = reactive<State>({
  loading: false,
  error: false,
  notFound: false,
  license: null,
  submitting: false,
  success: false,
  form: {
    businessPartner: cloneBusinessPartnerWithDefaults({}),
    role: null,
    academicYear: null,
  }
})

const {
  loading,
  error,
  notFound,
  license,
  submitting,
  success,
  form,
} = toRefs(state)

const featureEnabled = computed(() =>  {
  return tenant.supportsMultiUserLicenses();
})

const roles = computed(() =>  {
  return tenant.multiUserLicensesConfig.roles;
})

const email = computed(() =>  {
  return extractEmail(license.value!.businessPartner);
})

const salutations = computed(()  => {
  return tenant.salutations.filter(
    (salutation) => salutation.isUserSelectable
  );
})

const academicTitles = computed(()  => {
  return tenant.academicTitles.filter(
    (title) => title.isUserSelectable
  );
})

const requireAcademicYear = computed(() =>  {
  return (
    tenant.multiUserLicensesConfig.requireRoleDuringAccept &&
    selectedRole.value != null &&
    selectedRole.value.requireAcademicYear
  );
})

const selectedRole = computed(() =>  {
  return roles.value.find((role) => role.value === form.value.role)!;
})

async function fetchLicense() {
  try {
    loading.value = true;
    const response = await axios.get(route.fullPath);
    const newLicense = response.data as MultiUserLicense;
    newLicense.subscription = new Subscription(newLicense.subscription!);
    form.value.businessPartner = cloneBusinessPartnerWithDefaults(
      newLicense.businessPartner
    );
    license.value = newLicense;
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      notFound.value = true;
    } else {
      error.value = true;
    }
  } finally {
    loading.value = false;
  }
}

async function  submit() {
  try {
    submitting.value = true;
    await axios.post(route.path, form.value);
    success.value = true;
  } catch (error: any) {
    vfm.show("error");
  } finally {
    submitting.value = false;
  }
}

if (featureEnabled.value) {
  fetchLicense();
}
</script>
