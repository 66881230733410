<template>
  <div class="relative">
    <DatePicker
      :class="{
        'pointer-events-none': loading || disabled,
        'opacity-50': loading,
      }"
      :locale="$i18n.locale"
      is-inline
      is-expanded
      is-required
      is-range
      color="custom"
      :min-date="minDate"
      :max-date="maxDate"
      :attributes="calendarAttributes"
      @dayclick="handleDayClick"
      :model-value="modelValue"
      @update:modelValue="handleDateRangeChange"
    />
    <Spinner
      v-if="loading"
      class="absolute left-0 top-0 right-0 bottom-0 m-auto"
    />
  </div>
</template>
<script lang="ts" setup>
import Spinner from "@/components/Spinner.vue";
import { computed, defineAsyncComponent, PropType, ref } from "vue";
import { Issue } from "@/models/issue";
import { addWeeks } from "date-fns";
import { DateRange } from "@/models/dateRange";
import { useI18n } from "vue-i18n";
import 'v-calendar/dist/style.css';

const { t } = useI18n()

const props = defineProps({
  issues: Array as PropType<Issue[] | null>,
  loading: Boolean,
  modelValue: {
    type: Object as PropType<DateRange>,
    required: true
  },
  minDate: Date,
  maxDurationWeeks: {
    type: Number,
    default: 4
  },
  disabled: Boolean,
})

const emit = defineEmits(['update:modelValue'])

const DatePicker = defineAsyncComponent({
  // @ts-ignore
  loader: async () => (await import('v-calendar')).DatePicker,
  delay: 0,
  loadingComponent: Spinner,
});

const clickedDate = ref<Date | null>(null)

const calendarAttributes = computed(() => {
  if (props.issues === null) {
    return [];
  }
  return props.issues!.map((issue) => ({
    dot: true,
    dates: issue.dateOfFirstSale,
    popover: {
      label: t("issue.number-formatted", {
        issueNumber: issue.issueNumber,
      }),
      hideIndicator: true,
    },
  }));
})

const maxDate = computed(() => {
  if (clickedDate.value === null) {
    return null;
  }
  return addWeeks(clickedDate.value, props.maxDurationWeeks);
})

function handleDateRangeChange(dateRange: DateRange) {
  if (!props.disabled) {
    emit("update:modelValue", dateRange);
  }
}

function handleDayClick(event: any) {
  clickedDate.value = clickedDate.value === null ? event.date : null;
}
</script>
