<template>
  <li class="py-4 border-t">
    <div class="mb-2 font-display flex justify-between">
      <h3 class="font-bold text-lg">
        {{ rolesLabel }}
      </h3>
      <button type="button" v-if="!isEditing" @click="isEditing = true">
        <font-awesome-icon icon="edit" />
        <span class="sr-only sm:not-sr-only inline-block sm:ml-4">{{
            $t("app.edit")
          }}</span>
      </button>
    </div>
    <div>
      <Alert variant="info" v-if="futureAddresses.length">
        {{
          $tc(
            "subscription-business-partners.future-addresses",
            futureAddresses.length
          )
        }}
        <template #action>
          <button class="link" @click="showFutureAddresses">
            {{ $t("app.view") }}
          </button>
        </template>
      </Alert>
      <AlertDialog
        :name="businessPartner.bpNumber"
        :negative-text="$t('app.close')"
      >
        <BusinessPartnerAddressList
          :business-partners="futureAddresses"
        ></BusinessPartnerAddressList>
      </AlertDialog>
      <form @submit.prevent="submit" v-if="isEditing">
        <RequiredFieldsHint></RequiredFieldsHint>
        <BusinessPartnerAddressForm
          class="mt-4"
          :subscription="subscription"
          :business-partner="bp"
          :allow-first-name-change="$tenant.addressChangeConfig.allowFirstNameChange"
          :allow-last-name-change="$tenant.addressChangeConfig.allowLastNameChange"
          :allow-country-change="$tenant.addressChangeConfig.allowCountryChange"
          :allow-email-change="$tenant.addressChangeConfig.allowEmailChange"
          :allow-phone-number-change="$tenant.addressChangeConfig.allowPhoneNumberChange"
          :allow-date-of-birth-change="$tenant.addressChangeConfig.allowDateOfBirthChange"
          :email-required="emailRequired"
          :show-valid-from="$tenant.addressChangeConfig.allowTimedChanges"
          show-name
          show-email
          show-date-of-birth
          show-phone-number
          show-affected-issue-alert
        />
        <ButtonBar class="mt-6">
          <ButtonWithLoadingIndicator
            class="submit btn btn-primary"
            type="submit"
            :disabled="isSubmitting"
            :loading="isSubmitting"
          >
            {{ $t("bp-form.submit.label") }}
          </ButtonWithLoadingIndicator>
          <button
            class="submit btn mt-4 md:mt-0 md:ml-4"
            type="button"
            :disabled="isSubmitting"
            @click="cancelEdit"
          >
            {{ $t("bp-form.cancel.label") }}
          </button>
        </ButtonBar>
      </form>
      <BusinessPartnerAddress v-else :business-partner="bp" show-email />
    </div>
  </li>
</template>
<script lang="ts" setup>
import {
  BusinessPartner,
  BusinessPartnerRole,
  cloneBusinessPartnerWithDefaults,
} from "@/models/businessPartner";
import { Subscription } from "@/models/subscription";
import { shallowEqualObjects } from "@/util/equals";
import { isFuture } from "date-fns";
import { computed, defineProps, PropType, ref } from "vue";
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import ButtonBar from "@/components/ButtonBar.vue";
import BusinessPartnerAddress from "@/components/business-partner/BusinessPartnerAddress.vue";
import BusinessPartnerAddressForm from "@/components/business-partner/BusinessPartnerAddressForm.vue";
import Alert from "@/components/Alert.vue";
import BusinessPartnerAddressList from "@/components/business-partner/BusinessPartnerAddressList.vue";
import AlertDialog from "@/components/AlertDialog.vue";
import { useBusinessPartners } from "@/pinia/businessPartners";
import RequiredFieldsHint from "@/components/RequiredFieldsHint.vue";
import { useTracker } from "@/plugins/tracker";
import { useVfm } from "@/plugins/vfm";
import { useI18n } from "vue-i18n";

const props = defineProps({
  subscription: {
    type: Subscription,
    required: true
  },
  businessPartner: {
    type: Object as PropType<BusinessPartner>,
    required: true
  },
  roles: {
    type: Array as PropType<BusinessPartnerRole[]>,
    required: true
  }
})

const emit = defineEmits<{
  (e: 'success', value: BusinessPartner): void
  (e: 'error'): void
}>()

defineExpose({
  isDirty
})

const tracker = useTracker()
const vfm = useVfm()
const businessPartners = useBusinessPartners()
const {t} = useI18n()

const isSubmitting = ref(false)
const isEditing = ref(false)
const bp = ref(cloneBusinessPartnerWithDefaults(
  props.businessPartner
))

function cancelEdit() {
  bp.value = cloneBusinessPartnerWithDefaults(props.businessPartner);
  isEditing.value = false;
}

function showFutureAddresses() {
  vfm.show(props.businessPartner.bpNumber);
}

async function submit() {
  try {
    isSubmitting.value = true;
    await businessPartners.changeAddress({
      businessPartner: bp.value,
      subscriptionId: props.subscription.id,
    });
    isEditing.value = false;
    tracker.trackEvent("BusinessPartner", "ChangeAddress");
    emit("success", bp.value);
  } catch (error) {
    emit("error");
  } finally {
    isSubmitting.value = false;
  }
}

function isDirty() {
  return (
    isEditing.value && !shallowEqualObjects(props.businessPartner, bp.value)
  )
}

const emailRequired = computed(() => {
  // an email is required if the currently edited business partner has at least one of the roles of the logged in OCS user.
  return props.roles.some((role) => props.subscription.roles.includes(role));
})

const rolesLabel = computed(() => {
  return props.roles
    .map((role) => t(`business-partner-roles.${role}`))
    .join(", ");
})

const futureAddresses = computed(() => {
  return props.businessPartner.otherAddresses.filter((bp) =>
    isFuture(bp.validFrom!)
  );
})
</script>
