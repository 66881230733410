<template>
  <Overlay :show="withOverlay">
    <div class="text-white text-center max-w-lg p-4 z-20">
      <Spinner></Spinner>
      <p class="text-lg">{{ $t('popup-window.follow-instructions') }}</p>
      <p class="mt-2">
        <button type="button" class="underline" @click="focus">{{ $t('popup-window.focus-window') }}</button>
      </p>
    </div>
  </Overlay>
</template>
<script lang="ts" setup>
import { copyStyles } from "@/util/copyStyles";
import { computed, onBeforeUnmount, onMounted, PropType, watch } from "vue";
import Overlay from "@/components/Overlay.vue";
import Spinner from "@/components/Spinner.vue";

interface WindowFeatures {
  left?: number;
  top?: number;
  width: number;
  height: number;
  status?: boolean;
  scrollbars?: boolean;
  resizable?: boolean;
}

const props = defineProps({
  url: String,
  target: String,
  placeholder: {
    type: String,
    required: true
  },
  center: {
    type: String,
    default: "screen"
  },
  title: {
    type: String,
    default: ""
  },
  features: {
    type: Object as PropType<WindowFeatures>,
    default: (): WindowFeatures => {
      return {
        width: 640,
        height: 640,
        status: true,
        scrollbars: true,
        resizable: true,
      }
    }
  },
  withOverlay: Boolean
})

const emit = defineEmits(['message', 'close'])

function toWindowFeatures(obj: any): string {
  return Object.keys(obj)
    .reduce<string[]>((features, name: string) => {
      const value = obj[name];
      if (typeof value === "boolean") {
        features.push(`${name}=${value ? "yes" : "no"}`);
      } else {
        features.push(`${name}=${value}`);
      }
      return features;
    }, [])
    .join(",");
}

function buildWindowFeatures(defaultFeatures: WindowFeatures, center: string) {
  const features = Object.assign({}, defaultFeatures);
  if (center === "parent") {
    features.left =
      window.top!.outerWidth / 2 + window.top!.screenX - features.width / 2;
    features.top =
      window.top!.outerHeight / 2 + window.top!.screenY - features.height / 2;
  } else if (center === "screen") {
    let top = 0;
    let left = 0;
    window.outerWidth
      ? (left =
        Math.round((window.outerWidth - features.width) / 2) +
        window.screenX)
      : window.screen.width &&
      (left = Math.round((window.screen.width - features.width) / 2));
    window.outerHeight
      ? (top =
        Math.round((window.outerHeight - features.height) / 2) +
        window.screenY)
      : window.screen.height &&
      (top = Math.round((window.screen.height - features.height) / 2));

    features.left = left;
    features.top = top;
  }
  return toWindowFeatures(features);
}

let interval: number | null = null
let popupWindow: Window | null = null

const targetOrigin = computed(() => {
  if (!props.url) {
    return null
  }
  const url = new URL(props.url);
  return url.origin
})

function handleMessageEvent(event: MessageEvent) {
  if (event.origin === targetOrigin.value) {
    // only handle events from the original url's origin
    emit("message", event.data);
  }
}

function focus() {
  popupWindow?.focus()
}

onMounted(() => {
  const openedWindow = window.open(
    props.url,
    props.target,
    buildWindowFeatures(props.features, props.center)
  )
  if (!openedWindow) {
    console.error(
      "Unable to create window! Make sure that the component is only shown in response to some user action."
    );
    return;
  }

  // periodically check if the window was closed by the user
  interval = window.setInterval(() => {
    if(openedWindow.closed) {
      emit("close");
    }
  }, 100);

  // add a message handler for events
  window.addEventListener("message", handleMessageEvent);

  // render some default content if the user did not provide an url
  if (!props.url) {
    // the user provided no url. In this case we render the default content
    // i.e. the markup of the default slot.
    openedWindow.document.title = props.title;
    const element = openedWindow.document.createElement("div");
    element.classList.add(
      "flex",
      "h-screen",
      "items-center",
      "justify-center",
      "text-xl"
    );
    element.append(props.placeholder);
    openedWindow.document.body.appendChild(element);
    setTimeout(() => copyStyles(document, openedWindow.document), 0);
  }
  popupWindow = openedWindow
})

onBeforeUnmount(() => {
  const openedWindow = popupWindow
  if (!openedWindow) {
    return
  }
  window.clearInterval(interval!);
  window.removeEventListener("message", handleMessageEvent);
  if (!openedWindow.closed) {
    openedWindow.close()
  }
})

// watch for changes to the url
watch(() => props.url, (value) => {
  if (!value) {
    return
  }
  const openedWindow = popupWindow;
  if (!openedWindow || openedWindow.closed) {
    console.error("Cant change url if window is closed!");
    return;
  }
  openedWindow.location.href = value
})

const showOverlay = computed(() => props.withOverlay && props.url)

</script>
