export enum ScanStatus {
  Pending = "",
  Shipped = "90"
}

export interface PackageInfo {
  packageNumber: string
  versWeg: string
  consignmentNumber: string | null
  deliveryDate: Date | null
  scanStatus: string | null
  scanStatusDate: string | null
}
