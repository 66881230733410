<template>
  <AlertDialog name="order-attribute"
               disable-auto-hide
               @negative="close">
    <template #actions>
      <button
        class="btn font-semibold"
        @click="close"
        type="button"
        :disabled="loading"
      >
        {{ $t('app.close') }}
      </button>
      <ButtonWithLoadingIndicator
        v-if="!success"
        class="btn-primary font-semibold ml-2"
        type="submit"
        form="order-attribute-form"
        :loading="loading"
      >
        {{ $t('app.save') }}
      </ButtonWithLoadingIndicator>
    </template>
    <template #title>
      {{
        $t('subscription-detail.edit-attribute', {
          attribute: attribute.label
        })
      }}
    </template>
    <Alert variant="success" icon="check" v-if="success">
      {{$t('subscription-detail.attribute-updated')}}
    </Alert>
    <form @submit.prevent="submit" id="order-attribute-form" class="-mt-5" v-else>
      <FormInput
        :label="attribute.label"
        :type="attribute.inputType ?? 'text'"
        required
        v-model="value"
      />
    </form>
  </AlertDialog>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import AlertDialog from "@/components/AlertDialog.vue";
import { Subscription } from "@/models/subscription";
import { useVfm } from "@/plugins/vfm";
import { useTenant } from "@/plugins/tenant";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useAuth } from "@/pinia/auth";
import { useAxios } from "@/plugins/axios";
import { useTracker } from "@/plugins/tracker";
import { OrderAttributeConfig } from "@/models/orderAttributesConfig";
import FormInput from "@/components/form/FormInput.vue";
import { useOrders } from "@/pinia/orders";
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import Alert from "@/components/Alert.vue";
import ErrorModal from "@/components/ErrorModal.vue";

const props = defineProps<{
  subscription: Subscription,
  attribute: OrderAttributeConfig
}>()

const tenant = useTenant()
const vfm = useVfm()
const { t, d, tm } = useI18n()
const router = useRouter()
const auth = useAuth()
const axios = useAxios()
const tracker = useTracker()
const orders = useOrders()

const loading = ref(false)
const success = ref(false)
const value = ref<string>(props.attribute.value!)

function close() {
  vfm.hide('order-attribute');
}

async function submit() {
  loading.value = true
  try {
    await orders.updateAttribute({
      subscriptionId: props.subscription.id,
      attribute: {
        type: props.attribute.type,
        value: value.value
      }
    })
    success.value = true
  } catch (e) {
    vfm.show({
      component: ErrorModal
    })
  } finally {
    loading.value = false
  }
}

</script>
