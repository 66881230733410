<template>
  <PaymentMethodUpdaterCard
    :payment-method="PaymentMethod.INVOICE"
    :active="isActive"
    @edit="edit"
  >
    <template #details>
      <BusinessPartnerAddress
        class="mt-2"
        :business-partner="businessPartner"
      ></BusinessPartnerAddress>
    </template>
  </PaymentMethodUpdaterCard>
</template>

<script setup lang="ts">
import PaymentMethodUpdaterCard from "./PaymentMethodUpdaterCard.vue";
import { PaymentDetails, PaymentMethod } from "@/models/paymentDetails";
import { useRouter } from "vue-router";
import BusinessPartnerAddress from "@/components/business-partner/BusinessPartnerAddress.vue";
import { computed, PropType } from "vue";
import { Subscription } from "@/models/subscription";

const props = defineProps({
  subscription: {
    type: Subscription as PropType<Subscription>,
    required: true
  },
  paymentDetails: {
    type: Object as PropType<PaymentDetails>,
    required: true
  },
  disabled: Boolean
})
const emit = defineEmits(['success', 'error'])
const isActive = computed(() => PaymentMethod.INVOICE === props.paymentDetails.paymentMethod)

const router = useRouter()

const businessPartner = computed(() => props.subscription.orderingParty)

function edit() {
  router.push({
    name: "subscription-business-partners",
    params: {
      id: props.subscription.id,
    },
  });
}

</script>
