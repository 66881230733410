<template>
  <CustomizableButton
    :loading="downloadLoading"
    @click.native="showModal"
    name="gift-certificate"
    class="btn btn-primary"
  >
  </CustomizableButton>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { Subscription } from "@/models/subscription";
import CustomizableButton from "@/components/CustomizableButton.vue";
import { useAxios } from "@/plugins/axios";
import { useTracker } from "@/plugins/tracker";
import { useVfm } from "@/plugins/vfm";
import { useI18n } from "vue-i18n";
import GiftCertificatesModal from "@/components/GiftCertificatesModal.vue";

const props = defineProps({
  subscription: {
    type: Subscription,
    required: true
  }
})

const axios = useAxios()
const tracker = useTracker()
const vfm = useVfm()
const { t } = useI18n()

const downloadLoading = ref(false)

function showModal() {
  vfm.show({
    component: GiftCertificatesModal,
    bind: {
      subscription: props.subscription
    }
  })
}
</script>
