import 'iframe-resizer/js/iframeResizer.contentWindow';
import { createApp } from 'vue'
import App from './App.vue'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import './index.css'

// polyfillys
import 'core-js/proposals/promise-all-settled'

// plugins
import Icons from './plugins/icons'
import createI18n from "./plugins/i18n";
import { createStore } from "./pinia";
import AxiosPlugin from "./plugins/axios";
import TrackerPlugin from "./plugins/tracker";
import TenantPlugin from './plugins/tenant'
import VfmPlugin from './plugins/vfm'
import { Tenant } from "./models/tenant";
import router from "./router";
import { inIframe } from "@/util/iframe";
import { postMessage } from "@/util/window";
import { maska } from 'maska'
import { useAuth } from "@/pinia/auth";

const tenantJson = JSON.parse(document.getElementById("tenant")!.innerHTML);
const tenant = new Tenant(tenantJson);
const store = createStore(tenant);

const query = new URLSearchParams(window.location.search);
const isEmbedded = tenant.isEmbedded || inIframe()
const hideNav = Boolean(query.get('hideNav'))
const hideBackToOverview = Boolean(query.get('hideBackToOverview'))
const hasLoginToken = query.has('p')

const app = createApp(App, {
  isEmbedded,
  hideNav,
  hideBackToOverview
})

if (import.meta.env.MODE === 'staging' || import.meta.env.MODE === 'production') {
  const envMap: Record<string, string> = {
    'production': 'prod',
    'staging': 'stage',
    'development': 'dev'
  }

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: envMap[import.meta.env.MODE],
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: 0.25,
  });
}

app
  .use(TenantPlugin, tenant)
  .use(store)
  .use(AxiosPlugin)
  .use(Icons)
  .use(createI18n(tenant))
  .use(router)
  .use(TrackerPlugin, tenant, router)
  .use(VfmPlugin)
  .directive('mask', maska)

// Global navigation guard that checks if the user is authenticated and redirects him to the login page if not
router.beforeEach((to, from, next) => {
  const auth = useAuth()
  if (to.meta.noAuthenticated && auth.isLoggedIn) {
    // the user is logged in, but the route is only supposed to be shown to unauthenticated users
    next("/orders");
    return;
  }
  if (!to.meta.anonymous && !auth.isLoggedIn) {
    // the user is not logged in and the route does not allow anonymous access
    next({
      name: "login",
      query: to.query,
    });
    return;
  }

  // login to anonymous routes if a login token is present
  if (to.name != 'login' && to.meta.anonymous && !auth.isLoggedIn && hasLoginToken) {
    next({
      name: "login",
      query: to.query,
    });
    return;
  }
  next();
});

// this must be called AFTER the store has been added to the app
const authStore = useAuth()

// add store watchers that notify the parent window about session timeouts and login errors
authStore.$subscribe(
  (mutation, state) => {
    if (state.sessionHasTimedOut) {
      postMessage("sessionTimedOut");
    }
    if (!state.user && !isEmbedded) {
      router.replace("/login");
    }
  }
)

// load the session from the store if no login token is present
if (!hasLoginToken) {
  authStore.loadFromSession();
}

// finally, mount the app
app.mount('#app')
