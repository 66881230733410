<template>
  <AlertDialog name="cancellation-success"
               ref="root"
               :positive-text="$t('app.close')"
               :negative-text="supportsPrint ? $t('app.print') : undefined"
               disable-auto-hide
               @positive="close"
               @negative="print">
    <template #actions>
      <router-link
        v-if="showRevocationButton"
        class="btn font-semibold mr-4"
        :to="linkToRevocation"
        @click.native="hide"
      >
        {{ $t('buttons.cancellation-revocation') }}
      </router-link>
    </template>
    <template #title>
      <font-awesome-icon icon="check-circle" class="text-green-600"></font-awesome-icon>
      <span class="ml-4">{{ $t('subscription-cancellation.cancellation-success-title') }}</span>
    </template>
    <div class="prose prose--hard-break" v-html="successHtml"></div>
  </AlertDialog>
</template>

<script lang="ts" setup>
import { computed, PropType, ref, ComponentPublicInstance } from 'vue'
import AlertDialog from "@/components/AlertDialog.vue";
import { Subscription } from "@/models/subscription";
import { CancellationInfos } from "@/models/cancellationInfos";
import pupa from "pupa";
import { copyStyles } from "@/util/copyStyles";
import { useVfm } from "@/plugins/vfm";
import { useTenant } from "@/plugins/tenant";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useAuth } from "@/pinia/auth";

const props = defineProps({
  subscription: {
    type: Subscription,
    required: true
  },
  cancellationInfos: {
    type: Object as PropType<CancellationInfos>,
    required: true
  },
  cancellationDate: {
    type: String,
    required: true
  },
})

const tenant = useTenant()
const vfm = useVfm()
const {t,d,tm} = useI18n()
const router = useRouter()
const auth = useAuth()

const root = ref<ComponentPublicInstance|null>(null)

function hide() {
  vfm.hide('cancellation-success');
}

function close() {
  if (auth.isFullyLoggedIn) {
    router.push({
      name: 'subscription',
      params: {
        id: props.subscription.id
      }
    })
  } else {
    router.push({
      name: 'contact'
    })
  }
  vfm.hide('cancellation-success');
}

function print() {

  const w = window.open('', 'PRINT');
  if (!w) {
    return
  }

  w.document.write('<!DOCTYPE html><html><head><title>' + document.title + '</title>');
  w.document.write('</head><body >');
  w.document.write('<h1>' + document.title + '</h1>');
  w.document.write(root.value!.$el.innerHTML);
  w.document.write('</body></html>');

  copyStyles(window.document, w.document)

  w.document.close(); // necessary for IE >= 10
  w.focus(); // necessary for IE >= 10*/

  w.print();
  w.close();
}

const cancellationType = computed(() => {
  return props.cancellationInfos.cancellable ? 'regular' : 'exceptional'
})
const showRevocationButton = computed(() => {
  return tenant.supportsCancellationRevocation()
})
const linkToRevocation = computed(() => {
  return {
    name: 'contact',
    query: { subscriptionId: props.subscription.id, activity: tenant.cancellationConfig.revocationContactActivity }
  }
})
const successHtml = computed(() => {
  if (tenant.cancellationConfig.successMessageTemplate == null) {
    return t('subscription-cancellation.cancellation-success')
  }
  return pupa(tenant.cancellationConfig.successMessageTemplate, {
    timestamp: d(new Date(), 'long'),
    publication: props.subscription.displayName,
    orderNumber: props.subscription.orderNumber,
    bp: props.subscription.currentRoleBusinessPartner,
    cancellationType: (tm('subscription-cancellation.cancellation-types') as any)[cancellationType.value],
    cancellationDate: props.cancellationDate
  })
})
const supportsPrint = computed(() => 'print' in window)
</script>
