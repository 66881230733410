<template>
  <footer class="bg-dark text-white text-sm md:text-base p-8 flex">
    <p class="w-1/2">
      {{ $t("footer.provided-by") }}<br /><a href="https://dsb-fly.com" class="font-bold hover:underline">dsb fly</a>
    </p>
    <div class="w-1/2 text-right">
      <p class="mb-4">
        <b>{{ $t("footer.links") }}</b>
      </p>
      <p v-if="$tenant.supportsFrequentlyAskedQuestions()">
        <router-link to="/faq" class="hover:underline">{{
            $t("navbar.faq")
          }}
        </router-link>
      </p>
      <p v-if="$tenant.termsOfServiceUrl">
        <a :href="$tenant.termsOfServiceUrl" class="hover:underline">{{
            $t("footer.terms-of-service")
          }}</a>
      </p>
      <p v-if="$tenant.privacyPolicyUrl">
        <a :href="$tenant.privacyPolicyUrl" class="hover:underline">{{
            $t("footer.privacy-policy")
          }}</a>
      </p>
      <p v-if="$tenant.siteNoticeUrl">
        <a :href="$tenant.siteNoticeUrl" class="hover:underline">{{
            $t("footer.site-notice")
          }}</a>
      </p>
      <p v-if="$tenant.contactUrl">
        <a :href="$tenant.contactUrl" class="hover:underline">{{
            $t("footer.contact")
          }}</a>
      </p>
    </div>
  </footer>
</template>

<script lang="ts" setup>
</script>
