<template>
  <div class="grid gap-4 items-start" :class="twoCols ? 'lg:grid-cols-2' : ''">
    <DatePicker
      is-expanded
      :attributes="attributes"
      :min-date="minDate"
      :max-date="maxDate"
      :locale="$i18n.locale"
      color="custom"
      v-model="selectedDate"
    ></DatePicker>
    <Alert variant="info" icon="info-circle" v-if="!selectedDate">
      {{ $t("issue-calendar.select-date") }}
    </Alert>
    <alert
      variant="warning"
      icon="calendar"
      v-else-if="issuesOnSelectedDate.length === 0"
    >
      <i18n-t keypath="issue-calendar.no-issues-on-date">
        <template #date>{{ $d(selectedDate) }}</template>
      </i18n-t>
    </alert>
    <IssueCalendarListView
      :issues="issuesOnSelectedDate"
      v-if="issuesOnSelectedDate.length"
    >
    </IssueCalendarListView>
  </div>
</template>

<script lang="ts" setup>
import {  Issue } from "@/models/issue";
import { addYears, isSameDay } from "date-fns";
import { computed, defineAsyncComponent, PropType, ref } from "vue";
import Alert from "@/components/Alert.vue";
import IssueCalendarListView from "@/pages/issue-calendar/IssueCalendarListView.vue";
import Spinner from "@/components/Spinner.vue";
import 'v-calendar/dist/style.css';

const props = defineProps({
  issues: {
    type: Array as PropType<Issue[]>,
    required: true
  },
  twoCols: {
    type: Boolean,
    default: false
  }
})

const DatePicker = defineAsyncComponent({
  // @ts-ignore
  loader: async () => (await import('v-calendar')).DatePicker,
  delay: 0,
  loadingComponent: Spinner,
});

const minDate = ref(new Date())
const maxDate = ref(addYears(minDate.value, 1))
const selectedDate = ref<Date|null>(null)

const attributes = computed(() => {
  return [
    {
      key: "today",
      highlight: {
        fillMode: "light",
      },
      dates: minDate.value,
    },
    {
      key: "selected",
      highlight: true,
      dates: selectedDate.value,
    },
    ...props.issues.map((issue) => ({
      dates: issue.dateOfFirstSale,
      dot: true,
      customData: issue,
    })),
  ];
})

const issuesOnSelectedDate = computed(() => {
  if (selectedDate.value === null) {
    return [];
  }
  return props.issues.filter((issue) => {
    return isSameDay(selectedDate.value!, issue.dateOfFirstSale);
  });
})
</script>
