import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import OrdersPage from "@/pages/orders/OrdersPage.vue";
import FaqPage from "@/pages/faq/FaqPage.vue";
import LoginPage from "@/pages/login/LoginPage.vue";
import SubscriptionDetailsPage from "@/pages/subscription/SubscriptionDetailsPage.vue";
import MembershipsPage from "@/pages/memberships/MembershipsPage.vue";
import ContactPage from "@/pages/contact/ContactPage.vue";
import IssueCalendarPage from "@/pages/issue-calendar/IssueCalendarPage.vue";
import IssueCalendarPublicationPage from "@/pages/issue-calendar/publication/IssueCalendarPublicationPage.vue";
import LinkSubscriptionPage from "@/pages/link-subscription/LinkSubscriptionPage.vue";
import RenewalPage from "@/pages/renewal/RenewalPage.vue";
import UpgradePage from "@/pages/upgrade/UpgradePage.vue";
import CancellationPage from "@/pages/cancellation/CancellationPage.vue";
import EmailWidgetPage from "@/pages/email-widget/EmailWidgetPage.vue";
import SubscriptionAddressesPages from "@/pages/subscription/addresses/SubscriptionAddressesPages.vue";
import SubscriptionHolidayServicePage from "@/pages/subscription/holiday-service/SubscriptionHolidayServicePage.vue";
import SubscriptionPaymentDetailsPage from "@/pages/subscription/payment-details/SubscriptionPaymentDetailsPage.vue";
import SubscriptionComplaintPage from "@/pages/subscription/complaint/SubscriptionComplaintPage.vue";
import SubscriptionLicensesPage from "@/pages/subscription/licenses/SubscriptionLicensesPage.vue";
import SubscriptionCancellationPage from "@/pages/subscription/cancellation/SubscriptionCancellationPage.vue";
import SubscriptionRenewalPage from "@/pages/subscription/renewal/SubscriptionRenewalPage.vue";
import SubscriptionUpgradePage from "@/pages/subscription/upgrade/SubscriptionUpgradePage.vue";
import SubscriptionECardPage from "@/pages/subscription/ecard/SubscriptionECardPage.vue";
import ECardPage from "@/pages/e-card/ECardPage.vue";
import { HolidayServiceType } from "@/models/holidayServiceType";
import SubscriptionOptInsPage from "@/pages/subscription/opt-ins/SubscriptionOptInsPage.vue";
import AcceptLicensePage from "@/pages/subscription/licenses/AcceptLicensePage.vue";
import BusinessPartnersPage from "@/pages/business-partners/BusinessPartnersPage.vue";
import SubscriptionBusinessPartnerPage
  from "@/pages/subscription/business-partners/SubscriptionBusinessPartnerPage.vue";
import OptInWidgetPage from "@/pages/opt-in-widget/OptInWidgetPage.vue";

const routes = [
  {
    path: "/",
    redirect: "orders",
  },
  {
    path: "/link-subscription",
    name: "link-subscription",
    component: LinkSubscriptionPage,
  },
  {
    path: "/orders",
    name: "orders",
    component: OrdersPage,
  },
  {
    path: "/business-partners",
    name: "business-partners",
    component: BusinessPartnersPage,
  },
  {
    path: "/subscriptions/:id",
    name: "subscription",
    component: SubscriptionDetailsPage,
  },
  {
    path: "/subscriptions/:id/addresses",
    name: "subscription-business-partners",
    component: SubscriptionAddressesPages,
  },
  {
    path: "/subscriptions/:id/business-partners/:bpNumber",
    name: "subscription-business-partner",
    component: SubscriptionBusinessPartnerPage,
  },
  {
    path: "/subscriptions/:id/holiday-service",
    name: "subscription-holiday-service",
    component: SubscriptionHolidayServicePage,
  },
  {
    path: "/subscriptions/:id/holiday-redirect",
    name: "subscription-holiday-redirect",
    redirect(to) {
      return {
        name: "subscription-holiday-service",
        params: {
          id: to.params.id,
        },
        query: Object.assign({
            service: HolidayServiceType.Redirect,
          },
          to.query
        )
      };
    },
  },
  {
    path: "/subscriptions/:id/holiday-break",
    name: "subscription-holiday-break",
    redirect(to) {
      return {
        name: "subscription-holiday-service",
        params: {
          id: to.params.id,
        },
        query: Object.assign({
            service: HolidayServiceType.Break,
          },
          to.query
        )
      };
    },
  },
  {
    path: "/subscriptions/:id/holiday-download",
    name: "subscription-holiday-download",
    redirect(to) {
      return {
        name: "subscription-holiday-service",
        params: {
          id: to.params.id,
        },
        query: Object.assign({
            service: HolidayServiceType.Download,
          },
          to.query
        )
      };
    },
  },
  {
    path: "/subscriptions/:id/payment-details",
    name: "subscription-payment-details",
    component: SubscriptionPaymentDetailsPage,
  },
  {
    path: "/subscriptions/:id/complaint",
    name: "subscription-complaint",
    component: SubscriptionComplaintPage,
  },
  {
    path: "/subscriptions/:id/licenses",
    name: "subscription-licenses",
    component: SubscriptionLicensesPage,
  },
  {
    path: "/subscriptions/:id/licenses/:licenseId/accept",
    name: "accept-license",
    component: AcceptLicensePage,
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/subscriptions/:id/cancellation",
    name: "subscription-cancellation",
    component: SubscriptionCancellationPage,
  },
  {
    path: "/subscriptions/:id/renewal",
    name: "subscription-renewal",
    component: SubscriptionRenewalPage,
  },
  {
    path: "/subscriptions/:id/upgrade",
    name: "subscription-upgrade",
    component: SubscriptionUpgradePage,
  },
  {
    path: "/subscriptions/:id/opt-ins",
    name: "subscription-opt-ins",
    component: SubscriptionOptInsPage,
  },
  {
    path: "/subscriptions/:id/e-card",
    name: "subscription-e-card",
    component: SubscriptionECardPage,
  },
  {
    path: "/faq",
    name: "faq",
    component: FaqPage,
    meta: {
      anonymous: true,
    }
  },
  {
    path: "/memberships",
    name: "memberships",
    component: MembershipsPage,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactPage,
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/renewal",
    name: "renewal",
    component: RenewalPage,
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/upgrade",
    name: "upgrade",
    component: UpgradePage,
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/cancellation",
    name: "cancellation",
    component: CancellationPage,
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/email-widget",
    name: "email-widget",
    component: EmailWidgetPage,
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/opt-in-widget",
    name: "opt-in-widget",
    component: OptInWidgetPage,
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/e-card",
    name: "e-card",
    component: ECardPage,
  },
  {
    path: "/issue-calendar",
    name: "issue-calendar",
    component: IssueCalendarPage,
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/issue-calendar/publications/:publication",
    name: "issue-calendar-publication",
    component: IssueCalendarPublicationPage,
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: FaqPage,
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
    meta: {
      anonymous: true,
      noAuthenticated: true,
    },
  },
] as RouteRecordRaw[]

export default createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },

})
