<template>
  <span :title="name">
    {{ name }}
  </span>
</template>
<script lang="ts" setup>
import { BusinessPartner } from "@/models/businessPartner";
import { computed, PropType } from "vue";

const props = defineProps({
  businessPartner: {
    type: Object as PropType<BusinessPartner>,
    required: true
  }
})

const name = computed(() => {
  return [
    props.businessPartner.title,
    props.businessPartner.firstName,
    props.businessPartner.lastName1,
    props.businessPartner.lastName2,
  ]
    .filter((part) => part != null)
    .join(" ");
})
</script>
