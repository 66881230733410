<template>
  <div class="px-4 py-5 lg:px-8 max-w-xl mx-auto">
    <template v-if="isLoading">
      <p class="font-display text-2xl lg:text-3xl text-center">
        {{ $t("auth.login-loading") }}
      </p>
      <Spinner />
    </template>
    <Alert
      variant="error"
      v-if="loginError && !showLoginForm"
      class="text-lg mb-4"
    >
      {{ $t("auth.login-error") }}
    </Alert>
    <form v-if="showLoginForm" @submit.prevent="submitLoginForm">
      <h2 class="font-bold font-display text-3xl">{{$t('auth.login')}}</h2>
      <Alert
        v-if="loginError"
        class="mt-4"
        variant="error"
        data-testid="auth-login-error"
      >
        {{ $t("auth.login-error") }}
      </Alert>
      <div v-if="$tenant.supportsOAuth()" class="mt-4">
        <p>
          {{ $t('auth.sso-hint') }}
        </p>
        <CustomizableButton
          name="oauth"
          tag="a"
          href="/sso/authorize"
          class="btn btn-primary mt-6 block"
        ></CustomizableButton>
      </div>
      <div class="mt-4" v-else>
        <p>
          {{ $t("auth.email.hint") }}
        </p>
        <p
          class="mt-4 whitespace-pre-wrap"
          v-if="$tenant.standaloneLoginMessage !== null"
          v-html="$tenant.standaloneLoginMessage"
        ></p>
        <Alert v-if="submitSuccess" class="mt-4" variant="success">
          {{ $t("auth.email.check-your-inbox") }}
        </Alert>
        <FormInput
          name="email"
          :label="$t('auth.email.input-label')"
          type="email"
          :placeholder="$t('auth.email.input-placeholder')"
          required
          v-model="email"
        />
        <template v-if="showSsoIdInput">
          <FormInput
            name="sso-id"
            :label="$t('auth.sso-id.label')"
            :placeholder="$t('auth.sso-id.placeholder')"
            v-model="ssoId"
          />
          <p class="hint mt-1">
            {{ $t('auth.sso-id.hint') }}
          </p>
        </template>
        <ButtonWithLoadingIndicator
          type="submit"
          class="btn-primary w-full mt-6"
          :loading="submitting"
        >
          {{ $t("auth.email.continue") }}
        </ButtonWithLoadingIndicator>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import Alert from "@/components/Alert.vue";
import Spinner from "@/components/Spinner.vue";
import CustomizableButton from "@/components/CustomizableButton.vue";
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import FormInput from "@/components/form/FormInput.vue";
import { useAuth } from "@/pinia/auth";
import { useRoute, useRouter } from "vue-router";
import { useAxios } from "@/plugins/axios";
import { useTenant } from "@/plugins/tenant";

const authStore = useAuth()
const route = useRoute()
const router = useRouter()
const axios = useAxios()
const tenant = useTenant()

const isLoading = ref(false)
const submitting = ref(false)
const submitSuccess = ref(false)
const loginError = ref(null)
const showLoginFormOverride = ref(false)
const email = ref('')
const ssoId = ref('')
const showSsoIdInput = computed(() => import.meta.env.MODE !== "production")

function redirectToHome() {
  if (route.query.subscriptionId) {
    // redirect to the specific subscription
    router.replace({
      name: "subscription",
      params: {
        id: route.query.subscriptionId as string,
      },
    });
  } else if (route.redirectedFrom) {
    // redirect to the original location
    router.replace(route.redirectedFrom.path)
  } else {
    // redirect to the orders screen
    router.replace({ name: "orders" });
  }
}

async function loginUsingDev() {
  try {
    submitting.value = true;
    await authStore.loginUsingDev(email.value, ssoId.value);
    redirectToHome();
  } catch (e: any) {
    loginError.value = e;
  } finally {
    submitting.value = false;
  }
}

async function loginUsingOAuth() {
  try {
    isLoading.value = true;
    await authStore.loginUsingOAuth(route.query);
    redirectToHome();
  } catch (e: any) {
    loginError.value = e;
  } finally {
    isLoading.value = false;
  }
}

async function loginUsingMagicLink() {
  try {
    submitting.value = true;
    submitSuccess.value = false;
    loginError.value = null;
    showLoginFormOverride.value = true
    // trigger a magic link email
    await axios.post("/login/magic-link", {
      email: email.value,
    });
    submitSuccess.value = true;
  } catch (e: any) {
    loginError.value = e;
  } finally {
    submitting.value = false;
  }
}

async function loginUsingToken(token: string) {
  try {
    isLoading.value = true;
    await authStore.loginUsingToken(token);
    redirectToHome();
  } catch (e: any) {
    loginError.value = e;
  } finally {
    isLoading.value = false;
  }
}

function submitLoginForm() {
  if (import.meta.env.MODE === "production") {
    loginUsingMagicLink();
  } else {
    loginUsingDev();
  }
}

const token = route.query["p"];
if (typeof token === "string") {
  loginUsingToken(token);
} else if ("code" in route.query) {
  loginUsingOAuth();
} else {
  showLoginFormOverride.value = true;
}

const showLoginForm = computed(() => (
  showLoginFormOverride.value ||
  (!tenant.isEmbedded && loginError.value !== null)
))

</script>
