<template>
  <div>
    <div class="w-full border rounded-card mt-4">
      <table class="text-sm sm:text-base w-full overflow-x-auto">
        <thead>
          <tr class="border-none bg-gray-50">
            <th class="p-3" v-if="showTitleAcademic">{{ $t("business-partner.title-academic.label") }}</th>
            <th class="p-3">{{ $t("business-partner.name.label") }}</th>
            <th class="p-3">
              {{ $t("business-partner.email.label") }}
            </th>
            <th class="p-3">
              {{ $t("business-partner.reactivatable-abbr.label") }}
            </th>
            <th class="p-3" v-if="showActions"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="border-t border-tables"
            v-for="license in licensesInPage"
            :key="license.bpNumber"
          >
            <td class="p-3" v-if="showTitleAcademic">
              {{ license.businessPartner.titleAcademic }}
            </td>
            <td class="p-3">
              {{ extractName(license.businessPartner) }}
            </td>
            <td class="p-3">
              {{ extractEmailAddress(license.businessPartner) }}
            </td>
            <td class="p-3">
              <Checkmark
                :value="license.isReactivatable"
              ></Checkmark>
            </td>
            <td class="text-right whitespace-nowrap" v-if="showActions">
              <button
                v-if="license.isActive"
                class="py-3 px-4 text-gray-900"
                @click="emit('deactivate', license, props.object)"
                type="button"
              >
                <font-awesome-icon icon="pause"></font-awesome-icon>
              </button>
              <button
                v-else-if="license.isReactivatable"
                class="py-3 px-4 text-gray-900"
                @click="emit('activate', license, props.object)"
                type="button"
              >
                <font-awesome-icon icon="play"></font-awesome-icon>
              </button>
              <PopupMenu align="bottom">
                <MenuItem>
                  <button
                    class="btn btn-menu"
                    @click="emit('showDetails', license, props.object)"
                    type="button"
                  >
                    {{ $t('subscription-licenses.show-details') }}
                  </button>
                </MenuItem>
                <MenuItem v-if="license.isActive">
                  <button
                    class="btn btn-menu"
                    @click="emit('invite', license, props.object)"
                    type="button"
                  >
                    {{ $t('subscription-licenses.resend-invitation') }}
                  </button>
                </MenuItem>
                <MenuItem v-if="showDelete">
                  <button
                    class="btn btn-menu"
                    @click="emit('delete', license, props.object)"
                    type="button"
                  >
                    {{ $t('subscription-licenses.delete') }}
                  </button>
                </MenuItem>
              </PopupMenu>
            </td>
          </tr>
          <tr class="border-t border-tables" v-if="licenses.length === 0">
            <td colspan="4" class="p-3 text-center">
              {{ $t('subscription-licenses.no-licenses-found') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="p-3 bg-gray-50 w-full border border-t-0 border-tables"
      v-if="licenses.length > licensesPerPage"
    >
      <Pagination
        :items-per-page="licensesPerPage"
        :total="licenses.length"
        v-model="page"
      ></Pagination>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { MultiUserLicense } from "@/models/multiUserLicense";
import { MultiUserLicenseObject } from "@/models/multiUserLicenseObject";
import { BusinessPartner, extractEmail } from "@/models/businessPartner";
import { computed, PropType, ref } from "vue";
import Pagination from "@/components/pagination/Pagination.vue";
import PopupMenu from "@/components/PopupMenu.vue";
import { MenuItem } from "@headlessui/vue";
import Checkmark from "@/components/Checkmark.vue";

const props = defineProps({
  licenses: {
    type: Array as PropType<MultiUserLicense[]>,
    required: true
  },
  object: {
    type: Object as PropType<MultiUserLicenseObject>,
    required: true
  },
  showDelete: Boolean,
  showTitleAcademic: Boolean,
  showActions: Boolean
})

const emit = defineEmits(['showDetails', 'delete', 'invite', 'deactivate', 'activate'])

const page = ref(0)
const licensesPerPage = ref(10)

const licensesInPage = computed(() => {
  return props.licenses.slice(
    page.value * licensesPerPage.value,
    (page.value + 1) * licensesPerPage.value
  )
})

function extractName(businessPartner: BusinessPartner): string {
  if (businessPartner.company1) {
    return `${businessPartner.company1} ${businessPartner.company2}`;
  }
  return `${businessPartner.firstName} ${businessPartner.lastName1}`;
}

function extractEmailAddress(businessPartner: BusinessPartner) {
  return extractEmail(businessPartner);
}
</script>
