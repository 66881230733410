<template>
  <span v-if="subscription.paymentDetails">
    {{ $tm("payment-methods")[subscription.paymentDetails.paymentMethod] }}
  </span>
  <ContentLoader v-else viewBox="0 0 200 16">
    <rect x="100" y="0" rx="3" ry="3" width="100" height="16" />
  </ContentLoader>
</template>

<script lang="ts" setup>
import { Subscription } from "@/models/subscription";
import { useOrders } from "@/pinia/orders";
import { ContentLoader } from 'vue-content-loader'

const props = defineProps({
  subscription: {
    type: Subscription,
    required: true
  }
})

const orders = useOrders()
if (!props.subscription.paymentDetails) {
  orders.fetchPaymentDetails(props.subscription.id);
}
</script>
