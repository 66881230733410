import { CalendarIssue } from "@/models/issue";
import { acceptHMRUpdate, defineStore } from "pinia";
import { useOrders } from "@/pinia/orders";
import { Subscription } from "@/models/subscription";
import axios from "axios";

export interface AuthenticatedIssueCalendarState {
  issues: Array<CalendarIssue> | null;
  loading: boolean;
  error: boolean;
}

export const useAuthenticatedIssueCalendar = defineStore('authenticatedIssueCalendar', {
  state: (): AuthenticatedIssueCalendarState => ({
    issues: null,
    loading: false,
    error: false
  }),
  actions: {
    async fetch() {
      if (this.loading) {
        return
      }
      if (this.issues !== null){
        return
      }
      try {
        this.loading = true
        const orders = useOrders()
        await orders.fetch();
        // @ts-ignore
        const subscriptions = this.subscriptions as Subscription[]
        const issues = await Promise.all(
          subscriptions.map(async (subscription: Subscription) => {
            const response = await axios.get<Array<CalendarIssue>>(
              `/subscriptions/${subscription.id}/upcoming-issues`
            );
            return response.data.map((issue: CalendarIssue) => {
              issue.subscription = subscription;
              return issue;
            });
          })
        );
        this.$state = {
          issues: ([] as CalendarIssue[]).concat(...issues),
          loading: false,
          error: false
        }
      } catch (error) {
        this.$patch({
          error: true,
          loading: false
        })
      }
    },
  },
  getters: {
    subscriptions(state) {
      const orders = useOrders()
      const allSubscriptions = orders.listedSubscriptions
      const blacklistedPublications = this.tenant.issueCalendarConfig.blacklistedPublications;
      return allSubscriptions.filter((subscription) => {
        return (
          subscription.supportsIssueCalendar() &&
          !blacklistedPublications.includes(subscription.publication)
        );
      });
    },
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthenticatedIssueCalendar, import.meta.hot))
}
