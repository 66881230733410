import { createI18n as _createI18n } from 'vue-i18n'
import { set } from 'lodash-es'

import de from "@/translations/de-DE.json";
import en from "@/translations/en-GB.json";
import { Tenant } from "@/models/tenant";

const currencies = {
  EUR: {
    style: "currency",
    currency: "EUR",
  },
  GBP: {
    style: "currency",
    currency: "GBP",
  },
  USD: {
    style: "currency",
    currency: "USD",
  },
  AUD: {
    style: "currency",
    currency: "AUD",
  },
};

const datetimeFormats = {
  'de-DE': {
    short: {
      year: 'numeric', month: 'numeric', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'
    }
  },
  'en-GB': {
    short: {
      year: 'numeric', month: 'numeric', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric',
    }
  }
}


function getLocaleFromUrlParams() {
  const params = new URLSearchParams(window.location.search);
  const language = params.get("lang");
  if (!language) {
    return null;
  }
  if (language === "de") {
    return "de-DE";
  }
  if (language === "en") {
    return "en-GB";
  }
  return language;
}

function canonicalizeLocale(locale: string) {
  return locale.replace("_", "-");
}

export default function createI18n(tenant: Tenant) {

  const messages = {
    "de-DE": de,
    "en-GB": en,
  };

  // apply the translation overrides
  for (const key in tenant.translationOverrides) {
    // escape some special characters
    // see https://vue-i18n.intlify.dev/guide/essentials/syntax.html#special-characters
    const escapedMessage = tenant.translationOverrides[key].replace(/[@$|]/g, function (s) {
      return `{'${s}'}`
    })
    set(messages, key, escapedMessage);
  }

  return _createI18n({
    locale: canonicalizeLocale(getLocaleFromUrlParams() || tenant.defaultLocale),
    allowComposition: true,
    fallbackLocale: "en-GB",
    numberFormats: {
      "de-DE": currencies,
      "en-GB": currencies,
    },
    messages,
    // @ts-ignore
    datetimeFormats
  });
}
