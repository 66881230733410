import { BusinessPartner } from "@/models/businessPartner";
import { acceptHMRUpdate, defineStore } from "pinia";
import axios from "axios";
import { Subscription } from "@/models/subscription";

export interface BusinessPartnersState {
  businessPartners: { [key: string]: BusinessPartner };
}

export const useBusinessPartners = defineStore('businessPartners', {
  state: (): BusinessPartnersState => ({
    businessPartners: {},
  }),
  actions: {
    async changeAddress(
      payload: { businessPartner: BusinessPartner; subscriptionId: string }
    ) {
      await axios.post(
        `/subscriptions/${payload.subscriptionId}/business-partners/${payload.businessPartner.bpNumber}`,
        payload.businessPartner
      );
      this.updateBusinessPartner(payload.businessPartner)
    },
    addBusinessPartnersFromSubscriptions(
      subscriptions: Array<Subscription>
    ) {
      subscriptions.forEach((subscription) => {
        subscription.businessPartners.forEach((businessPartner) => {
          if (!(businessPartner.bpNumber in this.businessPartners)) {
            this.businessPartners[businessPartner.bpNumber] = businessPartner;
          }
        });
      });
    },
    addBusinessPartnersFromSubscription(subscription: Subscription) {
      subscription.businessPartners.forEach((businessPartner) => {
        if (!(businessPartner.bpNumber in this.businessPartners)) {
          this.businessPartners[businessPartner.bpNumber] = businessPartner;
        }
      });
    },
    updateBusinessPartner(businessPartner: BusinessPartner) {
      this.businessPartners[businessPartner.bpNumber] = businessPartner;
    },
  },
  getters: {
    getBusinessPartnerByBpNumber: (state) =>
      function (bpNumber: string): BusinessPartner {
        return state.businessPartners[bpNumber];
      },
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBusinessPartners, import.meta.hot))
}
