<template>
  <div id="app" :class="isEmbedded ? 'w-full bg-white' : 'container mx-auto bg-white'">
    <TheAnnouncement v-if="showAnnouncement" />
    <TheHeader v-if="!isEmbedded" />
    <div class="lg:flex">
      <TheNavbar v-if="showNavbar" class="lg:w-1/4 flex-none" />
      <main class="px-4 py-4 text-left flex-1 lg:w-3/4">
        <router-view v-slot="{ Component }">
          <transition name="fade-in-up">
            <component :is="Component" />
          </transition>
        </router-view>
      </main>
    </div>
    <TheFooter v-if="!isEmbedded" />
    <div id="chat-btn" class="fixed right-0 bottom-0"></div>
    <ModalsContainer></ModalsContainer>
  </div>
</template>

<script lang="ts" setup>
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheAnnouncement from "@/components/TheAnnouncement.vue";
import TheNavbar from "@/components/TheNavbar.vue";
import { useAuth } from "@/pinia/auth";
import { computed, provide } from "vue";
import { useTenant } from "@/plugins/tenant";

const props = defineProps({
  isEmbedded: Boolean,
  hideNav: Boolean,
  hideBackToOverview: Boolean
})

const auth = useAuth()
const tenant = useTenant()

const showNavbar = computed(() => auth.isFullyLoggedIn && !props.hideNav)
const showAnnouncement = computed(() => tenant.supportsAnnouncement())

provide('hideBackToOverview', props.hideBackToOverview)
</script>
