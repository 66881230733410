<template>
  <div>
    <section
      v-for="group in issueGroups"
      :key="group.date.valueOf()"
      class="mb-4"
    >
      <h4 class="font-medium font-display">
        {{ $d(group.date) }}
      </h4>
      <div
        class="grid grid-cols-1 gap-4 p-4 pl-4 mt-2 ml-4 border-l border-primary sm:grid-cols-2"
      >
        <IssueCalendarListItem
          v-for="issue in group.issues"
          :issue="issue"
          :key="issue.issue"
        />
      </div>
    </section>
  </div>
</template>

<script lang="ts" setup>
import { Issue } from "@/models/issue";
import { isSameDay } from "date-fns";
import { computed, PropType } from "vue";
import IssueCalendarListItem from "@/pages/issue-calendar/IssueCalendarListItem.vue";

interface IssueGroup {
  date: Date
  issues: Issue[]
}

const props = defineProps({
  issues: {
    type: Array as PropType<Issue[]>,
    required: true
  }
})

const issueGroups = computed(() =>  {
  // sort the issues based on their dateOfFirstSale, which makes grouping a lot easier
  const sortedIssues = props.issues.sort(
    (a: Issue, b: Issue) =>
      a.dateOfFirstSale.valueOf() - b.dateOfFirstSale.valueOf()
  );
  // group the issues based on their dateOfFirstSale
  const groups: IssueGroup[] = [];
  if (sortedIssues.length === 0) {
    return groups;
  }
  let group: IssueGroup = {
    date: sortedIssues[0].dateOfFirstSale,
    issues: [sortedIssues[0]],
  };
  for (let i = 1; i < sortedIssues.length; i++) {
    const issue = sortedIssues[i];
    if (!isSameDay(group.issues[0].dateOfFirstSale, issue.dateOfFirstSale)) {
      groups.push(group);
      group = {
        date: issue.dateOfFirstSale,
        issues: [],
      };
    }
    group.issues.push(issue);
  }
  groups.push(group);
  return groups;
})
</script>
