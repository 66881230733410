import { PaymentMethod } from "@/models/paymentDetails";

const paymentMethodIcons: Record<PaymentMethod, string | string[]> = {
  [PaymentMethod.NONE]: "",
  [PaymentMethod.PAYPAL]: ["fab", "cc-paypal"],
  [PaymentMethod.SEPA]: "wallet",
  [PaymentMethod.CREDIT_CARD]: "credit-card",
  [PaymentMethod.DIRECT_DEBIT]: "wallet",
  [PaymentMethod.INVOICE]: "file-invoice"
};

const cardProviderIcons = {
  visa: ["fab", "cc-visa"],
  mastercard: ["fab", "cc-mastercard"],
  amex: ["fab", "cc-amex"],
  discover: ["fab", "cc-discover"],
};

export function getIconForPaymentMethod(paymentMethod: PaymentMethod) {
  return paymentMethodIcons[paymentMethod];
}
