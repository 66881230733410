<template>
  <div>
    <h3 class="text-xl font-medium font-display">
      {{ $t("issue-calendar.title") }}
    </h3>
    <p class="mt-2">{{ $t("issue-calendar.heading") }}</p>
    <hr class="my-4" />
    <template v-if="$tenant.supportsIssueCalendar()">
      <AuthenticatedIssueCalendar v-if="auth.isFullyLoggedIn"></AuthenticatedIssueCalendar>
      <UnauthenticatedIssueCalendar v-else></UnauthenticatedIssueCalendar>
    </template>
    <FeatureDisabledAlert v-else></FeatureDisabledAlert>
  </div>
</template>

<script lang="ts" setup>
import AuthenticatedIssueCalendar from "@/pages/issue-calendar/AuthenticatedIssueCalendar.vue";
import { useAuth } from "@/pinia/auth";
import UnauthenticatedIssueCalendar from "@/pages/issue-calendar/UnauthenticatedIssueCalendar.vue";
import FeatureDisabledAlert from "@/components/FeatureDisabledAlert.vue";

const auth = useAuth()
</script>
