<template>
  <span :class="classes">
    {{ $tm(`subscription-detail.order-status`)[orderStatus] }}
  </span>
</template>
<script lang="ts" setup>
import { OrderStatus, Subscription } from "@/models/subscription";
import { computed } from "vue";

const classMap: Record<OrderStatus, string> = {
  [OrderStatus.ACTIVE_HALTED]: "text-orange-600",
  [OrderStatus.INACTIVE_KNOWN_ACTIVATION_DATE]: "text-success-600",
  [OrderStatus.ACTIVE]: "text-success-600",
  [OrderStatus.INACTIVE]: "text-danger-600",
  [OrderStatus.ACTIVE_KNOWN_TERMINATION_DATE]: "text-orange-600",
}

const props = defineProps({
  subscription: {
    type: Subscription,
    required: true
  }
})

const orderStatus = computed(() => {
  if (props.subscription.isTimebased && props.subscription.orderStatus === OrderStatus.INACTIVE_KNOWN_ACTIVATION_DATE) {
    return OrderStatus.ACTIVE;
  }
  return props.subscription.orderStatus;
})

const classes = computed(() => {
  return classMap[orderStatus.value];
})

</script>
