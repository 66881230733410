<template>
  <form ref="form" @submit.prevent="submit" class="flex flex-col">
    <FormSelect
      :options="contactActivities"
      :label="$t('contact.form.activity.label')"
      :placeholder="$t('contact.form.activity.placeholder')"
      v-model="activity"
      name-key="name"
      value-key="name"
      return-object
      required
    />
    <FormSelect
      :options="$tm('contact.form.related-to.options')"
      :label="$t('contact.form.related-to.label')"
      :placeholder="$t('contact.form.related-to.placeholder')"
      :model-value="relatedToComputed"
      @update:modelValue="relatedTo = $event"
      :disabled="requiresSubscription"
      required
    >
    </FormSelect>
    <FormSelect
      v-model="salutation"
      class="md:w-1/4"
      :label="$t('bp-form.salutation.label')"
      :options="salutations"
      value-key="code"
      name-key="title"
      :required="$tenant.contactConfig.requireSalutation"
    />
    <FormInput
      v-if="showCompanyInput"
      v-model="company"
      :label="$t('bp-form.company.label')"
      required
    ></FormInput>
    <div class="md:flex">
      <FormInput
        v-model="firstName"
        class="md:w-1/2"
        :label="$t('bp-form.first-name.label')"
        required
      ></FormInput>
      <FormInput
        v-model="lastName"
        class="md:w-1/2 md:ml-4"
        :label="$t('bp-form.last-name.label')"
        required
      ></FormInput>
    </div>
    <FormInput
      v-model="email"
      type="email"
      required
      :label="$t('bp-form.email.label')"
    ></FormInput>
    <FormSelect
      :label="$t('contact.form.publication.label')"
      name-key="description"
      value-key="publication"
      return-object
      :placeholder="$t('contact.form.publication.placeholder')"
      v-model="publication"
      :disabled="publicationsLoading"
      :loading="publicationsLoading"
      :options="allowedPublications"
      :required="requiresSubscription"
      :name-formatter="formatPublicationName"
    />
    <Alert
      class="mt-4"
      v-if="showInfoMessage"
      :variant="activity!.infoMessageType"
    >
      <div class="prose prose--hard-break" v-html="formattedInfoMessage"></div>
    </Alert>
    <div class="flex">
      <OrderNumberInput
        class="flex-1"
        v-model="orderNumber"
        :label="orderNumberLabel"
        :required="orderNumberRequired"
        :disabled="orderNumberMissing"
      />
      <FormInput
        v-if="showZipcodeInput"
        class="w-32 md:w-1/4 ml-4"
        v-model="zipcode"
        :label="$t('link-subscription.form.zipcode.label')"
        placeholder="34232"
        max-length="10"
        required
      />
    </div>
    <OrderNumberHint>
      {{ orderNumberHint }}
    </OrderNumberHint>
    <FormCheckbox
      class="mt-4"
      v-if="showMissingOrderNumberCheckbox"
      :label="$t('contact.form.order-number-missing.label')"
      v-model="orderNumberMissing"
    >
    </FormCheckbox>
    <FormTextarea
      v-if="showMessageInput"
      :label="$t('contact.form.message.label')"
      :placeholder="$t('contact.form.message.placeholder')"
      required
      :multiline="true"
      v-model="message"
    >
    </FormTextarea>
    <template v-if="showImageUpload">
      <FormFileInput
        class="mt-5"
        :label="$t('contact.form.image.label')"
        v-model="images"
        accept="image/png, image/jpeg, application/pdf"
        :max-file-size="2097152"
        type="file"
      >
      </FormFileInput>
      <p class="hint mt-2">
        {{ $t("contact.form.image.hint") }}
      </p>
    </template>
    <FormCheckbox
      v-if="showOptInCheckbox"
      class="mt-6"
      v-model="optIn"
      :label="optInCheckboxLabel"
    >
    </FormCheckbox>
    <ButtonBar class="mt-6">
      <ButtonWithLoadingIndicator
        type="submit"
        :loading="loading"
        :disabled="!activity"
        class="btn btn-primary font-semibold"
      >
        <span v-if="showMessageInput">
          {{ $t("contact.form.submit.label") }}
        </span>
        <span v-else>
          {{ $t("contact.form.continue.label") }}
        </span>
      </ButtonWithLoadingIndicator>
      <button
        v-if="showMessageInputOverrideButton"
        type="button"
        class="btn ml-2"
        @click="showMessageInputOverride = true"
      >
        {{ $t("contact.form.compose-message.label") }}
      </button>
    </ButtonBar>
    <AlertDialog name="success" :negative-text="$t('app.close')">
      {{ $t("contact.success") }}
    </AlertDialog>
    <AlertDialog name="error" :negative-text="$t('app.close')">
      {{ $t("contact.error") }}
    </AlertDialog>
    <LinkSubscriptionErrorAlertDialog
      :link-subscription-result="linkSubscriptionResult"
      :order-number="lastEnteredOrderNumber"
    ></LinkSubscriptionErrorAlertDialog>
  </form>
</template>
<script lang="ts" setup>
import {
  ContactActivity,
  ContactActivityVisibility,
} from "@/models/contactActivity";
import { Publication } from "@/models/publication";
import { formatInfoMessage } from "@/util/templates";
import pupa from "pupa";
import { LinkSubscriptionResult } from "@/models/linkSubscriptionResult";
import { computed, reactive, Ref, ref, toRefs } from "vue";
import OrderNumberInput from "@/components/OrderNumberInput.vue";
import FormSelect from "@/components/form/FormSelect.vue";
import FormInput from "@/components/form/FormInput.vue";
import Alert from "@/components/Alert.vue";
import OrderNumberHint from "@/components/OrderNumberHint.vue";
import FormTextarea from "@/components/form/FormTextarea.vue";
import FormCheckbox from "@/components/form/FormCheckbox.vue";
import ButtonBar from "@/components/ButtonBar.vue";
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import AlertDialog from "@/components/AlertDialog.vue";
import LinkSubscriptionErrorAlertDialog from "@/components/LinkSubscriptionErrorAlertDialog.vue";
import { useRoute, useRouter } from "vue-router";
import { useFetchPublications } from "@/pinia/publications";
import { useTenant } from "@/plugins/tenant";
import { useAuth } from "@/pinia/auth";
import FormFileInput from "@/components/form/FormFileInput.vue";
import { fileToBase64 } from "@/util/file";
import { useI18n } from "vue-i18n";
import { useVfm } from "@/plugins/vfm";
import { useAxios } from "@/plugins/axios";

interface State {
  publication: Publication | null
  salutation: string | null
  email: string | null
  company: string | null
  firstName: string | null
  lastName: string | null
  zipcode: string | null
  orderNumber: string | null
  bpNumber: string | null
  message: string | null
  images: File[] | null

  showMessageInputOverride: boolean
  attemptedLogin: boolean
  optIn: boolean
  relatedTo: string | null
  linkSubscriptionResult: LinkSubscriptionResult | null
  lastEnteredOrderNumber: string | null
  loading: boolean
  orderNumberMissing: boolean
}

function initialState(): State {
  return {
    publication: null,
    salutation: null,
    email: null,
    company: null,
    firstName: null,
    lastName: null,
    zipcode: null,
    orderNumber: null,
    bpNumber: null,
    message: null,
    showMessageInputOverride: false,
    attemptedLogin: false,
    images: null,
    optIn: false,
    relatedTo: null,
    linkSubscriptionResult: null,
    lastEnteredOrderNumber: null,
    loading: false,
    orderNumberMissing: false
  }
}

const route = useRoute()
const router = useRouter()
const vfm = useVfm()
const tenant = useTenant()
const auth = useAuth()
const axios = useAxios()
const { t } = useI18n()

let publications: Ref<Publication[]>
let publicationsLoading: Ref<boolean>

// load the publications from a remote source if set in the config
if (tenant.contactConfig.publications.length === 0) {
  const publicationsStore = useFetchPublications();
  publications = publicationsStore.userVisiblePublications
  publicationsLoading = publicationsStore.publicationsLoading
} else {
  publications = ref(tenant.contactConfig.publications)
  publicationsLoading = ref(false)
}

const state = reactive(Object.assign(initialState(), {
  email: route.query.email
}))

const {
  publication,
  salutation,
  email,
  company,
  firstName,
  lastName,
  zipcode,
  orderNumber,
  bpNumber,
  message,
  showMessageInputOverride,
  attemptedLogin,
  images,
  optIn,
  relatedTo,
  linkSubscriptionResult,
  lastEnteredOrderNumber,
  loading,
  orderNumberMissing
} = toRefs(state)

const contactActivities = tenant.contactConfig.formContactActivities.filter(
  (activity) => {
    return (
      activity.visibility === ContactActivityVisibility.All ||
      activity.visibility === ContactActivityVisibility.Anonymous
    );
  }
);

const activity = ref<ContactActivity | null>(null)
const allowedPublicationNumbers = ref<string[] | null>(null)
const form = ref<HTMLFormElement | null>(null)

const allowedPublications = computed(() => {
  if (allowedPublicationNumbers.value !== null && publications.value !== null) {
    return publications.value!.filter((publication: Publication) =>
      allowedPublicationNumbers.value!.includes(publication.publication)
    );
  }
  return publications.value;
})

const requiresSubscription = computed(() => {
  return activity.value != null && activity.value.requiresSubscription;
})

const relatedToComputed = computed(() => {
  return requiresSubscription.value ? "subscription" : relatedTo.value;
})

const orderNumberLabel = computed(() => {
  return relatedToComputed.value === "subscription"
    ? t("contact.form.order-number.label")
    : t("contact.form.order-bp-number.label");
})

const orderNumberHint = computed(() => {
  return relatedToComputed.value === "subscription"
    ? t("contact.form.order-number.hint")
    : t("contact.form.order-bp-number.hint");
})

const salutations = computed(() => {
  return tenant.salutations.filter(
    (salutation) => salutation.isUserSelectable
  );
})

const orderNumberRequired = computed(() => {
  return requiresSubscription.value && !orderNumberMissing.value
})

const showInfoMessage = computed(() => {
  return (
    activity.value != null &&
    activity.value.infoMessage != null &&
    ((requiresSubscription.value && publication.value != null) ||
      !requiresSubscription.value)
  );
})

const linkedPage = computed(() => {
  return activity.value ? activity.value.linkedPage : null;
})

const showOptInCheckbox = computed(() => {
  return (
    tenant.supportsOptIns() &&
    requiresSubscription.value &&
    publication.value !== null &&
    activity.value?.showOptInCheckbox === true
  )
})

const optInCheckboxLabel = computed(() => {
  return pupa(tenant.contactConfig.optInCheckboxLabelTemplate, {
    publication: publication.value!.description,
  });
})

const showMessageInputOverrideButton = computed(() => {
  return !showMessageInputOverride.value && (attemptedLogin.value || (linkedPage.value !== null && !activity.value!.disableMessageInput))
})

const showMessageInput = computed(() => {
  return (
    showMessageInputOverride.value ||
    orderNumberMissing.value ||
    (activity.value != null && activity.value.linkedPage == null)
  );
})

const showZipcodeInput = computed(() => {
  return linkedPage.value !== null && !showMessageInputOverride.value && !orderNumberMissing.value;
})

const showImageUpload = computed(() => {
  return (
    activity.value != null &&
    activity.value.linkedPage == null &&
    activity.value.supportsImageUpload
  );
})

const showMissingOrderNumberCheckbox = computed(() => {
  return activity.value?.allowMissingOrderNumber === true
})

const showCompanyInput = computed(() => {
  const foundSalutation = tenant.salutations.find(
    (s) => s.code === salutation.value
  );
  return foundSalutation?.isCompany ?? false;
})

const formattedInfoMessage = computed(() => {
  const phoneNumber = tenant.getContactPhoneNumber(
    publication.value?.publication
  );
  const email = tenant.getContactEmail(publication.value?.publication);
  return formatInfoMessage(activity.value!.infoMessage!, phoneNumber, email);
})

function formatPublicationName(publication: Publication) {
  if (publication.isDigital) {
    return `${publication.description} (digital)`;
  }
  return publication.description;
}

function clearForm() {
  (form.value as HTMLFormElement).reset();
  Object.assign(state, initialState());
}

function submit() {
  if (linkedPage.value && (!(orderNumberMissing.value || showMessageInputOverride.value))) {
    attemptLogin();
  } else {
    createContact();
  }
}

async function attemptLogin() {
  try {
    loading.value = true;
    lastEnteredOrderNumber.value = orderNumber.value;
    const subscription = await auth.loginUsingContactForm({
      publication: publication.value!,
      email: email.value!,
      orderNumber: orderNumber.value!,
      zipcode: zipcode.value!,
      preferredRole: activity.value!.preferredRole,
      optIn: optIn.value,
    });
    // the login was successful, redirect the user to the linked page
    router.push({
      name: linkedPage.value!,
      params: {
        id: subscription.id,
      },
      query: {
        returnTo: "contact",
      },
    });
  } catch (error: any) {
    if (error.response && error.response.status === 400) {
      linkSubscriptionResult.value = error.response
        .data as LinkSubscriptionResult;
    } else {
      linkSubscriptionResult.value = null;
    }
    vfm.show("linkSubscriptionError");
  } finally {
    loading.value = false;
    attemptedLogin.value = true;
  }
}

async function createContact() {
  // submit the contact request
  try {
    loading.value = true;

    const payload = {
      activity: activity.value,
      publication: publication.value,
      orderNumber: orderNumber.value,
      message: message.value,
      relatedTo: relatedToComputed.value,
      contactDetails: {
        salutation: salutation.value,
        email: email.value,
        firstName: firstName.value,
        lastName: lastName.value,
      },
      image: null as any,
      optIn: optIn.value
    };

    if (images.value && images.value.length) {
      const image = images.value[0];
      payload.image = {
        filename: image.name,
        mimeType: image.type,
        data: await fileToBase64(images.value[0]),
      };
    }

    await axios.post("/contact", payload);
    vfm.show("success");
    clearForm();
  } catch (e) {
    vfm.show("error");
  } finally {
    loading.value = false;
  }
}

if (route.query.publications) {
  allowedPublicationNumbers.value = (route.query.publications as string).split(",")
}
if (route.query.activity) {
  activity.value = contactActivities.find(
    (a) => a.activity === route.query.activity
  ) ?? null;
}

</script>
