import { Tenant } from "@/models/tenant";
import { computed, reactive, Ref } from "vue";
import { Subscription } from "@/models/subscription";

export function useSubscriptionRules(
  subscription: Ref<Subscription>,
  tenant: Tenant
) {

  const supportsHolidayService = computed(() => tenant.supportsHolidayService(subscription.value.mixType))

  return reactive({
    showActiveHolidayService: computed(() => supportsHolidayService.value && subscription.value.hasPlannedHolidayService()),
    showValidToDate: computed(() => subscription.value.validToOnSaleDate !== null && !subscription.value.isRenewal),
    showExpiryIssue: computed(() => subscription.value.isRenewal && (subscription.value.isCancelled() || !subscription.value.renewal!.autoRenewal)),
    showSuspensionDetails: computed(() => subscription.value.isSuspended()),
    showNextIssue: computed(() => subscription.value.hasNextIssue()),
    showBillingFrequency: computed(() => tenant.supportsBillingFrequency() && subscription.value.supportsBillingFrequency()),
    showPaymentMethod: computed(() => tenant.supportsPaymentMethodChange() && subscription.value.supportsPaymentMethodChange()),
    showCurrentCyclePaidAmount: computed(() => subscription.value.isRenewal && subscription.value.isActive() && subscription.value.isOrderingParty()),
    showAutoRenewal: computed(() => subscription.value.hasActiveAutoRenewal()),
    showNextPaymentDate: computed(() => subscription.value.hasActiveAutoRenewal() && subscription.value.isOrderingParty() && subscription.value.renewal!.autoRenewalNextPaymentDate),
    showNextPaymentAmount: computed(() => subscription.value.hasActiveAutoRenewal() && subscription.value.isOrderingParty()),
    showPremiumDescription: computed(() => subscription.value.premiumDescription != null && subscription.value.isOrderingParty()),
    showGoodsRecipient: computed(() => subscription.value.isOrderingParty() && subscription.value.hasDifferingGoodsRecipient()),
    showExternalLink: computed(() => tenant.supportsExternalSubscriptionLink(subscription.value)),
    showIssuesHistory: computed(() => tenant.supportsIssueHistory(subscription.value.publication, subscription.value.mixType) && subscription.value.supportsIssueHistory()),
    showPaymentHistory: computed(() => tenant.supportsPaymentHistory() && subscription.value.isOrderingParty()),
    showInvoiceStatus: computed(() => tenant.paymentHistoryConfig.showInvoiceStatus),
    showInvoiceStatusInfoText: computed(() => tenant.paymentHistoryConfig.showInvoiceStatus && tenant.paymentHistoryConfig.invoiceStatusInfoText !== null),
    showBundleSlaves: computed(() => subscription.value.isBundleMaster() && tenant.supportsBundleSlaves(subscription.value.publication)),
    showOfferDescription: computed(() => subscription.value.isTimebased && subscription.value.renewal?.maxCycleOfferOfferDescription),
    showValidFrom: computed(() => tenant.supportsValidFrom() && subscription.value.isActiveNotCancelled()),
    supportsRenewal: computed(() => tenant.supportsRenewal() && subscription.value.supportsRenewal(tenant.renewalConfig.supportsAutoRenewal)),
    supportsAddressChange: computed(() => tenant.supportsAddressChange() && subscription.value.supportsAddressChange()),
    supportsHolidayService: computed(() => supportsHolidayService.value && subscription.value.supportsHolidayService()),
    supportsOptIns: computed(() => tenant.supportsOptIns() && subscription.value.supportsOptIns()),
    supportsPaymentMethodChange: computed(() => tenant.supportsPaymentMethodChange() && subscription.value.supportsPaymentMethodChange()),
    supportsIssueComplaint: computed(() => tenant.supportsIssueComplaint(subscription.value.publication) && subscription.value.supportsIssueComplaint()),
    supportsGiftCertificate: computed(() => tenant.supportsGiftCertificate() && subscription.value.supportsGiftCertificateDownload(tenant.giftCertificateConfig.properties)),
    supportsMultiUserLicenses: computed(() => tenant.supportsMultiUserLicenses() && subscription.value.supportsMultiUserLicenses()),
    supportsCancellation: computed(() => tenant.supportsCancellation() && subscription.value.supportsCancellation()),
    supportsCancellationRevocation: computed(() => tenant.supportsCancellationRevocation() && subscription.value.supportsCancellationRevocation()),
    supportsUpgrade: computed(() => tenant.supportsUpgrade() && subscription.value.supportsUpgrade()),
    supportsECard: computed(() => tenant.supportsECard() && subscription.value.supportsECard()),
    supportsContactForm: computed(() => tenant.supportsContactForm()),
    showPhaseUpgrade: computed(() => tenant.supportsPhaseUpgrade() && subscription.value.supportsPhaseUpgrade()),
  })
}
