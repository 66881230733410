import { acceptHMRUpdate, defineStore } from "pinia";
import axios from "axios";
import { Publication } from "@/models/publication";
import { IssueCalendarPublication } from "@/models/issueCalendarPublication";
import { IssueCalendarMode } from "@/models/issueCalendarConfig";
import { Issue } from "@/models/issue";

export interface UnauthenticatedIssueCalendarState {
  mode: IssueCalendarMode | null;
  publications: IssueCalendarPublication[] | null;
  issues: Record<string, Issue[]>
  publicationsLoading: boolean;
  issuesLoading: boolean;
  error: boolean;
}

export const useUnauthenticatedIssueCalendar = defineStore('unauthenticatedIssueCalendar', {
  state: (): UnauthenticatedIssueCalendarState => ({
    mode: null,
    publications: null,
    issues: {},
    publicationsLoading: false,
    issuesLoading: false,
    error: false
  }),
  actions: {
    async fetchPublications() {
      if (this.publicationsLoading) {
        return
      }
      if (this.publications !== null) {
        return
      }
      try {
        this.publicationsLoading = true

        const response = await axios.get<Array<IssueCalendarPublication>>(
          `/issue-calendar/publications`
        );

        this.$patch({
          publications: response.data,
          publicationsLoading: false,
          error: false
        })
      } catch (error) {
        this.$patch({
          error: true,
          publicationsLoading: false
        })
      }
    },
    async fetchIssues(publication: Publication) {
      if (this.issuesLoading) {
        return
      }
      if (Array.isArray(this.issues[publication.publication])) {
        return
      }
      try {
        this.issuesLoading = true

        const response = await axios.get<Issue[]>(
          `/issue-calendar/publications/${publication.clientId}/${publication.publication}/upcoming-issues`
        );

        this.issues[publication.publication] = response.data
        this.issuesLoading = false

      } catch (error) {
        this.$patch({
          error: true,
          issuesLoading: false
        })
      }
    },
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUnauthenticatedIssueCalendar, import.meta.hot))
}
