<template>
  <div
    class="border rounded-card transition-shadow duration-200 hover:shadow"
    :class="expanded && 'shadow'"
  >
    <dt class="text-lg">
      <button
        @click="expanded = !expanded"
        type="button"
        class="p-3 md:p-4 text-left w-full flex justify-between items-start text-gray-800 focus:outline-none focus:text-gray-900"
      >
        <span class="font-semibold">
          {{ entry.title }}
        </span>
        <span class="ml-6 h-7 flex items-center">
          <svg
            class="h-6 w-6 transform"
            :class="expanded ? '-rotate-180' : 'rotate-0'"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </span>
      </button>
    </dt>
    <dd class="pr-12 p-4 pt-0" v-if="expanded">
      <p v-html="entry.content" class="prose prose--hard-break"></p>
    </dd>
  </div>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue'
import { FaqEntry } from "@/models/faqEntry";

defineProps( {
  entry: {
    type: Object as PropType<FaqEntry>,
    required: true
  }
})

const expanded = ref(false)
</script>
