<template>
  <div class="pb-4">
    <h3 class="text-xl font-medium font-display">
      {{ $t("link-subscription.title") }}
    </h3>
    <p class="mt-2">{{ $t("link-subscription.heading") }}</p>
    <RequiredFieldsHint class="mt-2"></RequiredFieldsHint>
    <hr class="mt-4" />
    <form @submit.prevent="submit" ref="form">
      <FormSelect
        v-if="$tenant.hasMultipleAvsClients"
        :label="$t('link-subscription.form.publication.label')"
        name-key="description"
        value-key="publication"
        :placeholder="$t('link-subscription.form.publication.placeholder')"
        v-model="formState.publication"
        :disabled="publicationsLoading"
        :loading="publicationsLoading"
        :options="userVisiblePublications"
        :name-formatter="formatPublicationName"
        return-object
        required
      />
      <OrderNumberInput
        v-model="formState.orderNumber"
        :label="$t('link-subscription.form.order-number.label')"
        required
      />
      <OrderNumberHint />
      <div class="flex">
        <FormInput
          v-if="showLastNameInput"
          class="w-4/6 mr-4 last:w-full"
          v-model="formState.lastName"
          :label="$t('link-subscription.form.last-name.label')"
          required
        />
        <FormInput
          class="w-2/6 first:w-full"
          v-model="formState.zipcode"
          :label="$t('link-subscription.form.zipcode.label')"
          :placeholder="$t('link-subscription.form.zipcode.placeholder')"
          max-length="10"
          required
        />
      </div>
      <p class="hint mt-1">
        {{ $t('link-subscription.form.verification-hint') }}
      </p>
      <FormFieldset
        v-if="$tenant.linkSubscriptionConfig.showIsGiftQuestion"
        :label="$t('link-subscription.form.is-gift.label')"
        v-model="formState.preferredRole"
        required
        :options="[
          { value: 'WE', label: $t('app.yes') },
          { value: 'AG', label: $t('app.no') },
        ]"
        name="preferred-role"
      >
      </FormFieldset>
      <div class="flex justify-end lg:justify-start">
        <ButtonWithLoadingIndicator
          :loading="submitting"
          class="mt-6 btn btn-primary"
          type="submit"
        >
          {{ $t("link-subscription.form.submit.label") }}
        </ButtonWithLoadingIndicator>
      </div>
    </form>
    <AlertDialog
      name="link-subscription-success"
      :negative-text="$t('link-subscription.actions.close')"
      :positive-text="$t('link-subscription.actions.continue')"
      @positive="$router.push('orders')"
    >
      {{
        $t("link-subscription.success", {
          orderNumber: lastLinkedOrderNumber,
        })
      }}
    </AlertDialog>
    <LinkSubscriptionErrorAlertDialog
      :link-subscription-result="linkSubscriptionResult"
      :order-number="lastLinkedOrderNumber"
      verification-method="name-and-zipcode"
    >
    </LinkSubscriptionErrorAlertDialog>
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive, ref, toRaw } from 'vue'
import FormSelect from "@/components/form/FormSelect.vue";
import FormInput from "@/components/form/FormInput.vue";
import FormFieldset from "@/components/form/FormFieldset.vue";
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import OrderNumberHint from "@/components/OrderNumberHint.vue";
import AlertDialog from "@/components/AlertDialog.vue";
import LinkSubscriptionErrorAlertDialog from "@/components/LinkSubscriptionErrorAlertDialog.vue";
import { Publication } from "@/models/publication";
import { LinkSubscriptionResult } from "@/models/linkSubscriptionResult";
import { useFetchPublications } from "@/pinia/publications";
import { LinkSubscriptionRequest, useOrders } from "@/pinia/orders";
import { useTracker } from "@/plugins/tracker";
import { useVfm } from "@/plugins/vfm";
import OrderNumberInput from "@/components/OrderNumberInput.vue";
import RequiredFieldsHint from "@/components/RequiredFieldsHint.vue";
import { useTenant } from "@/plugins/tenant";

interface FormState {
  publication: Publication | null
  orderNumber: string
  lastName: string
  zipcode: string
  preferredRole: string | null
}

function emptyForm(): FormState {
  return {
    publication: null,
    orderNumber: '',
    lastName: '',
    zipcode: '',
    preferredRole: null
  }
}

const tenant = useTenant()
const orders = useOrders()
const tracker = useTracker()
const vfm = useVfm()
const { userVisiblePublications, publicationsLoading } = useFetchPublications()

const formState = reactive<FormState>(emptyForm())

const formHadErrors = ref(false)
const submitting = ref(false)
const lastLinkedOrderNumber = ref<string | null>(null)
const linkSubscriptionResult = ref<LinkSubscriptionResult | null>(null)
const form = ref<HTMLFormElement | null>(null)

const showLastNameInput = computed(() => tenant.linkSubscriptionConfig.preferredRole === null && !tenant.linkSubscriptionConfig.showIsGiftQuestion)

function resetForm() {
  formHadErrors.value = false
  Object.assign(formState, emptyForm())
  form.value?.reset();
}

async function submit() {
  try {
    submitting.value = true;
    lastLinkedOrderNumber.value = formState.orderNumber;
    const subscription = await orders.linkSubscription(toRaw(formState) as LinkSubscriptionRequest);
    tracker.trackEvent(
      "Subscription",
      "Link",
      subscription.publication
    );
    vfm.show("link-subscription-success");
    resetForm();
  } catch (error: any) {
    if (error.response && error.response.status === 400) {
      linkSubscriptionResult.value = error.response
        .data as LinkSubscriptionResult;
    } else {
      linkSubscriptionResult.value = null;
    }
    vfm.show("linkSubscriptionError");
  } finally {
    submitting.value = false;
  }
}

function formatPublicationName(publication: Publication) {
  if (publication.isDigital) {
    return `${publication.description} (digital)`;
  }
  return publication.description;
}

</script>
