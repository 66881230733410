export function formatInfoMessage(rawMessage: string, phoneNumber: string, email: string) {
  let formattedMessage = rawMessage.replace(
    "{{email}}",
    `<a class="font-bold" href="mailto:${email}">${email}</a>`
  );
  formattedMessage = formattedMessage.replace(
    "{{phone}}",
    `<a class="font-bold" href="mailto:${phoneNumber}">${phoneNumber}</a>`
  );
  return formattedMessage;
}
