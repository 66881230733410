<template>
  <div>
    <PageTitle name="upgrade"></PageTitle>
    <p class="mt-2">
      <span v-if="auth.isFullyLoggedIn">
        {{ $t("upgrade.authenticated-heading") }}
      </span>
      <span v-else>
        {{ $t("upgrade.unauthenticated-heading") }}
      </span>
    </p>
    <hr class="my-4" />
    <FeatureDisabledAlert v-if="!$tenant.supportsUpgrade()"></FeatureDisabledAlert>
    <form v-else-if="auth.isFullyLoggedIn" @submit.prevent="redirectToUpgrade">
      <FormSelect
        :options="orders.subscriptions"
        :disabled="orders.loading"
        :loading="orders.loading"
        v-model="state.subscription"
        name-key="displayName"
        value-key="id"
        return-object
        :label="$t('contact.form.subscriptions.label')"
        :placeholder="$t('contact.form.subscriptions.placeholder')"
        required
      />
      <p class="mt-1 text-xs text-gray-700">
        {{ $t("contact.form.subscriptions.hint") }}
      </p>
      <ButtonBar class="mt-6">
        <button type="submit" class="btn btn-primary font-semibold">
          {{ $t("app.continue") }}
        </button>
      </ButtonBar>
    </form>
    <form v-else @submit.prevent="submit">
      <LinkSubscriptionErrorAlert
        v-if="state.showError"
        class="mt-6"
        :order-number="state.formData.orderNumber"
        :link-subscription-result="state.linkSubscriptionResult"
        verification-method="name"
      ></LinkSubscriptionErrorAlert>
      <OrderNumberInput
        class="flex-1"
        v-model="state.formData.orderNumber"
        :label="$t('upgrade.order-number.label')"
        :error-message="$t('upgrade.order-number.error')"
        required
      />
      <OrderNumberHint>
        {{ $t("upgrade.order-number.hint") }}
      </OrderNumberHint>
      <FormInput
        v-model="state.formData.lastName"
        :label="$t('business-partner.last-name.label')"
        required
      ></FormInput>
      <template v-if="showEmailInput">
        <FormInput
          v-model="state.formData.email"
          type="email"
          :label="$t('business-partner.email.label')"
          required
        ></FormInput>
        <i18n-t
          tag="p"
          keypath="renewal.email-hint"
          class="mt-2 text-gray-700 text-xs"
        >
          <template #contactForm>
            <router-link to="/contact" class="link"
            >{{ $t("app.contact-form") }}
            </router-link>
          </template>
        </i18n-t>
      </template>
      <ButtonBar class="mt-6">
        <ButtonWithLoadingIndicator
          type="submit"
          :loading="state.submitting"
          class="btn btn-primary font-semibold"
        >
          {{ $t("app.continue") }}
        </ButtonWithLoadingIndicator>
      </ButtonBar>
      <AlertDialog
        name="confirmEmail"
        :negative-text="$t('upgrade.abort')"
        :positive-text="$t('upgrade.continue-with-email')"
        @positive="continueWithEmail"
        @negative="abort"
        disable-auto-hide
        :loading="state.submitting"
      >
        <i18n-t keypath="upgrade.confirm-email">
          <template #email>
            <b>{{
                state.linkSubscriptionResult != null
                  ? state.linkSubscriptionResult.maskedEmail
                  : null
              }}</b>
          </template>
        </i18n-t>
      </AlertDialog>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive } from 'vue'
import PageTitle from "@/components/PageTitle.vue";
import ButtonBar from "@/components/ButtonBar.vue";
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import AlertDialog from "@/components/AlertDialog.vue";
import { Subscription } from "@/models/subscription";
import FormSelect from "@/components/form/FormSelect.vue";
import OrderNumberHint from "@/components/OrderNumberHint.vue";
import FormInput from "@/components/form/FormInput.vue";
import FeatureDisabledAlert from "@/components/FeatureDisabledAlert.vue";
import LinkSubscriptionErrorAlert from "@/components/LinkSubscriptionErrorAlert.vue";
import OrderNumberInput from "@/components/OrderNumberInput.vue";
import { useAuth } from "@/pinia/auth";
import { useOrders } from "@/pinia/orders";
import { LinkSubscriptionResult, LinkSubscriptionStatus } from "@/models/linkSubscriptionResult";
import { useRoute, useRouter } from "vue-router";
import { useVfm } from "@/plugins/vfm";

interface State {
  submitting: boolean
  formData: {
    orderNumber: string
    lastName: string
    email: string | null
  }
  showError: boolean
  linkSubscriptionResult: LinkSubscriptionResult | null
  subscription: Subscription | null
}

const route = useRoute()
const router = useRouter()
const vfm = useVfm()
const auth = useAuth()
const orders = useOrders()

const {
  orderNumber = "",
  lastName1 = "",
  email = null
} = route.query;

const state = reactive<State>({
  submitting: false,
  formData: {
    orderNumber: orderNumber as string,
    lastName: lastName1 as string,
    email: email as string | null,
  },
  showError: false,
  linkSubscriptionResult: null,
  subscription: null,
})

const showEmailInput = computed(() => {
  return (
    state.linkSubscriptionResult !== null &&
    state.linkSubscriptionResult.status ===
    LinkSubscriptionStatus.EmailRequired
  );
})

function showConfirmEmailModal() {
  vfm.show("confirmEmail");
}

function abort() {
  state.linkSubscriptionResult = null;
  vfm.hide("confirmEmail");
}

function redirectToUpgrade() {
  router.push({
    name: "subscription-upgrade",
    params: {
      id: state.subscription!.id,
    },
    query: {
      returnTo: "upgrade",
    },
  });
}

async function submit() {
  try {
    state.showError = false;
    state.submitting = true;
    const subscription = await auth.loginUsingOrderNumber(state.formData)
    router.replace({
      name: "subscription-upgrade",
      params: {
        id: subscription.id,
      },
      query: route.query
    });
  } catch (error: any) {
    if (error.response && error.response.status === 400) {
      state.linkSubscriptionResult = error.response
        .data as LinkSubscriptionResult;
      if (
        state.linkSubscriptionResult.status ===
        LinkSubscriptionStatus.ConfirmEmail
      ) {
        showConfirmEmailModal();
      } else {
        state.showError = true;
      }
    } else {
      state.showError = true;
      state.linkSubscriptionResult = null;
    }
  } finally {
    state.submitting = false;
  }
}

function continueWithEmail() {
  state.formData.email = state.linkSubscriptionResult!.email;
  submit();
}

if (state.formData.orderNumber && state.formData.lastName && state.formData.email) {
  submit()
} else if (auth.isFullyLoggedIn) {
  orders.fetch()
}
</script>
