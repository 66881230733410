<template>
  <span
    v-if="$tenant.theme.showSubscriptionTypeBadge"
    class="inline-block bg-primary text-xs text-white font-semibold uppercase"
    :class="{
      'px-2 py-1': !small,
      'px-1.5 py-0.5': small,
    }"
  >
    {{ label }}
  </span>
</template>
<script lang="ts" setup>
import { Subscription } from "@/models/subscription";
import { MediumType } from "@/models/mixType";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  subscription: {
    type: Subscription,
    required: true
  },
  small: Boolean
})

const {t} = useI18n()

const  label = computed(() => {
  if (props.subscription.isMembership()) {
    return t("subscription-detail.membership");
  }
  if (props.subscription.isBundleMaster()) {
    return t("subscription-detail.bundle");
  }
  if (props.subscription.isCombiSubscription) {
    return t("subscription-detail.combo");
  }
  switch (props.subscription.mixTypeDetails.mediumType) {
    case MediumType.DIGITAL_EDITION:
    case MediumType.ONLINE:
      return t("subscription-detail.digital");
    case MediumType.COMBO:
      return t("subscription-detail.combo");
    default:
      return t("subscription-detail.print");
  }
})
</script>
