<template>
  <button @click="back" class="text-left">
    <font-awesome-icon icon="chevron-left" class="mr-2" />
    {{ label }}
  </button>
</template>
<script lang="ts" setup>
import { Subscription } from "@/models/subscription";
import { computed, PropType } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

const props = defineProps({
  subscription: {
    type: Subscription as PropType<Subscription|null>,
    default: null
  },
  returnTo: {
    type: String,
    default: undefined
  }
})

const route = useRoute()
const router = useRouter()
const {t} = useI18n()

const label = computed(() => {
  if (route.query && route.query.returnTo != null) {
    if (route.query.returnTo === "contact") {
      return t("app.back-to-contact");
    }
    return t("app.back");
  }
  if (props.subscription != null) {
    return t("app.back-to", { route: props.subscription.displayName });
  }
  return t("app.back-to-overview");
})

function back() {
  if (props.returnTo) {
    router.push({name: props.returnTo})
  } else {
    router.back();
  }
}
</script>
