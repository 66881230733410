import { Subscription } from "@/models/subscription";

export enum LinkSubscriptionStatus {
  EmailRequired = "email_required",
  ConfirmEmail = "confirm_email",
  ValidationFailed = "validation_failed",
  VerificationFailed = "verification_failed",
  NotFound = "not_found",
  NotSupported = "not_supported",
  AlreadyLinked = "already_linked",
}

export interface LinkSubscriptionResult {
  status: LinkSubscriptionStatus;
  email: string | null;
  maskedEmail: string | null;
  subscription: Subscription | null
}
