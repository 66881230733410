import { Publication } from "@/models/publication";
import { acceptHMRUpdate, defineStore } from "pinia";
import axios from "axios";
import { computed } from "vue";

export interface PublicationsState {
  publications: Publication[] | null;
  loading: boolean;
  error: boolean;
}

export const usePublications = defineStore('publications', {
  state: (): PublicationsState => ({
    publications: null,
    loading: false,
    error: false
  }),
  actions: {
    async fetch() {
      if (this.loading || this.publications) return;
      this.loading = true
      try {
        const response = await axios.get<Array<Publication>>("/publications");
        this.$patch({
          publications: response.data,
          loading: false
        })
      } catch (error) {
        this.$patch({
          loading: false,
          error: true
        })
      }
    },
  },
  getters: {
    userVisiblePublications: (state): Publication[] => {
      // As it happens, one publication may be sold in different salesOrganisations
      // However, that doesn't matter for the user, so we just remove all duplicates and filter them by their publication number
      if (state.publications === null) {
        return [];
      }
      const publications: Record<string, Publication> = {};
      state.publications.forEach((publication) => {
        if (!(publication.publication in publications)) {
          publications[publication.publication] = publication;
        }
      });
      // Finally, return the list sorted alphabetically by the publication description
      return Object.values(publications).sort((a: Publication, b: Publication) =>
        a.description.localeCompare(b.description)
      );
    },
  }
})

export function useFetchPublications() {
  const store = usePublications()

  const publications = computed(() => store.publications)
  if (!publications.value) {
    store.fetch()
  }

  return {
    publications,
    userVisiblePublications: computed(() => store.userVisiblePublications),
    publicationsLoading: computed(() => store.loading),
  };
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePublications, import.meta.hot))
}
