<template>
  <button
    :disabled="loading"
    @click="confirmRequestInvoice"
    :title="$t('payments.request-invoice')"
  >
    <InlineIconSpinner v-if="loading" inverted />
    <font-awesome-icon v-else icon="file-invoice" />
    <slot />
  </button>
</template>

<script lang="ts" setup>
import { PurchaseOrder } from "@/models/purchaseOrder";
import { PropType, ref } from "vue";
import InlineIconSpinner from "@/components/InlineIconSpinner.vue";
import { Subscription } from "@/models/subscription";
import ConfirmInvoiceRequestModal from "@/components/ConfirmInvoiceRequestModal.vue";
import ErrorModal from "@/components/ErrorModal.vue";
import InvoiceRequestSuccessModal from "@/components/InvoiceRequestSuccessModal.vue";
import { useVfm } from "@/plugins/vfm";
import { useTracker } from "@/plugins/tracker";
import { useAxios } from "@/plugins/axios";
import { useI18n } from "vue-i18n";

interface RequestInvoiceResponse {
  status: 'success' | 'forwarded' | 'processing'
}

const props = defineProps({
  subscription: Subscription as PropType<Subscription | null | undefined> ,
  purchaseOrder: Object as PropType<PurchaseOrder | null | undefined>,
  invoiceNumber: {
    type: String as PropType<string | null | undefined>,
    default: undefined
  },
  externalDocumentNumber: {
    type: String as PropType<string | null | undefined>,
    default: undefined
  }
})

const vfm = useVfm()
const tracker = useTracker()
const axios = useAxios()
const {t} = useI18n()

const loading = ref(false)

function confirmRequestInvoice() {
  vfm.show({
    component: ConfirmInvoiceRequestModal,
    on: {
      confirm: requestInvoice
    }
  })
}

async function requestInvoice() {
  try {
    loading.value = true;
    let response;
    if (props.subscription != null) {
      response = await axios.post<RequestInvoiceResponse>(
        `subscriptions/${props.subscription!.id}/invoices/request`,
        null,
        {
          params: {
            invoiceNumber: props.invoiceNumber,
            externalDocumentNumber: props.externalDocumentNumber,
          }
        }
      );
      tracker.trackEvent(
        "Subscription",
        "RequestInvoice",
        props.subscription!.publication
      );
    } else if (props.purchaseOrder != null) {
      response = await axios.post<RequestInvoiceResponse>(
        `purchase-orders/${props.purchaseOrder!.id}/invoices/request`,
        null,
        {
          params: {
            invoiceNumber: props.invoiceNumber
          }
        }
      );
      tracker.trackEvent(
        "Subscription",
        "RequestInvoice",
        props.purchaseOrder!.distributionArticleNumber
      );
    }
    vfm.show({
      component: InvoiceRequestSuccessModal,
      bind: response?.data
    });
  } catch (error: any) {
    vfm.show({
      component: ErrorModal,
    })
  } finally {
    loading.value = false;
  }
}
</script>
