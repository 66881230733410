<template>
  <label class="flex select-none items-start overflow-visible">
    <input
      type="radio"
      :class="inputClass"
      :value="value"
      :checked="isChecked"
      @input="handleInput"
      :disabled="disabled"
      :name="name"
      :required="required"
    />
    <span class="ml-3">{{ label }}</span>
  </label>
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue";

const props = defineProps({
  label: String,
  name: String,
  value: String,
  disabled: Boolean,
  required: Boolean,
  modelValue: String as PropType<String | null | undefined>,
  inputClass: String
})

const emit = defineEmits(['update:modelValue'])

const isChecked = computed(() => {
  return props.modelValue === props.value
})

function handleInput(event: Event) {
  emit('update:modelValue', (event.target! as HTMLInputElement).value)
}
</script>

<style>
input[type="radio"]:checked {
  @apply bg-primary shadow;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  border-color: transparent;
}
input[type="radio"] {
  @apply flex-none appearance-none transition duration-100 border select-none outline-none rounded-full bg-white;
  width: 1.5rem;
  height: 1.5rem;
  background-size: 1.75rem 1.75rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-origin: border-box;
  color-adjust: exact;
}
input[type="radio"]:focus {
  @apply shadow-outline;
}
</style>
