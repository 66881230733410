<template>
  <div>
    <PageTitle name="renewal"></PageTitle>
    <p class="mt-2">
      <span v-if="auth.isFullyLoggedIn">
        {{ $t("renewal.authenticated-heading") }}
      </span>
      <span v-else>
        {{ $t("renewal.unauthenticated-heading") }}
      </span>
    </p>
    <hr class="my-4" />
    <FeatureDisabledAlert v-if="!$tenant.supportsRenewal()"></FeatureDisabledAlert>
    <form v-else-if="auth.isFullyLoggedIn" @submit.prevent="redirectToRenewal">
      <FormSelect
        :options="subscriptions"
        :disabled="subscriptionsLoading"
        :loading="subscriptionsLoading"
        v-model="subscription"
        name-key="displayName"
        value-key="id"
        return-object
        :label="$t('contact.form.subscriptions.label')"
        :placeholder="$t('contact.form.subscriptions.placeholder')"
        required
      />
      <p class="mt-1 text-xs text-gray-700">
        {{ $t("contact.form.subscriptions.hint") }}
      </p>
      <ButtonBar class="mt-6">
        <button type="submit" class="btn btn-primary font-semibold">
          {{ $t("app.continue") }}
        </button>
      </ButtonBar>
    </form>
    <form v-else @submit.prevent="submit">
      <LinkSubscriptionErrorAlert
        v-if="showError"
        class="mt-6"
        :order-number="formData.orderNumber"
        :link-subscription-result="linkSubscriptionResult"
        verification-method="name"
      ></LinkSubscriptionErrorAlert>
      <OrderNumberInput
        class="flex-1"
        v-model="formData.orderNumber"
        :label="$t('renewal.order-number.label')"
        :error-message="$t('renewal.order-number.error')"
        required
      />
      <OrderNumberHint>
        {{ $t("renewal.order-number.hint") }}
      </OrderNumberHint>
      <FormInput
        v-model="formData.lastName"
        :label="$t('business-partner.last-name.label')"
        required
      ></FormInput>
      <template v-if="showEmailInput">
        <FormInput
          v-model="formData.email"
          type="email"
          :label="$t('business-partner.email.label')"
          required
        ></FormInput>
        <i18n-t
          tag="p"
          keypath="renewal.email-hint"
          class="mt-2 text-gray-700 text-xs"
        >
          <template #contactForm>
            <router-link to="/contact" class="link"
            >{{ $t("app.contact-form") }}
            </router-link>
          </template>
        </i18n-t>
      </template>
      <ButtonBar class="mt-6">
        <ButtonWithLoadingIndicator
          type="submit"
          :loading="submitting"
          class="btn btn-primary font-semibold"
        >
          {{ $t("app.continue") }}
        </ButtonWithLoadingIndicator>
      </ButtonBar>
      <AlertDialog
        name="confirmEmail"
        :negative-text="$t('renewal.abort')"
        :positive-text="$t('renewal.continue-with-email')"
        @positive="continueWithEmail"
        @negative="abort"
        disable-auto-hide
        :loading="submitting"
      >
        <i18n-t keypath="renewal.confirm-email">
          <template #email>
            <b>{{
                linkSubscriptionResult != null
                  ? linkSubscriptionResult.maskedEmail
                  : null
              }}</b>
          </template>
        </i18n-t>
      </AlertDialog>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import PageTitle from "@/components/PageTitle.vue";
import ButtonBar from "@/components/ButtonBar.vue";
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import AlertDialog from "@/components/AlertDialog.vue";
import { Subscription } from "@/models/subscription";
import FormSelect from "@/components/form/FormSelect.vue";
import OrderNumberHint from "@/components/OrderNumberHint.vue";
import FormInput from "@/components/form/FormInput.vue";
import FeatureDisabledAlert from "@/components/FeatureDisabledAlert.vue";
import LinkSubscriptionErrorAlert from "@/components/LinkSubscriptionErrorAlert.vue";
import OrderNumberInput from "@/components/OrderNumberInput.vue";
import { useAuth } from "@/pinia/auth";
import { useOrders } from "@/pinia/orders";
import { LinkSubscriptionResult, LinkSubscriptionStatus } from "@/models/linkSubscriptionResult";
import { useRoute, useRouter } from "vue-router";
import { useVfm } from "@/plugins/vfm";

const auth = useAuth()
const orders = useOrders();
const route = useRoute()
const router = useRouter()
const vfm = useVfm()

const submitting = ref(false)
const formData = ref<{orderNumber: string, lastName: string, email: string | null}>({
  orderNumber: route.query.orderNumber as string ?? '',
  lastName: route.query.lastName1 as string ?? '',
  email: route.query.email as string ?? null,
})
const showError = ref(false)
const linkSubscriptionResult = ref<LinkSubscriptionResult | null>(null)
const subscription = ref<Subscription | null>()

const subscriptions = computed(() => orders.subscriptions)
const subscriptionsLoading = computed(() => orders.loading)
const showEmailInput = computed(() => {
  return (
    linkSubscriptionResult.value !== null &&
    linkSubscriptionResult.value.status ===
    LinkSubscriptionStatus.EmailRequired
  );
})

function showConfirmEmailModal() {
  vfm.show("confirmEmail");
}

function continueWithEmail() {
  formData.value.email = linkSubscriptionResult.value!.email;
  submit();
}

function abort() {
  linkSubscriptionResult.value = null;
  vfm.hide("confirmEmail");
}

function redirectToRenewal() {
  router.push({
    name: "subscription-renewal",
    params: {
      id: subscription.value!.id,
    },
    query: {
      returnTo: "renewal",
    },
  });
}

async function submit() {
  try {
    showError.value = false;
    submitting.value = true;
    const subscription = await auth.loginUsingOrderNumber(formData.value)
    router.replace({
      name: "subscription-renewal",
      params: {
        id: subscription.id,
      },
      query: route.query
    });
  } catch (error: any) {
    if (error.response && error.response.status === 400) {
      linkSubscriptionResult.value = error.response
        .data as LinkSubscriptionResult;
      if (
        linkSubscriptionResult.value.status ===
        LinkSubscriptionStatus.ConfirmEmail
      ) {
        showConfirmEmailModal();
      } else {
        showError.value = true;
      }
    } else {
      showError.value = true;
      linkSubscriptionResult.value = null;
    }
  } finally {
    submitting.value = false;
  }
}

if (auth.isFullyLoggedIn) {
  orders.fetch()
} else if (formData.value.email && formData.value.lastName && formData.value.orderNumber) {
  submit()
}
</script>
