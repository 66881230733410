<template>
  <AlertDialog name="gift-certificates"
               disable-auto-hide
               @negative="close">
    <template #actions>
      <button
        class="btn font-semibold"
        @click="close"
        type="button"
        :disabled="loading"
      >
        {{ $t('app.close') }}
      </button>
      <button
        class="btn btn-primary font-semibold"
        @click="downloadSelectGiftCertificate"
        type="button"
        :disabled="selectedGiftCertificate === null"
      >
        {{ $t('gift-certificates.download')}}
      </button>
    </template>
    <template #title>
      {{ $t('gift-certificates.title') }}
    </template>
    <Alert v-if="giftCertificates.length === 0 && !loading" variant="info"  icon="info-circle">
      {{$t('gift-certificates.empty')}}
    </Alert>
    <div v-else>
        <FormSelect
          class="-mt-5"
          :label="$t('gift-certificates.gift-certificate.label')"
          :placeholder="$t('gift-certificates.gift-certificate.placeholder')"
          :options="giftCertificates"
          :loading="loading"
          :disabled="loading"
          value-key="title"
          name-key="title"
          required
          return-object
          v-model="selectedGiftCertificate"
        />
        <p class="hint">
          {{$t('gift-certificates.gift-certificate.hint')}}
        </p>
    </div>
  </AlertDialog>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import AlertDialog from "@/components/AlertDialog.vue";
import { Subscription } from "@/models/subscription";
import { useVfm } from "@/plugins/vfm";
import { useTenant } from "@/plugins/tenant";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useAuth } from "@/pinia/auth";
import { useAxios } from "@/plugins/axios";
import { Download } from "@/models/download";
import FormSelect from "@/components/form/FormSelect.vue";
import { useTracker } from "@/plugins/tracker";
import Alert from "@/components/Alert.vue";

interface GiftCertificate {
  title: string
  download: Download
}

const props = defineProps({
  subscription: {
    type: Subscription,
    required: true
  },
})

const tenant = useTenant()
const vfm = useVfm()
const {t,d,tm} = useI18n()
const router = useRouter()
const auth = useAuth()
const axios = useAxios()
const tracker = useTracker()

const loading = ref(false)
const giftCertificates = ref<GiftCertificate[]>([])
const selectedGiftCertificate = ref<GiftCertificate|null>(null)

function close() {
  vfm.hide('gift-certificates');
}

async function loadGiftCertificates() {
  loading.value = true;
  try {
    const response = await axios.get<GiftCertificate[]>(
      `/subscriptions/${props.subscription.id}/gift-certificates`,
    );
    giftCertificates.value = response.data
  } catch (e) {
    // TODO
  } finally {
    loading.value = false
  }
}

function downloadSelectGiftCertificate() {
  tracker.trackEvent(
    "Subscription",
    "DownloadGiftCertificate",
    props.subscription.publication
  );
  window.open(selectedGiftCertificate.value!.download.url, '_blank')
}

onMounted(loadGiftCertificates)

</script>
