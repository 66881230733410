<template>
  <div
    class="border rounded-card border-t-0 rounded-t-none overflow-hidden"
  >
    <table class="text-sm sm:text-base w-full">
      <thead>
        <tr class="border-none bg-gray-50">
          <th class="p-3"
            :class="tenant.issueHistoryConfig.displayedCols?.indexOf('issueNumber') === -1 ? 'hidden' : ''"
          >
            {{ $t("delivered-issues.issue") }}
          </th>
          <th class="p-3 hidden"
            :class="tenant.issueHistoryConfig.displayedCols?.indexOf('issue') === -1 ? '' : 'md:table-cell'"
          >
            {{ $t("delivered-issues.issue-number") }}
          </th>
          <th class="p-3"
            :class="tenant.issueHistoryConfig.displayedCols?.indexOf('dateOfFirstSale') === -1 ? 'hidden' : ''"
          >
            {{ $t("delivered-issues.date-of-first-sale") }}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="border-t border-tables"
          v-for="issue in currentIssues"
          :key="issue.issue"
          data-testid="delivered-issues-row"
        >
          <td class="p-3"
            :class="tenant.issueHistoryConfig.displayedCols?.indexOf('issueNumber') === -1 ? 'hidden' : ''"
          >
            <IssueLabel :issue="issue"></IssueLabel>
          </td>
          <td class="p-3 hidden"
            :class="tenant.issueHistoryConfig.displayedCols?.indexOf('issue') === -1 ? '' : 'md:table-cell'"
          >
            {{ issue.issue }}
          </td>
          <td class="p-3"
            :class="tenant.issueHistoryConfig.displayedCols?.indexOf('dateOfFirstSale') === -1 ? 'hidden' : ''"
          >
            {{ $d(issue.dateOfFirstSale) }}
          </td>
          <td class="text-right">
            <DownloadButton
              class="p-3 text-primary"
              v-if="showDownload(issue)"
              :subscription="subscription"
              :issue="issue"
              icon-only
            />
          </td>
        </tr>
        <tr v-if="currentIssues.length === 0">
          <td colspan="5" class="text-center text-gray-700 p-3 py-9">
            {{$t('delivered-issues.empty')}}
          </td>
        </tr>
      </tbody>
      <tfoot v-if="subscription.deliveredIssues!.length > issuesPerPage">
        <tr class="border-t border-tables">
          <td colspan="5" class="p-3 bg-gray-50">
            <Pagination
              :items-per-page="issuesPerPage"
              :total="subscription.deliveredIssues!.length"
              v-model="page"
            ></Pagination>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
<script lang="ts" setup>
import { Subscription } from "@/models/subscription";
import { computed, ref } from "vue";
import EmptyState from "@/components/EmptyState.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import DownloadButton from "@/components/DownloadButton.vue";
import IssueLabel from "@/components/IssueLabel.vue";
import { Issue } from "@/models/issue";
import { DownloadStatus } from "@/models/downloadStatus";
import { useTenant } from "@/plugins/tenant";

const tenant = useTenant()

const props = defineProps({
  subscription: {
    type: Subscription,
    required: true
  },
})

const page = ref(0)
const issuesPerPage = ref(10)

const currentIssues = computed(() => {
  return props.subscription.deliveredIssues!.slice(
    page.value * issuesPerPage.value,
    (page.value + 1) * issuesPerPage.value
  );
})

function showDownload(issue: Issue) {
  return issue.downloadStatus === DownloadStatus.Found;
}
</script>
