<template>
  <div>
    <BackButton />
    <div class="py-4 border-t mt-4">
      <h3 class="text-xl font-medium font-display">
        {{ $t('business-partner.bp-number.label') }} {{ bpNumber }}
      </h3>
      <p class="mt-4">
        {{ $t('subscription-business-partner.heading') }}
      </p>
    </div>
    <Spinner v-if="subscriptionsLoading"></Spinner>
    <form v-else @submit.prevent="submit">
      <RequiredFieldsHint></RequiredFieldsHint>
      <BusinessPartnerAddressForm
        class="mt-4"
        :subscription="subscription"
        :business-partner="businessPartner"
        :allow-first-name-change="$tenant.addressChangeConfig.allowFirstNameChange"
        :allow-last-name-change="$tenant.addressChangeConfig.allowLastNameChange"
        :allow-country-change="$tenant.addressChangeConfig.allowCountryChange"
        :allow-email-change="$tenant.addressChangeConfig.allowEmailChange"
        :allow-phone-number-change="$tenant.addressChangeConfig.allowPhoneNumberChange"
        :allow-date-of-birth-change="$tenant.addressChangeConfig.allowDateOfBirthChange"
        :email-required="emailRequired"
        :show-valid-from="$tenant.addressChangeConfig.allowTimedChanges"
        show-name
        show-email
        show-date-of-birth
        show-phone-number
      />
      <template v-if="affectedSubscriptions.length">
        <FormSectionHeader
          :title="$t('subscription-business-partner.affected-subscriptions')"
          class="mt-6"
        >
        </FormSectionHeader>
        <div class="mt-5 grid grid-cols-3 gap-2">
          <BusinessPartnerSubscriptionItem
            v-for="affectedSubscription in affectedSubscriptions"
            :key="affectedSubscription.id"
            :subscription="affectedSubscription"></BusinessPartnerSubscriptionItem>
        </div>
      </template>
      <ButtonBar class="mt-6">
        <ButtonWithLoadingIndicator
          class="submit btn btn-primary"
          type="submit"
          :disabled="submitting"
          :loading="submitting"
        >
          {{ $t("bp-form.submit.label") }}
        </ButtonWithLoadingIndicator>
        <button
          class="submit btn mt-4 md:mt-0 md:ml-4"
          type="button"
          :disabled="submitting"
          @click="cancel"
        >
          {{ $t("bp-form.cancel.label") }}
        </button>
      </ButtonBar>
    </form>
    <AlertDialog
      :positive-text="$t('business-partner.change-address')"
      :negative-text="$t('app.cancel')"
      name="confirm"
      ref="confirmDialog"
    >
      <template #title>{{ $t('subscription-business-partner.confirm-address-change.title') }}</template>
      {{ $t('subscription-business-partner.confirm-address-change.text') }}
    </AlertDialog>
    <AlertDialog name="success" :negative-text="$t('app.close')">
      {{ $t("subscription-business-partners.address-change-success") }}
    </AlertDialog>
    <AlertDialog name="error" :negative-text="$t('app.close')">
      {{ $t("app.error") }}
    </AlertDialog>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch, watchEffect } from 'vue'
import BackButton from "@/components/BackButton.vue";
import PageTitle from "@/components/PageTitle.vue";
import Spinner from "@/components/Spinner.vue";
import BusinessPartnerAddressForm from "@/components/business-partner/BusinessPartnerAddressForm.vue";
import RequiredFieldsHint from "@/components/RequiredFieldsHint.vue";
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import ButtonBar from "@/components/ButtonBar.vue";
import AlertDialog from "@/components/AlertDialog.vue";
import { useSubscriptions } from "@/pinia/orders";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import { useBusinessPartners } from "@/pinia/businessPartners";
import { BusinessPartner, BusinessPartnerRole, cloneBusinessPartnerWithDefaults } from "@/models/businessPartner";
import { useTracker } from "@/plugins/tracker";
import { useVfm } from "@/plugins/vfm";
import { shallowEqualObjects } from "@/util/equals";
import FormSectionHeader from "@/components/form/FormSectionHeader.vue";
import BusinessPartnerSubscriptionItem from "@/pages/business-partners/BusinessPartnerSubscriptionItem.vue";

const route = useRoute()
const router = useRouter()
const businessPartners = useBusinessPartners()
const tracker = useTracker()
const vfm = useVfm()

const subscriptionId = route.params.id as string
const bpNumber = route.params.bpNumber as string

const { subscriptions, subscriptionsLoading, getSubscriptionById } = useSubscriptions()

const subscription = computed(() => {
  return getSubscriptionById(subscriptionId)
})

const initialBusinessPartner = businessPartners.getBusinessPartnerByBpNumber(bpNumber) ?? {}

const submitting = ref(false)
const businessPartner = ref<BusinessPartner>(cloneBusinessPartnerWithDefaults(initialBusinessPartner))
const originalBusinessPartner = ref<BusinessPartner>(cloneBusinessPartnerWithDefaults(initialBusinessPartner))
const form = ref<HTMLFormElement | null>(null)
const confirmDialog = ref(null)
const isDirty = computed(() => {
  return !shallowEqualObjects(businessPartner.value, originalBusinessPartner.value)
})
const emailRequired = computed(() => {
  return originalBusinessPartner.value.emailAddress != null
})

const affectedSubscriptions = computed(() => {
  return subscriptions.value.filter((subscription) => {
    return subscription.isPhysicalSubscription() && subscription.businessPartners.some((businessPartner) => {
      return businessPartner.role === BusinessPartnerRole.WARENEMPFAENGER && businessPartner.bpNumber === bpNumber
    })
  })
})

watch(subscription, () => {
  if (subscription.value) {
    const bp = businessPartners.getBusinessPartnerByBpNumber(bpNumber)
    originalBusinessPartner.value = cloneBusinessPartnerWithDefaults(bp)
    businessPartner.value = cloneBusinessPartnerWithDefaults(bp)
  }
})

async function submit() {
  try {
    const dialog = confirmDialog.value as any;
    if (!(await dialog.showConfirm())) {
      return;
    }
    submitting.value = true;
    await businessPartners.changeAddress({
      businessPartner: businessPartner.value!,
      subscriptionId: subscriptionId,
    });
    tracker.trackEvent("BusinessPartner", "ChangeAddress");
    originalBusinessPartner.value = cloneBusinessPartnerWithDefaults(businessPartner.value)
    form.value?.reset()
    vfm.show('success')
  } catch (error) {
    vfm.show('error')
  } finally {
    submitting.value = false;
  }
}

function cancel() {
  router.replace({ name: 'business-partners' })
}

function confirmDiscardChanges() {
  return window.confirm(
    "Sie haben ungespeicherte Änderungen. Möchten Sie diese verwerfen?"
  );
}

onBeforeRouteLeave(() => {
  if (isDirty.value && !confirmDiscardChanges()) {
    return false
  }
})

</script>
