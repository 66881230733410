<template>
  <address class="not-italic leading-snug">
    <p>
      {{ businessPartner.salutation }}
      {{ businessPartner.title }}
      {{ businessPartner.titleAcademic }}
    </p>
    <p>
      {{ businessPartner.firstName }}
      {{ businessPartner.lastName1 || businessPartner.lastName }}
    </p>
    <p v-if="businessPartner.jobTitle">
      {{ businessPartner.jobTitle }}
    </p>
    <p v-if="businessPartner.company1">
      {{ businessPartner.company1 }}
    </p>
    <template v-if="useAddressLines">
      <p v-if="businessPartner.addressLine1">
        {{ businessPartner.addressLine1 }}
      </p>
      <p v-if="businessPartner.addressLine2">
        {{ businessPartner.addressLine2 }}
      </p>
      <p v-if="businessPartner.addressLine3">
        {{ businessPartner.addressLine3 }}
      </p>
    </template>
    <p>
      {{ businessPartner.street }}
      {{ businessPartner.streetSuffix }}
      {{ businessPartner.streetNumber }}
      {{ businessPartner.streetNumberSuffix }}
    </p>
    <p v-if="businessPartner.info">
      {{ businessPartner.info }}
    </p>
    <template v-if="isUKAddress">
      <p>
        {{ businessPartner.city }}
      </p>
      <p>
        {{ businessPartner.zipcode }}
      </p>
    </template>
    <p v-else>{{ businessPartner.zipcode }} {{ businessPartner.city }}</p>
    <p>
      {{ $tm("countries")[businessPartner.countryCode] }}
    </p>
    <p v-if="showEmail">
      {{ businessPartner.emailAddress }}
    </p>
  </address>
</template>
<script lang="ts" setup>
import { BusinessPartner } from "@/models/businessPartner";
import { computed, PropType } from "vue";
import { useTenant } from "@/plugins/tenant";

const props = defineProps({
  businessPartner: {
    type: Object as PropType<BusinessPartner>,
    required: true
  },
  showEmail: Boolean
})

const tenant = useTenant()

const useAddressLines = computed(() => tenant.supportsAddressLines());
const isUKAddress = computed(() => props.businessPartner.countryCode === 'GB');
</script>
