<template>
  <RadioGroup :model-value="modelValue as any" @update:model-value="emit('update:modelValue', $event)">
    <RadioGroupLabel class="block mt-4 font-bold">
      {{ title }}
    </RadioGroupLabel>
    <div class="mt-2 grid grid-cols-1 gap-y-2 md:grid-cols-2 sm:gap-x-2">
      <RadioGroupOption as="template" v-for="recommendation in recommendations" :key="recommendation.id"
                        :value="recommendation" v-slot="{ checked, active }">
        <div
          :class="[checked ? 'border-transparent' : 'border-gray-300', active ? 'ring-2 ring-primary' : '', 'relative bg-white border rounded-card shadow-sm p-4 cursor-pointer focus:outline-none hover:shadow']">
          <div class="flex flex-col">
            <template v-if="recommendation.article">
              <RadioGroupLabel as="p" class="block text-gray-900 mb-1" v-if="recommendation.description">
                {{ recommendation.description }}
              </RadioGroupLabel>
              <RadioGroupDescription as="div" class="text-gray-700" :class="recommendation.description && 'mt-2'">
                {{ recommendation.article.articleText }}
              </RadioGroupDescription>
              <dl class="grid grid-cols-2 gap-1 border-t mt-4 pt-4">
                <DescriptionListItem v-if="recommendation.article.offerType && offerTypeTranslations[recommendation.article.offerType]" :label="$t('recommendation.offer-type.label')">
                  <Badge>{{ offerTypeTranslations[recommendation.article.offerType] }}</Badge>
                </DescriptionListItem>
                <DescriptionListItem :label="$t('recommendation.article-price.label')">
                  {{ $n(recommendation.article.price, recommendation.article.currencyKey) }}
                </DescriptionListItem>
              </dl>
            </template>
            <template v-else-if="recommendation.product">
              <div class="flex gap-4">
                <CoverImage :src="recommendation.product.images[0]?.url" class="w-1/4 h-auto self-start flex-none"></CoverImage>
                <div>
                  <RadioGroupLabel as="div" class="flex text-gray-900 mb-1">
                    {{ recommendation.product.title }}
                  </RadioGroupLabel>
                  <RadioGroupDescription as="div" class="text-gray-700 text-sm prose mt-2"
                                         v-html="recommendation.product.attributes.shortDesc">
                  </RadioGroupDescription>
                </div>
              </div>
              <dl class="grid grid-cols-2 gap-1 border-t mt-4 pt-4">
                <DescriptionListItem :label="$t('recommendation.article-price.label')">
                  {{ $n(Number(recommendation.product.price), recommendation.product.currency) }}
                </DescriptionListItem>
              </dl>
            </template>
            <template v-else-if="recommendation.upgradeOption">
              <RadioGroupLabel as="p" class="block text-gray-900 mb-1">
                {{ recommendation.description }}
              </RadioGroupLabel>
              <RadioGroupDescription as="div" class="text-gray-700 text-sm mt-2">
                {{ recommendation.upgradeOption.text }}
              </RadioGroupDescription>
              <dl class="grid grid-cols-2 gap-1 mt-4 border-t pt-4">
                <DescriptionListItem :label="$t('recommendation.upgrade-time.label')">
                  {{ $tm('recommendation.upgrade-time.options')[recommendation.upgradeOption.upgradeTime] }}
                </DescriptionListItem>
                <DescriptionListItem v-if="recommendation.upgradeOption.surchargeAmount"
                                     :label="$t('recommendation.surcharge.label')">
                  {{ $n(recommendation.upgradeOption.surchargeAmount, recommendation.upgradeOption.surchargeCurrency) }}
                </DescriptionListItem>
                <DescriptionListItem :label="$t('recommendation.next-payment.label')">
                  {{
                    $n(recommendation.upgradeOption.nextPaymentAmount, recommendation.upgradeOption.nextPaymentCurrency)
                  }}
                </DescriptionListItem>
              </dl>
            </template>
            <template v-else>
              <RadioGroupLabel as="p" class="block text-gray-900">
                {{ recommendation.description }}
              </RadioGroupLabel>
            </template>
          </div>
          <div
            :class="[active ? 'border' : 'border-2', checked ? 'border-primary' : 'border-transparent', 'absolute -inset-px rounded-card pointer-events-none']"
            aria-hidden="true" />
        </div>
      </RadioGroupOption>
    </div>
  </RadioGroup>
</template>

<script lang="ts" setup>
import { Recommendation } from "@/models/recommendation";
import { RadioGroup, RadioGroupOption, RadioGroupLabel, RadioGroupDescription } from "@headlessui/vue";
import { useI18n } from "vue-i18n";
import DescriptionListItem from "@/components/DescriptionListItem.vue";
import CoverImage from "@/components/CoverImage.vue";
import Badge from "@/components/Badge.vue";
import { computed } from "vue";

const props = defineProps<{
  title: string
  modelValue: Recommendation | null,
  recommendations: Recommendation[]
}>()

const emit = defineEmits<{
  (event: 'update:modelValue', value: Recommendation | null): void
}>()

const { n, tm } = useI18n()

const offerTypeTranslations = computed(() => tm('recommendation.offer-type.options') as Record<string, string>)
</script>
