export interface MixType {
  publication: string;
  edition: string;
  mixType: string;
  mixTypeDesc: string;
  mediumType: MediumType;
  complaintIssues: number;
  pdfDownload: boolean;
}

export enum MediumType {
  DIGITAL_EDITION = "DE",
  ONLINE = "ON",
  FOOD_SUPPLEMENT = "NE",
  DONATION = "DO",
  COLLECTION = "LB",
  COMBO = "KO",
}
