<template>
  <AlertDialog name="cross-selling-offer"
               ref="root"
               :positive-text="$t('subscription-renewal.show-offer')"
               :negative-text="$t('app.close')"
               disable-auto-hide
               @positive="toOfferPage"
               @negative="close">
    <template #title>
      <font-awesome-icon icon="check-circle" class="text-green-600"></font-awesome-icon>
      <span class="ml-4">{{$t('subscription-renewal.payment-success')}}</span>
    </template>
    <p>{{$t('subscription-renewal.cross-selling-offer')}}</p>
    <div class="mt-4 relative bg-white border rounded-card shadow-sm p-4 focus:outline-none">
      <div class="flex gap-4">
        <CoverImage :src="offer.product.images[0]?.url" class="w-1/4 h-auto self-start flex-none"></CoverImage>
        <div>
          <div class="flex text-gray-900 mb-1">
            {{ offer.product.title }}
          </div>
        </div>
      </div>
    </div>
    <Alert v-if="!offer.voucherCode" variant="error" icon="exclamation-circle" class="mt-4">
      <i18n-t keypath="subscription-renewal.voucher-code-not-available">
        <template #phone
        ><a
          class="link"
          :href="'tel:' + tenant.contactConfig.fallbackPhoneNumber"
        >{{ tenant.contactConfig.fallbackPhoneNumber }}</a>
        </template>
      </i18n-t>
    </Alert>
    <template v-else>
      <div class="flex items-end space-x-2">
        <FormInput class="flex-1" select-on-focus :label="$t('subscription-renewal.voucher-code.label')" :model-value="offer.voucherCode"
                   readonly />
        <button class="btn btn-primary" style="height:46px" @click="copyToClipboard">
          <font-awesome-icon :icon="copied ? 'check' : 'copy'" />
        </button>
      </div>
      <p class="hint mt-1 leading-5">{{$t('subscription-renewal.voucher-code.hint')}}</p>
    </template>
  </AlertDialog>
</template>

<script lang="ts" setup>
import { PropType, ref, ComponentPublicInstance } from 'vue'
import AlertDialog from "@/components/AlertDialog.vue";
import { Subscription } from "@/models/subscription";
import { useVfm } from "@/plugins/vfm";
import { useTenant } from "@/plugins/tenant";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useAuth } from "@/pinia/auth";
import { CrossSellingOffer } from "@/models/pendingPayment";
import CoverImage from "@/components/CoverImage.vue";
import FormInput from "@/components/form/FormInput.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useClipboard } from '@vueuse/core'
import Alert from "@/components/Alert.vue";

const props = defineProps({
  subscription: {
    type: Subscription,
    required: true
  },
  offer: {
    type: Object as PropType<CrossSellingOffer>,
    required: true
  },
})

const tenant = useTenant()
const vfm = useVfm()
const { t, d, tm } = useI18n()
const router = useRouter()
const auth = useAuth()

const root = ref<ComponentPublicInstance | null>(null)

function toOfferPage() {
  window.open(props.offer.product.url, '_blank');
}

const { copy, copied } = useClipboard()

function copyToClipboard() {
  copy(props.offer.voucherCode)
}

function close() {
  if (auth.isFullyLoggedIn) {
    router.push({
      name: 'subscription',
      params: {
        id: props.subscription.id
      }
    })
  } else {
    router.push({
      name: 'contact'
    })
  }
  vfm.hide('cross-selling-offer');
}

</script>
