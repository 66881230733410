<template>
  <div
    class="border p-4 rounded-input relative flex items-center"
    role="alert"
    :class="variantClasses"
  >
    <font-awesome-icon
      v-if="icon"
      :icon="icon"
      class="mr-4 mt-1 self-start"
    ></font-awesome-icon>
    <div class="flex-1">
      <slot />
    </div>
    <slot name="action"></slot>
  </div>
</template>
<script lang="ts" setup>
import { computed, PropType } from "vue";

type AlertVariant =
  "none" |
  "warning" |
  "success" |
  "error" |
  "info" |
  "primary" |
  "primary-border"

const variantClassesMap: Record<AlertVariant, string> = {
  "none": "",
  "warning": "bg-yellow-50 border-yellow-200 text-yellow-800",
  "success": "bg-green-50 border-green-200 text-green-800",
  error: "bg-red-50 border-red-200 text-red-800",
  "info": " bg-blue-50 border-blue-200 text-blue-800",
  "primary": "bg-primary border-primary text-white",
  "primary-border": "bg-white border-primary text-black"
};

const props = defineProps({
  variant: {
    type: String as PropType<AlertVariant>,
    default: "none"
  },
  icon: {
    type: String,
    default: undefined
  }
})

const variantClasses = computed(() => {
  return variantClassesMap[props.variant]
})
</script>
