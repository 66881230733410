export class DateUtils {
  public static toUTCDate(date: Date) {
    if (date === null) {
      return null;
    }
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
  }
}
