<template>
  <AlertDialog
    name="confirm-cancellation"
    @positive="positive"
    :positive-text="$t('subscription-cancellation.confirm')"
    :negative-text="$t('app.cancel')"
  >
    <template #title>{{$t('subscription-cancellation.title')}}</template>
    <i18n-t :keypath="confirmMessagePath">
      <template #name>
        <b>{{ subscription.displayName }}</b>
      </template>
      <template #date v-if="date">
        {{ date }}
      </template>
    </i18n-t>
  </AlertDialog>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { Subscription } from "@/models/subscription";
import AlertDialog from "@/components/AlertDialog.vue";

const props = defineProps({
  subscription: {
    type: Subscription,
    required: true
  },
  date: {
    type: String,
    default: null
  }
})

const emit = defineEmits<{
  (event: 'confirm') : void
}>()

function positive() {
  emit('confirm')
}

const confirmMessagePath = computed(() => {
  return props.date
    ? "subscription-cancellation.confirm-message-with-date"
    : "subscription-cancellation.confirm-message";
})

</script>
