<template>
  <div class="flex items-center">
    <div class="hidden sm:block">
      <p class="text-sm leading-5 text-gray-700">
        {{
          $t("pagination", {
            total: total,
            from: currentRange.from,
            to: currentRange.to,
          })
        }}
      </p>
    </div>
    <div class="flex-1"></div>
    <div>
      <nav class="relative z-0 inline-flex shadow-sm">
        <!--Previous-->
        <PaginationButton
          icon
          :disabled="hasPrevious"
          aria-label="Previous"
          @click="previousPage"
        >
          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </PaginationButton>
        <!--First-->
        <PaginationButton
          v-if="hasFirst"
          :active="modelValue === 0"
          @click="toPage(0)"
        >
          1
        </PaginationButton>
        <PaginationButton v-if="hasFirstEllipsis">&hellip;</PaginationButton>
        <!--Pages-->
        <PaginationButton
          v-for="p in pagesInRange"
          :key="p.number"
          :active="p.isCurrent"
          @click="toPage(p.number)"
        >
          {{ p.number + 1 }}
        </PaginationButton>
        <!--Last-->
        <PaginationButton v-if="hasLastEllipsis">&hellip;</PaginationButton>
        <PaginationButton
          v-if="hasLast"
          :active="modelValue === totalPageCount - 1"
          @click="toPage(totalPageCount - 1)"
        >
          {{ totalPageCount }}
        </PaginationButton>
        <!--Next-->
        <PaginationButton
          :disabled="hasNext"
          icon
          aria-label="Next"
          @click="nextPage"
        >
          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </PaginationButton>
      </nav>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import PaginationButton from "@/components/pagination/PaginationButton.vue";

const props = defineProps({
  modelValue: {
    type: Number,
    required: true
  },
  total: {
    type: Number,
    required: true
  },
  itemsPerPage: {
    type: Number,
    default: 10
  }
})

const emit = defineEmits(['update:modelValue'])

const totalPageCount = computed(() => {
  return Math.ceil(props.total / props.itemsPerPage);
})

const beforeCurrent = computed(() => {
  return 1;
})

const afterCurrent = computed(() => {
  return 1;
})

const hasPrevious = computed(() => {
  return props.modelValue > 0;
})

const hasNext = computed(() => {
  return props.modelValue < totalPageCount.value - 1;
})

const hasFirst = computed(() => {
  return props.modelValue > 1 + beforeCurrent.value;
})

const hasLast = computed(() => {
  return props.modelValue < totalPageCount.value - (2 + afterCurrent.value);
})

const hasFirstEllipsis = computed(() => {
  return props.modelValue >= beforeCurrent.value + 2;
})

const hasLastEllipsis = computed(() => {
  return props.modelValue < totalPageCount.value - (2 + afterCurrent.value);
})

const pagesInRange = computed(() => {
  let left = Math.max(0, props.modelValue - beforeCurrent.value);
  if (left - 1 === 0) {
    left--; // Do not show the ellipsis if there is only one to hide
  }
  let right = Math.min(
    props.modelValue + afterCurrent.value,
    totalPageCount.value - 1
  );
  if (totalPageCount.value - right === 2) {
    right++; // Do not show the ellipsis if there is only one to hide
  }
  const pages = [];
  for (let i = left; i <= right; i++) {
    pages.push(getPage(i));
  }
  return pages;
})

const currentRange = computed(() => {
  if (props.total === 0) {
    return {
      from: 0,
      to: 0,
    };
  }
  return {
    from: props.modelValue * props.itemsPerPage + 1,
    to: Math.min((props.modelValue + 1) * props.itemsPerPage, props.total),
  };
})

function nextPage() {
  if (props.modelValue < totalPageCount.value - 1) {
    emit("update:modelValue", props.modelValue + 1);
  }
}

function previousPage() {
  if (props.modelValue > 0) {
    emit("update:modelValue", props.modelValue - 1);
  }
}

function toPage(num: number) {
  if (props.modelValue === num) return;
  emit("update:modelValue", num);
}

function getPage(num: number) {
  return {
    number: num,
    isCurrent: props.modelValue === num,
  };
}


</script>

<style></style>
