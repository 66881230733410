<template>
  <div>
    <Alert v-if="!$tenant.supportsEmailWidget()" variant="error">
      <i18n-t keypath="email-widget.disabled">
        <template #email
        ><a
          class="link"
          :href="'mailto:' + $tenant.contactConfig.fallbackEmail"
        >{{ $tenant.contactConfig.fallbackEmail }}</a
        ></template
        >
        <template #phone
        ><a
          class="link"
          :href="'tel:' + $tenant.contactConfig.fallbackPhoneNumber"
        >{{ $tenant.contactConfig.fallbackPhoneNumber }}</a
        ></template
        >
      </i18n-t>
    </Alert>
    <template v-else-if="linkEmailResult === null">
      <h3 class="font-display font-medium text-xl">
        {{ $t("email-widget.title") }}
      </h3>
      <p class="mt-2">{{ $t("email-widget.heading") }}</p>
      <hr class="my-4" />
      <EmailWidgetForm
        :submitting="submitting"
        @submit="submit"
      ></EmailWidgetForm>
    </template>
    <EmailWidgetResult v-else :result="linkEmailResult"></EmailWidgetResult>
    <LinkSubscriptionErrorAlertDialog
      :link-subscription-result="linkSubscriptionResult"
      :order-number="lastSubmittedOrderNumber"
    >
    </LinkSubscriptionErrorAlertDialog>
  </div>
</template>

<script lang="ts" setup>
import {  ref } from 'vue'
import Alert from "@/components/Alert.vue";
import LinkSubscriptionErrorAlertDialog from "@/components/LinkSubscriptionErrorAlertDialog.vue";
import EmailWidgetResult from "@/pages/email-widget/EmailWidgetResult.vue";
import EmailWidgetForm from "@/pages/email-widget/EmailWidgetForm.vue";
import { LinkEmailResult } from "@/models/emailWidget";
import { LinkSubscriptionResult } from "@/models/linkSubscriptionResult";
import { useAxios } from "@/plugins/axios";
import { useTracker } from "@/plugins/tracker";
import { useVfm } from "@/plugins/vfm";

const axios = useAxios()
const tracker = useTracker()
const vfm = useVfm()

const submitting = ref(false)
const lastSubmittedOrderNumber = ref<string|null>(null)
const linkEmailResult = ref<LinkEmailResult | null>(null)
const linkSubscriptionResult = ref<LinkSubscriptionResult | null>(null)

async function submit(formData: any) {
  try {
    submitting.value = true;
    lastSubmittedOrderNumber.value = formData.orderNumber;
    const response = await axios.post<any>(`/link-email`, formData);
    linkEmailResult.value = new LinkEmailResult(response.data);
    tracker.trackEvent("Subscription", "LinkEmail");
  } catch (error: any) {
    if (error.response && error.response.status === 400) {
      linkSubscriptionResult.value = error.response
        .data as LinkSubscriptionResult;
    } else {
      linkSubscriptionResult.value = null;
    }
    vfm.show("linkSubscriptionError");
  } finally {
    submitting.value = false;
  }
}
</script>
