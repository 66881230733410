export interface Payment {
  id: string;
  date: Date | string;
  amount: number;
  invoiceNumber: string | null;
  externalDocumentNumber: string | null;
  position: number;
  archiveId?: string;
  documentId?: string;
  invoiceStatusCode: InvoiceStatus;
}

export enum InvoiceStatus {
  Unknown = "U",
  Paid = "A",
  PartiallyPaid = "T",
  Unpaid = "O",
}
