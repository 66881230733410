<template>
  <img :src="_src" v-bind="$attrs" alt="cover" />
</template>
<script lang="ts" setup>
import { computed, PropType, ref, watch } from "vue";
import placeholder from "@/assets/nopic.jpg";
import { useTenant } from "@/plugins/tenant";

const props = defineProps({
  src: {
    type: String as PropType<string | null | undefined>,
    default: undefined
  }
})

const tenant = useTenant()
const loaded = ref(false)

const _src = computed(() => {
  const placeholderUrl = tenant.coverImagePlaceholderUrl ?? placeholder
  return loaded.value ? props.src! : placeholderUrl;
})

function loadImage() {
  if (props.src) {
    const image = new Image();
    image.src = props.src;
    image.onload = () => {
      loaded.value = true;
    };
  }
}

watch(() => props.src, () => {
  loaded.value = false
  loadImage()
})

loadImage()
</script>
