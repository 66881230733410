<template>
  <div>
    <PageTitle name="e-card"></PageTitle>
    <p class="mt-2">
        {{ $t("e-card.heading") }}
    </p>
    <hr class="my-4" />
    <FeatureDisabledAlert v-if="!$tenant.supportsECard()" />
    <ECardForm v-else @close="close"/>
  </div>
</template>

<script lang="ts" setup>
import PageTitle from "@/components/PageTitle.vue";
import FeatureDisabledAlert from "@/components/FeatureDisabledAlert.vue";
import { useAuth } from "@/pinia/auth";
import ECardForm from "@/components/e-card/ECardForm.vue";

const auth = useAuth()

function close() {
  // TODO: what to do here?
  window.close()
}
</script>
