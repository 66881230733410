import { DownloadStatus } from "@/models/downloadStatus";
import { BusinessPartner, BusinessPartnerRole } from "@/models/businessPartner";
import { PackageInfo } from "@/models/packageInfo";

export interface PurchaseOrder {
  id: string;
  purchaseOrderId: string;
  purchaseOrderNumber: string;
  distributionArticleNumber: string;
  dateOfPurchaseOrderClient: Date;
  downloadStatus: DownloadStatus;
  coverImageUrl: string;
  distributionArticleDescription: string | null;
  distributionArticleEan: string | null;
  invoiceNumber: string | null;
  invoiceStatus: PurchaseOrderInvoiceStatus | null;
  invoicePaymentAmount: number | null;
  businessPartner: BusinessPartner | null;
  packageInfo: PackageInfo | null
  role: BusinessPartnerRole
  quantity: number | null
}

export enum PurchaseOrderInvoiceStatus {
  Open = "1",
  Paid = "2",
  Overpaid = "3",
}
