<template>
  <div>
    <PaymentMethodUpdaterCard
      :payment-method="PaymentMethod.PAYPAL"
      :active="isActive"
      :disabled="props.disabled"
      @edit="startUpdateProcess"
    >
      <template #details> </template>
    </PaymentMethodUpdaterCard>
    <PopupWindow
      ref="popup"
      v-if="isUpdating"
      :url="popupWindowUrl"
      :placeholder="$t('payment-method-updater.please-wait')"
      :title="$t('paypal-updater.add-account')"
      target="paypal"
      with-overlay
      @close="handlePopupClose"
      @message="handlePopupMessage"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from "vue";
import { PaymentDetails, PaymentMethod, PaypalAccount } from "@/models/paymentDetails";
import { useAxios } from "@/plugins/axios";
import { useTracker } from "@/plugins/tracker";
import PopupWindow from "@/components/PopupWindow.vue";
import PaymentMethodUpdaterCard from "./PaymentMethodUpdaterCard.vue";
import { Subscription } from "@/models/subscription";

const axios = useAxios();
const tracker = useTracker();

const props = defineProps({
  subscription: {
    type: Subscription as PropType<Subscription>,
    required: true
  },
  paymentDetails: {
    type: Object as PropType<PaymentDetails>,
    required: true
  },
  disabled: Boolean
})
const emit = defineEmits(['success', 'error'])
const isActive = computed(() => PaymentMethod.PAYPAL === props.paymentDetails.paymentMethod)

const isUpdating = ref(false);
const popupWindowUrl = ref("");
const popup = ref<typeof PopupWindow | null>(null);

const account = computed(() => props.paymentDetails.account as PaypalAccount)

function handlePopupClose() {
  isUpdating.value = false;
  popupWindowUrl.value = "";
}

function handlePopupMessage(message: string) {
  isUpdating.value = false;
  popupWindowUrl.value = "";
  emit("success");
}

async function fetchPaymentWidgetUrl() {
  const response = await axios.post<any>(
    `/subscriptions/${props.subscription.id}/payment-details/paypal`
  );
  return response.data.url;
}

async function  startUpdateProcess() {
  try {
    isUpdating.value = true;
    popupWindowUrl.value = await fetchPaymentWidgetUrl();
    tracker.trackEvent(
      "Subscription",
      "ChangePaymentDetails",
      PaymentMethod.PAYPAL
    );
  } catch (e) {
    isUpdating.value = false;
    emit("error");
  }
}

</script>
