import { BusinessPartner, BusinessPartnerRole } from "@/models/businessPartner";
import { Issue } from "@/models/issue";
import { Payment } from "@/models/payment";
import { SubscriptionRenewal } from "@/models/subscriptionRenewal";
import { PaymentDetails, PaymentMethod } from "@/models/paymentDetails";
import { MediumType, MixType } from "@/models/mixType";
import { HolidayServiceType } from "@/models/holidayServiceType";
import { isAfter, startOfDay } from "date-fns";
import { NonNullableField } from "@/types";

export class Subscription {
  constructor(json: Partial<Subscription>) {
    // TODO: find a better way to deserialize json into classes
    Object.assign(this, json);
  }

  id: string;
  orderNumber: string;
  publication: string;
  publicationName: string;
  edition: string;
  isRenewal: boolean;
  isTimebased: boolean;
  isMultiLicense: boolean;
  isCombiSubscription: boolean;
  paymentMethod: string;
  subOProcessedPMD: SubPaymentMethod;
  billingFrequency: string;
  billingFrequencyShortDescription: string;
  validFromOnSaleDate: Date;
  validToOnSaleDate: Date;
  orderStatus: OrderStatus;
  businessPartners: Array<BusinessPartner>;
  currentChangeInfo: MixTypeChangeInfo | null;
  currentSuspension: Suspension | null;
  renewal: SubscriptionRenewal | null;
  nextIssue: Issue | null;
  deliveredIssues: Array<Issue> | null;
  paymentHistory: Array<Payment> | null;
  bundles: Array<BundleSubscription> | null;
  coverImageUrl: string;
  premiumDescription: string;
  salesDocumentType: string;
  salesSource: string;
  salesOrganization: string;
  roles: BusinessPartnerRole[];
  mixType: string;
  mixTypeDetails: MixType;
  paymentDetails?: PaymentDetails | null;
  custOrderNumber: string;
  custOrderDate: Date;
  prSourceVar: string | null;
  orderAttributes: OrderAttribute[];
  currentPhase: SubscriptionPhase;
  isCancelable: boolean

  get clientId() {
    return this.id.substring(0, 3)
  }

  get displayName(): string {
    return this.mixTypeDetails
      ? this.mixTypeDetails.mixTypeDesc
      : this.publicationName;
  }

  get currentCyclePaidAmount(): number {
    if (this.isBundleMaster()) {
      return Number(this.renewal!.currentCycleBundlePaidAmount)
    }
    if (this.renewal!.autoRenewal) {
      return this.renewal!.autoRenewalLastPaymentAmount
    }
    return Number(this.renewal!.currentCyclePaidAmount)
  }

  get orderingParty(): BusinessPartner {
    return this.businessPartners.find(
      (businessPartner) =>
        businessPartner.role === BusinessPartnerRole.AUFTRAGGEBER
    )!;
  }

  get goodsRecipient(): BusinessPartner {
    return this.businessPartners.find(
      (businessPartner) =>
        businessPartner.role === BusinessPartnerRole.WARENEMPFAENGER
    )!;
  }

  get currentRoleBusinessPartner() {
    return this.businessPartners.find((businessPartner) =>
      this.roles.includes(businessPartner.role!)
    )!;
  }

  get currentPaymentMethod(): PaymentMethod {
    // TODO: this is not a 100% correct and will be replaced in the future
    if (this.paymentMethod === "E") {
      return PaymentMethod.DIRECT_DEBIT;
    }
    if (this.paymentMethod === "K") {
      return PaymentMethod.CREDIT_CARD;
    }
    if (this.subOProcessedPMD) {
      if (this.subOProcessedPMD.subPaymentMethod === "660") {
        return PaymentMethod.PAYPAL;
      }
      if (this.subOProcessedPMD.subPaymentMethod === "000") {
        return PaymentMethod.INVOICE;
      }
      return PaymentMethod.CREDIT_CARD;
    }
    return PaymentMethod.INVOICE;
  }

  isActive(): boolean {
    return (
      this.orderStatus === OrderStatus.ACTIVE ||
      this.orderStatus === OrderStatus.INACTIVE_KNOWN_ACTIVATION_DATE ||
      this.orderStatus === OrderStatus.ACTIVE_KNOWN_TERMINATION_DATE
    );
  }

  isActiveNotCancelled(): boolean {
    return (
      this.orderStatus === OrderStatus.ACTIVE ||
      this.orderStatus === OrderStatus.INACTIVE_KNOWN_ACTIVATION_DATE
    );
  }

  isInactiveWithKnownActivationDate(): boolean {
    return this.orderStatus === OrderStatus.INACTIVE_KNOWN_ACTIVATION_DATE
  }

  isActiveStrict(): boolean {
    // same as isActive but doesn't count subscriptions whose activation date is in the future
    return (
      this.orderStatus === OrderStatus.ACTIVE ||
      this.orderStatus === OrderStatus.ACTIVE_KNOWN_TERMINATION_DATE
    );
  }

  isSuspended() {
    return this.orderStatus === OrderStatus.ACTIVE_HALTED;
  }

  isCancelled() {
    return this.orderStatus === OrderStatus.ACTIVE_KNOWN_TERMINATION_DATE;
  }

  isInactive() {
    return this.orderStatus === OrderStatus.INACTIVE;
  }

  isDigitalSubscription(): boolean {
    return (
      this.mixTypeDetails?.mediumType === MediumType.DIGITAL_EDITION ||
      this.mixTypeDetails?.mediumType === MediumType.ONLINE
    );
  }

  isPhysicalSubscription() {
    return !this.isDigitalSubscription();
  }

  isMembership(): boolean {
    return this.isRenewal && this.renewal!.offerClassification === "M";
  }

  isBundleSlave(): boolean {
    return this.isRenewal && this.renewal!.renewalBundleComponent === "S";
  }

  isBundleMaster(): boolean {
    return this.isRenewal && this.renewal!.renewalBundleComponent === "M";
  }

  isManagedExternally(): boolean {
    return this.salesDocumentType === SalesDocumentType.WBZ;
  }

  isNotManagedExternally(): boolean {
    return this.salesDocumentType !== SalesDocumentType.WBZ;
  }

  isOrderingParty(): boolean {
    return this.roles.includes(BusinessPartnerRole.AUFTRAGGEBER);
  }

  isLicenseManager(): boolean {
    return this.roles.includes(BusinessPartnerRole.LIZENZVERWALTER);
  }

  isGoodsRecipient(): boolean {
    return this.roles.includes(BusinessPartnerRole.WARENEMPFAENGER);
  }

  isInvoiceRecipient(): boolean {
    return this.roles.includes(BusinessPartnerRole.RECHNUNGSEMPFAENGER);
  }

  hasNextIssue(): boolean {
    // there is no next issue if the subscription is time based
    return !this.isTimebased && this.isActive() && this.nextIssue != null;
  }

  hasAutoRenewal(): boolean {
    return this.isRenewal && this.renewal!.autoRenewal
  }

  hasActiveAutoRenewal(): boolean {
    return this.isActiveNotCancelled() && this.isRenewal && this.renewal!.autoRenewal;
  }

  hasInactiveAutoRenewal(): boolean {
    return this.isActiveNotCancelled() && this.isRenewal && !this.renewal!.autoRenewal;
  }

  hasPaymentMethod(): boolean {
    return this.paymentMethod != null;
  }

  hasDifferingGoodsRecipient(): boolean {
    return this.orderingParty.bpNumber !== this.goodsRecipient.bpNumber;
  }

  hasPlannedMixTypeChange(): boolean {
    return this.currentChangeInfo?.futureMixTypeToIssueNr != null;
  }

  findActiveHolidayAddress(): BusinessPartner | null {
    const goodsRecipient = this.goodsRecipient;
    if (!goodsRecipient) {
      return null;
    }
    if (!Array.isArray(goodsRecipient.bpHolidayAddress) || goodsRecipient.bpHolidayAddress.length === 0) {
      return null;
    }
    const now = startOfDay(new Date())
    return (
      goodsRecipient.bpHolidayAddress.find((bp) => bp.validFrom != null && bp.validTo != null && isAfter(bp.validTo, now)) || null
    );
  }

  hasPlannedHolidayRedirect(): boolean {
    return this.findActiveHolidayAddress() !== null;
  }

  hasPlannedSuspension() {
    return (
      this.currentSuspension != null &&
      this.currentSuspension.suspendedToDate != null
    );
  }

  hasPlannedHolidayService() {
    return (
      this.hasPlannedHolidayRedirect() ||
      this.hasPlannedSuspension() ||
      this.hasPlannedMixTypeChange()
    );
  }

  get activeHolidayServiceType() {
    if (this.hasPlannedHolidayRedirect()) {
      return HolidayServiceType.Redirect;
    }
    if (this.hasPlannedSuspension()) {
      return HolidayServiceType.Break;
    }
    if (this.hasPlannedMixTypeChange()) {
      return HolidayServiceType.Download;
    }
    return null;
  }

  supportsHolidayService(): boolean {
    return (
      this.isNotManagedExternally() &&
      !this.isLicenseManager() &&
      ((this.isActive() && !this.isTimebased) ||
        (this.isSuspended() && this.currentSuspension!.suspendedToDate != null))
    );
  }

  supportsIssueComplaint(): boolean {
    return (
      this.isActive() && this.isNotManagedExternally() && (this.isPhysicalSubscription() || this.isCombiSubscription) && !this.isTimebased && !this.isLicenseManager()
    );
  }

  supportsCancellation(): boolean {
    return this.isCancelable || this.isManagedExternally();
  }

  supportsCancellationRevocation(): boolean {
    return this.isCancelled();
  }

  supportsUpgrade(): boolean {
    return (
      this.isOrderingParty()
    )
  }

  supportsECard(): boolean {
    // TODO
    return false;
    // return this.isActive()
  }

  supportsPaymentMethodChange() {
    return (
      this.isActive() && this.isOrderingParty() && this.isNotManagedExternally()
    );
  }

  supportsBillingFrequency() {
    return (
      this.isActive() && this.isOrderingParty() && this.isNotManagedExternally()
    );
  }

  supportsIssueCalendar() {
    return this.isActive() && !this.isTimebased && !this.isLicenseManager();
  }

  supportsPaymentHistory() {
    // TODO: all states, renewal etc?
    return this.isOrderingParty() && this.isNotManagedExternally();
  }

  supportsAddressChange() {
    return !this.isLicenseManager() && this.isNotManagedExternally();
  }

  supportsOptIns() {
    return !this.isLicenseManager() && this.isNotManagedExternally();
  }

  supportsIssueHistory() {
    return !this.isMembership() && !this.isBundleMaster() && !this.isLicenseManager();
  }

  supportsDownload() {
    return (
      this.mixTypeDetails != null &&
      this.mixTypeDetails.pdfDownload &&
      this.isGoodsRecipient()
    );
  }

  isGiftSubscription(properties: Record<keyof Subscription, string[]>) {
    // check if any of the property values matches
    let property: keyof Subscription;
    for (property in properties) {
      const propertyValue = this[property] as any
      if (propertyValue != null && properties[property]!.includes(propertyValue)) {
        return true
      }
    }
    return false
  }

  supportsGiftCertificateDownload(properties: Record<keyof Subscription, string[]>) {
    return this.isOrderingParty() && this.isGiftSubscription(properties)
  }

  supportsRenewal(supportsAutoRenewal: boolean) {
    return (
      this.isRenewal && (supportsAutoRenewal || !this.renewal!.autoRenewal) && this.isOrderingParty()
    );
  }

  supportsMultiUserLicenses() {
    return (
      this.isMultiLicense && (this.isLicenseManager() || this.isOrderingParty())
    );
  }

  supportsPhaseUpgrade(): boolean {
    return (
      this.currentPhase?.canUpgrade && this.isOrderingParty() && this.isActive()
    );
  }

}

interface SubPaymentMethod {
  subPaymentMethod: string;
}

export enum OrderStatus {
  ACTIVE = "A",
  ACTIVE_KNOWN_TERMINATION_DATE = "AK",
  INACTIVE = "I",
  INACTIVE_KNOWN_ACTIVATION_DATE = "IZ",
  ACTIVE_HALTED = "IU",
}

export enum SalesDocumentType {
  WBZ = "WBZA",
}

export interface MixTypeChangeInfo {
  futureMixType: string;
  futureMixTypeFromIssueNr: string;
  futureMixTypeToIssueNr?: string;
}

export interface Suspension {
  suspendedFromDate: Date;
  suspendedToDate: Date;
  suspendedFromIssueNr?: string;
  suspendedToIssueNr?: string;
}

export interface BundleSubscription {
  bundleOrderNumber: string;
  publication: string;
  quantity: number;
}

export interface OrderAttribute {
  type: string;
  value: string;
}

export type RenewalSubscription = NonNullableField<Subscription, 'renewal'>
