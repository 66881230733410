<template>
  <li class="border rounded-card shadow p-3">
    <div class="flex items-start justify-between">
      <div class="">
        <BusinessPartnerAddress class="font-semibold"
                                :business-partner="entry.businessPartner"></BusinessPartnerAddress>
        <p class="">{{ $t('business-partner.bp-number.label') }}: {{ entry.businessPartner.bpNumber }}</p>
      </div>
      <router-link class="btn btn-primary inline-flex items-center" :to="linkToBusinessPartner"
                   v-if="supportsAddressChange">
        <font-awesome-icon icon="edit" class="sm:mr-2"></font-awesome-icon>
        <span class="hidden sm:block">{{ $t('business-partner.change-address') }}</span>
      </router-link>
    </div>
    <hr class="my-2" />
    <h5 class="font-semibold">{{ $t('business-partner.subscriptions-and-roles') }}</h5>
    <div class="grid md:grid-cols-2 gap-2 mt-1">
      <BusinessPartnerSubscriptionItem v-for="subscription in entry.subscriptions"
                                       :key="subscription.subscription.id"
                                       :subscription="subscription.subscription"
                                       :roles="subscription.roles"
      />
    </div>
  </li>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { BusinessPartnerListEntry } from "@/pages/business-partners/BusinessPartnersPage.vue";
import BusinessPartnerAddress from "@/components/business-partner/BusinessPartnerAddress.vue";
import BusinessPartnerSubscriptionItem from "@/pages/business-partners/BusinessPartnerSubscriptionItem.vue";
import { useTenant } from "@/plugins/tenant";

const props = defineProps({
  entry: {
    type: Object as PropType<BusinessPartnerListEntry>,
    required: true
  }
})

const tenant = useTenant()

const linkToBusinessPartner = computed(()=> {
  return {
    name: 'subscription-business-partner',
    params: {
      id: props.entry.subscriptions[0].subscription.id,
      bpNumber: props.entry.businessPartner.bpNumber
    }
  }
})
const supportsAddressChange = computed( () => {
  return tenant.supportsAddressChange() && props.entry.subscriptions.every((s) => s.subscription.supportsAddressChange())
})
</script>
