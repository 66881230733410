<template>
  <div>
    <div class="w-full border rounded-card mt-4">
      <table class="text-sm sm:text-base w-full overflow-x-auto">
        <thead>
          <tr class="border-none bg-gray-50">
            <th class="p-3">{{ $t("business-partner.name.label") }}</th>
            <th class="p-3">
              {{ $t("business-partner.email.label") }}
            </th>
            <th class="p-3"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="border-t border-tables"
            v-for="bp in businessPartners"
            :key="bp.bpNumber"
          >
            <td class="p-3">
              {{ extractName(bp) }}
            </td>
            <td class="p-3">
              {{ extractEmailAddress(bp) }}
            </td>
            <td class="text-right whitespace-nowrap">
              <button
                  v-if="showDelete"
                  class="py-3 px-4 text-gray-900"
                  @click="$emit('delete', bp)"
                  type="button"
              >
                <font-awesome-icon icon="trash"></font-awesome-icon>
              </button>
            </td>
          </tr>
          <tr class="border-t border-tables" v-if="businessPartners.length === 0">
            <td colspan="4" class="p-3 text-center">
              {{ $t('subscription-licenses.no-license-managers-found') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { BusinessPartner, extractEmail } from "@/models/businessPartner";
import { PropType } from "vue";

const props = defineProps({
  businessPartners: {
    type: Array as PropType<BusinessPartner[]>,
    required: true
  },
  showDelete: Boolean,
})

const emit = defineEmits(['showDetails', 'delete'])

function extractName(businessPartner: BusinessPartner): string {
  if (businessPartner.company1) {
    return `${businessPartner.company1} ${businessPartner.company2 ?? ''}`;
  }
  return `${businessPartner.firstName} ${businessPartner.lastName1}`;
}

function extractEmailAddress(businessPartner: BusinessPartner) {
  return extractEmail(businessPartner);
}
</script>
