<template>
  <AlertDialog
    :loading="loading"
    :positive-text="replace ? $t('subscription-licenses.replace-manager') : $t('subscription-licenses.add-manager')"
    :negative-text="$t('app.cancel')"
    @negative="$emit('update:modelValue', false)"
    disable-auto-hide
    name="add-license-manager"
    form="add-license-manager-form"
    scrollable
  >
    <form @submit.prevent="submit" id="add-license-manager-form">
      <p class="font-semibold">
        {{ replace ? $t('subscription-licenses.replace-manager') : $t('subscription-licenses.add-manager') }}</p>
      <FormSelect
        class="w-1/4"
        :label="$t('business-partner.salutation.label')"
        :options="salutations"
        :disabled="loading"
        value-key="code"
        name-key="title"
        required
        v-model="businessPartner.salutationCode"
      />
      <FormInput
        v-model="businessPartner.emailAddress"
        :disabled="loading"
        required
        :label="$t('business-partner.email.label')"
        type="email"
      ></FormInput>
      <FormInput
        v-model="businessPartner.firstName"
        :disabled="loading"
        required
        max-length="30"
        :label="$t('business-partner.first-name.label')"
      ></FormInput>
      <FormInput
        v-model="businessPartner.lastName1"
        :disabled="loading"
        required
        max-length="30"
        :label="$t('business-partner.last-name.label')"
      ></FormInput>
    </form>
  </AlertDialog>
</template>

<script lang="ts" setup>
import { computed, PropType, ref, watchEffect } from 'vue'
import AlertDialog from "@/components/AlertDialog.vue";
import FormInput from "@/components/form/FormInput.vue";
import FormSelect from "@/components/form/FormSelect.vue";
import { BusinessPartner, cloneBusinessPartnerWithDefaults } from "@/models/businessPartner";
import { useVfm } from "@/plugins/vfm";
import { Salutation } from "@/models/salutation";

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: Boolean,
    default: false
  },
  replace: {
    type: Boolean,
    default: false
  },
  salutations: {
    type: Array as PropType<Salutation[]>,
    required: true
  }
})

const emit = defineEmits(['submit', 'update:modelValue'])

const vfm = useVfm()

const businessPartner = ref<BusinessPartner>(cloneBusinessPartnerWithDefaults({}))

function submit() {
  emit('submit', businessPartner.value)
}

watchEffect(() => {
  if (props.modelValue) {
    businessPartner.value = cloneBusinessPartnerWithDefaults({})
    vfm.show('add-license-manager')
  } else {
    vfm.hide('add-license-manager')
  }
})
</script>
