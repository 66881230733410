<template>
  <div>
    <PageTitle name="cancellation"></PageTitle>
    <p class="mt-2">
      <span v-if="auth.isFullyLoggedIn">
        {{ $t("cancellation.authenticated-heading") }}
      </span>
      <span v-else>
        {{ $t("cancellation.unauthenticated-heading") }}
      </span>
    </p>
    <hr class="my-4" />
    <FeatureDisabledAlert v-if="!$tenant.supportsCancellation()"></FeatureDisabledAlert>
    <AuthenticatedCancellationForm v-else-if="auth.isFullyLoggedIn"></AuthenticatedCancellationForm>
    <UnauthenticatedCancellationForm v-else></UnauthenticatedCancellationForm>
  </div>
</template>

<script lang="ts" setup>
import { useAuth } from "@/pinia/auth";
import FeatureDisabledAlert from "@/components/FeatureDisabledAlert.vue";
import AuthenticatedCancellationForm from "@/pages/cancellation/AuthenticatedCancellationForm.vue";
import UnauthenticatedCancellationForm from "@/pages/cancellation/UnauthenticatedCancellationForm.vue";
import PageTitle from "@/components/PageTitle.vue";

const auth = useAuth()
</script>
