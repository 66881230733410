<template>
  <div class="pb-4 md:pb-8">
    <BackButton :subscription="subscription" />
    <div class="py-4 border-t mt-4">
      <PageTitle name="complaint"></PageTitle>
      <p class="mt-4">
        {{ $t("subscription-complaint.heading") }}
      </p>
      <RequiredFieldsHint class="mt-2"></RequiredFieldsHint>
    </div>
    <Spinner class="small-indicator" v-if="loading" />
    <div
      v-else-if="hasNoComplaintIssues"
      class="bg-gray-50 text-red-600 md:text-lg text-center p-4 border"
    >
      {{ $t("subscription-complaint.no-issues") }}
      <br class="hidden md:block" />
      {{ $t("subscription-complaint.contact-customer-support") }} <br />
      <router-link
        :to="{
          name: 'contact',
          query: { subscriptionId: $route.params.id, showMessageInput: 'true' },
        }"
        class="inline-block btn btn-primary mt-4"
      >{{ $t("subscription-complaint.to-contact") }}
      </router-link>
    </div>
    <ul v-else>
      <IssueComplaintCard
        v-for="issue in complaintIssues"
        :key="issue.issueNumber"
        :subscription="subscription"
        :issue="issue"
        @success="handleSuccess"
        @error="showErrorAlertDialog"
        v-model="selectedIssue"
      />
    </ul>
    <AlertDialog
      name="success"
      :negative-text="$t('app.back')"
      @negative="$router.back()"
    >
      {{ successMessage }}
      <a v-if="downloadUrl" :href="downloadUrl" target="_blank" class="link">{{
          $t("subscription-complaint.to-download")
        }}</a>
    </AlertDialog>
    <AlertDialog name="error" ref="error" :negative-text="$t('app.close')">
      {{ $t("app.error") }}
    </AlertDialog>
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive, ref, toRefs } from 'vue'
import BackButton from "@/components/BackButton.vue";
import PageTitle from "@/components/PageTitle.vue";
import Spinner from "@/components/Spinner.vue";
import { Issue } from "@/models/issue";
import { CompensationOption } from "@/models/compensationOption";
import IssueComplaintCard from "@/pages/subscription/complaint/IssueComplaintCard.vue";
import type { IssueComplaintEvent }  from "@/pages/subscription/complaint/IssueComplaintCard.vue";
import AlertDialog from "@/components/AlertDialog.vue";
import { useRoute } from "vue-router";
import { useOrders } from "@/pinia/orders";
import { useVfm } from "@/plugins/vfm";
import { useTracker } from "@/plugins/tracker";
import { useI18n } from "vue-i18n";
import RequiredFieldsHint from "@/components/RequiredFieldsHint.vue";

interface SubscriptionComplaintState {
  loading: boolean
  deliveredIssuesLoading: boolean
  deliveredIssuesError: boolean
  selectedIssue: Issue | null
  successMessage: string | null
  downloadUrl: string | null
}

const route = useRoute()
const orders = useOrders()
const vfm = useVfm()
const tracker = useTracker()
const i18n = useI18n()


const state = reactive<SubscriptionComplaintState>({
  loading: false,
  deliveredIssuesLoading: false,
  deliveredIssuesError: false,
  selectedIssue: null,
  successMessage: null,
  downloadUrl: null,
})

const {
  deliveredIssuesLoading,
  deliveredIssuesError,
  selectedIssue,
  successMessage,
  downloadUrl,
  loading
} = toRefs(state)

const subscriptionId = route.params.id as string
const subscription = computed(() => orders.getSubscriptionById(subscriptionId))


const numComplaintIssues = computed<number>(() => subscription.value?.mixTypeDetails?.complaintIssues ?? 0)
const complaintIssues = computed(() => subscription.value!.deliveredIssues!.slice(0, numComplaintIssues.value))
const hasNoComplaintIssues = computed(() => numComplaintIssues.value === 0 || complaintIssues.value!.length === 0)

async function fetchData() {
  try {
    loading.value = true;
    await orders.fetchSubscription(subscriptionId)
    if (numComplaintIssues.value !== 0) {
      await orders.fetchDeliveredIssues(subscriptionId);
    }
  } catch (error) {
    loading.value = true;
  } finally {
    loading.value = false;
  }
}

function handleSuccess(event: IssueComplaintEvent) {
  if (event.compensationOption === CompensationOption.Download) {
    // track the download
    tracker.trackEvent("Frontend", "PdfDownload", event.downloadUrl!);
    // trigger the download
    downloadUrl.value = event.downloadUrl;
    window.location.href = event.downloadUrl!;
  }
  // @ts-ignore
  successMessage.value = i18n.tm("subscription-complaint.success")[event.compensationOption];
  downloadUrl.value = event.downloadUrl;
  vfm.show("success");
}

function showSuccessAlertDialog() {
  vfm.show("success");
}

function showErrorAlertDialog() {
  vfm.show("error");
}

fetchData()
</script>
