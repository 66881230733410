<template>
  <div>
    <p>{{ $t("orders.heading") }}</p>
    <p class="mt-4 font-semibold">{{ $t("orders.delay-notice") }}</p>
    <div class="relative flex items-center mt-4">
      <input
        v-model="query"
        class="block w-full py-2 pl-9 pr-3 border rounded-input focus:shadow-outline focus:outline-none"
        type="search"
        :placeholder="$t('orders.search.placeholder')"
      />
      <div
        class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
      >
        <font-awesome-icon
          icon="search"
          :class="query.length !== 0 ? 'text-primary' : 'text-gray-400'"
        />
      </div>
      <router-link
        v-if="$tenant.supportsManualRegistration()"
        to="/link-subscription"
        class="btn btn-primary flex-shrink-0 ml-2"
      >{{ $t("navbar.link-subscription") }}
      </router-link>
    </div>
    <EmptyState v-if="error" :text="$t('orders.loading-error')">
      <button
        type="button"
        class="btn btn-primary btn-small"
        @click="fetchData"
      >
        {{ $t("app.try-again") }}
      </button>
    </EmptyState>
    <Spinner v-else-if="loading" />
    <template v-else>
      <div v-if="$tenant.supportsPendingOrders() && hasPendingOrders">
        <hr class="mt-4" />
        <h2 class="mt-4 text-xl font-medium font-display">
          {{ $t("orders.pending-orders") }}
          ({{ filteredPendingOrders.length }})
        </h2>
        <p>
          {{ $t("orders.pending-orders-hint") }}
        </p>
        <div>
          <ul class="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2">
            <PendingOrderListItem
              v-for="order in filteredPendingOrders"
              :key="order.orderNumber + order.articleNumber"
              :order="order"
            />
          </ul>
        </div>
      </div>
      <div v-if="$tenant.supportsMembership() && hasMemberships">
        <hr class="mt-4" />
        <h2 class="mt-4 text-xl font-medium font-display">
          {{ $t("orders.your-memberships") }}
          ({{ filteredMemberships.length }})
        </h2>
        <div>
          <ul class="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2">
            <SubscriptionListItem
              v-for="subscription in filteredMemberships"
              :key="subscription.id"
              :subscription="subscription"
            />
          </ul>
        </div>
      </div>
      <div v-if="hasSubscriptions">
        <hr class="mt-6" />
        <div class="md:flex items-center">
          <h2
            class="mt-4 text-xl font-medium font-display"
            data-testid="orders-your-subscriptions"
          >
            {{ $t("orders.your-subscriptions") }}
            ({{ filteredSubscriptions.length }})
          </h2>
          <div class="flex-1"></div>
          <FormSelect
            v-if="showFilter"
            class="mt-2 md:mt-4"
            v-model="filter"
            :options="$tm('orders.filter.options')"
          ></FormSelect>
        </div>
        <div>
          <ul
            v-if="filteredSubscriptions.length"
            class="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2"
          >
            <SubscriptionListItem
              v-for="subscription in filteredSubscriptions"
              :key="subscription.id"
              :subscription="subscription"
              data-testid="order-list-subscription"
            />
          </ul>
          <EmptyState v-else :text="$t('orders.no-subscriptions-added')" />
        </div>
      </div>
      <div v-if="hasPurchaseOrders">
        <hr class="mt-6" />
        <h2
          class="mt-4 text-xl font-medium font-display"
          data-testid="orders-your-purchase-orders"
        >
          {{ $t("orders.your-purchase-orders") }}
          ({{ filteredPurchaseOrders.length }})
        </h2>
        <div>
          <ul
            v-if="filteredPurchaseOrders.length"
            class="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2"
          >
            <PurchaseOrderListItem
              v-for="purchaseOrder in filteredPurchaseOrders"
              :key="purchaseOrder.id + purchaseOrder.distributionArticleNumber"
              :purchase-order="purchaseOrder"
              @show-address="showAddress"
            />
          </ul>
          <EmptyState v-else :text="$t('orders.no-purchases-found')" />
        </div>
      </div>
      <div v-if="showEmptyState">
        <hr class="mt-6" />
        <EmptyState :text="$t('orders.empty')"></EmptyState>
      </div>
    </template>
    <AlertDialog name="downloadError" :positive-text="$t('app.close')">
      {{ $t("issue.download-not-available") }}
    </AlertDialog>
    <AlertDialog name="downloadStarted" :positive-text="$t('app.close')">
      {{ $t("issue.download-started") }}
    </AlertDialog>
    <AlertDialog
      name="linkSubscription"
      :positive-text="$t('orders.link-subscription')"
      :negative-text="$t('app.close')"
      @positive="$router.push('/link-subscription')"
    >{{ $t("orders.no-orders-found") }}
    </AlertDialog>
    <AlertDialog
      name="businessPartner"
      click-to-close
      :negative-text="$t('app.close')"
    >
      <template #title>
        {{ $t("purchase-order.current-address") }}
      </template>
      <BusinessPartnerAddress v-if="businessPartnerToShow" :business-partner="businessPartnerToShow" />
    </AlertDialog>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import EmptyState from "@/components/EmptyState.vue";
import Spinner from "@/components/Spinner.vue";
import SubscriptionListItem from "@/pages/orders/SubscriptionListItem.vue";
import PurchaseOrderListItem from "@/pages/orders/PurchaseOrderListItem.vue";
import PendingOrderListItem from "@/pages/orders/PendingOrderListItem.vue";
import FormSelect from "@/components/form/FormSelect.vue";
import { orderBy } from "lodash-es";
import AlertDialog from "@/components/AlertDialog.vue";
import { Subscription } from "@/models/subscription";
import { PurchaseOrder } from "@/models/purchaseOrder";
import { useOrders } from "@/pinia/orders";
import { useTenant } from "@/plugins/tenant";
import { useVfm } from "@/plugins/vfm";
import BusinessPartnerAddress from "@/components/business-partner/BusinessPartnerAddress.vue";
import { BusinessPartner } from "@/models/businessPartner";

const orders = useOrders()
const tenant = useTenant()
const vfm = useVfm()

const query = ref('')
const filter = ref(tenant.defaultSubscriptionFilter)
const businessPartnerToShow = ref<BusinessPartner | null>(null)

function showAddress(businessPartner: BusinessPartner) {
  businessPartnerToShow.value = businessPartner
  vfm.show("businessPartner");
}

async function fetchData() {
  const didFetch = await orders.fetch();
  if (
    didFetch &&
    !orders.hasOrders &&
    tenant.supportsManualRegistration()
  ) {
    // show a dialog prompting the user to register a new subscription
    vfm.show("linkSubscription");
  }
  if (!didFetch) {
    orders.clearNewSubscriptionAvailable()
  }
}

const lowercaseQuery = computed(() => query.value.toLowerCase())
const hasPurchaseOrders = computed(() => Boolean(orders.purchaseOrders.length))
const hasSubscriptions = computed(() => Boolean(orders.subscriptions.length))
const hasMemberships = computed(() => Boolean(orders.membershipSubscriptions.length))
const hasPendingOrders = computed(() => Boolean(orders.pendingOrders.length))
const loading = computed(() => orders.loading)
const error = computed(() => orders.error)
const sortedSubscriptions = computed<Subscription[]>(() => orderBy(orders.listedSubscriptions, ["custOrderDate"], ["desc"]))
const sortedPurchaseOrders = computed<PurchaseOrder[]>(() => orderBy(orders.purchaseOrders, ["dateOfPurchaseOrderClient"], ["desc"]))
const filteredMemberships = computed(() => orders.membershipSubscriptions.filter((sub) =>
  sub.displayName.toLowerCase().includes(lowercaseQuery.value)
))
const filteredSubscriptions = computed(() => sortedSubscriptions.value.filter((sub) => {
  const isActive = sub.isActive();
  return (
    ((filter.value === "active" && isActive) ||
      (filter.value === "inactive" && !isActive) ||
      filter.value === "all") &&
    sub.displayName.toLowerCase().includes(lowercaseQuery.value)
  );
}))
const filteredPendingOrders = computed(() => orders.pendingOrders.filter((order) =>
  order.title.toLowerCase().includes(lowercaseQuery.value)
))
const filteredPurchaseOrders = computed(() => sortedPurchaseOrders.value.filter((order) =>
  (order.distributionArticleDescription || "")
    .toLowerCase()
    .includes(lowercaseQuery.value)
))
const showEmptyState = computed(() => !orders.subscriptions.length && !orders.purchaseOrders.length && (!tenant.supportsPendingOrders() || !orders.pendingOrders.length))
const showFilter = computed(() => sortedSubscriptions.value.some((subscription) => subscription.isInactive()))

fetchData()
</script>
