<template>
  <CustomizableButton
    class="btn btn-primary"
    :loading="loading"
    @click.native="upgradePhase"
    name="upgrade-phase"
  >
  </CustomizableButton>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import CustomizableButton from "@/components/CustomizableButton.vue";
import { useOrders } from "@/pinia/orders";
import { Subscription } from "@/models/subscription";

const props = defineProps({
  subscription: {
    type: Subscription,
    required: true
  }
})

const orders = useOrders()
const loading = ref(false)

async function upgradePhase() {
  try {
    loading.value = true
    await orders.upgradePhase(props.subscription.id)
  } catch (e) {
    console.log(e)
  } finally {
    loading.value = false
  }
}
</script>
