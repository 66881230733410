<template>
  <div>
    <template v-if="showName">
      <FormSectionHeader
        :title="$t('bp-form.name-and-address')"
      ></FormSectionHeader>
      <div class="flex -mx-2">
        <FormSelect
          class="w-1/4 px-2"
          v-if="allowFirstNameChange"
          :label="$t('bp-form.salutation.label')"
          :options="salutations"
          value-key="code"
          name-key="title"
          required
          v-model="businessPartner.salutationCode"
        />
        <FormInput
          v-else
          class="w-1/4 px-2"
          :label="$t('bp-form.salutation.label')"
          :model-value="businessPartner.salutation"
          disabled
        />
        <FormInput
          v-if="businessPartner.title"
          class="w-1/4 px-2"
          :label="$t('bp-form.title.label')"
          :disabled="!allowFirstNameChange"
          required
          v-model="businessPartner.title"
        />
      </div>
      <div class="flex flex-wrap -mx-2">
        <FormInput
          class="w-full md:w-1/2 px-2"
          :label="$t('bp-form.first-name.label')"
          max-length="35"
          v-model="businessPartner.firstName"
          :disabled="!allowFirstNameChange"
          required
        />
        <FormInput
          class="w-full md:w-1/2 px-2"
          :label="$t('bp-form.last-name.label')"
          max-length="35"
          v-model="businessPartner.lastName1"
          required
          :disabled="!allowLastNameChange"
        />
      </div>
      <div v-if="!allowFirstNameChange || !allowLastNameChange">
        <small>{{ $t("bp-form.change-name-message") }} </small>
      </div>
    </template>
    <div v-if="isCompany" class="flex flex-wrap -mx-2">
      <FormInput
        class="w-full px-2"
        :label="$t('bp-form.job-title.label')"
        :placeholder="$t('bp-form.job-title.placeholder')"
        max-length="35"
        v-model="businessPartner.jobTitle"
      />
      <FormInput
        class="w-full md:w-1/2 px-2"
        :label="$t('bp-form.company.label')"
        :placeholder="$t('bp-form.company.placeholder')"
        max-length="35"
        :disabled="!allowFirstNameChange"
        required
        v-model="businessPartner.company1"
      />
      <FormInput
        class="w-full md:w-1/2 px-2"
        :label="$t('bp-form.department.label')"
        :placeholder="$t('bp-form.department.placeholder')"
        max-length="35"
        v-model.trim="businessPartner.department"
      />
    </div>
    <template v-if="useAddressLines">
      <FormInput
        :label="$t('bp-form.address-line-1.label')"
        max-length="35"
        required
        v-model.trim="businessPartner.addressLine1"
      />
      <FormInput
        :label="$t('bp-form.address-line-2.label')"
        max-length="35"
        v-model.trim="businessPartner.addressLine2"
      />
      <FormInput
        :label="$t('bp-form.address-line-3.label')"
        max-length="35"
        v-model.trim="businessPartner.addressLine3"
      />
    </template>
    <div v-else class="flex flex-wrap -mx-2">
      <FormInput
        class="w-full md:w-1/2 px-2"
        :label="$t('bp-form.street.label')"
        max-length="35"
        required
        v-model.trim="businessPartner.street"
      />
      <FormInput
        class="w-1/2 md:w-1/4 px-2"
        :label="$t('bp-form.street-number.label')"
        max-length="10"
        required
        v-model.trim="businessPartner.streetNumber"
      />
      <FormInput
        class="w-1/2 md:w-1/4 px-2"
        :label="$t('bp-form.street-number-suffix.label')"
        max-length="10"
        v-model.trim="businessPartner.streetNumberSuffix"
      />
    </div>
    <div class="flex -mx-2">
      <FormInput
        v-if="!isUKAddress"
        class="w-1/3 mx-2"
        :label="$t('bp-form.zipcode.label')"
        min-length="4"
        required
        v-model.trim="businessPartner.zipcode"
      />
      <FormInput
        class="w-2/3 mx-2"
        :label="$t('bp-form.city.label')"
        max-length="35"
        required
        v-model.trim="businessPartner.city"
      />
      <FormInput
        v-if="isUKAddress"
        class="w-1/3 mx-2"
        :label="$t('bp-form.zipcode.label')"
        pattern="([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})"
        required
        v-model.trim="businessPartner.zipcode"
      />
    </div>
    <FormSelect
      :label="$t('bp-form.countries.label')"
      :placeholder="$t('bp-form.countries.placeholder')"
      :options="countries"
      :disabled="!allowCountryChange || supportedCountriesLoading"
      :loading="supportedCountriesLoading"
      required
      v-model="businessPartner.countryCode"
    />
    <div v-if="!allowCountryChange">
      <small>{{ $t("bp-form.change-country-message") }} </small>
    </div>
    <FormSectionHeader
      v-if="_showEmail || _showPhoneNumber"
      :title="$t('bp-form.contact')"
      class="mt-6"
    ></FormSectionHeader>
    <FormInput
      v-if="_showEmail"
      :disabled="!allowEmailChange"
      :required="emailRequired"
      class="w-full"
      :label="$t('bp-form.email.label')"
      max-length="35"
      v-model.trim="businessPartner.emailAddress"
    />
    <div v-if="_showEmail && !allowEmailChange">
      <small>{{ $t("bp-form.change-email-message") }} </small>
    </div>
    <FormInput
      v-if="_showPhoneNumber"
      :disabled="!allowPhoneNumberChange"
      class="w-full"
      :label="$t('bp-form.phone.label')"
      max-length="35"
      v-model.trim="businessPartner.phoneNumber"
    />
    <div v-if="_showPhoneNumber && !allowPhoneNumberChange">
      <small>{{ $t("bp-form.change-phone-number-message") }} </small>
    </div>
    <FormSectionHeader
      v-if="_showDateOfBirth"
      :title="$t('bp-form.other')"
      class="mt-6"
    ></FormSectionHeader>
    <FormInput
      v-if="_showDateOfBirth"
      type="date"
      class="w-full"
      :disabled="!allowDateOfBirthChange"
      :label="$t('bp-form.date-of-birth.label')"
      :min="minDateOfBirth"
      max-length="10"
      null-if-empty
      v-model="businessPartner.dateOfBirth as string"
    />
    <div v-if="_showDateOfBirth && !allowDateOfBirthChange">
      <small>{{ $t("bp-form.change-date-of-birth-message") }} </small>
    </div>
    <FormSectionHeader
      v-if="showValidFrom"
      :title="$t('bp-form.valid-from.label')"
      class="mt-6"
    ></FormSectionHeader>
    <fieldset class="block mt-5" v-if="showValidFrom">
      <FormRadio
        value="asap"
        :label="$t('bp-form.valid-from.options.asap')"
        v-model="execution"
        name="execution"
      ></FormRadio>
      <FormRadio
        class="mt-2"
        value="timed"
        :label="$t('bp-form.valid-from.options.timed')"
        v-model="execution"
        name="execution"
      ></FormRadio>
    </fieldset>
    <Alert variant="warning" v-if="_showAffectedIssueAlert" class="mt-5">
      {{
        $t("subscription-business-partners.next-issue-already-shipped", {
          dateOfFirstSale: $d(subscription.nextIssue!.dateOfFirstSale),
        })
      }}
    </Alert>
    <FormInput
      v-if="execution === 'timed'"
      type="date"
      :min="minDate"
      class="w-full"
      max-length="10"
      :model-value="String(businessPartner.validFrom)"
      @update:modelValue="businessPartner.validFrom = $event"
    />
  </div>
</template>
<script lang="ts" setup>
import { BusinessPartner } from "@/models/businessPartner";
import { LocaleMessageObject, useI18n } from "vue-i18n";
import { Subscription } from "@/models/subscription";
import { addDays, formatISO, isBefore, subYears } from "date-fns";
import { computed, PropType, ref } from "vue";
import FormRadio from "@/components/form/FormRadio.vue";
import Alert from "@/components/Alert.vue";
import FormInput from "@/components/form/FormInput.vue";
import FormSelect from "@/components/form/FormSelect.vue";
import FormSectionHeader from "@/components/form/FormSectionHeader.vue";
import { useAxios } from "@/plugins/axios";
import { useTenant } from "@/plugins/tenant";

const props = defineProps({
  subscription: {
    type: Subscription,
    required: true
  },
  businessPartner: {
    type: Object as PropType<BusinessPartner>,
    required: true
  },
  showName: Boolean,
  allowCountryChange: Boolean,
  allowFirstNameChange: Boolean,
  allowLastNameChange: Boolean,
  showEmail: Boolean,
  showPhoneNumber: Boolean,
  showDateOfBirth: Boolean,
  showValidFrom: Boolean,
  showAffectedIssueAlert: Boolean,
  allowEmailChange: Boolean,
  allowPhoneNumberChange: Boolean,
  allowDateOfBirthChange: Boolean,
  emailRequired: Boolean,
})

const axios = useAxios()
const tenant = useTenant()
const {tm} = useI18n()

const supportedCountries = ref<string[]|null>(null)
const supportedCountriesLoading = ref(false)
const execution = ref("asap")

async function fetchSupportedCountries() {
  // fetch the supported countries for this role, assuming WE if no business partner was provided
  const role = props.businessPartner.role
  try {
    supportedCountriesLoading.value = true;
    const response = await axios.get<string[]>(
      `/subscriptions/${props.subscription.id}/supported-countries`,
      {
        params: {
          role,
        },
      }
    );
    supportedCountries.value = response.data;
  } catch (e) {
    // TODO!
  } finally {
    supportedCountriesLoading.value = false;
  }
}

const _showEmail = computed(() => {
  // don't show the email field if the email address is empty and a change is disabled
  return (
    props.showEmail &&
    (props.allowEmailChange || props.businessPartner.emailAddress != null)
  );
})

const minDate = computed(() => {
  return formatISO(addDays(new Date(), 1), {
    representation: "date",
  });
})

const minDateOfBirth = computed(() => {
  return formatISO(subYears(new Date(), 100), {
    representation: "date",
  });
})

const _showPhoneNumber = computed(() => {
  return (
    props.showPhoneNumber &&
    (props.allowPhoneNumberChange || props.businessPartner.phoneNumber != null)
  );
})

const _showDateOfBirth = computed(() => {
  return (
    props.showDateOfBirth &&
    (props.allowDateOfBirthChange || props.businessPartner.dateOfBirth != null)
  );
})

const salutations = computed(() => {
  if (props.allowFirstNameChange) {
    return tenant.salutations.filter(
      (salutation) => salutation.isUserSelectable
    );
  }
  return tenant.salutations;
})

const allCountries = computed(() => {
  const countries = tm("countries") as LocaleMessageObject;
  return Object.entries(countries).map(([key, translation]) => ({
    value: key,
    name: translation.toString(),
  }));
})

const countries = computed(() => {
  if (Array.isArray(supportedCountries.value)) {
    return allCountries.value.filter((country) =>
      supportedCountries.value!.includes(country.value)
    )
  }
  return allCountries.value;
})

const isCompany = computed(() => {
  const salutation = tenant.salutations.find(
    (salutation) => salutation.code === props.businessPartner.salutationCode
  );
  return salutation?.isCompany ?? false;
})

const isUKAddress = computed(() =>{
  return props.businessPartner.countryCode === "GB";
})

const _showAffectedIssueAlert = computed(() => {
  return (
    props.showAffectedIssueAlert &&
    execution.value === "asap" &&
    props.subscription.isPhysicalSubscription() &&
    props.subscription.nextIssue !== null &&
    isBefore(props.subscription.nextIssue.dateOfFirstShipping, new Date())
  );
})

const useAddressLines = computed(() =>  {
  return tenant.supportsAddressLines()
})


if (props.allowCountryChange) {
  // for now, we only fetch the supported countries for new business partners
  fetchSupportedCountries();
}
if (props.businessPartner.dateOfBirth && props.businessPartner.dateOfBirth instanceof Date) {
  props.businessPartner.dateOfBirth = props.businessPartner.dateOfBirth.toISOString().substring(0, 10)
}
</script>
