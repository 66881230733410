<template>
  <li
    class="flex transition-all duration-200 transform border rounded-card shadow hover:-translate-y-1 hover:shadow-lg"
  >
    <div class="hidden w-1/2 p-2 border-r md:block">
      <CoverImage :src="purchaseOrder.coverImageUrl" />
    </div>
    <div class="flex flex-col w-full">
      <div class="flex p-3 md:pb-2 t">
        <CoverImage
          class="h-24 mr-2 md:hidden"
          :src="purchaseOrder.coverImageUrl"
        />
        <h3 class="font-bold">
          {{ purchaseOrder.distributionArticleDescription }}
        </h3>
      </div>
      <dl class="grid grid-cols-2 gap-1 mx-3 text-sm md:px-0 leading-tight">
        <dt>{{ $t("purchase-order.order-number") }}:</dt>
        <dd class="text-right">
          {{ purchaseOrder.purchaseOrderNumber }}
        </dd>
        <dt>{{ $t("purchase-order.date-of-purchase") }}:</dt>
        <dd class="text-right">
          {{ $d(purchaseOrder.dateOfPurchaseOrderClient) }}
        </dd>
        <template v-if="showQuantity">
          <dt>{{ $t("purchase-order.quantity.label") }}:</dt>
          <dd class="text-right">
            {{ purchaseOrder.quantity }}
          </dd>
        </template>
        <template v-if="purchaseOrder.invoiceStatus">
          <dt>{{ $t("purchase-order.invoice-status") }}:</dt>
          <dd class="text-right">
            <PurchaseOrderInvoiceStatusLabel
              :invoice-status="purchaseOrder.invoiceStatus"
            />
          </dd>
        </template>
        <template v-if="showShippingStatus">
          <dt>{{ $t("purchase-order.shipping-status.label") }}:</dt>
          <dd class="text-right">
            <PurchaseOrderShippingStatusLabel
              @click="showShippingDetails"
              :package-info="purchaseOrder.packageInfo!"
            />
          </dd>
        </template>
      </dl>
      <div class="flex-1"></div>
      <div class="flex m-3 justify-end">
        <DownloadButton
          v-if="showDownloadButton"
          :purchase-order="purchaseOrder"
          class="btn btn-primary mr-2"
        ><span class="ml-2">{{ $t("purchase-order.download") }}</span>
        </DownloadButton>
        <PopupMenu class="popup-menu" v-if="showAddressButton || showRequestInvoiceButton">
          <RequestInvoiceButton
            v-if="showRequestInvoiceButton"
            class="btn btn-primary w-full"
            :purchase-order="purchaseOrder"
            :invoice-number="purchaseOrder.invoiceNumber"
          >
            <span class="ml-2">{{ $t("purchase-order.request-invoice") }}</span>
          </RequestInvoiceButton>
          <button
            v-if="showAddressButton"
            class="btn btn-primary w-full"
            @click="$emit('showAddress', purchaseOrder.businessPartner)"
            :title="$t('payments.request-invoice')"
          >
            <font-awesome-icon icon="address-card" class="mr-1" />
            {{ $t("purchase-order.show-address") }}
          </button>
        </PopupMenu>
      </div>
    </div>
  </li>
</template>
<script lang="ts" setup>
import { PurchaseOrder } from "@/models/purchaseOrder";
import { DownloadStatus } from "@/models/downloadStatus";
import { computed, PropType } from "vue";
import CoverImage from "@/components/CoverImage.vue";
import DownloadButton from "@/components/DownloadButton.vue";
import PurchaseOrderInvoiceStatusLabel from "@/pages/orders/PurchaseOrderInvoiceStatusLabel.vue";
import RequestInvoiceButton from "@/components/RequestInvoiceButton.vue";
import PopupMenu from "@/components/PopupMenu.vue";
import PurchaseOrderShippingStatusLabel from "@/pages/orders/PurchaseOrderShippingStatusLabel.vue";
import PurchaseOrderShippingDetailsModal from "@/pages/orders/PurchaseOrderShippingDetailsModal.vue";
import { useTenant } from "@/plugins/tenant";
import { useVfm } from "@/plugins/vfm"
import { BusinessPartnerRole } from "@/models/businessPartner";

const props = defineProps({
  purchaseOrder: {
    type: Object as PropType<PurchaseOrder>,
    required: true
  }
})
const emit = defineEmits(['showAddress'])

const tenant = useTenant()
const vfm = useVfm()

const showDownloadButton = computed(() => {
  return (
    tenant.supportsPurchaseOrderDownload() &&
    (props.purchaseOrder.downloadStatus === DownloadStatus.Found ||
      props.purchaseOrder.downloadStatus === DownloadStatus.Expired)
  );
})

const showRequestInvoiceButton = computed(() => {
  return tenant.supportsInvoiceRequest() && props.purchaseOrder.role === BusinessPartnerRole.AUFTRAGGEBER
})

const showAddressButton = computed(() => {
  return props.purchaseOrder.businessPartner != null
})

const showShippingStatus = computed(() => {
  return tenant.supportsShippingStatus() && props.purchaseOrder.packageInfo?.scanStatus != null
})

const showQuantity = computed(() => {
  return props.purchaseOrder?.quantity !== null && props.purchaseOrder.quantity > 1
})

function showShippingDetails() {
  vfm.show({
    component: PurchaseOrderShippingDetailsModal,
    bind: {
      packageInfo: props.purchaseOrder.packageInfo
    }
  });
}

</script>
