<template>
  <div class="pb-4 md:pb-8">
    <BackButton :subscription="subscription" />
    <div class="pt-4 border-t mt-4">
      <PageTitle name="e-card"></PageTitle>
      <p class="mt-4">
        {{ $t("e-card.heading") }}
      </p>
    </div>
    <ECardForm @close="$router.back()"/>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import BackButton from "@/components/BackButton.vue";
import PageTitle from "@/components/PageTitle.vue";
import { useRoute } from "vue-router";
import { useOrders } from "@/pinia/orders";
import ECardForm from "@/components/e-card/ECardForm.vue";

const route = useRoute()
const orders = useOrders()

const subscriptionId = route.params.id as string
const subscription = computed(() => orders.getSubscriptionById(subscriptionId))

</script>
