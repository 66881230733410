import { createPinia } from "pinia";
import { Tenant } from "@/models/tenant";
import { markRaw } from "vue";

export function createStore(tenant: Tenant) {
  const pinia = createPinia()
  pinia.use(({store}) => {
    store.tenant = markRaw(tenant)
    store.$state.tenant = markRaw(tenant)
  })
  return pinia
}

declare module 'pinia' {
  export interface PiniaCustomProperties {
    // you can define simpler values too
    tenant: Tenant
  }
}


