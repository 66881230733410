<template>
  <div role="alert">
    <div v-if="show" class="relative flex" :class="variantClasses">
      <div class="flex-1 py-1 px-2  prose" v-html="announcementConfig.text"></div>
      <button class="px-2 py-1" @click="show = false" type="button">
        <font-awesome-icon icon="times" />
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from "vue";
import { useTenant } from "@/plugins/tenant";

const variantClassesMap = new Map<string, string>([
  ["warning", "bg-warning-100 border-warning-200 text-warning-900"],
  ["success", "bg-success-100 border-success-200 text-success-900"],
  ["error", "bg-danger-100 border-danger-200 text-danger-900"],
  ["info", "bg-blue-200 border-blue-200 text-blue-600"],
  ["primary", "bg-primary border-primary text-white"],
]);

const tenant = useTenant();

const announcementConfig = computed<AnnouncementConfig>(() => tenant.announcementConfig);
const variantClasses = computed(() => variantClassesMap.get(announcementConfig.value.variant))

const show = ref(true);
</script>
