<template>
  <div>
    <Spinner v-if="publicationsLoading" />
    <div v-else>
      <p>{{ $t('issue-calendar.select-publication') }}</p>
      <div class="relative mt-2">
        <input
          v-model="search"
          class="block w-full py-2 pl-9 pr-3 border rounded-input focus:shadow-outline focus:outline-none "
          type="search"
          autofocus
          placeholder="Suche..."
        />
        <div
          class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
        >
          <font-awesome-icon
            icon="search"
            :class="search.length !== 0 ? 'text-primary' : 'text-gray-400'"
          />
        </div>
      </div>
      <ul v-if="hasPublications" class="grid sm:grid-cols-2 md:grid-cols-3 gap-2 mt-4">
        <IssueCalendarPublicationListItem v-for="publication in filteredPublications"
                                          :key="publication.publication.publication"
                                          :publication="publication" />
      </ul>
      <EmptyState v-else :text="$t('issue-calendar.no-publications')" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { storeToRefs } from "pinia";
import EmptyState from "@/components/EmptyState.vue";
import Spinner from "@/components/Spinner.vue";
import { useUnauthenticatedIssueCalendar } from "@/pinia/unauthenticatedIssueCalendar";
import { IssueCalendarPublication } from "@/models/issueCalendarPublication";
import Fuse from "fuse.js";
import IssueCalendarPublicationListItem from "@/pages/issue-calendar/IssueCalendarPublicationListItem.vue";
import { useRouter } from "vue-router";

const issueCalendar = useUnauthenticatedIssueCalendar();
const router = useRouter()

const search = ref('')

// @ts-ignore
const { publicationsLoading, publications } = storeToRefs(issueCalendar)

const hasPublications = computed(() => Boolean(publications.value?.length))

const fusedPublications = computed(() => {
  return new Fuse(publications.value!, {
    ignoreLocation: true,
    keys: ['publication.description']
  })
})

const filteredPublications = computed(() => {
  if (search.value === '') {
    return publications.value ?? []
  }
  return fusedPublications.value.search(search.value).map((result) => result.item)
})


function selectPublication(publication: IssueCalendarPublication) {
  router.push({
    name: 'issue-calendar-publication',
    params: {
      publication: publication.publication.publication
    }
  })
}

issueCalendar.fetchPublications()
</script>
