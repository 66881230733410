<template>
  <h3 class="font-display font-medium text-xl">
    {{ label }}
  </h3>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import { useTenant } from "@/plugins/tenant";
import { useI18n } from "vue-i18n";

const props = defineProps({
  name: {
    type: String,
    required: true
  }
})

const tenant = useTenant()
const { locale, tm } = useI18n()

const label = computed(() => {
  const spec = tenant.buttonConfig[props.name];
  if (spec) {
    const translation = spec.translations[locale.value];
    if (translation) {
      return translation;
    }
  }
  return (tm("buttons") as any)[props.name];
})
</script>
