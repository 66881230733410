<template>
  <div class="mt-5">
    <Alert v-if="validationFailed" variant="error" class="mb-2">{{
        $t("file-input.file-too-large")
      }}</Alert>
    <label class="block">
      {{ label }} <span v-if="required" class="text-primary">*</span>
      <input
        :name="name"
        @change="handleChange"
        class="input mt-1 py-2 px-3 border rounded-input block w-full focus:shadow-outline focus:outline-none placeholder-gray-500"
        type="file"
        :accept="accept"
        :multiple="multiple"
        :required="required"
        :disabled="disabled"
      />
    </label>
  </div>
</template>
<script lang="ts" setup>
import { PropType, ref } from "vue";
import Alert from "@/components/Alert.vue";

const props = defineProps( {
  name: String,
  label: String,
  required: Boolean,
  accept: String,
  multiple: Boolean,
  maxFileSize: {
    type: Number,
    default: Number.MAX_SAFE_INTEGER
  },
  disabled: Boolean,
  modelValue: Array as PropType<any>
})

const emit = defineEmits(['update:modelValue'])

const validationFailed = ref(false)

function handleChange(event: Event) {
  const target = event.target as HTMLInputElement
  const files = Array.from(target.files as any) as File[];
  if (!files.length) {
    emit("update:modelValue", null);
    return;
  }
  const isValid = files.every((file) => {
    return file.size <= props.maxFileSize;
  });
  if (isValid) {
    validationFailed.value = false;
    emit("update:modelValue", files);
  } else {
    validationFailed.value = true;
    target.value = "";
  }
}
</script>
