<template>
  <div>
    <div class="border-b pb-4 mb-4" v-if="subscription">
      <BackButton :subscription="subscription" />
    </div>
    <PageTitle name="contact"></PageTitle>
    <p class="mt-2">{{ $t("contact.heading") }}</p>
    <RequiredFieldsHint class="mt-2"></RequiredFieldsHint>
    <template v-if="$tenant.supportsContactForm()">
      <AuthenticatedContactForm v-if="auth.isFullyLoggedIn"></AuthenticatedContactForm>
      <UnauthenticatedContactForm v-else></UnauthenticatedContactForm>
    </template>
    <FeatureDisabledAlert v-else></FeatureDisabledAlert>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, Ref } from 'vue'
import PageTitle from "@/components/PageTitle.vue";
import AuthenticatedContactForm from "@/pages/contact/AuthenticatedContactForm.vue";
import UnauthenticatedContactForm from "@/pages/contact/UnauthenticatedContactForm.vue";
import FeatureDisabledAlert from "@/components/FeatureDisabledAlert.vue";
import { useAuth } from "@/pinia/auth";
import BackButton from "@/components/BackButton.vue";
import { useSubscription } from "@/pinia/orders";
import { useRoute } from "vue-router";
import RequiredFieldsHint from "@/components/RequiredFieldsHint.vue";
import { useTenant } from "@/plugins/tenant";
import { Subscription } from "@/models/subscription";


const auth = useAuth()
const route = useRoute()
const tenant = useTenant()

let subscription: Ref<Subscription>
if (auth.isFullyLoggedIn && route.query.subscriptionId) {
  subscription = useSubscription(route.query.subscriptionId as string).subscription
}

onMounted(() => {
  if (
    tenant.contactConfig.customScript !== null &&
    document.getElementById("custom-script") === null
  ) {
    const scriptTag = document.createElement("script");
    scriptTag.id = "custom-script";
    scriptTag.type = "text/javascript";
    scriptTag.innerHTML = tenant.contactConfig.customScript;
    document.documentElement.appendChild(scriptTag);
  }
})

onBeforeUnmount(() => {
  const script = document.getElementById("custom-script")
  if (script) {
    document.documentElement.removeChild(script)
  }
})
</script>
