<template>
  <div class="overflow-x-auto">
    <table
      class="text-sm sm:text-base w-full border border-t-0 rounded-t-none "
    >
      <thead>
        <tr class="border-none bg-gray-50">
          <th class="p-3">{{ $t("payments.table.number") }}</th>
          <th class="p-3">{{ $t("payments.table.date") }}</th>
          <th class="p-3">{{ $t("payments.table.price") }}</th>
          <th class="p-3" v-if="showInvoiceStatus">
            {{ $t("payments.status.label") }}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="border-t border-tables"
          v-for="payment in currentPayments"
          :key="payment.id!"
        >
          <td class="p-3">
            {{ payment.id }}
          </td>
          <td class="p-3">
            {{ formatDate(payment.date) }}
          </td>
          <td class="p-3">{{ $n(payment.amount, "EUR") }}</td>
          <td class="p-3" v-if="showInvoiceStatus">
            <InvoiceStatusBadge
              :status="payment.invoiceStatusCode"
            ></InvoiceStatusBadge>
          </td>
          <td class="text-right">
            <RequestInvoiceButton
              v-if="supportsInvoiceRequest"
              class="text-primary p-3"
              :subscription="subscription"
              :invoice-number="payment.invoiceNumber"
              :external-document-number="payment.externalDocumentNumber"
            >
            </RequestInvoiceButton>
            <DownloadInvoiceButton
              v-if="supportsInvoiceDownload && payment.documentId"
              class="text-primary p-3"
              :subscription="subscription"
              :payment="payment"
            ></DownloadInvoiceButton>
          </td>
        </tr>
        <tr v-if="subscription.paymentHistory!.length === 0">
          <td colspan="5" class="text-center text-gray-700 p-3 py-9">
            {{$t('payments.empty')}}
          </td>
        </tr>
      </tbody>
      <tfoot v-if="subscription.paymentHistory!.length > paymentsPerPage">
        <tr class="border-t border-tables">
          <td colspan="5" class="p-3 bg-gray-50">
            <Pagination
              :entity="$t('subscription-detail.download-invoices')"
              :items-per-page="paymentsPerPage"
              :total="subscription.paymentHistory!.length"
              v-model="page"
            ></Pagination>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
<script lang="ts" setup>
import { Subscription } from "@/models/subscription";
import { computed, ref } from "vue";
import InvoiceStatusBadge from "@/components/InvoiceStatusBadge.vue";
import RequestInvoiceButton from "@/components/RequestInvoiceButton.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import DownloadInvoiceButton from "@/components/DownloadInvoiceButton.vue";
import { useTenant } from "@/plugins/tenant";
import { useI18n } from "vue-i18n";

const tenant = useTenant()
const {d} = useI18n()

const props = defineProps({
  subscription: {
    type: Subscription,
    required: true
  },
  showInvoiceStatus: Boolean,
})

const page = ref(0)
const paymentsPerPage = ref(10)

function formatDate(date: Date| string) {
  if (date instanceof Date) {
    return d(date);
  }
  return date;
}

const supportsInvoiceRequest = computed(() => {
  return tenant.supportsInvoiceRequest();
})

const supportsInvoiceDownload = computed(() => {
  return tenant.supportsInvoiceDownload();
})

const currentPayments = computed(() => {
  return props.subscription.paymentHistory!.slice(
    page.value * paymentsPerPage.value,
    (page.value + 1) * paymentsPerPage.value
  );
})
</script>
