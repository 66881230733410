<template>
  <vue-final-modal
    v-model="_show"
    :name="name"
    :click-to-close="clickToClose"
    v-slot="{ params, close }"
    classes="flex justify-center items-center"
    content-class="relative flex flex-col max-h-full mx-4 border rounded bg-white w-full sm:max-w-xl"
    overlay-class="bg-gray-500 bg-opacity-75"
  >
    <span class="mr-8 text-xl font-display font-bold p-4 pb-0" v-if="$slots.title">
      <slot name="title"></slot>
    </span>
    <div class="alert-dialog-content p-4">
      <slot :params="params"></slot>
    </div>
    <div class="flex-shrink-0 flex justify-end items-center p-4 print:hidden">
      <slot name="actions"></slot>
      <button
        v-if="negativeText"
        class="btn font-semibold"
        @click="negative"
        type="button"
        :disabled="loading"
      >
        {{ negativeText }}
      </button>
      <ButtonWithLoadingIndicator
        :loading="loading"
        v-if="positiveText"
        class="btn-primary font-semibold ml-4"
        :form="form"
        :type="form != null ? 'submit' : 'button'"
        @click="positive"
      >
        {{ positiveText }}
      </ButtonWithLoadingIndicator>
    </div>
  </vue-final-modal>
</template>
<script lang="ts" setup>
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import { ref } from "vue";
import { useVfm } from "@/plugins/vfm";

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  positiveText: String,
  negativeText: String,
  loading: Boolean,
  disableAutoHide: Boolean,
  scrollable: Boolean,
  clickToClose: Boolean,
  form: String,
})

const emit = defineEmits(['positive','negative'])

const vfm = useVfm()

const _show = ref(false)
const confirm = ref<Function|null>(null)

function positive() {
  if (!props.disableAutoHide) {
    vfm.hide(props.name);
  }
  if (confirm.value) {
    confirm.value(true);
    confirm.value = null;
  }
  emit('positive')
}

function negative() {
  if (!props.disableAutoHide) {
    vfm.hide(props.name);
  }
  if (confirm.value) {
    confirm.value(false);
    confirm.value = null;
  }
  emit('negative')
}

function showConfirm(): Promise<boolean> {
  vfm.show(props.name);
  return new Promise((resolve) => {
    confirm.value = resolve;
  });
}

function show() {
  vfm.show(props.name);
}

function hide() {
  vfm.hide(props.name);
}

defineExpose({
  show,
  hide,
  showConfirm
})

</script>

<style>
@media not print {
  .alert-dialog-content {
    @apply flex-grow md:text-lg overflow-y-auto;
  }
}

@media print {
  .alert-dialog-content {
    @apply flex-grow md:text-lg;
  }
}
</style>
