<template>
  <label class="block mt-5">
    {{ label }} <span v-if="required" class="text-primary">*</span>
    <input
      ref="input"
      :name="name"
      :value="modelValue"
      @input="handleInput"
      class="input mt-1 py-2 px-3 border rounded-input block w-full focus:ring focus:outline-none placeholder-gray-500"
      :class="inputClass"
      :type="type"
      :placeholder="placeholder"
      :required="required"
      :minlength="minLength"
      :maxlength="maxLength"
      :pattern="pattern"
      :disabled="disabled"
      :min="min"
      @focus="handleFocus"
    />
  </label>
</template>
<script lang="ts" setup>
import { PropType, ref } from "vue";

const props = defineProps({
  name: String as PropType<string | undefined>,
  label: String,
  placeholder: String,
  type: String,
  required: Boolean,
  minLength: String,
  maxLength: String,
  pattern: String,
  min: String,
  max: String,
  disabled: Boolean,
  nullIfEmpty: Boolean,
  inputClass: String,
  selectOnFocus: Boolean,
  modelValue: {
    type: String as PropType<string | null | undefined>,
    default: null
  }
})

const input = ref<HTMLInputElement | undefined>(undefined)

const emit = defineEmits(['update:modelValue'])

function handleInput(event: Event) {
  const value = (event.target! as HTMLInputElement).value
  emit('update:modelValue', value.length === 0 && props.nullIfEmpty ? null : value)
}

function handleFocus() {
  if (props.selectOnFocus) {
      input.value?.select()
  }
}
</script>

<style>
.input:disabled {
  background: #f5f5f5;
}
</style>
