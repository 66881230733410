import { App, inject } from "vue";
import { Tenant } from "@/models/tenant";

export function useTenant(): Tenant {
  return inject('tenant')!;
}

export default {
  install: (app: App, tenant: Tenant) => {
    app.config.globalProperties.$tenant = tenant
    app.provide('tenant', app.config.globalProperties.$tenant)
  }
}
