<template>
  <form class="md:grid grid-cols-2 gap-x-4" @submit.prevent="submit">
    <RequiredFieldsHint class="mt-2"></RequiredFieldsHint>
    <p class="label col-span-2 mt-5">{{ $t('e-card.template.label') }} <span class="text-primary">*</span></p>
    <div class="col-span-2 md:grid grid-cols-2 gap-4 mt-2">
      <div class="hidden md:block">
        <img v-if="form.template" :alt="form.template.name" :src="form.template.previewImageUrl" :title="form.template.name" class="rounded-card">
        <div v-else class="preview-placeholder border-2 border-dashed rounded flex items-center justify-center">
          <font-awesome-icon icon="image" size="2x" class="text-gray-500"/>
        </div>
      </div>
      <div>
        <FormSelect
          v-model="category"
          :options="categories"
          :placeholder="$t('e-card.category.placeholder')"
        ></FormSelect>
        <ul class="flex overflow-y-auto md:grid grid-cols-4 gap-2 mt-2 items-start p-1 -m-1">
          <li v-for="template in templatesInCategory"
              :key="template.name"
              class="w-3/5 md:w-auto flex-shrink-0 rounded-card overflow-hidden opacity-70 hover:opacity-100 cursor-pointer"
              :class="template.name === form.template?.name ? 'ring-2 ring-primary opacity-100' : ''"
              @click="selectTemplate(template)">
            <img :src="template.previewImageUrl" :alt="template.name" :title="template.name">
          </li>
        </ul>
      </div>
    </div>
    <FormInput
      class="col-span-2"
      v-model="form.from"
      required
      :label="$t('e-card.from.label')"
    ></FormInput>
    <FormInput
      v-model="form.to"
      required
      :label="$t('e-card.to.label')"
    ></FormInput>
    <FormInput
      v-model="form.toEmail"
      type="email"
      required
      :label="$t('e-card.to-email.label')"
    ></FormInput>
    <FormTextarea
      class="col-span-2"
      v-model="form.message"
      required
      :label="$t('e-card.message.label')"
    ></FormTextarea>
    <div
      class="label col-span-2 mt-5"
    >{{ $t('e-card.execution.label') }}
      <span class="text-primary">*</span>
    </div>
    <fieldset class="block mt-2">
      <FormRadio
        value="asap"
        :label="$t('e-card.execution.options.asap')"
        v-model="execution"
        name="execution"
      ></FormRadio>
      <FormRadio
        class="mt-2"
        value="timed"
        :label="$t('e-card.execution.options.timed')"
        v-model="execution"
        name="execution"
      ></FormRadio>
    </fieldset>
    <FormInput
      type="datetime-local"
      :min="minDate"
      class="w-full"
      max-length="10"
      :disabled="execution === 'asap'"
      v-model="form.sendAt"
    />
    <ButtonBar class="mt-6 col-span-2">
      <ButtonWithLoadingIndicator
        type="submit"
        :loading="busy"
        :disabled="form.template === null"
        class="btn btn-primary"
      >
        {{ $t('e-card.submit.label') }}
      </ButtonWithLoadingIndicator>
      <button type="button" class="btn mt-2 md:ml-2 md:mt-0" @click="emit('close')">{{ $t('app.cancel') }}</button>
    </ButtonBar>
    <AlertDialog
      ref="success"
      name="success"
      :negative-text="$t('app.back')"
      @negative="emit('close')"
    >
      <template #title>
        {{ $t("e-card.success.title") }}
      </template>
      <i18n-t keypath="e-card.success.message">
        <template #email>
          <b>{{ form.toEmail }}</b>
        </template>
      </i18n-t>
    </AlertDialog>
  </form>
</template>

<script lang="ts" setup>
import FormInput from "@/components/form/FormInput.vue";
import FormTextarea from "@/components/form/FormTextarea.vue";
import FormRadio from "@/components/form/FormRadio.vue";
import { addDays, formatISO } from "date-fns";
import { computed, reactive, ref } from "vue";
import ErrorModal from "@/components/ErrorModal.vue";
import { useTenant } from "@/plugins/tenant";
import { useVfm } from "@/plugins/vfm";
import { useAxios } from "@/plugins/axios";
import FormSelect from "@/components/form/FormSelect.vue";
import ButtonBar from "@/components/ButtonBar.vue";
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import RequiredFieldsHint from "@/components/RequiredFieldsHint.vue";
import AlertDialog from "@/components/AlertDialog.vue";
import { ECardTemplate } from "@/models/eCardConfig";

const emit = defineEmits<{
  (event: 'close'): void
}>()

const tenant = useTenant()
const vfm = useVfm()
const axios = useAxios()

const execution = ref<'asap' | 'timed'>('asap')
const busy = ref(false)

const templates = tenant.eCardConfig.templates

const form = reactive({
  from: '',
  to: '',
  toEmail: '',
  message: '',
  sendAt: null,
  template: null as ECardTemplate|null
})
const category = ref(null)

const minDate = computed(() => {
  return formatISO(addDays(new Date(), 1), {
    representation: "date",
  });
})

const categories = computed(() => {
  return Array.from(new Set(templates.map((t) => t.category)))
})

const templatesInCategory = computed(() => {
  if (category.value === null) {
    return templates;
  }
  return templates.filter((template) => template.category === category.value);
})

function selectTemplate(template: ECardTemplate) {
  form.template = template
}

async function submit() {
  busy.value = true;
  try {
    await axios.post(
      `/e-cards`,
      {
        ...form,
        // set the correct timezone by parsing the date string
        sendAt: form.sendAt !== null ? new Date(form.sendAt) : null
      },
    );
    vfm.show("success");
  } catch (error) {
    vfm.show({
      component: ErrorModal
    });
  } finally {
    busy.value = false;
  }
}

</script>

<style>
.preview-placeholder {
  min-height: 24rem;
}
</style>
