<template>
  <div>
    <BackButton :subscription="subscription" />
    <div class="py-4 border-t mt-4">
      <PageTitle name="addresses"></PageTitle>
      <p class="mt-4">
        {{ $t("subscription-business-partners.heading") }}
      </p>
    </div>
    <Spinner v-if="subscriptionLoading" class=""></Spinner>
    <ul v-else>
      <BusinessPartnerRoleAddressForm
        v-for="(roles, bpNumber) in businessPartners"
        :key="bpNumber"
        :roles="roles"
        :subscription="subscription"
        :business-partner="getBusinessPartnerByBpNumber(bpNumber)"
        @success="handleSuccess"
        @error="handleError"
        ref="form"
      >
      </BusinessPartnerRoleAddressForm>
    </ul>
    <AlertDialog name="success" :negative-text="$t('app.close')">
      {{ $t("subscription-business-partners.address-change-success") }}
    </AlertDialog>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import BackButton from "@/components/BackButton.vue";
import PageTitle from "@/components/PageTitle.vue";
import BusinessPartnerAddressForm from "@/components/business-partner/BusinessPartnerAddressForm.vue";
import { BusinessPartner, BusinessPartnerRole } from "@/models/businessPartner";
import BusinessPartnerRoleAddressForm from "@/components/business-partner/BusinessPartnerRoleAddressForm.vue";
import AlertDialog from "@/components/AlertDialog.vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { useSubscription } from "@/pinia/orders";
import { useBusinessPartners } from "@/pinia/businessPartners";
import Spinner from "@/components/Spinner.vue";
import { useVfm } from "@/plugins/vfm";

const route = useRoute()
const vfm = useVfm()
const {getBusinessPartnerByBpNumber} = useBusinessPartners()

const subscriptionId = route.params.id as string

const { subscription, subscriptionLoading } = useSubscription(subscriptionId)

const form = ref<HTMLFormElement[] |null>(null)

const businessPartners = computed(() => {
  const roleMap: Record<string, BusinessPartnerRole[]> = {};
  subscription.value.businessPartners.forEach((businessPartner) => {
    if (businessPartner.bpNumber in roleMap) {
      roleMap[businessPartner.bpNumber].push(businessPartner.role!);
    } else {
      roleMap[businessPartner.bpNumber] = [businessPartner.role!];
    }
  });
  return roleMap;
})

function handleSuccess(businessPartner: BusinessPartner) {
  vfm.show("success");
}

function handleError() {
  vfm.show("error");
}

function confirmDiscardChanges() {
  return window.confirm(
    "Sie haben ungespeicherte Änderungen. Möchten Sie diese verwerfen?"
  );
}

function hasDirtyForm() {
  return (
    Array.isArray(form.value) &&
    form.value[0] &&
    // @ts-ignore
    form.value.some((form) => form.isDirty())
  );
}

onBeforeRouteLeave(() => {
  if (hasDirtyForm() && !confirmDiscardChanges()) {
    return false
  }
})
</script>
