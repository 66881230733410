<template>
  <div class="pb-4">
    <BackButton :subscription="subscription" />
    <div class="py-4 border-t mt-4">
      <PageTitle name="opt-ins"></PageTitle>
      <p class="mt-4">
        {{ $t("subscription-opt-in.heading") }}
      </p>
    </div>
    <Spinner v-if="loading" />
    <form v-else-if="optIns?.length" @submit.prevent="submit">
      <Alert v-if="hasPendingConfirmation" variant="info" class="mb-4" icon="info-circle">
        {{ $t("subscription-opt-in.confirmation-pending") }}
      </Alert>
      <p class="font-semibold">{{ $t("subscription-opt-in.current") }}:</p>
      <ul class="mt-4">
        <li v-for="optIn in optIns" :key="optIn.type" class="mt-2">
          <FormCheckbox :label="optIn.description" v-model="optIn.value" />
        </li>
      </ul>
      <ButtonWithLoadingIndicator
        class="btn-primary mt-4"
        :disabled="!hasChanged"
        :loading="submitting"
        type="submit"
      >
        {{ $t("app.save") }}
      </ButtonWithLoadingIndicator>
    </form>
    <EmptyState v-else :text="$t('subscription-opt-in.empty')" />
    <AlertDialog name="error" :negative-text="$t('app.close')">
      {{ $t("app.error") }}
    </AlertDialog>
  </div>
</template>
<script lang="ts" setup>
import { Subscription } from "@/models/subscription";
import AlertDialog from "@/components/AlertDialog.vue";
import Spinner from "@/components/Spinner.vue";
import EmptyState from "@/components/EmptyState.vue";
import FormCheckbox from "@/components/form/FormCheckbox.vue";
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import BackButton from "@/components/BackButton.vue";
import PageTitle from "@/components/PageTitle.vue";
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useAxios } from "@/plugins/axios";
import { useVfm } from "@/plugins/vfm";
import { useOrders } from "@/pinia/orders";
import { useTracker } from "@/plugins/tracker";
import Alert from "@/components/Alert.vue";
import { useOptIns } from "@/pinia/optIns";

interface OptInWithInitialValue extends OptIn {
  initialValue: boolean;
}

const route = useRoute()
const axios = useAxios()
const vfm = useVfm()
const tracker = useTracker()
const orders = useOrders()
const optInsStore = useOptIns()

const subscriptionId = route.params.id as string

const loading = ref(false)
const submitting = ref(false)
const optIns = ref<OptInWithInitialValue[] | null>(null)

const subscription = computed(() => orders.getSubscriptionById(subscriptionId))
const businessPartner = computed(() => subscription.value.currentRoleBusinessPartner)
const hasChanged = computed(() => {
  if (optIns.value == null) {
    return false;
  }
  return optIns.value.reduce(
    (hasChanged, optIn) => hasChanged || optIn.value !== optIn.initialValue,
    false
  );
})
const hasPendingConfirmation = computed(() => optInsStore.pendingConfirmations.has(subscriptionId))

async function submit() {
  try {
    submitting.value = true;
    // filter out all changed opt-ins
    const changedOptIns = optIns.value!.filter(
      (optIn) => optIn.value !== optIn.initialValue
    );
    await optInsStore.update(subscriptionId, businessPartner.value.bpNumber, changedOptIns)
    tracker.trackEvent(
      "Subscription",
      "ChangeOptIns",
      subscription.value.publication
    );
    // set the initial value of all opt-ins to their current value
    optIns.value!.forEach((optIn) => {
      optIn.initialValue = optIn.value;
    });
  } catch (e) {
    vfm.show("error");
  } finally {
    submitting.value = false;
  }
}

async function fetch() {
  try {
    loading.value = true;
    await orders.fetchSubscription(subscriptionId)
    const response = await axios.get<Array<OptIn>>(
      `/subscriptions/${subscriptionId}/business-partners/${businessPartner.value.bpNumber}/opt-ins`
    );
    optIns.value = response.data.map((optIn) => ({
      ...optIn,
      initialValue: optIn.value,
    }));
  } catch (error) {
    vfm.show("error");
  } finally {
    loading.value = false;
  }
}

onMounted(() => fetch())
</script>
