// TODO: find a more type-safe way of parsing json into objects?
export class JSONHelper {
  public static deserialize(data: string): any {
    return JSON.parse(data, JSONHelper.reviveDates);
  }
  public static serialize(obj: any): string {
    return JSON.stringify(obj);
  }

  private static reviveDates(key: any, value: any): any {
    if (typeof value === "string") {
      // this regular expression matches date strings in the format 'YYYY-MM-DD'
      const a = /^\d{4}-[01]\d-[0-3]\d(T[0-2]\d:[0-5]\d:[0-5]\d[+-][0-2]\d:[0-5]\d|Z)?$/.exec(
        value
      );
      if (a) {
        return new Date(a[0]);
      }
    }
    return value;
  }
}
