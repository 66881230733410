<template>
  <div>
    <h3 class="text-xl font-medium font-display">
      {{ $t("faq.title") }}
    </h3>
    <p class="prose mt-2" v-html="headingHtml"></p>
    <div class="mt-4 space-y-4">
      <div v-for="(group, title) in groupedEntries" :key="title">
        <h4 class="text-lg font-display">{{ title }}</h4>
        <dl class="space-y-2 mt-1">
          <FaqEntry
            v-for="(entry, i) in group"
            :key="i"
            :entry="entry"
          />
        </dl>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import FaqEntry from "@/pages/faq/FaqEntry.vue";
import { useAuth } from "@/pinia/auth";
import { useTenant } from "@/plugins/tenant";
import { computed } from "vue";
import { groupBy } from "lodash-es";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const auth = useAuth()
const tenant = useTenant()
const { t } = useI18n()
const route = useRoute()

const generalQuestionsGroupTitle = computed(() => t("faq.general-questions"))

const headingHtml = computed(() => {
  if (tenant.faqConfig?.headingHtml != null) {
    return tenant.faqConfig.headingHtml
  }
  return t('faq.heading')
})

const categoriesToShow = computed<string[]|null>(() => {
  const param = route.query.category
  if (Array.isArray(param)) {
    return param as string[]
  }
  if (typeof param === 'string') {
    return [param]
  }
  return null
})

const groupedEntries = computed(() => {
  let entries = tenant.faqEntries.map((entry) => ({
    ...entry,
    category: entry.category ?? generalQuestionsGroupTitle.value
  }))
  if (!auth.isFullyLoggedIn) {
    entries = entries.filter((entry) => {
      return Boolean(entry.public);
    });
  }
  if (categoriesToShow.value !== null) {
    entries = entries.filter((entry) => {
      return categoriesToShow.value!.includes(entry.category)
    })
  }
  return groupBy(entries, 'category');
})

</script>
