<template>
  <div class="text-center">
    <p class="text-center text-green-600">
      <font-awesome-icon icon="check" size="4x"></font-awesome-icon>
    </p>
    <p class="mt-6 text-center font-display font-medium text-lg sm:text-xl">
      {{ $t("email-widget.success-title") }}
    </p>
    <div class="mt-2 prose prose--hard-break" v-html="successMessage"></div>
    <EmailWidgetRewards
      class="mt-6"
      :rewards="result.rewards"
    ></EmailWidgetRewards>
  </div>
</template>

<script lang="ts" setup>
import { LinkEmailResult } from "@/models/emailWidget";
import { computed, PropType } from "vue";
import EmailWidgetRewards from "@/pages/email-widget/EmailWidgetRewards.vue";
import { useTenant } from "@/plugins/tenant";
import { useI18n } from "vue-i18n";

defineProps({
  result: {
    type: Object as PropType<LinkEmailResult>,
    required: true
  }
})

const tenant = useTenant()
const {t} = useI18n()

const successMessage = computed(() => {
  return tenant.emailWidgetConfig.successMessageHtml ?? t('email-widget.success-message')
})

</script>
