<template>
  <component
    :is="editing ? 'form' : 'div'"
    class="border bg-gray-50 px-4 py-4 sm:px-6 sm:py-5 items-center rounded-card"
    @submit.prevent="$emit('submit')"
    :class="{
      'border-primary-light shadow': active,
      flex: !active && !editing,
      'md:flex': active && !editing,
    }"
  >
    <div class="flex-1 flex">
      <font-awesome-icon :icon="icon" class="flex-0" size="lg" />
      <div class="flex-1 ml-4">
        <div class="font-bold leading-tight text-gray-900">
          {{ name }}
        </div>
        <div>
          <slot v-if="editing" name="form"></slot>
          <slot v-else-if="active" name="details"></slot>
        </div>
      </div>
    </div>
    <div
      class="flex justify-end"
      :class="{
        'mt-4': active || editing,
        'md:mt-0': !editing,
      }"
    >
      <button
        v-if="!editing && !disabled"
        type="button"
        class="btn btn-border"
        @click="$emit('edit')"
      >
        <span v-if="active">{{
            $t("subscription-payment-details.update-method")
          }}</span>
        <span v-else>{{
            $t("subscription-payment-details.change-method")
          }}</span>
      </button>
      <button
        v-if="editing"
        type="button"
        class="btn btn-border"
        :disabled="disabled"
        @click="$emit('cancel')"
      >
        {{ $t("app.cancel") }}
      </button>
      <ButtonWithLoadingIndicator
        v-if="editing"
        :disabled="disabled"
        :loading="loading"
        type="submit"
        :inverted-spinner="false"
        class="btn-border ml-2"
      >
        {{ $t("app.save") }}
      </ButtonWithLoadingIndicator>
    </div>
  </component>
</template>

<script lang="ts" setup>
import { getIconForPaymentMethod } from "@/util/paymentMethodIcons";
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import { PaymentMethod } from "@/models/paymentDetails";
import { computed, PropType } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  paymentMethod: {
    type: String as PropType<PaymentMethod>,
    required: true
  },
  active: Boolean,
  editing: Boolean,
  disabled: Boolean,
  loading: Boolean,
})

const emit = defineEmits(['submit', 'edit', 'cancel'])

const {tm} = useI18n()

const icon = computed(()=> {
  return getIconForPaymentMethod(props.paymentMethod);
})
const name = computed(()=> {
  // @ts-ignore
  return tm("payment-methods")[props.paymentMethod];
})
</script>
