<template>
  <div>
    <BackButton :subscription="subscription" />
    <div class="py-4 border-t mt-4">
      <PageTitle name="holiday-service"></PageTitle>
      <i18n-t
        v-if="hasPlannedHolidayService"
        tag="p"
        class="mt-4"
        keypath="subscription-holiday-service.heading-planned"
      >
        <template #contactForm>
          <router-link to="/contact" class="link"
          >{{ $t("app.contact-form") }}
          </router-link>
        </template>
      </i18n-t>
      <p class="mt-4" v-else>
        {{ $t("subscription-holiday-service.page-heading") }}
      </p>
    </div>
    <Spinner v-if="subscriptionLoading"></Spinner>
    <Alert variant="warning" v-else-if="!supportsHolidayService">
      {{ $t("subscription-holiday-service.not-available") }}
    </Alert>
    <template v-else-if="hasPlannedHolidayService">
      <h4 class="font-semibold">
        {{ $t("subscription-holiday-service.active-service") }}:
      </h4>
      <Spinner v-if="plannedHolidayService === null" />
      <div
        v-else
        class="p-4 border shadow border-primary-light bg-gray-50 mt-2"
      >
        <h4 class="font-bold text-lg">
          {{ plannedServiceLabel }}
        </h4>
        <div class="mt-4 grid gap-2 md:grid-cols-3">
          <div>
            <dt class="font-semibold">
              {{ $t("subscription-holiday-service.from-issue") }}:
            </dt>
            <dd>
              {{ plannedHolidayService.fromIssueNumber }}
            </dd>
          </div>
          <div>
            <dt class="font-semibold">
              {{ $t("subscription-holiday-service.to-issue") }}:
            </dt>
            <dd>
              {{ plannedHolidayService.toIssueNumber }}
            </dd>
          </div>
          <div v-if="plannedHolidayService.address">
            <dt class="font-semibold">
              {{ $t("subscription-holiday-service.to-address") }}:
            </dt>
            <dd>
              <BusinessPartnerAddress
                :business-partner="plannedHolidayService.address"
              ></BusinessPartnerAddress>
            </dd>
          </div>
        </div>
        <ButtonBar
          class="mt-4 flex justify-end"
          v-if="plannedHolidayService.isCancellable"
        >
          <ButtonWithLoadingIndicator
            :inverted-spinner="false"
            :loading="isSubmitting"
            class="btn btn-border"
            @click="cancelPlannedHolidayService"
          >{{ $t("subscription-holiday-service.cancel") }}
          </ButtonWithLoadingIndicator>
        </ButtonBar>
      </div>
    </template>
    <form @submit.prevent="submit" class="-mt-5" v-else>
      <RequiredFieldsHint class="mt-4"></RequiredFieldsHint>
      <FormSelect
        :label="$t('subscription-holiday-service.service.label')"
        :placeholder="$t('subscription-holiday-service.service.placeholder')"
        required
        :options="supportedHolidayServices"
        v-model="selectedService"
      />
      <p class="font-semibold mt-4" v-if="selectedService">
        {{ $tm("subscription-holiday-service.disclaimer")[selectedService] }}
      </p>
      <div class="mt-4">
        {{ $t("subscription-holiday-service.holiday-interval.label") }}&nbsp;<span class="text-primary">*</span>
      </div>
      <HolidayDateRangePicker
        class="mt-2"
        v-model="dateRange"
        :loading="issuesLoading"
        :issues="issues"
        :min-date="minDate"
        :max-duration-weeks="maxDurationWeeks"
      />
      <i18n-t
        tag="p"
        class="hint mt-1"
        keypath="subscription-holiday-service.holiday-interval.hint"
      >
        <template #contactForm>
          <router-link to="/contact" class="link"
          >{{ $t("app.contact-form") }}
          </router-link>
        </template>
        <template #maxDuration>
          {{ $tc("subscription-holiday-service.weeks", maxDurationWeeks) }}
        </template>
      </i18n-t>
      <Alert class="my-4" v-if="issues" variant="none">
        <span
          v-html="
            $tc(
              'subscription-holiday-service.issues-in-interval',
              issues.length
            )
          "
        />
        <SimpleIssueList
          v-if="issues.length"
          class="mt-2"
          :issues="issues"
        ></SimpleIssueList>
      </Alert>
      <Alert class="my-4" v-if="isNextIssueAlreadyShipped" variant="error">
        {{
          $t("subscription-holiday-service.next-issue-already-shipped", {
            dateOfFirstSale: $d(nextIssue!.dateOfFirstSale),
            })
        }}
      </Alert>
      <BusinessPartnerAddressForm
        v-if="hasSelectedRedirect"
        class="mt-5"
        :business-partner="redirectBusinessPartner!"
        :subscription="subscription"
        show-name
        allow-first-name-change
        allow-last-name-change
        allow-country-change
      >
      </BusinessPartnerAddressForm>
      <i18n-t
        v-if="hasSelectedRedirect"
        tag="p"
        keypath="subscription-holiday-service.country-hint"
        class="hint mt-2"
      >
        <template #contactForm>
          <router-link to="/contact" class="link"
          >{{ $t("app.contact-form") }}
          </router-link>
        </template>
      </i18n-t>
      <ButtonBar class="mt-6">
        <ButtonWithLoadingIndicator
          class="submit btn btn-primary"
          type="submit"
          :disabled="isSubmitting || isSubmitDisabled"
          :loading="isSubmitting"
        >
          {{ $t("subscription-holiday-service.submit") }}
        </ButtonWithLoadingIndicator>
      </ButtonBar>
    </form>
    <AlertDialog
      v-if="dateRange.start && dateRange.end && selectedService && issues"
      :positive-text="$t('subscription-holiday-service.confirm.action')"
      :negative-text="$t('app.cancel')"
      name="confirm"
      ref="confirmModal"
    >
      <i18n-t tag="p" keypath="subscription-holiday-service.confirm.message">
        <template #startDate
        ><b>{{ $d(dateRange.start) }}</b></template
        >
        <template #endDate
        ><b>{{ $d(dateRange.end) }}</b></template
        >
        <template #selectedService
        ><b>{{ selectedServiceLabel }}</b></template
        >
        <template #startIssue
        >
          <IssueLabel :issue="nextIssue!"></IssueLabel
          >
        </template>
        <template #endIssue
        >
          <IssueLabel :issue="lastIssue!"></IssueLabel
          >
        </template>
      </i18n-t>
    </AlertDialog>
    <AlertDialog
      :positive-text="$t('subscription-holiday-service.confirm-cancel.action')"
      :negative-text="$t('app.cancel')"
      name="confirm-cancel"
      ref="confirmCancelModal"
    >
      <span
        v-html="$t('subscription-holiday-service.confirm-cancel.message')"
      />
    </AlertDialog>
    <AlertDialog :negative-text="$t('app.close')" name="errorModal" ref="errorModal">
      <i18n-t keypath="subscription-holiday-service.error.message">
        <template #contactForm>
          <router-link to="/contact" class="link"
          >{{ $t("app.contact-form") }}
          </router-link>
        </template>
      </i18n-t>
    </AlertDialog>
    <AlertDialog :negative-text="$t('app.close')" name="successModal" ref="successModal">
      <span
        v-html="
          $t('subscription-holiday-service.success.message', {
            selectedService: selectedServiceLabel,
          })
        "
      />
    </AlertDialog>
    <AlertDialog
      :negative-text="$t('app.close')"
      name="cancelSuccessModal"
      ref="cancelSuccessModal"
    >
      {{ $t("subscription-holiday-service.cancel-success.message") }}
    </AlertDialog>
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive, ref, toRefs, watch } from 'vue'
import BackButton from "@/components/BackButton.vue";
import PageTitle from "@/components/PageTitle.vue";
import Alert from "@/components/Alert.vue";
import Spinner from "@/components/Spinner.vue";
import BusinessPartnerAddress from "@/components/business-partner/BusinessPartnerAddress.vue";
import ButtonBar from "@/components/ButtonBar.vue";
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import FormSelect from "@/components/form/FormSelect.vue";
import BusinessPartnerAddressForm from "@/components/business-partner/BusinessPartnerAddressForm.vue";
import IssueLabel from "@/components/IssueLabel.vue";
import { BusinessPartner, cloneBusinessPartnerWithDefaults } from "@/models/businessPartner";
import { Issue } from "@/models/issue";
import { formatISO, isBefore } from "date-fns";
import { Subscription } from "@/models/subscription";
import { useRoute } from "vue-router";
import HolidayDateRangePicker from "@/pages/subscription/holiday-service/HolidayDateRangePicker.vue";
import SimpleIssueList from "@/components/SimpleIssueList.vue";
import { DateUtils } from "@/util/date";
import AlertDialog from "@/components/AlertDialog.vue";
import { HolidayServiceType } from "@/models/holidayServiceType";
import { DateRange } from "@/models/dateRange";
import { useAxios } from "@/plugins/axios";
import { useOrders, useSubscription } from "@/pinia/orders";
import RequiredFieldsHint from "@/components/RequiredFieldsHint.vue";
import { useTenant } from "@/plugins/tenant";
import { useI18n } from "vue-i18n";
import { useTracker } from "@/plugins/tracker";

enum DateType {
  saleDate = 1,
  issueLabelRunDate = 2,
  publicationDate = 3,
}

interface HolidayService {
  type: HolidayServiceType;
  fromIssueNumber: string;
  toIssueNumber: string;
  address?: BusinessPartner;
  isCancellable: boolean;
}

interface HolidayServiceState {
  isSubmitting: boolean
  dateRange: DateRange,
  issuesLoading: boolean,
  issues: Issue[] | null,
  redirectBusinessPartner: BusinessPartner
  selectedService: HolidayServiceType | null
  hasPlannedHolidayService: boolean
  plannedHolidayService: HolidayService | null
}


const route = useRoute()
const axios = useAxios()
const orders = useOrders()
const tenant = useTenant()
const tracker = useTracker()
const { tm } = useI18n()

const subscriptionId = route.params.id as string
const { subscription, subscriptionLoading } = useSubscription(subscriptionId)

const confirmCancelModal = ref(null)
const confirmModal = ref(null)

const state = reactive<HolidayServiceState>({
  isSubmitting: false,
  dateRange: {
    start: null,
    end: null,
  },
  issuesLoading: false,
  issues: null,
  redirectBusinessPartner: cloneBusinessPartnerWithDefaults({}),
  selectedService: null,
  hasPlannedHolidayService: false,
  plannedHolidayService: null
})

const {
  isSubmitting,
  dateRange,
  issuesLoading,
  issues,
  redirectBusinessPartner,
  selectedService,
  hasPlannedHolidayService,
  plannedHolidayService,
} = toRefs(state)

const errorModal = ref<InstanceType<typeof AlertDialog> | null>(null)
const successModal = ref<InstanceType<typeof AlertDialog> | null>(null)
const cancelSuccessModal = ref<InstanceType<typeof AlertDialog> | null>(null)

const maxDurationWeeks = computed(() => {
  return tenant.getHolidayServiceMaxDuration(
    subscription.value.publication
  );
})

const supportsHolidayService = computed(() => {
  return (
    tenant.supportsHolidayService(subscription.value.publication) &&
    subscription.value.supportsHolidayService()
  )
})

const selectedServiceLabel = computed(() => {
  if (selectedService.value === null) {
    return "";
  }
  // @ts-ignore
  return tm("subscription-holiday-service.title")[
    selectedService.value
    ];
})

const plannedServiceLabel = computed(() => {
  // @ts-ignore
  return tm("subscription-holiday-service.title")[
    plannedHolidayService.value!.type
    ];
})

const supportedHolidayServices = computed(() => {
  const translations = tm("subscription-holiday-service.title");
  const services = [];
  if (tenant.supportsHolidayRedirect()) {
    services.push({
      // @ts-ignore
      name: translations[HolidayServiceType.Redirect],
      value: HolidayServiceType.Redirect,
    });
  }
  if (tenant.supportsHolidayBreak()) {
    services.push({
      // @ts-ignore
      name: translations[HolidayServiceType.Break],
      value: HolidayServiceType.Break,
    });
  }
  if (tenant.supportsHolidayDownload()) {
    services.push({
      // @ts-ignore
      name: translations[HolidayServiceType.Download],
      value: HolidayServiceType.Download,
    });
  }
  return services;
})

const minDate = computed(() => {
  const currentDate = new Date();
  // start from the next day
  currentDate.setDate(currentDate.getDate() + 1);
  return currentDate;
})

const isSubmitDisabled = computed(() => {
  return (
    isSubmitting.value ||
    !(issues.value && issues.value.length) ||
    (issues.value &&
      issues.value.length === 1 &&
      isNextIssueAlreadyShipped.value)
  );
})

const nextIssue = computed(() => {
  return issues.value && issues.value.length ? issues.value[0] : null;
})

const lastIssue = computed(() => {
  return issues.value && issues.value.length
    ? issues.value[issues.value.length - 1]
    : null;
})

const isNextIssueAlreadyShipped = computed(() => {
  return (
    nextIssue.value !== null && isBefore(nextIssue.value!.dateOfFirstShipping, new Date())
  );
})

const hasSelectedRedirect = computed(() => {
  return selectedService.value === HolidayServiceType.Redirect;
})

const linkToChangeAddress = computed(() => {
  return {
    name: "subscription-business-partners",
    params: {
      id: subscription.value.id,
    },
  };
})


async function fetchIssues(startDate: Date, endDate: Date, dateType: DateType) {
  const response = await axios.get<Array<Issue>>(
    `/subscriptions/${subscriptionId}/issues`,
    {
      params: {
        dateFrom: formatISO(startDate, {
          representation: "date",
        }),
        dateTo: formatISO(endDate, { representation: "date" }),
        dateType: dateType,
      },
    }
  );
  return response.data;
}

async function initPlannedHolidayRedirect(subscription: Subscription) {
  // this is the only case where we need to fetch the issues manually
  hasPlannedHolidayService.value = true;
  try {
    const holidayAddress = subscription.findActiveHolidayAddress()!;
    const issues = await fetchIssues(
      holidayAddress.validFrom!,
      holidayAddress.validTo!,
      DateType.saleDate
    );
    plannedHolidayService.value = {
      type: HolidayServiceType.Redirect,
      fromIssueNumber: issues[0].issueNumber,
      toIssueNumber: issues[issues.length - 1].issueNumber,
      address: holidayAddress,
      // cancellable if the first issue in that interval has not been shipped yet
      isCancellable: isBefore(new Date(), issues[0].dateOfFirstShipping),
    };
  } catch (e) {
    console.log(e);
  }
}

async function initPlannedMixTypeChange(subscription: Subscription) {
  hasPlannedHolidayService.value = true;
  plannedHolidayService.value = {
    type: HolidayServiceType.Download,
    fromIssueNumber: subscription.currentChangeInfo!
      .futureMixTypeFromIssueNr,
    toIssueNumber: subscription.currentChangeInfo!.futureMixTypeToIssueNr!,
    isCancellable: false,
  };
}

async function initPlannedSuspension(subscription: Subscription) {
  hasPlannedHolidayService.value = true;
  plannedHolidayService.value = {
    type: HolidayServiceType.Break,
    fromIssueNumber: subscription.currentSuspension!.suspendedFromIssueNr!,
    toIssueNumber: subscription.currentSuspension!.suspendedToIssueNr!,
    // TODO: when is this still cancellable? if the first issue has not been shipped yet?
    isCancellable: !subscription.isSuspended(),
  };
}

watch(() => subscription.value, (subscription) => {
  if (!subscription) {
    return;
  }
  redirectBusinessPartner.value = cloneBusinessPartnerWithDefaults(
    subscription.goodsRecipient,
    true
  );
  if (subscription.hasPlannedHolidayRedirect()) {
    initPlannedHolidayRedirect(subscription);
  } else if (subscription.hasPlannedMixTypeChange()) {
    initPlannedMixTypeChange(subscription);
  } else if (subscription.hasPlannedSuspension()) {
    initPlannedSuspension(subscription);
  } else {
    plannedHolidayService.value = null;
    hasPlannedHolidayService.value = false;
  }
}, { immediate: true });

orders.fetchSubscription(subscriptionId)

function resetForm() {
  dateRange.value = {
    end: null,
    start: null,
  };
  issues.value = null;
}

function showSuccessAlert() {
  (successModal.value as any).show();
}

async function fetchUpcomingIssues(startDate: Date, endDate: Date, dateType: DateType) {
  const response = await axios.get<Array<Issue>>(
    `/subscriptions/${subscription.value.id}/upcoming-issues`,
    {
      params: {
        dateFrom: formatISO(startDate, {
          representation: "date",
        }),
        dateTo: formatISO(endDate, { representation: "date" }),
        dateType: dateType,
      },
    }
  );
  return response.data;
}

async function fetchIssuesForSelectedInterval() {
  try {
    issuesLoading.value = true;
    issues.value = await fetchUpcomingIssues(
      dateRange.value.start!,
      dateRange.value.end!,
      DateType.saleDate
    );
  } catch (e) {
    (errorModal.value as any).show();
  } finally {
    issuesLoading.value = false;
  }
}

async function submit() {
  // @ts-ignore
  if (!(await confirmModal.value.showConfirm())) {
    return;
  }
  try {
    isSubmitting.value = true;
    await orders.setupHolidayService({
      subscriptionId: subscription.value.id,
      service: selectedService.value!,
      dateFrom: DateUtils.toUTCDate(dateRange.value.start!)!,
      dateTo: DateUtils.toUTCDate(dateRange.value.end!)!,
      issueNumberFrom: issues.value![0].issueNumber,
      issueNumberTo: issues.value![issues.value!.length - 1].issueNumber,
      businessPartner: hasSelectedRedirect.value
        ? redirectBusinessPartner.value
        : null,
    })
    tracker.trackEvent(
      "Subscription",
      "SetupHolidayService",
      selectedService.value!
    );
    resetForm();
    showSuccessAlert();
  } catch (error) {
    (errorModal.value as any).show();
  } finally {
    isSubmitting.value = false;
  }
}

async function cancelPlannedHolidayService() {
  // @ts-ignore
  if (!(await confirmCancelModal.value.showConfirm())) {
    return;
  }
  try {
    isSubmitting.value = true;
    await orders.cancelHolidayService({
      subscriptionId: subscription.value.id,
      service: plannedHolidayService.value!.type,
    });
    tracker.trackEvent(
      "Subscription",
      "CancelHolidayService",
      selectedService.value!
    );
    (cancelSuccessModal.value as any).show();
  } catch (error) {
    (errorModal.value as any).show();
  } finally {
    isSubmitting.value = false;
  }
}

watch(dateRange, (val) => {
  if (val !== null && val.start !== null && val.end !== null) {
    fetchIssuesForSelectedInterval();
  }
})

if (route.query.service) {
  selectedService.value = route.query.service as HolidayServiceType;
}

</script>
