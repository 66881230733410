import { Subscription } from "@/models/subscription";
import { Download } from "@/models/download";

export interface LinkEmailReward {
  key: string;
  description: string;
  coverImageUrl: string;
  download: Download;
}

export class LinkEmailResult {
  subscription: Subscription;
  rewards: LinkEmailReward[];

  constructor(json: any) {
    this.subscription = new Subscription(json.subscription);
    this.rewards = json.rewards;
  }
}
