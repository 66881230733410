<template>
  <div class="pb-4 md:pb-8">
    <BackButton :subscription="subscription" />
    <div class="py-4 border-t mt-4">
      <PageTitle name="licenses"></PageTitle>
      <p class="mt-4">
        {{ $t("subscription-licenses.heading") }}
      </p>
    </div>
    <Spinner class="small-indicator" v-if="loading" />
    <div v-else class="relative">
      <!-- licenses for START -->
      <div v-for="object in licensesObjects">
        <p class="font-bold underline mt-12">{{object.orderNumber}} ({{object.publicationName}})</p>
        <!-- license manager -->
        <template v-if="config.supportsLicenseManager && object.selfServiceEnabled">
          <h4 class="font-semibold">
            {{ $t("subscription-licenses.license-managers") }}
          </h4>
          <LicenseManagerList
            :business-partners="licenseManagers"
            :show-delete="!subscription.isLicenseManager()"
            @delete="confirmDeleteLicenseManager" />
          <ButtonBar>
            <button
              class="btn btn-primary mt-4 mb-6"
              @click="showAddLicenseManagerModal = true"
            >
              {{
                hasLicenseManager ? $t("subscription-licenses.replace-manager") : $t("subscription-licenses.add-manager")
              }}
            </button>
          </ButtonBar>
        </template>
        <Alert variant="info" icon="info-circle" class="mb-4" v-if="!object.selfServiceEnabled">
          {{ $t('subscription-licenses.self-service-disabled') }}
          <template #action>
            <router-link
              :to="{
                name: 'contact',
                query: { subscriptionId: subscription.id, activity: config.selfServiceContactActivity },

              }"
              class="btn btn-border ml-4"
            >
              {{ $t('app.to-contact') }}
            </router-link>
          </template>
        </Alert>

        <!-- active licenses -->
        <h4 class="font-semibold">
          {{ $t("subscription-licenses.active-licenses") }} ({{
            getActiveLicenses(object).length
          }}/{{ object.maxActiveLicenses }})
        </h4>
        <MultiUserLicenseList
          :licenses="getActiveLicenses(object)"
          :object="object"
          :show-delete="config.allowDelete"
          :show-title-academic="config.requireTitleDuringAccept"
          :show-actions="object.selfServiceEnabled"
          @deactivate="deactivateLicense"
          @delete="confirmDelete"
          @invite="confirmInvite"
          @show-details="showLicenseDetails"
        ></MultiUserLicenseList>
        <ButtonBar v-if="object.selfServiceEnabled">
          <button
            class="btn btn-primary mt-4"
            @click="showAddModal = true; chosenOrderNumber = object.orderNumber"
            :disabled="addDisabled(object)"
          >
            {{ $t("subscription-licenses.add") }}
          </button>
        </ButtonBar>
        <AddLicenseDialog
          v-model="showAddModal"
          :salutations="salutations"
          :config="config"
          :loading="adding"
          :object="object"
          @submit="addLicense"
        ></AddLicenseDialog>

        <!-- inactive licenses -->
        <h4 class="font-semibold mt-6">
          {{ $t("subscription-licenses.inactive-licenses") }}
        </h4>
        <MultiUserLicenseList
          :licenses="getInactiveLicenses(object)"
          :object="object"
          :show-delete="config.allowDelete"
          :show-title-academic="config.requireTitleDuringAccept"
          :show-actions="object.selfServiceEnabled"
          @activate="activateLicense"
          @delete="confirmDelete"
          @show-details="showLicenseDetails"
        ></MultiUserLicenseList>
      </div>
      <!-- licenses for END -->

      <div
        v-if="updating"
        class="absolute inset-0 bg-white opacity-50 flex flex-col justify-center content-center"
      >
        <Spinner no-margin></Spinner>
      </div>
    </div>
    <AlertDialog
      :loading="deleting"
      :positive-text="$t('subscription-licenses.delete')"
      :negative-text="$t('app.cancel')"
      disable-auto-hide
      name="confirm-delete"
      ref="confirmDeleteModal"
    >
      <template #title>
        {{ $t('subscription-licenses.delete') }}
      </template>
      <i18n-t
        tag="span"
        keypath="subscription-licenses.confirm-delete"
        v-if="licenseToDelete"
      >
        <template #name>
          <b>
            {{ licenseToDelete.businessPartner.firstName }}
            {{ licenseToDelete.businessPartner.lastName1 }}
          </b>
        </template>
      </i18n-t>
    </AlertDialog>
    <AlertDialog
      :loading="deleting"
      :positive-text="$t('subscription-licenses.delete-manager')"
      :negative-text="$t('app.cancel')"
      disable-auto-hide
      name="confirm-delete-license-manager"
      ref="confirmDeleteLicenseManagerModal"
    >
      <template #title>
        {{ $t('subscription-licenses.delete-manager') }}
      </template>
      <i18n-t
        tag="span"
        keypath="subscription-licenses.confirm-delete-license-manager"
        v-if="licenseManagerToDelete"
      >
        <template #name>
          <b>
            {{ licenseManagerToDelete.firstName }}
            {{ licenseManagerToDelete.lastName1 }}
          </b>
        </template>
      </i18n-t>
    </AlertDialog>
    <AlertDialog
      :loading="sendingInvitation"
      :positive-text="$t('subscription-licenses.send-invitation')"
      :negative-text="$t('app.cancel')"
      disable-auto-hide
      name="confirm-send-invitation"
      ref="confirmSendInvitationModal"
    >
      <i18n-t
        keypath="subscription-licenses.confirm-send-invitation"
        v-if="licenseToSendInvitationTo !== null"
      >
        <template #email>
          <b>{{ confirmInvitationEmail }}</b>
        </template>
      </i18n-t>
    </AlertDialog>
    <AddLicenseManagerDialog
      v-model="showAddLicenseManagerModal"
      :loading="adding"
      :replace="hasLicenseManager"
      :salutations="salutations"
      @submit="addLicenseManager"
    ></AddLicenseManagerDialog>
    <LicenseDetailsDialog
      :subscription="subscription"
      :orderNumber="chosenOrderNumber"
      :license="selectedLicense"
      :show-confirmed-status="config.showConfirmedStatus"
      :show-role="config.requireRoleDuringAccept"
      :show-address="config.showAddress"
    ></LicenseDetailsDialog>
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive, ref, toRefs } from 'vue'
import BackButton from "@/components/BackButton.vue";
import PageTitle from "@/components/PageTitle.vue";
import Spinner from "@/components/Spinner.vue";
import ButtonBar from "@/components/ButtonBar.vue";
import { MultiUserLicense } from "@/models/multiUserLicense";
import { MultiUserLicenseObject } from "@/models/multiUserLicenseObject";
import { BusinessPartner, extractEmail } from "@/models/businessPartner";
import MultiUserLicenseList from "@/pages/subscription/licenses/MultiUserLicenseList.vue";
import AlertDialog from "@/components/AlertDialog.vue";
import { useRoute } from "vue-router";
import { useSubscription } from "@/pinia/orders";
import { useAxios } from "@/plugins/axios";
import { useVfm } from "@/plugins/vfm";
import LicenseManagerList from "@/pages/subscription/licenses/LicenseManagerList.vue";
import { useTenant } from "@/plugins/tenant";
import LicenseDetailsDialog from "@/pages/subscription/licenses/LicenseDetailsDialog.vue";
import AddLicenseDialog, { AddLicenseFormData } from "@/pages/subscription/licenses/AddLicenseDialog.vue";
import AddLicenseManagerDialog from "@/pages/subscription/licenses/AddLicenseManagerDialog.vue";
import Alert from "@/components/Alert.vue";
import ErrorModal from "@/components/ErrorModal.vue";

interface State {
  licenseManagerToDelete: BusinessPartner | null,
  licenseToDelete: MultiUserLicense | null
  licenseToSendInvitationTo: MultiUserLicense | null
  selectedLicense: MultiUserLicense | null,
  deleting: boolean
  sendingInvitation: boolean
  adding: boolean
  updating: boolean
  showAddModal: boolean
  showAddLicenseManagerModal: boolean
  chosenOrderNumber: string
}


const route = useRoute()
const axios = useAxios()
const vfm = useVfm()
const tenant = useTenant()

const config = tenant.multiUserLicensesConfig

const subscriptionId = route.params.id as string
const { subscription, subscriptionLoading } = useSubscription(subscriptionId)

const licensesLoading = ref(false)
const licensesObjects = ref<MultiUserLicenseObject[]>([])
const licenseManagers = ref<BusinessPartner[]>([])

const confirmSendInvitationModal = ref(null)
const confirmDeleteLicenseManagerModal = ref(null)
const confirmDeleteModal = ref(null)
const showAddLicenseManagerModal = ref(false)

const state = reactive<State>({
  licenseManagerToDelete: null,
  licenseToDelete: null,
  licenseToSendInvitationTo: null,
  selectedLicense: null,
  deleting: false,
  sendingInvitation: false,
  adding: false,
  updating: false,
  showAddModal: false,
  showAddLicenseManagerModal: false,
  chosenOrderNumber: ''
})

const {
  licenseManagerToDelete,
  licenseToDelete,
  licenseToSendInvitationTo,
  selectedLicense,
  deleting,
  sendingInvitation,
  adding,
  updating,
  showAddModal,
  chosenOrderNumber,
} = toRefs(state)

const loading = computed(() => subscriptionLoading.value || licensesLoading.value)
const hasLicenseManager = computed(() => licenseManagers.value.length !== 0)



function getActiveLicenses(object: MultiUserLicenseObject) {
  return object.licenses!.filter((license) => license.isActive);
}

function getInactiveLicenses(object: MultiUserLicenseObject) {
  return object.licenses!.filter((license) => !license.isActive);
}

function getRemainingLicenses(object: MultiUserLicenseObject) {
  return object.maxActiveLicenses - getActiveLicenses(object).length;
}

function addDisabled(object: MultiUserLicenseObject) {
  return getRemainingLicenses(object) <= 0 || subscription.value.isInactive();
}


const salutations = computed(() => {
  return tenant.salutations.filter(
    (salutation) => salutation.isUserSelectable
  );
})
const confirmInvitationEmail = computed(() => {
  return extractEmail(licenseToSendInvitationTo.value!.businessPartner)!;
})


async function fetchLicenseManagers() {
  const response = await axios.get<BusinessPartner[]>(
    `/subscriptions/${subscriptionId}/license-managers`
  )
  return response.data
}

async function fetchLicenses() {
  const response = await axios.get<MultiUserLicenseObject[]>(
    `/subscriptions/${subscriptionId}/licenses`
  )
  return response.data
}

async function fetchData() {
  try {
    licensesLoading.value = true;
    const [licensesResult, licenseManagersResult] = await Promise.all([
      fetchLicenses(),
      config.supportsLicenseManager ? fetchLicenseManagers() : Promise.resolve([])
    ]);

    licensesObjects.value = licensesResult;
    licenseManagers.value = licenseManagersResult;
  } catch (error) {
    vfm.show({
      component: ErrorModal
    });
  } finally {
    licensesLoading.value = false;
  }
}

async function confirmDelete(license: MultiUserLicense, object: MultiUserLicenseObject) {
  licenseToDelete.value = license;
  const confirmed = await (confirmDeleteModal.value as any).showConfirm();
  if (!confirmed) {
    (confirmDeleteModal.value as any).hide();
    return;
  }
  try {
    deleting.value = true;
    await axios.delete(
      `/subscriptions/${subscription.value.id}/objects/${object.orderNumber}/licenses/${licenseToDelete.value.id}`
    );

    object.licenses!.splice(object.licenses!.indexOf(license), 1);
    (confirmDeleteModal.value as any).hide();
    licenseToDelete.value = null;
  } catch (error) {
    vfm.show({
      component: ErrorModal
    });
  } finally {
    deleting.value = false;
  }
}

async function confirmDeleteLicenseManager(licenseManager: BusinessPartner) {
  licenseManagerToDelete.value = licenseManager;
  const confirmed = await (confirmDeleteLicenseManagerModal.value as any).showConfirm();
  if (!confirmed) {
    (confirmDeleteLicenseManagerModal.value as any).hide();
    return;
  }
  try {
    deleting.value = true;
    await axios.delete(
      `/subscriptions/${subscription.value.id}/license-managers/${licenseManager.bpNumber}`
    );
    licenseManagers.value!.splice(licenseManagers.value!.indexOf(licenseManager), 1);
    (confirmDeleteLicenseManagerModal.value as any).hide();
    licenseManagerToDelete.value = null;
  } catch (error) {
    vfm.show({
      component: ErrorModal
    });
  } finally {
    deleting.value = false;
  }
}

function showLicenseDetails(license: MultiUserLicense, orderNumber: string) {
  selectedLicense.value = Object.assign({}, license)
  chosenOrderNumber.value = orderNumber;
}

async function addLicense(formData: AddLicenseFormData, object: MultiUserLicenseObject) {
  try {
    adding.value = true;
    const response = await axios.post<MultiUserLicense>(
      `/subscriptions/${subscription.value.id}/objects/${chosenOrderNumber.value}/licenses`,
      formData
    );

    object.licenses.push(response.data);
    showAddModal.value = false
  } catch (error) {
    vfm.show({
      component: ErrorModal
    });
  } finally {
    adding.value = false;
  }
}

async function addLicenseManager(businessPartner: BusinessPartner) {
  try {
    adding.value = true;
    const response = await axios.post<BusinessPartner>(
      `/subscriptions/${subscription.value.id}/license-managers`,
      businessPartner
    );
    licenseManagers.value = [response.data];
    showAddLicenseManagerModal.value = false
  } catch (error) {
    vfm.show({
      component: ErrorModal
    });
  } finally {
    adding.value = false;
  }
}

function activateLicense(license: MultiUserLicense, object: MultiUserLicenseObject) {
  updateLicense(license, object, true);
}

function deactivateLicense(license: MultiUserLicense, object: MultiUserLicenseObject) {
  updateLicense(license, object, false);
}

async function updateLicense(license: MultiUserLicense, object: MultiUserLicenseObject, isActive: boolean) {
  try {
    updating.value = true;
    const response = await axios.post<MultiUserLicense>(
      `/subscriptions/${subscription.value.id}/objects/${object.orderNumber}/licenses/${license.id}`,
      { isActive }
    );

    object.licenses![object.licenses!.indexOf(license)] = response.data;
  } catch (error) {
    vfm.show({
      component: ErrorModal
    });
  } finally {
    updating.value = false;
  }
}

async function confirmInvite(license: MultiUserLicense, object: MultiUserLicenseObject) {
  licenseToSendInvitationTo.value = license;
  const confirmed = await (confirmSendInvitationModal.value as any).showConfirm();
  if (!confirmed) {
    (confirmSendInvitationModal.value as any).hide();
    return;
  }

  try {
    sendingInvitation.value = true;
    await axios.post<MultiUserLicense>(
      `/subscriptions/${subscription.value.id}/objects/${object.orderNumber}/licenses/${license.id}/send-invitation`
    );
    (confirmSendInvitationModal.value as any).hide();
  } catch (error) {
    vfm.show({
      component: ErrorModal
    });
  } finally {
    sendingInvitation.value = false;
  }
}

fetchData()

</script>
