<template>
  <div>
    <ul class="grid md:grid-cols-3 gap-4">
      <li
        v-for="reward in rewards"
        :key="reward.key"
        class="p-2 shadow rounded-card border flex flex-col"
      >
        <CoverImage :src="reward.coverImageUrl" class="w-40 mx-auto" />
        <div class="flex-1"></div>
        <p class="font-bold mt-4">
          {{ reward.description }}
        </p>
        <DownloadButton
          class="btn btn-primary w-full mt-4"
          :download="reward.download"
          :disabled="downloadsDisabled"
          @complete="downloadsDisabled = true"
        ></DownloadButton>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { LinkEmailReward } from "@/models/emailWidget";
import CoverImage from "@/components/CoverImage.vue";
import DownloadButton from "@/components/DownloadButton.vue";
import { PropType, ref } from "vue";

defineProps({
  rewards: {
    type: Array as PropType<LinkEmailReward[]>,
    required: true
  }
})

const downloadsDisabled = ref(false)
</script>
