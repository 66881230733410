<template>
  <AlertDialog name="invoice-request-success" :positive-text="$t('app.close')">
    <template #title>
      <font-awesome-icon icon="check-circle" class="text-green-600"></font-awesome-icon>
      <span class="ml-4">{{$t(titleKey)}}</span>
    </template>
    {{ $t(messageKey) }}
  </AlertDialog>
</template>

<script lang="ts" setup>
import { computed, defineComponent, PropType } from 'vue'
import AlertDialog from "@/components/AlertDialog.vue";

const props = defineProps({
  status: {
    type: String as PropType<'success' | 'forwarded' | 'processing'>,
    required: true
  }
})

const titleKey = computed(() => `payments.invoice-request-${props.status}-title`)
const messageKey = computed(() => `payments.invoice-request-${props.status}-message`)
</script>
