<template>
  <span class="group hover:underline cursor-pointer" :class="isShipped ? 'text-success-600' : 'text-gray-500'">
    {{ isShipped ? $t(`purchase-order.shipping-status-shipped`) : $t(`purchase-order.shipping-status-pending`) }}
    <font-awesome-icon icon="info-circle" />
  </span>
</template>
<script lang="ts" setup>
import { computed, defineComponent, PropType } from "vue";
import { PackageInfo, ScanStatus } from "@/models/packageInfo";
import Badge from "@/components/Badge.vue";

const props = defineProps({
  packageInfo: {
    type: Object as PropType<PackageInfo>,
    required: true
  }
})

const isShipped = computed(() => props.packageInfo.scanStatus === ScanStatus.Shipped)
</script>
