import { acceptHMRUpdate, defineStore } from 'pinia'
import { User } from "@/models/user";
import axios from "axios";
import { Subscription } from "@/models/subscription";
import { Publication } from "@/models/publication";
import { BusinessPartnerRole } from "@/models/businessPartner";
import { useOrders } from "@/pinia/orders";
import { usePublications } from "@/pinia/publications";
import { useBusinessPartners } from "@/pinia/businessPartners";
import { useAuthenticatedIssueCalendar } from "@/pinia/authenticatedIssueCalendar";
import * as Sentry from "@sentry/vue";
import { nextTick } from "vue";

export interface AuthState {
  user: User | null;
  accessToken: string | null;
  sessionHasTimedOut: boolean;
  loginSource: LoginSource | null;
}

export enum LoginSource {
  Email = "email",
  Token = "token",
  ContactForm = "contact-form",
  OrderNumber = "order-number",
  SSO = "sso",
}

export interface LoginResponse {
  user: User;
  accessToken: string;
  loginSource: LoginSource;
  subscription?: Subscription;
}

export const useAuth = defineStore('auth', {
  state: (): AuthState => ({
    user: null,
    accessToken: null,
    loginSource: null,
    sessionHasTimedOut: false,
  }),
  actions: {
    async loginUsingToken(token: string): Promise<any> {
      const response = await axios.post<LoginResponse>("/login/token", {
        token: token,
      });
      this._handleLoginSuccess(response.data, true)
    },
    async loginUsingOAuth(params: object): Promise<any> {
      const response = await axios.post<LoginResponse>("/login/oauth", null, {
        params,
      });
      this._handleLoginSuccess(response.data, true)
    },
    async loginUsingDev(email: string, ssoId?: string): Promise<any> {
      const response = await axios.post<LoginResponse>("/login/dev", {
        email: email,
        ssoId: ssoId
      });
      this._handleLoginSuccess(response.data, true)
    },
    async loginUsingOrderNumber(
      payload: {
        orderNumber: string;
        lastName: string;
      }
    ): Promise<Subscription> {
      const response = await axios.post<LoginResponse>(
        "/login/order-number",
        payload
      );
      const subscription = new Subscription(response.data.subscription!);

      this._handleLoginSuccess(response.data)
      return subscription;
    },
    async loginUsingContactForm(
      payload: {
        email: string;
        orderNumber: string;
        zipcode: string;
        publication?: Publication;
        preferredRole?: BusinessPartnerRole;
        optIn?: boolean;
      }
    ): Promise<Subscription> {
      const response = await axios.post<LoginResponse>(
        "/login/contact-form",
        payload
      );
      const subscription = new Subscription(response.data.subscription!);

      this._handleLoginSuccess(response.data)
      return subscription;
    },
    async logOut(sessionTimedOut: boolean = false) {
      // clear the sessionStorage
      try {
        window.sessionStorage.clear();
      } catch (e) {
        // some user have disabled session storage, ignore this error
      }

      // reset all stores
      this.$reset()
      this.sessionHasTimedOut = sessionTimedOut

      Sentry.setUser(null)

      // clear the other store after some delay, in order to prevent any state issues
      await nextTick()
      useOrders().$reset()
      usePublications().$reset()
      useBusinessPartners().$reset()
      useAuthenticatedIssueCalendar().$reset()
    },
    loadFromSession() {
      try {
        const json = window.sessionStorage.getItem("authState");
        if (json) {
          this._handleLoginSuccess(JSON.parse(json) as LoginResponse)
        }
      } catch (e) {
        // unable to load the auth state
        // probably due to some browser security issues
      }
    },
    _handleLoginSuccess(loginResponse: LoginResponse, persist: boolean = false) {
      this.user = loginResponse.user
      this.loginSource = loginResponse.loginSource
      this.accessToken = loginResponse.accessToken
      if (persist) {
        try {
          window.sessionStorage.setItem("authState", JSON.stringify(loginResponse));
        } catch (e) {
          // some user have disabled session storage, ignore this error
        }
      }
      Sentry.setUser({
        id: String(loginResponse.user.id),
        email: loginResponse.user.email,
      })
    }
  },
  getters: {
    isLoggedIn(state) {
      return state.user != null;
    },
    isFullyLoggedIn(state) {
      return (
        state.user != null &&
        (state.loginSource === LoginSource.Email ||
          state.loginSource === LoginSource.Token ||
          state.loginSource === LoginSource.SSO)
      );
    },
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuth, import.meta.hot))
}
