<template>
  <span :title="label">{{ label }}</span>
  <span v-if="showDateInternal"> ({{ formattedDate }})</span>
</template>
<script lang="ts" setup>
import { computed, PropType } from "vue";
import { Issue } from "@/models/issue";

const props = defineProps({
  issue: {
    type: [String, Object] as PropType<string | Issue | null>,
    default: null
  },
  showDate: {
    type: Boolean,
    default: false
  }
})

const label = computed(() => {
  if (props.issue === null) {
    return ''
  }
  return typeof props.issue === "object"
    ? props.issue.description || props.issue.issueNumber
    : props.issue;
})

const showDateInternal = computed(() => props.showDate && typeof props.issue === 'object')
const formattedDate = computed(() => (props.issue as Issue).dateOfFirstSale.toLocaleDateString())
</script>
