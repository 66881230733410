<template>
  <AlertDialog
    name="error-modal"
    :negative-text="$t('app.close')"
    :positive-text="$t('app.to-contact')"
    @positive="positive">
      <template #title>
        <font-awesome-icon icon="exclamation-circle" class="text-red-600"></font-awesome-icon>
        <span class="ml-4">{{ $t('app.an-error-occurred') }}</span>
      </template>
      {{ displayMessage }}
  </AlertDialog>
</template>

<script lang="ts" setup>
import { computed, defineComponent } from 'vue'
import AlertDialog from "@/components/AlertDialog.vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

const props = defineProps({
  message: {
    type: String,
    default: undefined
  }
})

const router = useRouter()
const {t} = useI18n()

const displayMessage = computed(() => props.message ?? t('app.error-message'))

function positive() {
    router.push('/contact')
}
</script>
