<template>
  <label class="block mt-5">
    {{ label }} <span v-if="required" class="text-primary">*</span>
    <textarea
      :value="modelValue as string"
      @input="handleInput"
      class="input mt-1 p-3 border rounded-input block w-full focus:shadow-outline placeholder-gray-500"
      :rows="rows"
      :placeholder="placeholder"
      :required="required"
      :minlength="minLength"
      :maxlength="maxLength"
      :disabled="disabled"
    ></textarea>
  </label>
</template>
<script lang="ts" setup>
import { PropType } from "vue";

const props = defineProps({
  label: String,
  placeholder: String,
  required: Boolean,
  disabled: Boolean,
  minLength: [Number, String],
  maxLength: [Number, String],
  rows: [Number, String],
  modelValue: {
    type: String as PropType<string | null | undefined>,
    default: null
  }
})

const emit = defineEmits(['update:modelValue'])

function handleInput(event: Event) {
  emit('update:modelValue', (event.target! as HTMLInputElement).value)
}
</script>
