<template>
  <li
    class="flex transition-all duration-200 transform border rounded-card shadow hover:-translate-y-1 hover:shadow-lg overflow-hidden"
  >
    <div class="hidden w-1/2 p-2 border-r md:block relative">
      <CoverImage :src="subscription.coverImageUrl" />
      <SubscriptionTypeBadge
        :subscription="subscription"
        small
        class="absolute top-0 left-0 rounded-br-card"
      />
    </div>
    <div class="flex flex-col w-full">
      <div class="flex items-start p-3 md:pb-2">
        <CoverImage
          class="h-28 mr-2 md:hidden"
          :src="subscription.coverImageUrl"
        />
        <div>
          <h3 class="font-bold">{{ subscription.displayName }}</h3>
          <SubscriptionTypeBadge
            :subscription="subscription"
            small
            class="inline md:hidden"
          />
        </div>
      </div>
      <dl class="grid grid-cols-2 gap-1 mx-3 text-sm leading-tight md:px-0">
        <template v-if="!isBundleSlave">
          <dt>{{ $t("subscription-detail.status") }}:</dt>
          <dd class="text-right">
            <OrderStatusLabel :subscription="subscription" />
          </dd>
        </template>
        <template
          v-if="
            subscription.validToOnSaleDate !== null && !subscription.isRenewal
          "
        >
          <dt data-testid="valid-until-label">
            {{ $t("subscription-detail.valid-until") }}:
          </dt>
          <dd class="text-right" data-testid="expiry-date">
            {{ $d(subscription.validToOnSaleDate!) }}
          </dd>
        </template>
        <template v-if="subscription.hasNextIssue()">
          <dt>{{ $t("subscription-detail.next-issue") }}:</dt>
          <dd class="text-right">
            <IssueLabel :issue="subscription.nextIssue!" show-date></IssueLabel>
          </dd>
        </template>
        <template
          v-if="showExpiry"
        >
          <dt data-testid="valid-until-label">
            {{
              $t(subscription.isTimebased ? 'subscription-detail.active-until' : 'subscription-detail.expiry-issue')
            }}:
          </dt>
          <dd class="text-right truncate">
            <IssueLabel :issue="subscription.renewal!.expiryDesc || subscription.renewal!.expiryIssueNr"></IssueLabel>
          </dd>
        </template>
        <template v-if="!isBundleSlave && subscription.hasActiveAutoRenewal()">
          <dt>{{ $t("subscription-detail.auto-renewal") }}:</dt>
          <dd class="text-right">
            <Checkmark :value="true"></Checkmark>
          </dd>
        </template>
        <template
          v-if="
            !isBundleSlave &&
            subscription.isOrderingParty() &&
            subscription.hasDifferingGoodsRecipient()
          "
        >
          <dt>{{ $t("subscription-detail.goods-recipient") }}:</dt>
          <dd class="text-right truncate">
            <BusinessPartnerName
              :business-partner="subscription.goodsRecipient"
            ></BusinessPartnerName>
          </dd>
        </template>
      </dl>
      <div class="flex-1"></div>
      <div class="flex m-3 justify-end">
        <DownloadButton
          v-if="showDownload"
          :subscription="subscription"
          class="btn mr-2 btn-primary"
        ><span class="ml-2">{{
            $t("subscription-detail.e-paper")
          }}</span></DownloadButton
        >
        <CustomizableButton
          v-if="!isBundleSlave"
          :to="{
            name: 'subscription',
            params: { id: subscription.id },
          }"
          tag="router-link"
          name="manage"
          class="block btn btn-primary"
        ></CustomizableButton>
      </div>
    </div>
  </li>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { Subscription } from "@/models/subscription";
import CustomizableButton from "@/components/CustomizableButton.vue";
import CoverImage from "@/components/CoverImage.vue";
import SubscriptionTypeBadge from "@/components/SubscriptionTypeBadge.vue";
import IssueLabel from "@/components/IssueLabel.vue";
import OrderStatusLabel from "@/components/OrderStatusLabel.vue";
import Checkmark from "@/components/Checkmark.vue";
import DownloadButton from "@/components/DownloadButton.vue";
import BusinessPartnerName from "@/components/business-partner/BusinessPartnerName.vue";
import { useTenant } from "@/plugins/tenant";

const props = defineProps({
  subscription: {
    type: Subscription,
    required: true
  }
})

const tenant = useTenant()

const isBundleSlave = computed(() => props.subscription.isBundleSlave())

const showDownload = computed(() => {
  return tenant.supportsIssueDownload() && (
      props.subscription.isActiveStrict() || (
        props.subscription.isInactiveWithKnownActivationDate() && tenant.downloadConfig.showDownloadForSubsWithKnownActivationDate))
    && props.subscription.supportsDownload()
})

const showExpiry = computed(() => {
  return props.subscription.isActive() &&
    !isBundleSlave.value &&
    props.subscription.isRenewal &&
    !props.subscription.renewal!.autoRenewal
})
</script>
