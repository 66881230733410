<template>
  <div>
    <template v-if="auth.isFullyLoggedIn">
      <BackButton :subscription="subscription" />
      <div class="border-t my-4"></div>
    </template>
    <div>
      <PageTitle name="cancellation"></PageTitle>
      <p class="mt-4">
        {{ $t("subscription-cancellation.heading") }}
      </p>
    </div>
    <Spinner class="small-indicator" v-if="loading" />
    <Alert v-else-if="alreadyCancelled" icon="check-circle" variant="success" class="mt-4">
      {{ $t("subscription-cancellation.already-cancelled") }}
    </Alert>
    <Alert v-else-if="isManagedExternally" icon="exclamation-circle" variant="error" class="mt-4">
      <i18n-t keypath="subscription-cancellation.not-possible-managed-externally">
        <template #orderNumber>
          {{ subscription.orderNumber }}
        </template>
      </i18n-t>
    </Alert>
    <form
      v-else-if="canCancel"
      ref="form"
      @submit.prevent="confirmCancellation"
      class="w-full text-left mt-4"
    >
      <SubscriptionDetails :subscription="subscription"/>
      <div
        class="bg-gray-50 border rounded-card border-primary p-4 mt-4"
        v-if="tenant.cancellationConfig.benefitsHtml"
      >
        <h3 class="font-display font-bold text-lg">
          {{ $t("subscription-cancellation.lost-benefits") }}:
        </h3>
        <div
          class="mt-4 prose prose--hard-break"
          v-html="tenant.cancellationConfig.benefitsHtml"
        ></div>
      </div>
      <div>
        <FormSelect
          :label="$t('subscription-cancellation.reason.label')"
          :placeholder="$t('subscription-cancellation.reason.placeholder')"
          :options="cancellationReasons"
          value-key="cancelReason"
          name-key="longDescription"
          return-object
          :model-value="selectedCancellationReason"
          :disabled="cancellationInfosLoading || submitting"
          @update:modelValue="handleReasonChange"
          required
        ></FormSelect>
        <Spinner v-if="cancellationInfosLoading"></Spinner>
        <div v-else-if="cancellationInfos">
          <template v-if="cancellationInfos.cancellable">
            <div v-if="showCancelForm">
              <FormSelect
                :label="$t('subscription-cancellation.date.label')"
                :name-formatter="formatIssueName"
                value-key="issue"
                :options="cancellationInfos.issues"
                :disabled="submitting"
                v-model="selectedIssue"
                required
                return-object
              ></FormSelect>
              <FormTextarea
                v-if="$tenant.cancellationConfig.showFeedbackForm"
                :label="$t('subscription-cancellation.feedback.label')"
                max-length="255"
                class="mt-4"
                v-model="feedback"
              >
              </FormTextarea>
            </div>
            <RecommendationPicker
              v-else
              :title="$t('subscription-cancellation.our-offers')"
              :recommendations="cancellationInfos.recommendations"
              v-model="selectedRecommendation"
            >
            </RecommendationPicker>
          </template>
          <template v-else>
            <dl class="mt-5">
              <dt class="font-semibold inline-block">
                {{ $t("subscription-cancellation.suggested-cancellation-date") }}:
              </dt>&nbsp;<dd class="inline-block">
              {{$t('subscription-cancellation.earliest-possible-date')}}
            </dd>
            </dl>
            <FormTextarea
              :label="$t('subscription-cancellation.reason-text.label')"
              max-length="255"
              class="mt-4"
              required
              v-model="feedback"
            >
            </FormTextarea>
            <p class="hint">
              {{ $t('subscription-cancellation.reason-text.hint') }}
            </p>
          </template>
        </div>
        <div
          class="flex my-20 justify-center items-center flex-col"
          v-else
        >
          <font-awesome-icon
            icon="mouse-pointer"
            class="text-gray-200 w-64"
            size="3x"
          ></font-awesome-icon>
          <p class="text-gray-500 mt-4 text-center">
            {{ $t('subscription-cancellation.select-reason-to-continue') }}
          </p>
        </div>
        <ButtonBar class="mt-4 gap-2">
          <ButtonWithLoadingIndicator
            type="submit"
            class="btn btn-primary"
            :loading="submitting"
            :disabled="submitDisabled"
            v-if="showCancelButton"
          >
            {{ $t("subscription-cancellation.confirm") }}
          </ButtonWithLoadingIndicator>
          <ButtonWithLoadingIndicator
            v-else
            type="button"
            @click="showRecommendationConfirmDialog"
            class="btn btn-primary"
            :loading="submitting"
          >{{ $t("subscription-cancellation.take-offer") }}
          </ButtonWithLoadingIndicator>
          <button
            class="btn btn-border"
            type="button"
            @click="$router.back()"
          >
            {{ $t("subscription-cancellation.abort") }}
          </button>
          <div class="hidden md:block flex-1"></div>
          <button
            v-if="!showCancelButton"
            class="btn btn-border"
            type="button"
            @click="hideRecommendations"
          >
            {{ $t("subscription-cancellation.reject-offers") }}
          </button>
          <button
            v-if="showCancelFormOverride"
            class="btn btn-border"
            type="button"
            @click="showRecommendations"
          >
            {{ $t("subscription-cancellation.show-offers") }}
          </button>
        </ButtonBar>
        <router-link
          v-if="$tenant.supportsHolidayBreak()"
          :to="linkToSubscriptionPause"
          class="link text-xs underline mt-4"
        >
          {{ $t("subscription-cancellation.pause-subscription") }}
        </router-link>
      </div>
    </form>
    <Alert v-else variant="error" class="mt-4">
      {{ $t("subscription-cancellation.not-possible") }}
    </Alert>
    <AlertDialog
      ref="recommendationConfirm"
      name="recommendationConfirm"
      :negative-text="$t('app.cancel')"
      :positive-text="$t('app.yes')"
      @positive="acceptRecommendation"
      click-to-close
    >
      <template #title>
        {{ $t("subscription-cancellation.recommendation-confirm-title") }}
      </template>
      {{ $t("subscription-cancellation.recommendation-confirm") }}
    </AlertDialog>
    <AlertDialog
      ref="recommendationSuccess"
      name="recommendationSuccess"
      :negative-text="$t('app.back')"
      @negative="$router.back()"
    >
      <template #title>
        {{ $t("subscription-cancellation.recommendation-success-title") }}
      </template>
      {{ $t("subscription-cancellation.recommendation-success") }}
    </AlertDialog>
    <AlertDialog
      ref="cancellationSuccess"
      name="cancellationSuccess"
      :negative-text="$t('app.back')"
      @negative="$router.back()"
    >
      <template #title>
        {{ $t("subscription-cancellation.cancellation-success-title") }}
      </template>
      {{ $t("subscription-cancellation.cancellation-success") }}
    </AlertDialog>
    <PopupWindow
      ref="popup"
      v-if="showPopup"
      :url="popupWindowUrl"
      :placeholder="$t('subscription-cancellation.please-wait')"
      :title="$t('subscription-cancellation.popup-window-title')"
      target="cancellation"
      with-overlay
      @message="handlePopupMessage"
      @close="handlePopupClose"
    ></PopupWindow>
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive, ref, toRefs } from 'vue'
import { CancellationInfos } from "@/models/cancellationInfos";
import { CancelReason } from "@/models/cancelReason";
import { Recommendation } from "@/models/recommendation";
import BackButton from "@/components/BackButton.vue";
import FormSelect from "@/components/form/FormSelect.vue";
import Spinner from "@/components/Spinner.vue";
import FormTextarea from "@/components/form/FormTextarea.vue";
import ButtonBar from "@/components/ButtonBar.vue";
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import CancellationRecommendation from "@/pages/subscription/cancellation/CancellationRecommendation.vue";
import PageTitle from "@/components/PageTitle.vue";
import PopupWindow from "@/components/PopupWindow.vue";
import { Subscription } from "@/models/subscription";
import { useRoute } from "vue-router";
import { HolidayServiceType } from "@/models/holidayServiceType";
import AlertDialog from "@/components/AlertDialog.vue";
import Alert from "@/components/Alert.vue";
import { useVfm } from "@/plugins/vfm";
import { useAxios } from "@/plugins/axios";
import { useOrders, useSubscription } from "@/pinia/orders";
import { useAuth } from "@/pinia/auth";
import { useTenant } from "@/plugins/tenant";
import { useTracker } from "@/plugins/tracker";
import CancellationConfirmationDialog from "@/pages/subscription/cancellation/CancellationConfirmationDialog.vue";
import ErrorModal from "@/components/ErrorModal.vue";
import CancellationSuccessModal from "@/components/CancellationSuccessModal.vue";
import { useI18n } from "vue-i18n";
import { Issue } from "@/models/issue";
import SubscriptionDetails from "@/components/SubscriptionDetails.vue";
import RecommendationPicker from "@/components/RecommendationPicker.vue";
import { AcceptRecommendationResult } from "@/models/acceptRecommendationResult";

interface SubscriptionCancellationState {
  cancellationInfos: CancellationInfos | null
  cancellationInfosLoading: boolean
  cancellationReasons: CancelReason[]
  cancellationReasonsLoading: boolean
  selectedCancellationReason: CancelReason | null
  selectedIssue: Issue | null
  showCancelFormOverride: boolean
  feedback: string
  submitting: boolean
  showPopup: boolean
  popupWindowUrl: string
}

const route = useRoute()
const auth = useAuth()
const axios = useAxios()
const tenant = useTenant()
const vfm = useVfm()
const orders = useOrders()
const tracker = useTracker()
const { d, t } = useI18n()

const subscriptionId = route.params.id as string
const { subscription, subscriptionLoading } = useSubscription(subscriptionId)

const state = reactive<SubscriptionCancellationState>({
  cancellationInfos: null,
  cancellationInfosLoading: false,
  cancellationReasons: [],
  cancellationReasonsLoading: false,
  selectedCancellationReason: null,
  selectedIssue: null,
  showCancelFormOverride: false,
  feedback: '',
  submitting: false,
  showPopup: false,
  popupWindowUrl: '',
})

const {
  cancellationInfos,
  cancellationInfosLoading,
  cancellationReasons,
  cancellationReasonsLoading,
  selectedCancellationReason,
  selectedIssue,
  showCancelFormOverride,
  feedback,
  submitting,
  showPopup,
  popupWindowUrl,
} = toRefs(state)

const success = ref(false)
const selectedRecommendation = ref<Recommendation | null>(null)
const loading = computed(() => subscriptionLoading.value || cancellationReasonsLoading.value)
const canCancel = computed(() => cancellationReasons.value.length !== 0)
const alreadyCancelled = computed(() => subscription.value.isCancelled() || subscription.value.isInactive())
const isManagedExternally = computed(() => subscription.value?.isManagedExternally() === true)
const hasRecommendations = computed(() => Boolean(cancellationInfos.value?.recommendations.length))

const linkToSubscriptionPause = computed(() => {
  return {
    name: "subscription-holiday-service",
    params: {
      id: subscription.value.id,
    },
    query: {
      service: HolidayServiceType.Break,
    },
  };
})

const showCancelForm = computed(() => {
  return (
    !tenant.cancellationConfig.supportsRecommendations ||
    (cancellationInfos.value !== null &&
      (showCancelFormOverride.value || cancellationInfos.value!.recommendations.length === 0))
  );
})

const showCancelButton = computed(() => {
  return showCancelFormOverride.value || !hasRecommendations.value
})

const submitDisabled = computed(() => {
  return submitting.value || success.value
})

function formatIssueName(issue: Issue) {
  switch (tenant.cancellationConfig.cancellationDateStyle) {
    case "date":
      return d(issue.dateOfFirstSale);
    case "issue_number":
      return t('subscription-cancellation.to-issue', {
        issue: issue.issueNumber
      })
    case "issue_number_date":
      return t('subscription-cancellation.to-issue', {
        issue: `${issue.issueNumber} (${d(issue.dateOfFirstSale)})`
      })
  }
}

function getFormattedCancellationDate(issue: Issue|null) {
  if (issue === null) {
    return t('subscription-cancellation.earliest-possible-date')
  }
  return formatIssueName(issue)
}

async function loadCancellationInfos() {
  try {
    cancellationInfosLoading.value = true;
    cancellationInfos.value = null
    selectedIssue.value = null
    const response = await axios.get<CancellationInfos>(
      `/subscriptions/${subscriptionId}/cancellation-infos`,
      {
        params: {
          cancellationReason: selectedCancellationReason.value!.cancelReason
        }
      }
    );
    cancellationInfos.value = response.data;
    selectedIssue.value = response.data.issues[0] ?? null
    selectedRecommendation.value = response.data.recommendations[0] ?? null
  } catch (error) {
    vfm.show({
      component: ErrorModal
    });
  } finally {
    cancellationInfosLoading.value = false;
  }
}

async function loadCancellationReasons() {
  try {
    cancellationReasonsLoading.value = true;
    const response = await axios.get<CancelReason[]>(
      `/subscriptions/${subscriptionId}/cancellation-reasons`
    );
    cancellationReasons.value = response.data;
    if (tenant.cancellationConfig.defaultCancelReason !== null) {
      const defaultCancellationReason = response.data.find((cancelReason) => {
        return cancelReason.cancelReason === tenant.cancellationConfig.defaultCancelReason
      });
      if (defaultCancellationReason) {
        selectedCancellationReason.value = defaultCancellationReason
        // noinspection ES6MissingAwait
        loadCancellationInfos()
      }
    }
  } catch (error) {
    vfm.show({
      component: ErrorModal
    });
  } finally {
    cancellationReasonsLoading.value = false;
  }
}

function showCancellationSuccessDialog() {
  vfm.show({
    component: CancellationSuccessModal,
    bind: {
      subscription: subscription.value,
      cancellationInfos: cancellationInfos.value,
      cancellationDate: getFormattedCancellationDate(selectedIssue.value)
    }
  });
}

function showRecommendationConfirmDialog() {
  vfm.show("recommendationConfirm");
}
function showRecommendationSuccessDialog() {
  vfm.show("recommendationSuccess");
}

function handleReasonChange(value: CancelReason) {
  selectedCancellationReason.value = value;
  showCancelFormOverride.value = false;
  loadCancellationInfos();
}

function handlePopupMessage() {
  showPopup.value = false;
  popupWindowUrl.value = "";
  showRecommendationSuccessDialog();
}

function handlePopupClose() {
  showPopup.value = false;
  popupWindowUrl.value = "";
}

async function acceptRecommendation() {
  const recommendation = selectedRecommendation.value!
  submitting.value = true;
  showPopup.value = recommendation.openInNewTab;
  try {
    const response = await axios.post<AcceptRecommendationResult>(
      `/subscriptions/${subscription.value.id}/recommendations/${recommendation.recommendationId}/accept`,
      null,
      {
        params: {
          category: "CANCEL",
          cancelReason: selectedCancellationReason.value!.cancelReason,
          source: 'cancellation'
        },
      }
    );
    const data = response.data;
    // TODO: what if showPopup is false but the service returns an url?
    if (showPopup.value && data.url) {
      popupWindowUrl.value = data.url;
    } else {
      showRecommendationSuccessDialog();
    }
  } catch (error) {
    showPopup.value = false;
    popupWindowUrl.value = "";
    vfm.show({
      component: ErrorModal
    });
  } finally {
    submitting.value = false;
  }
}

function hideRecommendations() {
  showCancelFormOverride.value = true;
}

function showRecommendations() {
  showCancelFormOverride.value = false
}

function confirmCancellation() {
  if (tenant.cancellationConfig.requireConfirmation) {
    vfm.show({
      component: CancellationConfirmationDialog,
      bind: {
        subscription: subscription.value,
        date: getFormattedCancellationDate(selectedIssue.value)
      },
      on: {
        confirm: cancel
      }
    });
  } else {
    cancel()
  }
}

async function cancel() {
  try {
    submitting.value = true;
    await orders.cancelSubscription({
      subscriptionId: subscription.value.id,
      reason: selectedCancellationReason.value!,
      feedback: feedback.value,
      issue: selectedIssue.value,
    });
    tracker.trackEvent(
      "Subscription",
      "Cancel",
      subscription.value.publication
    );
    success.value = true
    showCancellationSuccessDialog();
  } catch (error) {
    vfm.show({
      component: ErrorModal
    });
  } finally {
    submitting.value = false;
  }
}

loadCancellationReasons()
</script>
