<template>
  <div
    class="border rounded-card p-3"
  >
    <dl class="grid grid-cols-2 gap-1">
      <template v-if="subscription">
        <dt class="col-span-2 font-bold">
          {{ subscription.displayName }}
        </dt>
        <dt>{{ $t("issue.number") }}:</dt>
      </template>
      <dd>
        {{$t('issue.issue')}} {{ issue.issueNumber }}
      </dd>
      <template v-if="issue.description">
        <dt>{{ $t("issue.description") }}:</dt>
        <dd>
          {{ issue.description.trim() }}
        </dd>
      </template>
    </dl>
  </div>
</template>
<script lang="ts" setup>
import { CalendarIssue, Issue } from "@/models/issue";
import { computed, PropType } from "vue";

const props = defineProps({
  issue: {
    type: Object as PropType<CalendarIssue|Issue>,
    required: true
  }
})

const subscription = computed(() => (props.issue as CalendarIssue).subscription)
</script>
