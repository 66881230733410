<template>
  <Alert variant="error">
    <i18n-t :keypath="errorMessagePath">
      <template #orderNumber>{{ orderNumber }}</template>
      <template #maskedEmail>{{ email }}</template>
    </i18n-t>
  </Alert>
</template>

<script lang="ts" setup>
import {
  LinkSubscriptionResult,
  LinkSubscriptionStatus,
} from "@/models/linkSubscriptionResult";
import { computed, PropType } from "vue";
import Alert from "@/components/Alert.vue";
import { VerificationMethod } from "@/components/LinkSubscriptionErrorAlertDialog.vue";

const props = defineProps({
  linkSubscriptionResult: Object as PropType<LinkSubscriptionResult | null | undefined>,
  orderNumber: String,
  verificationMethod: {
    type: String as PropType<VerificationMethod>,
    default: 'zipcode'
  },
})

const email = computed(() =>{
  return props.linkSubscriptionResult
    ? props.linkSubscriptionResult.maskedEmail
    : null;
})

const errorMessagePath = computed(() => {
  if (!props.linkSubscriptionResult) {
    return "contact.errors.unknown-error";
  }
  switch (props.linkSubscriptionResult!.status) {
    case LinkSubscriptionStatus.EmailRequired:
      return "contact.errors.email-required";
    case LinkSubscriptionStatus.ConfirmEmail:
      break;
    case LinkSubscriptionStatus.ValidationFailed:
      return "contact.errors.validation-failed";
    case LinkSubscriptionStatus.VerificationFailed:
      return "contact.errors.invalid-" + props.verificationMethod;
    case LinkSubscriptionStatus.NotFound:
      return "contact.errors.subscription-not-found";
    case LinkSubscriptionStatus.NotSupported:
      return "contact.errors.subscription-not-supported";
    case LinkSubscriptionStatus.AlreadyLinked:
      return "contact.errors.subscription-already-linked";
  }
  return "contact.errors.unknown-error";
})
</script>
