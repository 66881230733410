<template>
  <form ref="form" @submit.prevent="submit" class="flex flex-col -mt-5">
    <FormInput
      v-model="formData.email"
      type="email"
      required
      :label="$t('bp-form.email.label')"
    ></FormInput>
    <div class="flex">
      <OrderNumberInput
        class="flex-1"
        v-model="formData.orderNumber"
        :label="$t('email-widget.order-number.label')"
        :error-message="$t('email-widget.order-number.error')"
        required
      />
      <FormInput
        class="w-32 md:w-1/4 ml-4"
        v-model="formData.zipcode"
        :label="$t('link-subscription.form.zipcode.label')"
        max-length="10"
        required
      />
    </div>
    <OrderNumberHint>
      {{ $t("email-widget.order-number.hint") }}
    </OrderNumberHint>
    <FormCheckbox class="mt-6" v-model="formData.optIn" v-if="$tenant.supportsOptIns()">
      {{ $tenant.emailWidgetConfig.optInCheckboxLabel }}
    </FormCheckbox>
    <ButtonBar class="mt-6">
      <ButtonWithLoadingIndicator
        type="submit"
        :loading="submitting"
        class="btn btn-primary font-semibold"
      >
        {{ $t('email-widget.submit') }}
      </ButtonWithLoadingIndicator>
    </ButtonBar>
  </form>
</template>


<script lang="ts">
export default {
  inheritAttrs: false
}
</script>

<script lang="ts" setup>
import { ref } from "vue";
import ButtonBar from "@/components/ButtonBar.vue";
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import FormCheckbox from "@/components/form/FormCheckbox.vue";
import FormInput from "@/components/form/FormInput.vue";
import OrderNumberHint from "@/components/OrderNumberHint.vue";
import OrderNumberInput from "@/components/OrderNumberInput.vue";

const props = defineProps({
  submitting: Boolean
})

const emit = defineEmits(['submit'])

const formData = ref({
  email: "",
  orderNumber: "",
  zipcode: "",
  optIn: false,
})

function submit() {
  emit("submit", formData.value);
}
</script>
