<template>
  <div>
    <form @submit.prevent="submit">
      <FormInput
        v-model="subscriptionForm.email"
        type="email"
        :label="$t('business-partner.email.label')"
        :disabled="subscriptionFormDisabled"
        required
      ></FormInput>
      <i18n-t
        tag="p"
        keypath="cancellation.email-hint"
        class="mt-2 text-gray-700 text-xs"
      >
        <template #contactForm>
          <router-link to="/contact" class="link"
          >{{ $t("app.contact-form") }}
          </router-link>
        </template>
      </i18n-t>
      <div class="flex">
        <OrderNumberInput
          class="flex-1"
          v-model="subscriptionForm.orderNumber"
          :label="$t('cancellation.order-number.label')"
          :error-message="$t('cancellation.order-number.error')"
          :disabled="subscriptionFormDisabled"
          required
        />
        <FormInput
          class="w-32 md:w-1/4 ml-4"
          v-model="subscriptionForm.zipcode"
          :label="$t('link-subscription.form.zipcode.label')"
          :disabled="subscriptionFormDisabled"
          placeholder="34232"
          max-length="10"
          required
        />
      </div>
      <OrderNumberHint>
        {{ $t("cancellation.order-number.hint") }}
      </OrderNumberHint>
      <Alert variant="success" icon="check-circle" class="mt-5" v-if="subscriptionFormDisabled">
        {{ $t('cancellation.correct-message') }}
        <template #action>
          <button
            @click="reset"
            type="button"
            class="btn btn-border ml-4"
          >
            {{ $t('cancellation.correct-action') }}
          </button>
        </template>
      </Alert>
      <Alert variant="info" icon="info-circle" class="mt-5" v-else>
        {{ $t('cancellation.check-message') }}
        <template #action>
          <ButtonWithLoadingIndicator
            id="check-btn"
            type="submit"
            :loading="busy"
            class="btn btn-border ml-4"
          >
            {{ $t('cancellation.check-action') }}
          </ButtonWithLoadingIndicator>
        </template>
      </Alert>
      <div v-if="subscription">
        <FormSelect
          :label="$t('subscription-cancellation.reason.label')"
          :placeholder="$t('subscription-cancellation.reason.placeholder')"
          :options="cancellationReasons"
          value-key="cancelReason"
          name-key="longDescription"
          return-object
          :model-value="cancellationForm.selectedCancellationReason"
          required
          @update:modelValue="handleReasonChange"
        ></FormSelect>
        <div
          v-if="cancellationInfos && !cancellationInfos.cancellable"
        >
          <FormInput
            :label="$t('subscription-cancellation.date.label')"
            :model-value="$t('subscription-cancellation.earliest-possible-date')"
            required
            disabled
          />
          <p class="hint">
            {{ $t('subscription-cancellation.extraordinary-termination-hint') }}
          </p>
        </div>
        <FormSelect
          v-else
          :label="$t('subscription-cancellation.date.label')"
          :placeholder="cancellationInfos === null ? $t('subscription-cancellation.select-reason-to-load') : $t('subscription-cancellation.date.placeholder')"
          :name-formatter="formatIssueName"
          value-key="issue"
          :options="cancellationInfos?.issues ?? []"
          :loading="cancellationInfosLoading"
          :disabled="cancellationInfos === null"
          v-model="cancellationForm.selectedIssue"
          required
          return-object
        ></FormSelect>
      </div>
      <div v-else>
        <FormInput
          :label="$t('subscription-cancellation.reason.label')"
          :model-value="$t('subscription-cancellation.reason.default')"
          disabled
        >
        </FormInput>
        <FormInput
          :label="$t('subscription-cancellation.date.label')"
          :model-value="$t('subscription-cancellation.date.default')"
          disabled
        >
        </FormInput>
      </div>
      <FormTextarea
        v-if="$tenant.cancellationConfig.showFeedbackForm"
        :label="$t('subscription-cancellation.feedback.label')"
        max-length="255"
        class="mt-4"
        v-model="cancellationForm.feedback"
      >
      </FormTextarea>
      <ButtonBar class="mt-6 space-x-2">
        <ButtonWithLoadingIndicator
          type="submit"
          :loading="busy"
          class="btn btn-primary"
        >
          {{ $t('subscription-cancellation.submit.label') }}
        </ButtonWithLoadingIndicator>
      </ButtonBar>
    </form>
    <PopupWindow
      v-if="showPopup"
      :url="popupWindowUrl"
      :placeholder="$t('subscription-cancellation.please-wait')"
      :title="$t('subscription-cancellation.popup-window-title')"
      target="cancellation"
      with-overlay
      @message="handlePopupMessage"
      @close="handlePopupClose"
    ></PopupWindow>
  </div>
</template>

<script lang="ts" setup>

import { useTenant } from "@/plugins/tenant";
import { useAxios } from "@/plugins/axios";
import { useVfm } from "@/plugins/vfm";
import { useOrders } from "@/pinia/orders";
import { useI18n } from "vue-i18n";
import { computed, reactive, ref } from "vue";
import { LinkSubscriptionResult } from "@/models/linkSubscriptionResult";
import { Subscription } from "@/models/subscription";
import { CancelReason } from "@/models/cancelReason";
import { Issue } from "@/models/issue";
import { CancellationInfos } from "@/models/cancellationInfos";
import ErrorModal from "@/components/ErrorModal.vue";
import LinkSubscriptionErrorAlertDialog from "@/components/LinkSubscriptionErrorAlertDialog.vue";
import { useAuth } from "@/pinia/auth";
import FormInput from "@/components/form/FormInput.vue";
import OrderNumberInput from "@/components/OrderNumberInput.vue";
import OrderNumberHint from "@/components/OrderNumberHint.vue";
import ButtonBar from "@/components/ButtonBar.vue";
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import PopupWindow from "@/components/PopupWindow.vue";
import FormSelect from "@/components/form/FormSelect.vue";
import FormTextarea from "@/components/form/FormTextarea.vue";
import Alert from "@/components/Alert.vue";
import { useTracker } from "@/plugins/tracker";
import CancellationSuccessModal from "@/components/CancellationSuccessModal.vue";

const auth = useAuth()
const tenant = useTenant()
const axios = useAxios()
const vfm = useVfm()
const orders = useOrders()
const { d, t } = useI18n()
const tracker = useTracker()

const linkSubscriptionResult = ref<LinkSubscriptionResult | null>(null)
const subscription = ref<Subscription | null>(null)

const subscriptionForm = reactive({
  orderNumber: '',
  zipcode: '',
  email: '',
})

const cancellationForm = reactive({
  selectedCancellationReason: null as CancelReason | null,
  selectedIssue: null as Issue | null,
  feedback: '',
})

const cancellationInfos = ref<CancellationInfos | null>(null)
const cancellationInfosLoading = ref(false)
const cancellationReasons = ref<CancelReason[]>([])
const busy = ref(false)
const showPopup = ref(false)
const popupWindowUrl = ref('')
const success = ref(false)

const subscriptionFormDisabled = computed(() => {
  return subscription.value !== null
})

function showRecommendationSuccessDialog() {
  vfm.show("recommendationSuccess");
}

function handlePopupMessage() {
  showPopup.value = false;
  popupWindowUrl.value = "";
  showRecommendationSuccessDialog();
}

function handlePopupClose() {
  showPopup.value = false;
  popupWindowUrl.value = "";
}

async function loadCancellationReasons(subscription: Subscription) {
  const response = await axios.get<CancelReason[]>(
    `/subscriptions/${subscription.id}/cancellation-reasons`
  );
  return response.data
}

async function checkSubscription() {
  try {
    busy.value = true;
    subscription.value = null
    linkSubscriptionResult.value = null
    cancellationReasons.value = []

    const tempSubscription = await auth.loginUsingContactForm(subscriptionForm)
    if (tempSubscription.isManagedExternally()) {
      vfm.show({
        component: ErrorModal,
        bind: {
          message: t('not-possible-managed-externally')
        }
      });
      return
    }
    const tempCancellationReasons = await loadCancellationReasons(tempSubscription);
    if (tempCancellationReasons.length === 0) {
      // this subscription is not cancellable
      vfm.show({
        component: ErrorModal,
        bind: {
          message: t('cancellation.not-possible')
        }
      });
      return
    }
    cancellationReasons.value = tempCancellationReasons
    if (tenant.cancellationConfig.defaultCancelReason !== null) {
      const defaultCancellationReason = tempCancellationReasons.find((cancelReason) => {
        return cancelReason.cancelReason === tenant.cancellationConfig.defaultCancelReason
      });
      if (defaultCancellationReason) {
        cancellationForm.selectedCancellationReason = defaultCancellationReason
        // noinspection ES6MissingAwait
        loadCancellationInfos(tempSubscription)
      }
    }
    subscription.value = tempSubscription
  } catch (error: any) {
    if (error.response && error.response.status === 400) {
      const linkSubscriptionResult = error.response.data as LinkSubscriptionResult;
      vfm.show({
        component: LinkSubscriptionErrorAlertDialog,
        bind: {
          linkSubscriptionResult: linkSubscriptionResult,
          orderNumber: subscriptionForm.orderNumber,
        }
      })
    } else {
      vfm.show({
        component: ErrorModal,
      });
    }
  } finally {
    busy.value = false;
  }
}

async function loadCancellationInfos(subscription: Subscription) {
  try {
    cancellationInfosLoading.value = true;
    cancellationInfos.value = null
    cancellationForm.selectedIssue = null
    const response = await axios.get<CancellationInfos>(
      `/subscriptions/${subscription.id}/cancellation-infos`,
      {
        params: {
          cancellationReason: cancellationForm.selectedCancellationReason!.cancelReason
        }
      }
    );
    cancellationInfos.value = response.data;
    cancellationForm.selectedIssue = response.data.issues[0] ?? null
  } catch (error) {
    vfm.show({
      component: ErrorModal
    });
  } finally {
    cancellationInfosLoading.value = false;
  }
}

function getFormattedCancellationDate(issue: Issue | null) {
  if (issue === null) {
    return t('subscription-cancellation.earliest-possible-date')
  }
  return formatIssueName(issue)
}

function reset() {
  subscription.value = null
  cancellationReasons.value = []
  cancellationInfos.value = null
  cancellationForm.selectedIssue = null
  cancellationForm.selectedCancellationReason = null
}

async function submit(event: Event) {
  if ((event as SubmitEvent).submitter?.id === 'check-btn') {
    await checkSubscription();
    return;
  }
  try {
    busy.value = true;
    let checkedSubscription = subscription.value;
    if (checkedSubscription === null) {
      // the subscription has not been checked before, so we need to do it here
      checkedSubscription = await auth.loginUsingContactForm(subscriptionForm);
      if (checkedSubscription.isManagedExternally()) {
        vfm.show({
          component: ErrorModal,
          bind: {
            message: t('not-possible-managed-externally')
          }
        });
        return
      }
      // and fetch the cancellation reasons
      const tempCancellationReasons = await loadCancellationReasons(checkedSubscription);
      if (tempCancellationReasons.length === 0) {
        // this subscription is not cancellable
        vfm.show({
          component: ErrorModal,
          bind: {
            message: t('cancellation.not-possible')
          }
        });
        return
      }
      // TODO: what if no default cancellation reason is configured?
      const defaultCancellationReason = tempCancellationReasons.find((cancelReason) => {
        return cancelReason.cancelReason === tenant.cancellationConfig.defaultCancelReason
      });
      if (defaultCancellationReason) {
        cancellationForm.selectedCancellationReason = defaultCancellationReason
      }
    }
    const cancellationInfos = await orders.cancelSubscription({
      subscriptionId: checkedSubscription.id,
      reason: cancellationForm.selectedCancellationReason,
      feedback: cancellationForm.feedback,
      issue: cancellationForm.selectedIssue,
    });
    tracker.trackEvent(
      "Subscription",
      "Cancel",
      checkedSubscription.publication
    );
    vfm.show({
      component: CancellationSuccessModal,
      bind: {
        subscription: checkedSubscription,
        cancellationInfos: cancellationInfos,
        cancellationDate: getFormattedCancellationDate(cancellationForm.selectedIssue)
      }
    });
    reset()
  } catch (error: any) {
    if (error.response && error.response.status === 400) {
      const linkSubscriptionResult = error.response.data as LinkSubscriptionResult;
      vfm.show({
        component: LinkSubscriptionErrorAlertDialog,
        bind: {
          linkSubscriptionResult: linkSubscriptionResult,
          orderNumber: subscriptionForm.orderNumber,
        }
      })
    } else {
      vfm.show({
        component: ErrorModal,
      });
    }
  } finally {
    busy.value = false;
  }
}

function formatIssueName(issue: Issue) {
  switch (tenant.cancellationConfig.cancellationDateStyle) {
    case "date":
      return d(issue.dateOfFirstSale);
    case "issue_number":
      return t('subscription-cancellation.to-issue', {
        issue: issue.issueNumber
      })
    case "issue_number_date":
      return t('subscription-cancellation.to-issue', {
        issue: `${issue.issueNumber} (${d(issue.dateOfFirstSale)})`
      })
  }
}

function handleReasonChange(value: CancelReason) {
  cancellationForm.selectedCancellationReason = value;
  loadCancellationInfos(subscription.value!);
}

</script>
