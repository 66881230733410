<template>
  <form ref="form" @submit.prevent="submit" class="flex flex-col">
    <FormSelect
      :options="contactActivities"
      :label="$t('contact.form.activity.label')"
      :placeholder="$t('contact.form.activity.placeholder')"
      v-model="activity"
      name-key="name"
      value-key="name"
      return-object
      required
    />
    <template v-if="requiresSubscription">
      <FormSelect
        :options="subscriptions"
        :disabled="subscriptionsLoading"
        :loading="subscriptionsLoading"
        v-model="subscriptionId"
        value-key="id"
        :name-formatter="formatSubscriptionName"
        :label="$t('contact.form.subscriptions.label')"
        :placeholder="$t('contact.form.subscriptions.placeholder')"
        required
      />
      <p class="mt-1 text-xs text-gray-700">
        {{ $t("contact.form.subscriptions.hint") }}
      </p>
    </template>
    <Alert
      class="mt-5"
      v-if="showInfoMessage"
      :variant="activity!.infoMessageType"
    >
      <div class="prose prose--hard-break" v-html="formattedInfoMessage"></div>
    </Alert>
    <FormTextarea
      v-if="showMessageInput"
      :label="$t('contact.form.message.label')"
      :placeholder="$t('contact.form.message.placeholder')"
      required
      :multiline="true"
      v-model="message"
    >
    </FormTextarea>
    <template v-if="showImageUpload">
      <FormFileInput
        class="mt-5"
        :label="$t('contact.form.image.label')"
        v-model="images"
        accept="image/png, image/jpeg, application/pdf"
        :max-file-size="2097152"
        type="file"
      >
      </FormFileInput>
      <p class="hint mt-2">
        {{ $t("contact.form.image.hint") }}
      </p>
    </template>
    <FormCheckbox
      v-if="showOptInCheckbox"
      class="mt-5"
      v-model="optIn"
      :label="optInCheckboxLabel"
    >
    </FormCheckbox>
    <ButtonBar class="mt-5">
      <ButtonWithLoadingIndicator
        type="submit"
        :loading="loading"
        :disabled="!activity"
        class="btn btn-primary font-semibold"
      >
        <span v-if="showMessageInput">
          {{ $t("contact.form.submit.label") }}
        </span>
        <span v-else>
          {{ $t("contact.form.continue.label") }}
        </span>
      </ButtonWithLoadingIndicator>
      <button
        v-if="showMessageInputOverrideButton"
        type="button"
        class="btn ml-2"
        @click="showMessageInputOverride = true"
      >
        {{ $t("contact.form.compose-message.label") }}
      </button>
    </ButtonBar>
    <AlertDialog name="success" :negative-text="$t('app.close')">
      {{ $t("contact.success") }}
    </AlertDialog>
    <AlertDialog name="error" :negative-text="$t('app.close')">
      {{ $t("contact.error") }}
    </AlertDialog>
  </form>
</template>
<script lang="ts" setup>
import ButtonWithLoadingIndicator from "@/components/ButtonWithLoadingIndicator.vue";
import FormSelect from "@/components/form/FormSelect.vue";
import ButtonBar from "@/components/ButtonBar.vue";
import {
  ContactActivity,
  ContactActivityVisibility,
} from "@/models/contactActivity";
import FormTextarea from "@/components/form/FormTextarea.vue";
import { Subscription } from "@/models/subscription";
import { formatInfoMessage } from "@/util/templates";
import AlertDialog from "@/components/AlertDialog.vue";
import FormFileInput from "@/components/form/FormFileInput.vue";
import { fileToBase64 } from "@/util/file";
import Alert from "@/components/Alert.vue";
import { computed, reactive, ref, toRefs, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useTenant } from "@/plugins/tenant";
import { useOrders } from "@/pinia/orders";
import FormCheckbox from "@/components/form/FormCheckbox.vue";
import pupa from "pupa";
import { useVfm } from "@/plugins/vfm";
import { useAxios } from "@/plugins/axios";
import { useI18n } from "vue-i18n";

interface State {
  activity: ContactActivity | null
  subscriptionId: string | null
  message: string | null
  images: File[] | null
  optIn: boolean
  showMessageInputOverride: boolean
  loading: boolean
}

function initialState(): State {
  return {
    activity: null,
    subscriptionId: null,
    message: null,
    images: null,
    optIn: false,
    showMessageInputOverride: false,
    loading: false,
  }
}

const route = useRoute()
const router = useRouter()
const vfm = useVfm()
const axios = useAxios()
const tenant = useTenant()
const orders = useOrders()
const { tm } = useI18n()

const contactActivities = tenant.contactConfig.formContactActivities.filter(
  (activity) => {
    return (
      activity.visibility === ContactActivityVisibility.All ||
      activity.visibility === ContactActivityVisibility.Authenticated
    );
  }
);

const state = reactive(Object.assign(initialState()));

const {
  activity,
  subscriptionId,
  message,
  images,
  optIn,
  showMessageInputOverride,
  loading,
} = toRefs(state)

const form = ref<HTMLFormElement | null>(null)

const subscriptions = computed(() => orders.subscriptions)
const subscriptionsLoading = computed(() => orders.loading)
const requiresSubscription = computed(() => {
  return activity.value !== null && activity.value.requiresSubscription;
})

const showInfoMessage = computed(() => {
  return (
    activity.value !== null &&
    activity.value.infoMessage != null &&
    ((requiresSubscription.value && subscriptionId.value !== null) ||
      !requiresSubscription.value)
  );
})

const subscription = computed(() => {
  if (!subscriptionId.value) {
    return null
  }
  return orders.getSubscriptionById(subscriptionId.value);
})

const linkedPage = computed(() => {
  if (activity.value === null) {
    return null;
  }
  if (subscription.value == null || subscription.value.isManagedExternally()) {
    return null;
  }
  return activity.value.linkedPage;
})

const showMessageInput = computed(() => {
  return (activity.value !== null && (!activity.value.requiresSubscription || subscription.value !== null)) && (showMessageInputOverride.value || linkedPage.value === null);
})

const showImageUpload = computed(() => {
  return (
    activity.value !== null &&
    activity.value.linkedPage == null &&
    activity.value.supportsImageUpload
  );
})

const showOptInCheckbox = computed(() => {
  return (
    tenant.supportsOptIns() &&
    requiresSubscription.value &&
    subscription.value !== null &&
    showMessageInput.value
  )
})

const showMessageInputOverrideButton = computed(() => {
  return linkedPage.value !== null && !activity.value!.disableMessageInput && !showMessageInputOverride.value
})

const optInCheckboxLabel = computed(() => {
  return pupa(tenant.contactConfig.optInCheckboxLabelTemplate, {
    publication: subscription.value!.publicationName,
  });
})

const formattedInfoMessage = computed(() => {
  const phoneNumber = tenant.getContactPhoneNumber(
    subscription.value?.publication
  );
  const email = tenant.getContactEmail(subscription.value?.publication);
  return formatInfoMessage(activity.value!.infoMessage!, phoneNumber, email);
})

function formatSubscriptionName(subscription: Subscription) {
  const statusLabel = (tm(`subscription-detail.order-status`) as any)[subscription.orderStatus];
  return `${subscription.displayName} (${subscription.orderNumber}) - ${statusLabel}`
}

function clearForm() {
  (form.value as HTMLFormElement).reset();
  Object.assign(state, initialState())
}

async function submit() {
  if (linkedPage.value && !showMessageInputOverride.value) {
    // navigate to the linked page
    router.push({
      name: linkedPage.value,
      params: {
        id: subscriptionId.value,
      },
      query: {
        returnTo: "contact",
      },
    });
    return;
  }
  // submit the contact request
  try {
    loading.value = true;
    const payload = {
      activity: activity.value,
      subscriptionId: subscriptionId.value,
      message: message.value,
      image: null as any,
      optIn: optIn.value
    };

    if (images.value && images.value.length) {
      const image = images.value[0];
      payload.image = {
        filename: image.name,
        mimeType: image.type,
        data: await fileToBase64(images.value[0]),
      };
    }

    await axios.post("/contact", payload);
    vfm.show("success");
    clearForm();
  } catch (e) {
    vfm.show("error");
  } finally {
    loading.value = false;
  }
}


if (route.query.subscriptionId) {
  subscriptionId.value = route.query.subscriptionId as string
}

if (route.query.showMessageInput) {
  showMessageInputOverride.value = true;
}
if (route.query.linkedPage) {
  activity.value = contactActivities.find(
    (a) => a.linkedPage === route.query.linkedPage
  ) ?? null;
}
if (route.query.activity) {
  activity.value = contactActivities.find(
    (a) => a.activity === route.query.activity
  ) ?? null;
}

watch(activity, () => {
  showMessageInputOverride.value = false
})

orders.fetch()

</script>
