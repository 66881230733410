<template>
  <div>
    <BackButton :subscription="subscription" />
    <div class="py-4 border-t mt-4">
      <PageTitle name="payment-details"></PageTitle>
      <p class="mt-4">
        {{ $t("subscription-payment-details.heading") }}
      </p>
    </div>
    <Spinner v-if="loading" />
    <div v-else-if="paymentDetails">
      <div v-if="subscription.hasActiveAutoRenewal()" class="mb-4">
        <h4 class="font-semibold">
          {{ $t("subscription-payment-details.next-payment") }}
        </h4>
        <p class="mt-2">
          {{ $t("subscription-payment-details.next-payment-amount") }}:
          {{
            $n(
              subscription.renewal.autoRenewalNextPaymentAmount,
              subscription.renewal.orderCurrency
            )
          }}
        </p>
        <p v-if="subscription.renewal.autoRenewalNextPaymentDate">
          {{ $t("subscription-payment-details.next-payment-date") }}:
          {{ $d(subscription.renewal.autoRenewalNextPaymentDate) }}
        </p>
      </div>
      <h4 class="font-semibold">
        {{ $t("subscription-payment-details.active-method") }}
      </h4>
      <component
        v-if="hasActivePaymentMethod"
        class="mt-2"
        :is="getPaymentMethodUpdater(paymentDetails.paymentMethod)"
        :subscription="subscription"
        :payment-details="paymentDetails"
        :disabled="!canUpdateCurrentPaymentMethod"
        active
        @success="showSuccessMessage"
        @error="showErrorMessage"
      />
      <alert v-else class="my-4 text-gray-700">
        {{ $t("subscription-payment-details.no-active-method") }}
      </alert>
      <h4 class="font-semibold mt-4">
        {{ $t("subscription-payment-details.inactive-methods") }}
      </h4>
      <ul class="mt-2" v-if="allowedChangeDirections.length">
        <li class="mb-2" v-for="cd in allowedChangeDirections" :key="cd.to">
          <component
            :is="getPaymentMethodUpdater(cd.to)"
            :subscription="subscription"
            :payment-details="paymentDetails"
            @success="showSuccessMessage"
            @error="showErrorMessage"
          />
        </li>
      </ul>
      <Alert v-else class="my-4 text-gray-700">
        {{ $t("subscription-payment-details.no-change-possible") }}
      </Alert>
    </div>
    <AlertDialog name="success" :negative-text="$t('app.close')">
      {{ $t("subscription-payment-details.success-message") }}
    </AlertDialog>
    <AlertDialog name="error" :negative-text="$t('app.close')">
      {{ $t("app.error") }}
    </AlertDialog>
    <AlertDialog
      name="loading-error"
      :negative-text="$t('app.back')"
      @negative="$router.back()"
    >
      {{ $t("app.error") }}
    </AlertDialog>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineComponent, ref } from 'vue'
import Alert from "@/components/Alert.vue";
import Spinner from "@/components/Spinner.vue";
import PageTitle from "@/components/PageTitle.vue";
import BackButton from "@/components/BackButton.vue";
import { PaymentDetails, PaymentMethod } from "@/models/paymentDetails";
import SepaUpdater from "@/pages/subscription/payment-details/SepaUpdater.vue";
import CreditCardUpdater from "@/pages/subscription/payment-details/CreditCardUpdater.vue";
import DirectDebitUpdater from "@/pages/subscription/payment-details/DirectDebitUpdater.vue";
import PaypalUpdater from "@/pages/subscription/payment-details/PaypalUpdater.vue";
import InvoiceUpdater from "@/pages/subscription/payment-details/InvoiceUpdater.vue";
import { PaymentMethodChangeDirection } from "@/models/paymentMethodChangeConfig";
import AlertDialog from "@/components/AlertDialog.vue";
import { useRoute } from "vue-router";
import { useOrders, useSubscription } from "@/pinia/orders";
import { useVfm } from "@/plugins/vfm";
import axios from "axios";
import { useTenant } from "@/plugins/tenant";
import { RenewalSubscription } from '@/models/subscription';


const updaters: Record<PaymentMethod, any> = {
  [PaymentMethod.NONE]: null,
  [PaymentMethod.SEPA]: SepaUpdater,
  [PaymentMethod.CREDIT_CARD]: CreditCardUpdater,
  [PaymentMethod.DIRECT_DEBIT]: DirectDebitUpdater,
  [PaymentMethod.PAYPAL]: PaypalUpdater,
  [PaymentMethod.INVOICE]: InvoiceUpdater,
};

const route = useRoute()
const vfm = useVfm()
const tenant = useTenant()
const orders = useOrders()

const subscriptionId = route.params.id as string

const loading = ref(false)
const paymentDetails = ref<PaymentDetails | null>(null)
const paymentDetailsLoading = ref(false)

const subscription = computed(() => orders.getSubscriptionById(subscriptionId) as RenewalSubscription)
const hasActivePaymentMethod = computed(() => paymentDetails.value !== null && paymentDetails.value!.paymentMethod !== PaymentMethod.NONE)

const allowedChangeDirections = computed(() => {
  // get all allowed targets, excluding the current payment
  const currentPaymentMethod = paymentDetails.value!.paymentMethod;
  return tenant.paymentMethodChangeConfig.allowedDirections.filter(
    (direction: PaymentMethodChangeDirection) =>
      direction.from === currentPaymentMethod &&
      direction.to !== currentPaymentMethod
  );
})

const canUpdateCurrentPaymentMethod = computed(() => {
  const currentPaymentMethod = paymentDetails.value!.paymentMethod;
  return tenant.paymentMethodChangeConfig.allowedDirections.some(
    (direction: PaymentMethodChangeDirection) =>
      direction.from === currentPaymentMethod &&
      direction.to === currentPaymentMethod
  );
})

function getPaymentMethodUpdater(paymentMethod: PaymentMethod): any {
  return updaters[paymentMethod];
}

function showErrorMessage() {
  vfm.show("error");
}

function showSuccessMessage() {
  vfm.show("success");
}

async function fetchPaymentDetails() {
  try {
    loading.value = true;
    await orders.fetchSubscription(subscriptionId)
    const response = await axios.get<PaymentDetails>(
      `/subscriptions/${subscriptionId}/payment-details`
    );
    paymentDetails.value = response.data
  } catch (error) {
    vfm.show("loading-error");
  } finally {
    loading.value = false;
  }
}

fetchPaymentDetails()
</script>
