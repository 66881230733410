import { acceptHMRUpdate, defineStore } from "pinia";
import axios from "axios";

export interface OptInsState {
  pendingConfirmations: Set<string>;
}

export const useOptIns = defineStore('publications', {
  state: (): OptInsState => ({
    pendingConfirmations: new Set(),
  }),
  actions: {
    async update(subscriptionId: string, bpNumber: string, optIns: OptIn[]) {
      await axios.post(
        `/subscriptions/${subscriptionId}/business-partners/${bpNumber}/opt-ins`,
        {
          optIns: optIns,
        }
      );
      this.pendingConfirmations.add(subscriptionId)
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOptIns, import.meta.hot))
}
