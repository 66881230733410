<template>
  <div>
    <div class="lg:hidden">
      <button
        @click="isOpen = !isOpen"
        type="button"
        class="block w-full p-4 text-left text-gray-800 focus:outline-none"
        data-testid="navbar-hamburger-btn"
      >
        <font-awesome-icon
          :icon="isOpen ? 'times' : 'bars'"
          class="mr-2"
          size="lg"
          fixed-width
        />
        {{ menuLabel }}
      </button>
    </div>
    <nav :class="isOpen ? 'flex' : 'hidden'" class="flex-col lg:flex">
      <NavbarLink
        to="/orders"
        @click.native="isOpen = false"
        data-testid="navbar-orders"
      >{{ $t("navbar.orders")
        }}<transition name="fade"
        ><Badge
          v-if="newSubscriptionAvailable"
          color="green"
          border
          dot
          data-testid="navbar-new-subscription-badge"
        >{{ $t("navbar.new") }}</Badge
        ></transition
        >
      </NavbarLink>
      <NavbarLink
        v-if="$tenant.supportsIssueCalendar()"
        to="/issue-calendar"
        @click.native="isOpen = false"
        data-testid="navbar-issue-calendar"
      >{{ $t("navbar.issue-calendar") }}</NavbarLink
      >
      <NavbarLink
        v-if="$tenant.supportsManualRegistration()"
        to="/link-subscription"
        @click.native="isOpen = false"
        data-testid="navbar-link-subscription"
      >{{ $t("navbar.link-subscription") }}</NavbarLink
      >
      <NavbarLink
        v-if="$tenant.supportsMembership()"
        to="/memberships"
        @click.native="isOpen = false"
        data-testid="navbar-membership"
      >{{ $t("navbar.memberships") }}</NavbarLink
      >
      <NavbarLink
        to="/business-partners"
        @click.native="isOpen = false"
        data-testid="addresses"
      >{{ $t("navbar.business-partners") }}</NavbarLink
      >
      <NavbarLink
        v-if="$tenant.supportsFrequentlyAskedQuestions()"
        to="/faq"
        @click.native="isOpen = false"
        data-testid="navbar-faq"
      >{{ $t("navbar.faq") }}</NavbarLink
      >
      <NavbarLink
        v-if="$tenant.supportsContactForm()"
        to="/contact"
        @click.native="isOpen = false"
        data-testid="navbar-contact"
      >{{ $t("navbar.contact") }}</NavbarLink
      >
      <template v-if="$tenant.supportsExternalNavigation()">
        <NavbarLink
          v-for="link in $tenant.externalNavigationLinks"
          :key="link.url"
          :to="link.url"
          :open-in-new-tab="link.openInNewTab"
          external
        >
          {{ link.text }}
        </NavbarLink>
      </template>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import NavbarLink from "@/components/NavbarLink.vue";
import Badge from "@/components/Badge.vue";
import { useOrders } from "@/pinia/orders";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const route = useRoute()
const {t,te} = useI18n()
const orders = useOrders()

const isOpen = ref(false)

const newSubscriptionAvailable = computed(() => orders.newSubscriptionAvailable);
const menuLabel = computed(() => {
  const translationKey = `navbar.${route.name as string}`;
  return te(translationKey)
    ? t(translationKey)
    : t("app.menu");
})
</script>
