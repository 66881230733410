<template>
  <button @click="changeLocale" class="w-6 duration-100 border hover:shadow">
    <img
      v-if="locale === 'de-DE'"
      class="flag"
      src="../assets/flag-de.svg"
      alt="Deutsch"
    />
    <img
      v-if="locale === 'en-GB'"
      src="../assets/flag-en.svg"
      alt="English"
    />
  </button>
</template>
<script lang="ts" setup>
import { useI18n } from "vue-i18n";

const { locale } = useI18n()

function changeLocale() {
  if (locale.value === "de-DE") {
    locale.value = "en-GB";
  } else {
    locale.value = "de-DE";
  }
}
</script>
